import { Col, Row, Spin } from 'antd';
import img1 from './singleReport1.png';
import img2 from './singleReport2.png';
import img3 from './singleReport3.png';
import './reports.css';
import SingleReport from './SingleReport';
import i18n from 'i18next';
import { useQuery } from 'react-query';
import { getPublications } from 'api/publication';
import { getCurrentLng } from 'utils/helper';
import I18nLink from 'common/components/I18nLink';
import useWindowDimensions from 'common/hooks/useWindowDimensions';

const Reports = () => {
  const { width } = useWindowDimensions();
  const lang = getCurrentLng();
  const { data: publications, isLoading, isError } = useQuery('publications', getPublications);

  if (isLoading) {
    return (
      <section className="reports-message">
        <Spin size="large" />
        <p style={{ fontWeight: 600 }}>{i18n.t('_COMMON._LOADING')}</p>
      </section>
    );
  }

  if (isError) {
    return (
      <section className="reports-message">
        <p>{i18n.t('_COMMON._ERROR')}</p>
      </section>
    );
  }

  return (
    <div className="home-reports-wrapper">
      <div className="reports-header">
        <div className="explore-ind-test report">
          <span>{i18n.t('_HOME._REPORTS._REPORTS')}</span>
        </div>
        <p>{i18n.t('_HOME._REPORTS._SUBTITLE')}</p>
      </div>

      <section className="reports-section-wrapper" style={{ rowGap: '40px' }}>
        {publications?.slice(0, 5).map((publication) => {
          return (
            <SingleReport
              style={{
                maxWidth: width > 1000 ? 400 : width > 700 ? 400 : 450,
              }}
              key={publication.id}
              image={publication.thumbnail}
              title={publication[`name${lang}`]}
              author={publication.author}
              href={publication.file || ''}
              date={publication.date}
            />
          );
        })}

        {!publications?.length && <p className="reports-message">{i18n.t('_COMMON._NO_DATA')}</p>}
      </section>

      <div className="report-view-btn" style={{ paddingRight: width > 700 ? '30px' : '5px' }}>
        <I18nLink to={'/reports'}>{i18n.t('_COMMON._VIEW_ALL')}</I18nLink>
      </div>
    </div>
  );
};

export default Reports;
