import * as React from 'react';
import { useFormik } from 'formik';
import Input from 'common/components/Input/Input';
import { validationSchema, translationBasePath } from './MFAFormValidation';
import Button from 'common/components/Button/Button';
import { authenticateSms, resendCode } from 'api/auth';
import { setAuthData } from 'utils/tokenMgmt';
import Form from 'common/components/Form/Form';
import { Label } from 'common/components/Input/styled';
import { notification, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { AuthFormContainer } from 'common/components/Form/styled';

interface IFormInitialValues {
  code: string;
}

interface MFAFormProps {
  history: {
    push: (path: string) => void;
  };
  match: {
    params: {
      token: string;
    };
  };
}

export const MFAForm: React.FunctionComponent<MFAFormProps> = (props: MFAFormProps) => {
  const initialValues: IFormInitialValues = { code: '' };
  const { t } = useTranslation();
  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema(t),
    onSubmit: (values) => {
      authenticateSms(values.code, props.match.params.token)
        .then((res) => {
          setAuthData(res.data.accessToken);
          props.history.push('/');
        })
        .catch((error) => {
          notification.error({ message: t('_LOGIN._WRONG_CODE') });
        });
    },
  });
  return (
    <AuthFormContainer>
      <div className="form-wrapper">
        <Form onSubmit={formik.handleSubmit}>
          <Input
            name="code"
            label={t(`${translationBasePath}._CODE`)}
            value={formik.values.code}
            onChange={formik.handleChange}
            error={formik.errors.code}
          />
          <Row>
            <Label>{t(`${translationBasePath}._DIDNT_RECEIVE_A_CODE`)} </Label>
            <a style={{ paddingLeft: '.5em' }} onClick={() => resendCode(props.match.params.token)}>
              {t(`${translationBasePath}._RESEND_CODE`)}
            </a>
          </Row>
          <Button htmlType="submit">{t(`${translationBasePath}._SUBMIT`)}</Button>
        </Form>
      </div>
    </AuthFormContainer>
  );
};
