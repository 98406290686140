import React, { useContext, useState, useRef, useEffect, memo } from 'react';
import './briefs.css';
import { notification, Select } from 'antd';
import { getIndicators } from 'api/indicator';
import { useQuery, useMutation, QueryClient, useQueryClient } from 'react-query';
import { getCurrentLng } from 'utils/helper';
import { Indicator } from 'models/indicator';
import { BriefContext } from './BriefContext';
import { BriefPayload } from 'models/briefs';
import { createBrief } from 'api/public';
import i18n from 'i18next';
const { Option } = Select;

const AddBrief = ({ category }) => {
  const queryClient = useQueryClient();

  //payload for post request
  const [payload, setPayload] = useState<BriefPayload>();
  const { data } = useQuery('getIndicators', getIndicators);
  const lang = getCurrentLng();
  //select ref
  const ref = useRef(null as any);
  const { setAddedBriefs, addedBriefs, setLoading } = useContext(BriefContext);
  //default selected value for select
  const [selectedValue, setSelectedValue] = useState(data ? (data[0] ? data[0][`name${lang}`] : null) : null);

  //post request
  const { mutate: postBrief, isLoading } = useMutation(createBrief, {
    onError: (e) => {
      setLoading(false);
      return notification['error']({
        message: i18n.t('_BRIEFS._ERROR_CREATING_BRIEF'),
        description: <p style={{ color: 'red', fontWeight: 500, fontSize: '18px' }}> {i18n.t('_COMMON._ERROR')}</p>,
        duration: 3,
      });
    },
    onSuccess: async (data) => {
      await queryClient.refetchQueries('allbriefs');
      notification['success']({
        message: i18n.t('_BRIEFS._SUCCESS'),
        description: (
          <p style={{ color: 'green', fontSize: '18px', fontWeight: 500 }}> {i18n.t('_BRIEFS._SUCCESS_MESSAGE')}</p>
        ),
        duration: 3,
      });
      setAddedBriefs([]);
      setLoading(false);
    },
  });

  //handle submit->create a brief

  const handleSubmit = () => {
    if (payload && payload.briefIndicators.length > 0) {
      postBrief(payload);
    } else {
      notification['error']({
        message: i18n.t('_BRIEFS._ERROR_CREATING_BRIEF'),
        description: i18n.t('_BRIEFS._INDICATOR_BRIEF_MISSING'),
        duration: 3,
      });
    }
    window.scroll(0, 0);
  };

  //add a new brief on Select change
  const handleAddBrief = (indicator: Indicator) => {
    // setSelectedValue(JSON.stringify(indicator));

    if (indicator) {
      setAddedBriefs((addedBriefs) => {
        return [
          ...addedBriefs,
          {
            indicator: indicator[`name${lang}`],
            indicatorId: indicator.id || 0,
            graph_image: indicator.graph_image,
            storyline: '',
          },
        ];
      });
    } else {
      return;
    }
    //select component
    ref.current.blur();
    setSelectedValue(null);
  };

  //change the payload as indicators briefs change
  useEffect(() => {
    const briefs = addedBriefs.map((brief) => {
      return {
        indicatorId: brief.indicatorId,
        storyline: brief.storyline,
      };
    });
    const payloadState = {
      nameEN: JSON.parse(JSON.stringify(category))?.nameEN || '',
      nameSQ: JSON.parse(JSON.stringify(category))?.nameSQ || '',
      briefIndicators: briefs,
    };
    setPayload(payloadState);
  }, [addedBriefs]);

  //change the UI of parent, via context
  useEffect(() => {
    if (isLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [isLoading]);

  return (
    <>
      <div className="brief-wrapper" style={{ padding: 20, paddingRight: 0 }}>
        <div className="add-brief-indicator">
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <p style={{ fontWeight: 600 }}>{i18n.t('_BRIEFS._ADD_NEW_INDICATOR')}</p>
            <Select
              ref={ref}
              defaultValue={selectedValue}
              onChange={(e) => handleAddBrief(JSON.parse(String(e)))}
              value={selectedValue}
              style={{ width: '250px', cursor: 'pointer' }}
              showSearch
              placeholder={i18n.t('_BRIEFS._ADD_NEW_INDICATOR')}
              optionFilterProp="children"
              filterOption={(input, option) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
              {data?.map((indicator) => {
                const isSelected = addedBriefs.find((brief) => brief.indicatorId === indicator.id);
                if (isSelected) return;
                return (
                  <Option key={indicator[`key`]} value={JSON.stringify(indicator)}>
                    {indicator[`name${lang}`]}
                  </Option>
                );
              })}
            </Select>
          </div>
        </div>
        <div
          className="report-view-btn"
          style={{ width: '100%', padding: '15px', paddingBottom: '5px', textAlign: 'end', paddingRight: '5px' }}>
          <a onClick={() => handleSubmit()}>{i18n.t('_BRIEFS._SUBMIT')}</a>
        </div>
      </div>
    </>
  );
};

export default memo(AddBrief);
