import React, { useEffect, useState } from 'react';
import AddBrief from './AddBrief';
import AddBriefComponent from './BriefComponent';
import { AddedBriefModel, BriefContext } from './BriefContext';
import BriefSubheader from './BriefSubheader';
import { Select, Spin } from 'antd';
import bufferToImage from 'utils/bufferToImage';
import { useQuery } from 'react-query';
import { getModules } from 'api/public';
import { getCurrentLng } from 'utils/helper';
import i18 from 'i18next';
const { Option } = Select;

const Index = () => {
  const lang = getCurrentLng();

  const {
    data: modules,
    isError,
    isLoading: moduleLoading,
  } = useQuery('modules', getModules, {
    refetchOnWindowFocus: false,
  });
  const [selectedValue, setSelectedValue] = useState<undefined | string>();
  const [addedBriefs, setAddedBriefs] = useState<AddedBriefModel[]>([]);
  const [loading, setLoading] = useState(false);

  const context = {
    addedBriefs: addedBriefs,
    setAddedBriefs: setAddedBriefs,
    setLoading: setLoading,
    loading: loading,
  };

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const currentDate = () => {
    const monthsEN = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    const monthsSQ = [
      'Janar',
      'Shkurt',
      'Mars',
      'Prill',
      'Maj',
      'Qershor',
      'Korrik',
      'Gusht',
      'Shtator',
      'Tetor',
      'Nentor',
      'Dhjetor',
    ];

    const date = new Date();
    const [month, day, year] = [date.getMonth(), date.getDate(), date.getFullYear()];

    if (lang === 'EN') {
      return `${day} ${monthsEN[month]} ${year}`;
    } else {
      return `${day} ${monthsSQ[month]} ${year}`;
    }
  };

  if (moduleLoading) {
    return (
      <div style={{ height: '300px', display: 'grid', placeContent: 'center' }}>
        <Spin size="large" />
        <p>{i18.t('_BRIEFS._MODULE_LOADING')}</p>
      </div>
    );
  }
  if (isError) {
    return <p style={{ height: '300px', display: 'grid', placeContent: 'center' }}>{i18.t('_COMMON._ERROR')}</p>;
  }
  if (!modules?.length) {
    return <h3 style={{ height: '300px', display: 'grid', placeContent: 'center' }}>{i18.t('_BRIEFS._NO_MODULES')}</h3>;
  }
  //loading-from context
  if (loading) {
    return (
      <div className="adding-brief" style={{ display: 'grid', placeContent: 'center', fontSize: '20px' }}>
        <Spin size="large" />
        <p>{i18.t('_BRIEFS._CREATING_BRIEFS')}</p>
      </div>
    );
  }

  return (
    <BriefContext.Provider value={context}>
      <BriefSubheader />
      <div className="brief-wrapper-container">
        <div className="brief-header">
          <div className="info-briefs">
            <div className="brief-info">
              <div>
                <span>{i18.t('_BRIEFS._THEME')}</span>
                {modules && (
                  <span className="span-category">
                    <Select
                      className="sel-brief"
                      style={{ width: '150px' }}
                      placeholder="Select a module"
                      defaultValue={selectedValue ? selectedValue[`name${lang}`] : modules[0][`name${lang}`]}
                      value={JSON.stringify(selectedValue)}
                      onSelect={(e) => {
                        setSelectedValue(JSON.parse(e));
                      }}>
                      {modules.map((theme) => {
                        return (
                          <Option key={theme[`name${lang}`]} value={JSON.stringify(theme)}>
                            {theme[`name${lang}`]}
                          </Option>
                        );
                      })}
                    </Select>
                  </span>
                )}
              </div>

              <div style={{ textAlign: 'center' }}>
                <span>{i18.t('_BRIEFS._FREQUENCY')}</span>
                <span>{i18.t('_BRIEFS._YEARLY')}</span>
              </div>
              <div style={{ textAlign: 'center' }}>
                <span>{i18.t('_BRIEFS._DATE_GENERATED')}</span>
                <span>{currentDate()}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="brief-category">{selectedValue ? selectedValue[`name${lang}`] : modules[0][`name${lang}`]}</div>
        <div className="briefs-list">
          {addedBriefs.length === 0 && <h2 className="no-briefs">{i18.t('_BRIEFS._NO_BRIEFS')}</h2>}
          {addedBriefs?.map((brief) => {
            return (
              <AddBriefComponent
                id={brief.indicatorId}
                indicator={brief.indicator}
                image={bufferToImage(brief.graph_image?.data)}
                key={brief.indicatorId}
              />
            );
          })}
        </div>
        <AddBrief category={selectedValue ? selectedValue : modules?.[0]} />
      </div>
    </BriefContext.Provider>
  );
};

export default Index;
