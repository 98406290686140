import { getThemes } from 'api/public';
import ThemeCard from 'common/components/ThemeCard/ThemeCard';
import { Spin } from 'antd';
import { useQuery } from 'react-query';
import i18n from 'i18next';
import I18nLink from 'common/components/I18nLink';
import { useTranslation } from 'react-i18next';
import { StrategyTheme } from './StrategyTheme';
import { getCurrentLng } from 'utils/helper';
import { sortThemes } from 'helpers/themes';
import './themesection.css';

const ThemesSection = () => {
  const currLang = getCurrentLng();
  const { data, isLoading, isError } = useQuery('themes', getThemes);
  const { t } = useTranslation();
  const sortedThemes = sortThemes(data?.filter((theme) => theme.key !== 'ness') || []);

  sortedThemes.splice(1, 0, StrategyTheme);

  const sfTheme = data?.filter((theme) => theme.key === 'sf');

  if (isError) {
    return <div className="theme-error">{i18n.t('_COMMON._ERROR')}</div>;
  }

  if (isLoading) {
    return (
      <div className="themes-spinner">
        <Spin delay={200} size="large" />
        <h4 style={{ paddingTop: '20px', fontWeight: 600 }}> {i18n.t('_HOME._LOADING')}</h4>
      </div>
    );
  }

  return (
    <div className="theme-padding-wrapper">
      <div className="explore-ind-test theme">
        <span>{t('_HOME._THEMES')}</span>
      </div>
      <div className="theme-section-wrapper desktop">
        {sortedThemes?.slice(0, 3).map((theme, index) => {
          return (
            <ThemeCard
              key={theme.key}
              image={theme.image}
              path={index === 1 ? '/strategies' : `/themes/${theme.key}`}
              acronym={theme[`acronym${currLang}`]}
              name={theme[`name${currLang}`]}
            />
          );
        })}
        {sfTheme && sfTheme[0] && (
          <ThemeCard
            image={sfTheme[0].image}
            key={sfTheme[0].key}
            path={`/themes/${sfTheme[0].key}`}
            acronym={sfTheme[0][`acronym${currLang}`]}
            name={sfTheme[0][`name${currLang}`]}
          />
        )}
      </div>
      <div className="report-view-btn theme-view-btn">
        <I18nLink to="/themes" style={{ fontSize: '16px' }}>
          {i18n.t('_HOME._VIEW_ALL_THEMES')}
        </I18nLink>
      </div>
    </div>
  );
};

export default ThemesSection;
