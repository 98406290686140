import { AxiosResponse } from 'axios';
import { apiUrl, post, get } from 'utils/apiMgmt';

export const forgotPassword = (email: string): Promise<AxiosResponse<any, any>> => {
  return post(apiUrl + '/auth/forgot-password', { email });
};

export const resetPassword = (password: string, token: string): Promise<AxiosResponse<any, any>> => {
  return post(apiUrl + '/auth/reset-password', { password, token });
};

export const login = (email: string, password: string): Promise<AxiosResponse<any, any>> => {
  return post(apiUrl + '/auth/login', { password, email });
};

export const authenticateSms = (code: string, token: string): Promise<AxiosResponse<any, any>> => {
  return post(apiUrl + '/auth/sms-authentication', { code }, { Authorization: `Bearer ${token}` });
};

export const resendCode = (token): Promise<AxiosResponse<any, any>> => {
  return get(apiUrl + '/auth/resend-code', {}, { Authorization: `Bearer ${token}` });
};
