import { useState } from 'react';
import { Select, Spin } from 'antd';
import { useQuery } from 'react-query';
import { getModules, getSingleTheme } from 'api/public';
import i18next from 'i18next';
import GlanceData from './components/GlanceData';
import ThemeSubheader from 'pages/Theme/IntegrationTheme/components/ThemeSubheader';
import { getCurrentLng } from 'utils/helper';
import CsvDownload from 'react-json-to-csv';
import { generateDataForCSV } from './helpers/generateDataForCSV';
import download from 'pages/Explorer/components/Indicator/components/icons/download.svg';
import './style.css';

const themeKey = 'employment_at_a_glance';
const { Option } = Select;

const index = () => {
  const {
    data: modules,
    isError,
    isLoading,
  } = useQuery('modules', getModules, {
    refetchOnWindowFocus: false,
  });
  const [csvData, setCsvData] = useState(null) as any;
  const {
    data: employmentAtAGlance,
    isLoading: isLoadingTheme,
    isError: isErrorTheme,
  } = useQuery(['singleTheme', themeKey], () => getSingleTheme(themeKey), {
    refetchOnWindowFocus: false,
  });

  const [selectedValue, setSelectedValue] = useState<string>(modules?.[0]?.nameEN || '');

  const lng = getCurrentLng();
  const selectOptions = [
    {
      value: '2010',
    },
    {
      value: '2011',
    },
    {
      value: '2012',
    },
    {
      value: '2013',
    },
    {
      value: '2014',
    },
    {
      value: '2015',
    },
    {
      value: '2016',
    },
    {
      value: '2017',
    },
    {
      value: '2018',
    },
    {
      value: '2019',
    },
    {
      value: '2020',
    },
    {
      value: '2021',
    },
    {
      value: '2022',
    },
  ];

  const [currentYear, setCurrentYear] = useState('2022');
  const [baselineYear, setBaselineYear] = useState('2018');

  const csvButtonStyles = {
    border: 'none',
    color: '#27323e',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    gap: 10,
    padding: 6,
    fontWeight: 600,
  };

  if (isLoading || isLoadingTheme) {
    return (
      <div style={{ height: '300px', display: 'grid', placeContent: 'center' }}>
        <Spin size="large" />
        {i18next.t('_COMMON._LOADING')}
      </div>
    );
  }

  if (isError || isErrorTheme || !employmentAtAGlance) {
    return <p style={{ height: '200px', fontSize: '20px', textAlign: 'center' }}> {i18next.t('_COMMON._ERROR')}</p>;
  }

  return (
    <div className="glance-wrapper">
      <ThemeSubheader themeKey={employmentAtAGlance.key} title={employmentAtAGlance[`name${lng}`]} description={''} />
      <div className="glance-info">
        <div>
          <div className="theme-category">
            <span>{i18next.t('_EMPLOYMENT_GLANCE._THEME')}</span>
            <span>
              {modules && (
                <span className="span-category">
                  <Select
                    style={{ width: '120px' }}
                    defaultValue={modules[0]?.nameEN}
                    onSelect={(e) => {
                      setSelectedValue(e);
                    }}>
                    {modules.map((theme, i) => {
                      return <Option value={theme?.nameEN || i}>{theme?.[`name${lng}`]}</Option>;
                    })}
                  </Select>
                </span>
              )}
            </span>
          </div>
          <div className="comparison-dates">
            <div>
              <span>{i18next.t('_EMPLOYMENT_GLANCE._BASELINE')}</span>
              <span>
                <Select
                  onSelect={(val) => setBaselineYear(val)}
                  defaultValue={baselineYear}
                  style={{ height: '30px', padding: '0px', color: '#27323e' }}>
                  {selectOptions.map((option) => {
                    return (
                      <Option key={option.value} value={option.value}>
                        {option.value}
                      </Option>
                    );
                  })}
                </Select>
              </span>
            </div>
            <div>
              <span>{i18next.t('_EMPLOYMENT_GLANCE._COMPARE')}</span>
              <Select
                onSelect={(val) => setCurrentYear(val)}
                defaultValue={currentYear}
                style={{ height: '30px', padding: '0px', color: '#27323e' }}>
                {selectOptions.map((option) => {
                  return (
                    <Option key={option.value} value={option.value}>
                      {option.value}
                    </Option>
                  );
                })}
              </Select>
            </div>
          </div>
        </div>
        {csvData && (
          <div>
            <CsvDownload
              className="csv-down-glance"
              style={csvButtonStyles}
              data={generateDataForCSV(csvData, selectedValue || '')}
              filename="Employment-at-a-glance.csv">
              <span>{i18next.t('_COMMON._DOWNLOAD')}</span>
              <img src={download} />
            </CsvDownload>
          </div>
        )}
      </div>
      <GlanceData setCsvData={setCsvData} currentYear={currentYear} baselineYear={baselineYear} />
    </div>
  );
};

export default index;
