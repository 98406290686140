import { Col, Menu, Row } from 'antd';
import { Link } from 'react-router-dom';
import ChangePassword from './ChangePassword';
import AccountDetails from './AccountDetails';
import { useTranslationTerm } from 'utils/helper';
import { term } from './common';
import I18nLink from 'common/components/I18nLink';
import useWindowDimensions from 'common/hooks/useWindowDimensions';

enum Sections {
  accountDetails = 'account-details',
  changePassword = 'change-password',
}

export default ({ menuItem }: { menuItem: string }) => {
  const { width } = useWindowDimensions();

  const CurrentComponent = () => {
    switch (menuItem) {
      case Sections.accountDetails:
        return <AccountDetails />;
      case Sections.changePassword:
        return <ChangePassword />;
      default:
        return <AccountDetails />;
    }
  };
  return (
    <div style={{ padding: width > 700 ? '0 50px' : '0 1.5rem' }}>
      <h1
        style={{
          marginTop: '2em',
          marginBottom: '2em',
          paddingBottom: '.5em',
          borderBottom: '3px solid #ebebeb',
          fontWeight: 600,
          fontSize: '19px',
        }}>
        {term('_title')}
      </h1>
      <Row gutter={width > 700 ? [0, 0] : [20, 20]}>
        <Col md={5} sm={8} xs={16} span={5} style={{ paddingRight: '1em' }}>
          <Menu
            selectedKeys={[menuItem || Sections.accountDetails]}
            style={{ border: 'none', fontWeight: 600, fontSize: '16px' }}>
            <Menu.Item key="account-details">
              <I18nLink to="/dashboard/manage-account">{term('_account_details._title')}</I18nLink>
            </Menu.Item>
            <Menu.Item key="change-password">
              <I18nLink to="/dashboard/manage-account/change-password">{term('_change_password._title')}</I18nLink>
            </Menu.Item>
          </Menu>
        </Col>
        <Col md={19} xs={24} span={19}>
          <CurrentComponent />
        </Col>
      </Row>
    </div>
  );
};
