import * as React from 'react';
import Input from 'common/components/Input/Input';
import Button from 'common/components/Button/Button';
import { createIndicator, updateIndicator } from 'api/indicator';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { validationSchema, translationBasePath } from './IndicatorFormValidation';
import { useMutation, useQuery } from 'react-query';
import { Col, message, notification, Radio, Row, Space, Spin } from 'antd';
import AntForm from 'antd/lib/form';
import Form from 'common/components/Form/Form';
import Select from 'common/components/Select/Select';
import { getCurrentLng } from 'utils/helper';
import Submodule from 'models/submodule';
import { AnalyticalChange, Indicator, Frequency } from 'models/indicator';
import { generateUnitTypeOptions, getSelectOptions } from './helpers';
import { getIndicatorFilters } from 'api/public';

interface IFormPublication {
  onClose: () => void;
  refetch: () => void;
  indicator?: Indicator;
  submodules?: Submodule[];
  institutions?: any;
  uniqueKeys: string[];
}
interface IFormInitialValues {
  key: string;
  nameEN: string;
  nameSQ: string;
  descriptionEN: string;
  descriptionSQ: string;
  frequency: Frequency[] | string;
  isPublic: boolean;
  analyticalChange: AnalyticalChange;
  isPositiveNumberPositiveTrend: boolean;
  submoduleId?: number;
  institutionId?: number;
  dataset: string;
  variables: any;
  id: number;
  defaultFrequency: string;
  defaultUnitType: string;
}

const IndicatorForm: React.FunctionComponent<IFormPublication> = (props) => {
  const { onClose, refetch, submodules, institutions, indicator, uniqueKeys } = props;
  const { t } = useTranslation();
  const mutationHook = indicator ? useMutation(updateIndicator) : useMutation(createIndicator);
  const { mutateAsync } = mutationHook;
  const currentLanguage: string = getCurrentLng();
  const nameLngKey = `name${currentLanguage}`;
  const { selectAnalyticalChange, selectMultipleFrequency, selectUnitTypes, selectInstitutions, selectSubmodules } =
    getSelectOptions(t, institutions, submodules, nameLngKey);
  const {
    data: filters,
    isLoading,
    isError,
  } = indicator
    ? useQuery([`getIndicatorFilters-${indicator.id}`], () => getIndicatorFilters(indicator.id))
    : { data: undefined, isError: false, isLoading: false };

  const selectableUnitTypes = indicator ? generateUnitTypeOptions(filters, indicator) : selectUnitTypes;

  const initialValues: IFormInitialValues = {
    key: indicator?.key || '',
    nameEN: indicator?.nameEN || '',
    nameSQ: indicator?.nameSQ || '',
    descriptionEN: indicator?.descriptionEN || '',
    descriptionSQ: indicator?.descriptionSQ || '',
    frequency: indicator?.frequency || '',
    analyticalChange: indicator?.analyticalChange || AnalyticalChange.MOM,
    submoduleId: indicator?.submoduleId,
    institutionId: indicator?.institutionId,
    dataset: indicator?.dataset || '',
    isPositiveNumberPositiveTrend: indicator?.isPositiveNumberPositiveTrend || true,
    isPublic: indicator?.isPublic || true,
    variables: indicator?.variables || [],
    id: indicator?.id || 0,
    defaultFrequency: indicator?.defaultFrequency || '',
    defaultUnitType: indicator?.defaultUnitType || '',
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema(t),
    onSubmit: (values: IFormInitialValues) => {
      mutateAsync(values)
        .then(() => {
          indicator
            ? message.success(t(`${translationBasePath}._UPDATED_INDICATOR_SUCCESS`))
            : message.success(t(`${translationBasePath}._CREATED_INDICATOR_SUCCESS`));
          onClose();
          formik.resetForm();
          refetch();
        })
        .catch((err) => {
          notification.error({
            message: err.message,
          });
        });
    },
  });
  const filterDefaultFrequencyData = (
    formValues: IFormInitialValues,
    selectMultipleFrequency: {
      value: Frequency;
      label: string;
    }[]
  ) => {
    if (!formValues.frequency) {
      return undefined;
    }
    return selectMultipleFrequency.filter((freq) => formValues.frequency.includes(freq.value));
  };

  if (isError) {
    return <div className="theme-error">{t('_COMMON._ERROR')}</div>;
  }

  if (isLoading) {
    return (
      <div className="themes-spinner" style={{ background: 'white' }}>
        <Spin delay={200} size="large" />
        <h4 style={{ paddingTop: '20px' }}>{t('_HOME._LOADING')}</h4>
      </div>
    );
  }

  return (
    <Form onSubmit={formik.handleSubmit} gap="5px">
      <Row gutter={24} justify="space-between">
        <Col span={8} key={1}>
          <Input
            name="key"
            value={formik.values.key}
            label={t(`${translationBasePath}._KEY`) + '*'}
            onChange={(e) => {
              if (!uniqueKeys.includes(e.target.value)) {
                formik.setFieldValue('key', e.target.value);
              } else {
                message.error(t(`${translationBasePath}._ERROR_KEY`));
              }
            }}
            error={formik.errors.key}
            readOnly={!!indicator}
          />
        </Col>
        <Col span={8} key={2}>
          <Input
            name="nameEN"
            value={formik.values.nameEN}
            label={t(`${translationBasePath}._NAMEEN`) + '*'}
            onChange={formik.handleChange}
            error={formik.errors.nameEN}
          />
        </Col>
        <Col span={8} key={3}>
          <Input
            name="nameSQ"
            value={formik.values.nameSQ}
            label={t(`${translationBasePath}._NAMESQ`) + '*'}
            onChange={formik.handleChange}
            error={formik.errors.nameSQ}
          />
        </Col>
      </Row>
      <Row gutter={24} justify="space-between">
        <Col span={12} key={1}>
          <AntForm.Item name="frequency">
            <Select
              data={selectMultipleFrequency}
              mode="multiple"
              label={t(`${translationBasePath}._FREQUENCY`)}
              defaultValue={indicator?.frequency}
              onChange={(e) => {
                formik.setFieldValue('frequency', e);
              }}
              value={formik.values.frequency}
            />
          </AntForm.Item>
        </Col>
        <Col span={12} key={2}>
          <AntForm.Item name="analyticalChange">
            <Select
              data={selectAnalyticalChange}
              label={t(`${translationBasePath}._ANALYTICAL_CHANGE`) + '*'}
              defaultValue={indicator?.analyticalChange}
              onChange={(e) => {
                formik.setFieldValue('analyticalChange', e);
              }}
              value={formik.values.analyticalChange}
              error={formik.errors.analyticalChange}
            />
          </AntForm.Item>
        </Col>
      </Row>

      <Row gutter={24} justify="space-between">
        <Col span={12} key={1}>
          <AntForm.Item name="submoduleId">
            <Select
              showSearch
              data={selectSubmodules}
              label={t(`${translationBasePath}._SUBMODULE`) + '*'}
              onChange={(e) => {
                const submodule = JSON.parse(JSON.stringify(e));
                formik.setFieldValue('submoduleId', parseInt(submodule.value));
              }}
              defaultValue={selectSubmodules.find((sm) => sm.value === formik.values.submoduleId)}
              value={selectSubmodules.find((sm) => sm.value === formik.values.submoduleId)}
              filterOption={(input, option) =>
                option && option.children
                  ? option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                  : false
              }
              labelInValue
            />
          </AntForm.Item>
        </Col>
        <Col span={12} key={2}>
          <AntForm.Item name="institutionId">
            <Select
              showSearch
              data={selectInstitutions}
              label={t(`${translationBasePath}._INSTITUTION`) + '*'}
              onChange={(e) => {
                const institution = JSON.parse(JSON.stringify(e));
                formik.setFieldValue('institutionId', parseInt(institution.value));
              }}
              defaultValue={selectInstitutions.find((i) => i.value === formik.values.institutionId)}
              value={selectInstitutions.find((i) => i.value === formik.values.institutionId)}
              filterOption={(input, option) =>
                option && option.children
                  ? option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                  : false
              }
              labelInValue
            />
          </AntForm.Item>
        </Col>
      </Row>

      <Row gutter={24} justify="space-between">
        <Col span={12} key={1}>
          <AntForm.Item name="defaultFrequency">
            <Select
              showSearch
              data={filterDefaultFrequencyData(formik.values, selectMultipleFrequency)}
              label={t(`${translationBasePath}._DEFAULT_FREQUENCY`) + '*'}
              onChange={(e) => {
                const defaultFrequency = JSON.parse(JSON.stringify(e));
                formik.setFieldValue('defaultFrequency', defaultFrequency.value);
              }}
              defaultValue={
                formik.values.frequency.length
                  ? selectMultipleFrequency.find((df) => df.value === formik.values.defaultFrequency)
                  : ''
              }
              value={
                formik.values.frequency.length
                  ? selectMultipleFrequency.find((df) => df.value === formik.values.defaultFrequency)
                  : ''
              }
              filterOption={(input, option) =>
                option && option.children
                  ? option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                  : false
              }
              error={formik.errors.defaultFrequency}
              labelInValue
            />
          </AntForm.Item>
        </Col>
        <Col span={12} key={2}>
          <AntForm.Item name="defaultUnitType">
            <Select
              showSearch
              data={selectableUnitTypes}
              label={t(`${translationBasePath}._DEFAULT_UNIT_TYPE`) + '*'}
              onChange={(e) => {
                const defaultUnitType = JSON.parse(JSON.stringify(e));
                formik.setFieldValue('defaultUnitType', defaultUnitType.value);
              }}
              defaultValue={selectableUnitTypes.find((ut) => ut.value === formik.values.defaultUnitType)}
              value={selectableUnitTypes.find((ut) => ut.value === formik.values.defaultUnitType)}
              filterOption={(input, option) =>
                option && option.children
                  ? option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                  : false
              }
              labelInValue
              error={formik.errors.defaultUnitType}
            />
          </AntForm.Item>
        </Col>
      </Row>

      <Row gutter={24} justify="space-between">
        <Col span={12} key={3}>
          <AntForm.Item label={t(`${translationBasePath}._IS_NUMBER_POSITIVE_TREND`) + '*'}>
            <Radio.Group
              defaultValue={indicator ? indicator.isPositiveNumberPositiveTrend : true}
              onChange={(e) => {
                formik.setFieldValue('isPositiveNumberPositiveTrend', e.target.value);
              }}>
              <Space direction="horizontal">
                <Radio value={true}>{t(`${translationBasePath}._POSITIVE`)}</Radio>
                <Radio value={false}>{t(`${translationBasePath}._NEGATIVE`)}</Radio>
              </Space>
            </Radio.Group>
          </AntForm.Item>
        </Col>
        <Col span={12} key={4}>
          <AntForm.Item label={t(`${translationBasePath}._IS_INDICATOR_PUBLIC`) + '*'}>
            <Radio.Group
              defaultValue={indicator ? indicator.isPublic : true}
              onChange={(e) => {
                formik.setFieldValue('isPublic', e.target.value);
              }}>
              <Space direction="horizontal">
                <Radio value={true}>{t(`${translationBasePath}._PUBLIC`)}</Radio>
                <Radio value={false}>{t(`${translationBasePath}._NOT_PUBLIC`)}</Radio>
              </Space>
            </Radio.Group>
          </AntForm.Item>
        </Col>
      </Row>
      <Input
        name="dataset"
        value={formik.values.dataset}
        label={t(`${translationBasePath}._DATASET`) + '*'}
        onChange={formik.handleChange}
        error={formik.errors.dataset}
      />

      <Input
        name="descriptionEN"
        value={formik.values.descriptionEN}
        label={t(`${translationBasePath}._DESCRIPTION_EN`) + '*'}
        onChange={formik.handleChange}
        error={formik.errors.descriptionEN}
      />

      <Input
        name="descriptionSQ"
        value={formik.values.descriptionSQ}
        label={t(`${translationBasePath}._DESCRIPTION_SQ`) + '*'}
        onChange={formik.handleChange}
        error={formik.errors.descriptionSQ}
      />

      <Button
        htmlType="submit"
        type="primary"
        style={{ marginTop: 10 }}
        disabled={!formik.isValid || formik.values === initialValues}>
        {indicator ? t(`${translationBasePath}._UPDATE`).toUpperCase() : t(`${translationBasePath}._CREATE`)}
      </Button>
    </Form>
  );
};

export default IndicatorForm;
