import React, { useState } from 'react';
import { downloadUploadedCsv, getIndicators } from 'api/indicator';
import { useMutation, useQuery } from 'react-query';
import Table from 'common/components/Table/Table';
import { Button, Modal, notification, Row, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { formatDate, getCurrentLng } from 'utils/helper';
import { UploadOutlined } from '@ant-design/icons';
import IndicatorValueUpload from './IndicatorValueUpload';
import { translationBasePath, TranslationKeys } from './constants';
import DownloadOutlined from '@ant-design/icons/lib/icons/DownloadOutlined';
import I18nLink from 'common/components/I18nLink';
import { useHistory } from 'react-router-dom';
import { Title } from '../ManageUsers/styled';
import { ColumnProps } from 'antd/lib/table';
import { syncLastValues } from 'api/indicator';

const Indicators: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [modalOpen, setModalOpen] = useState(false);
  const [currentId, setCurrentId] = useState('');
  const { data, isLoading } = useQuery('dashboardIndicators', getIndicators);
  const syncLastValuesMutation = useMutation(syncLastValues);

  const downloadMutation = useMutation(downloadUploadedCsv);

  const modal = modalOpen && (
    <Modal
      maskClosable={false}
      footer={null}
      visible
      width="700px"
      title={t(`${translationBasePath}.${TranslationKeys.uploadCSV}`)}
      onCancel={() => {
        setModalOpen(false);
      }}>
      <IndicatorValueUpload id={currentId} />
    </Modal>
  );

  const title = t(`${translationBasePath}.${TranslationKeys.indicatorValues}`);
  const indicatorHeader = (
    <Row justify="space-between" style={{ marginBottom: 10 }}>
      <Title>{title}</Title>
      <div>
        <Button
          style={{ marginRight: 20 }}
          type="primary"
          onClick={() => {
            syncLastValuesMutation
              .mutateAsync()
              .then(() => {
                notification.success({ message: 'Sync successfully' });
              })
              .catch((err) => notification.error({ message: err.message }));
          }}>
          {t(`${translationBasePath}._SYNC_LAST_VALUES`)}
        </Button>
        <Button type="primary" href="/indicator_values_sample.xlsx">
          {t(`${translationBasePath}._SAMPLE_XLSX`)}
        </Button>
      </div>
    </Row>
  );

  const currentLanguage: string = getCurrentLng();
  const nameLngKey = `name${currentLanguage}`;

  const columns: ColumnProps<any>[] = [
    {
      key: 'key',
      title: t(`${translationBasePath}._KEY`),
      dataIndex: 'key',
      render: (text: string, _record) => <span>{text}</span>,
    },
    {
      key: nameLngKey,
      title: t(`${translationBasePath}._${nameLngKey.toUpperCase()}`),
      dataIndex: nameLngKey,
      render: (text: string, _record) => <span>{text}</span>,
    },
    {
      key: 'updatedAt',
      title: t(`${translationBasePath}._UPDATEDAT`),
      dataIndex: 'updatedAt',
      render: (text: string, _record) => <span>{formatDate(text)}</span>,
    },
    {
      title: t(`${translationBasePath}.${TranslationKeys.action}`),
      key: 'action',
      dataIndex: 'action',
      render: (_text, record) => (
        <Space size="middle">
          <Button
            style={{ fontSize: '15px' }}
            shape="round"
            type="link"
            onClick={() => {
              setModalOpen(true);
              setCurrentId(record.id);
            }}>
            <UploadOutlined />
            {t(`${translationBasePath}.${TranslationKeys.uploadCSV}`)}
          </Button>
          <Button
            style={{ fontSize: '15px' }}
            shape="round"
            type="link"
            onClick={() => {
              downloadMutation
                .mutateAsync(record.id)
                .then((res) => {
                  if (res.link) {
                    const link = document.createElement('a');
                    link.setAttribute('href', res.link);
                    link.setAttribute('target', '_blank');
                    link.click();
                  } else {
                    notification.warn({ message: t(`${translationBasePath}.${TranslationKeys.fileNotFound}`) });
                  }
                })
                .catch((err) => notification.error({ message: err.message }));
            }}>
            <DownloadOutlined />
            {t(`${translationBasePath}.${TranslationKeys.downloadData}`)}
          </Button>
          <Button style={{ fontSize: '15px' }} shape="round" type="link">
            <I18nLink to={`/dashboard/indicator-values/${record.id}/uploads`}>
              {t(`${translationBasePath}.${TranslationKeys.viewJobs}`)}
            </I18nLink>
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <div>
      {indicatorHeader}
      {modal}
      <Table
        loading={isLoading}
        dataSource={data}
        columns={columns}
        rowClassName="admin-table-row"
        pagination={false}
      />
    </div>
  );
};

export default Indicators;
