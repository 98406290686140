import * as React from 'react';
import { useMutation } from 'react-query';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { uploadAndProcessCSV } from 'api/indicator';
import Button from 'common/components/Button/Button';
import { message, Row } from 'antd';
import Input from 'common/components/Input/Input';
import I18nLink from 'common/components/I18nLink';
import { translationBasePath, TranslationKeys } from './constants';

interface IUpload {
  id: string;
}

const IndicatorValuesUpload: React.FunctionComponent<IUpload> = (props) => {
  const [toProcess, setToProcess] = useState<File | null>(null);
  const [processing, setProcessing] = useState(false);
  const [enabledUploadView, setEnabledUploadView] = useState(true);
  const [errorUpload, setErrorUpload] = useState<string | undefined>();
  const { t } = useTranslation();
  const { mutateAsync } = useMutation(uploadAndProcessCSV);

  const handleUpload = () => {
    setProcessing(true);
    const data = new FormData();

    if (toProcess) {
      data.append('file', toProcess, toProcess.name);
    }

    mutateAsync({ body: data, id: props.id })
      .then(() => {
        message.success(t(`${translationBasePath}.${TranslationKeys.fileProcessedSuccessfully}`));
        setProcessing(false);
        setEnabledUploadView(false);
      })
      .catch((err) => {
        setProcessing(false);
        setEnabledUploadView(false);
        message.error(JSON.stringify(err));
      });
  };

  const onFileChange = async (e) => {
    const file = e.target.files[0];
    const maxSizeReached = file?.size >= 100 * 1024 * 1024 || false;

    if (maxSizeReached) {
      const errorMessage = t(`${translationBasePath}.${TranslationKeys.fileTooLarge}`);
      message.error(errorMessage);
      setErrorUpload(errorMessage);
    } else {
      setErrorUpload(undefined);
      setToProcess(file);
    }
  };

  return (
    <>
      <Input
        type="file"
        onChange={onFileChange}
        name="file"
        accept="text/csv"
        label={t(`${translationBasePath}.${TranslationKeys.uploadCSVFile}`)}
        error={errorUpload}
      />
      <Row justify="space-between" style={{ marginTop: 16 }}>
        <Button
          type="primary"
          onClick={handleUpload}
          disabled={!!errorUpload || !toProcess || !enabledUploadView}
          loading={processing}>
          {t(`${translationBasePath}.${TranslationKeys.initiateFileProcessing}`)}
        </Button>
        <Button type="primary" disabled={enabledUploadView}>
          <I18nLink to={`/dashboard/indicator-values/${props.id}/uploads`}>
            {t(`${translationBasePath}.${TranslationKeys.checkUploadProgress}`)}
          </I18nLink>
        </Button>
      </Row>
    </>
  );
};

export default IndicatorValuesUpload;
