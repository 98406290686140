import styled, { createGlobalStyle } from 'styled-components';

export const GS = createGlobalStyle`
  body {
    
  }
`;

export const H1 = styled.h1`
  font-weight: bold;
  font-size: 48px;
  font-weight: 700;
  color: black;
`;

export const HeroMenu = styled.div`
  position: relative;
  border: none;
  padding-top: 2.2em;
  color: #151515;
  display: flex;
  gap: 20px;
  justify-content: center;

  li {
    color: #151515;
    cursor: pointer;
    transition: color 0.3s ease-in;
    list-style: none;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 14px;
    line-height: 26px;
    &:hover {
      color: #116530;
      border-bottom: 2px solid #116530;
    }
  }
`;
