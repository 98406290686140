import { apiUrl, post, put, get, remove } from 'utils/apiMgmt';
import { CreateIndicatorUserBody, Indicator, IndicatorUserWithId, IndicatorWithId } from 'models/indicator';
import { IndicatorVariable } from 'models/indicator';

export const createIndicator = async (body: Indicator): Promise<Indicator> => {
  const { data } = await post(apiUrl + '/indicators', body);
  return data;
};

export const uploadAndProcessCSV = async ({ body, id }): Promise<any> => {
  const { data } = await post(apiUrl + `/indicator_values/${id}/process-csv`, body);
  return data;
};

export const deleteIndicator = async (id: number): Promise<Indicator> => {
  const { data } = await remove(apiUrl + '/indicators/' + id);
  return data;
};

export const updateIndicator = async (body): Promise<Indicator> => {
  const { id, ...toUpdate } = body;
  const { data } = await put(apiUrl + `/indicators/${id}`, toUpdate);
  return data;
};

export const getIndicator = async (id: number): Promise<IndicatorWithId> => {
  const { data } = await get(apiUrl + '/indicators/' + id);
  return data;
};

export const downloadUploadedCsv = async (id: number): Promise<any> => {
  const { data } = await get(apiUrl + '/indicator_values/' + id + '/download');
  return data;
};

export const getIndicators = async (): Promise<Indicator[]> => {
  const { data } = await get(apiUrl + '/indicators');
  return data;
};

export const getIndicatorUsers = async (indicatorId: string): Promise<IndicatorUserWithId[]> => {
  const { data } = await get(apiUrl + `/indicators/${indicatorId}/users`);
  return data;
};

export const createIndicatorUser = async (
  indicatorId: string,
  body: CreateIndicatorUserBody
): Promise<IndicatorUserWithId> => {
  const { data } = await post(apiUrl + `/indicators/${indicatorId}/users`, body);
  return data;
};

export const removeIndicatorUser = async (indicatorId: string, userId: number): Promise<IndicatorUserWithId> => {
  const { data } = await remove(apiUrl + `/indicators/${indicatorId}/users/${userId}`);
  return data;
};

export const getUploadJobs = async (id: number): Promise<any> => {
  const { data } = await get(apiUrl + `/indicator_values/${id}/uploads`);
  return data;
};
export const getIndicatorVariables = async (): Promise<IndicatorVariable[]> => {
  const { data } = await get(apiUrl + `/indicators/variables`);
  return data;
};

export const downloadIndicatorData = async (id: number): Promise<{ link: string }> => {
  const { data } = await get(apiUrl + `/indicator_values/${id}/download`);
  return data;
};

export const syncLastValues = async (): Promise<any> => {
  const { data } = await put(apiUrl + `/indicator_values/sync-indicator-values`);
  return data;
};
