import { apiUrl, post, put, get, remove } from 'utils/apiMgmt';
import { CreateThemeUserBody, Theme, ThemeUser } from 'models/theme';
import { deleteFile } from './file-upload';

export const createTheme = async (body: Theme): Promise<Theme> => {
  const { data } = await post(apiUrl + '/themes', body);
  return data;
};

export const getSingleTheme = async (key: string): Promise<Theme> => {
  const { data } = await get(apiUrl + `/themes/${key}`);
  return data;
};

export const deleteTheme = async (theme: Theme): Promise<Theme> => {
  if (theme.image) {
    await deleteFile({ fileUrl: theme.image });
  }
  const { data } = await remove(apiUrl + '/themes/' + theme.key);
  return data;
};

export const previewTheme = async (key: string): Promise<Theme> => {
  const { data } = await get(apiUrl + `/themes/${key}`);
  return data;
};

export const updateTheme = async (body): Promise<Theme> => {
  const { key, ...toUpdate } = body;
  const { data } = await put(apiUrl + `/themes/${key}`, toUpdate);
  return data;
};

export const getThemes = async (): Promise<Theme[]> => {
  const { data } = await get(apiUrl + '/themes');
  return data;
};

export const getThemeUsers = async (themeId: number): Promise<ThemeUser[]> => {
  const { data } = await get(apiUrl + `/themes/${themeId}/users`);
  return data;
};

export const createThemeUser = async (themeId: number, body: CreateThemeUserBody): Promise<ThemeUser> => {
  const { data } = await post(apiUrl + `/themes/${themeId}/users`, body);
  return data;
};

export const removeThemeUser = async (themeId: number, userId: number): Promise<ThemeUser> => {
  const { data } = await remove(apiUrl + `/themes/${themeId}/users/${userId}`);
  return data;
};
