import Module from './module';

export enum PublicationType {
  BOOKLET = 'Booklet',
  BROCHURE = 'Brochure',
  CASE_STUDY = 'Case_Study',
  FACE_FACTS = 'Face_Facts',
  GUIDANCE_NOTE = 'Guidance_Note',
  ISSUE_BRIEF = 'Issue_Brief',
  LESSONS_LEARNED_COMPARATIVE_EXPERIENCE = 'Lessons_Learned_Comparative_Experience',
  NEWSLETTER = 'Newsletter',
  POLICY_BRIEF = 'Policy_Brief',
  REPORT = 'Report',
  RESEARCH_DISCUSSION_PAPERS = 'Research_Discussion_Papers',
  STRATEGY = 'Strategy',
  TOOLKIT_HOW_TO_MANUAL = 'Toolkit_HowTo_Manual',
}

export enum Languages {
  English = 'English',
  Albanian = 'Albanian',
}

export enum LanguagesAbbreviation {
  English = 'EN',
  Albanian = 'SQ',
}

export interface Publication {
  id?: number;
  nameEN?: string;
  nameSQ?: string;
  type: PublicationType | string;
  file?: string;
  thumbnail?: string;
  lang?: Languages;
  link?: any;
  module?: Module;
  moduleId?: number | string;
  createdAt?: Date;
  updatedAt?: Date;
  date: Date;
  author: string;
}

export interface PublicationWithID extends Publication {
  moduleId: number;
  id: number;
  type: PublicationType;
  lang: Languages;
}

export interface PublicationFilters {
  type: PublicationType[];
  lang: Languages[];
  sort: string[];
}
