import Flex from 'common/components/Flex/Flex';
import { FormContainerStyled } from 'common/components/Form/styled';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { ForgotPasswordForm } from './ForgotPasswordForm';

const ForgotPassword: React.FunctionComponent = () => {
  return (
    <FormContainerStyled>
      <ForgotPasswordForm />
    </FormContainerStyled>
  );
};

export default withTranslation()(ForgotPassword);
