import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
    body {
        padding: 0;
        margin: 0;
    }

.dashboard-spinner {
    position: relative;
    width: 100%;
    height: 400px;
    display: grid;
    place-content: center;
}
p.dashboard-no-data {
    font-weight: bold;
    font-size: 20px;
    height: 400px;
    display: grid;
    place-content: center;
}

`;

export default GlobalStyle;
