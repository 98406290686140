import './employmentindicators.css';
import { Spin } from 'antd';
import { useQuery } from 'react-query';
import { getModules } from 'api/public';
import { getCurrentLng } from 'utils/helper';
import i18n from 'i18next';
import I18nLink from 'common/components/I18nLink';
import { SubmoduleWithID } from 'models/indicator';
import React from 'react';
import { ModuleWithID } from 'models/module';

const EmploymentIndicators: React.FunctionComponent = () => {
  const { data, isLoading, isError } = useQuery('modules', getModules);
  if (isError) {
    return <div className="theme-error">{i18n.t('_COMMON._ERROR')}</div>;
  }
  if (isLoading) {
    return (
      <div className="modules-spinner">
        <Spin delay={200} size="large" />
        <h4 style={{ paddingTop: '20px', fontWeight: 600 }}> {i18n.t('_HOME._LOADING')}</h4>
      </div>
    );
  }

  return (
    <div id="categories" className="employment-indicators-wrapper">
      <div className="explore-ind">
        <span>{i18n.t('_HOME._EXPLORE_DATA')}</span>
      </div>
      <main>
        {data?.map((module) => {
          return <ModuleSection key={module.id} module={module} submodules={module.submodules} />;
        })}
      </main>
    </div>
  );
};

export default EmploymentIndicators;

interface ModuleSectionProps {
  module: ModuleWithID;
  submodules: SubmoduleWithID[] | undefined;
}

const ModuleSection: React.FC<ModuleSectionProps> = ({ submodules, module }) => {
  const currLang = getCurrentLng();
  return (
    <section>
      <h1 className="text">
        <I18nLink to={`/category/${module.id}`}>{module[`name${currLang}`]}</I18nLink>
      </h1>
      <div className="indicators-wrapper">
        {submodules?.map((submodule) => {
          const submoduleId = submodule?.id || '';
          return (
            <I18nLink key={submodule.id} to={`/category/${module.id}/subcategory/${submoduleId}`}>
              <div className="indicator">
                <p>{submodule[`name${currLang}`]}</p>
              </div>
            </I18nLink>
          );
        })}
      </div>
    </section>
  );
};
