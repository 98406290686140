import styled from 'styled-components';
import { FormProps } from './Form';
import fullLogo from 'common/images/adp-logo.svg';
import { Link } from 'react-router-dom';

const StyledForm = styled.form`
  display: flex;
  flex-direction: ${(props: FormProps) => props.direction};
  gap: ${(props: FormProps) => props.gap};
`;

export const AuthFormContainer = styled.div`
  .form-wrapper {
    padding: 3em;
    width: 500px;
    font-size: 1.2em;
    border-radius: 6px;
    background: white;
    box-shadow: -3px 7px 9px 0px #23565529;

    @media screen and (max-width: 600px) {
      position: relative;
      width: 400px;
      margin: auto;
    }
    @media screen and (max-width: 440px) {
      position: relative;
      width: 380px;
      margin: auto;
    }
    @media screen and (max-width: 400px) {
      position: relative;
      width: 350px;
      margin: auto;
    }
    @media screen and (max-width: 370px) {
      position: relative;
      width: 320px;
      margin: auto;
    }
    @media screen and (max-width: 320px) {
      position: relative;
      width: 280px;
      margin: auto;
    }
    input {
      font-size: 1em;
      padding: 0.5em 1em;
      border-radius: 6px;
      border-width: 3px !important;
      &:focus,
      &:hover {
        box-shadow: none;
        border-width: 3px !important;
      }
    }
    button {
      font-size: 1.2em;
      height: 50px;
      border-radius: 6px;
    }
  }
`;

export const FormContainerStyled = styled(({ children, className, ...props }) => (
  <div className={className}>
    <Link to={'/'}>
      <img src={fullLogo} width={300} />
    </Link>
    {children}
  </div>
))`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 100vh;
  padding: 2em 0;
  background: linear-gradient(45deg, #0e989836, transparent);
  img {
    margin-bottom: 2.4em;
  }
`;

export { StyledForm };
