import React from 'react';
import ThemeIframe from './components/ThemeIframe';
import ThemeSubheader from './components/ThemeSubheader';
import { Divider, Spin } from 'antd';
import '../styles/theme.css';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { getSingleTheme } from 'api/public';
import { getCurrentLng } from 'utils/helper';
import i18n from 'i18next';

const Theme = () => {
  const lang = getCurrentLng();
  const { themeKey } = useParams<{ themeKey: string }>();
  const { data, isLoading, isError } = useQuery(['singleTheme', themeKey], () => getSingleTheme(themeKey), {
    refetchOnWindowFocus: false,
  });

  const themeTitle = data?.nameEN || '';
  const themeDescription = data?.[`description${lang}`] || '';
  const footnote = data?.[`footnote${lang}`] || '';
  const iFrame = data?.[`iFrame${lang}`] || '';
  const dataSource = data?.source || '';
  const institution = data?.institution?.[`name${lang}`] || i18n.t('_THEMES._NO_AVAILABLE');
  if (isLoading) {
    return (
      <div className="theme-page-spinner">
        <Spin />
        <p>{i18n.t('_COMMON._LOADING')}</p>
      </div>
    );
  }

  if (isError) {
    return <div className="theme-page-spinner">{i18n.t('_COMMON._ERROR')}</div>;
  }

  return (
    <div className="theme-page">
      <ThemeSubheader themeKey={themeKey} title={themeTitle} description={themeDescription} />
      <ThemeIframe institution={institution} IFrame={iFrame} footnote={footnote} dataSource={dataSource} />
      <Divider />
    </div>
  );
};

export default Theme;
