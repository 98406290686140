import Flex from 'common/components/Flex/Flex';
import { FormContainerStyled } from 'common/components/Form/styled';
import React from 'react';
import { ResetPasswordForm } from './ResetPasswordForm';

interface Props {
  match: any;
  history: any;
}

const ResetPassword: React.FunctionComponent<Props> = (props: Props) => {
  return (
    <FormContainerStyled>
      <ResetPasswordForm history={props.history} match={props.match} />
    </FormContainerStyled>
  );
};

export default ResetPassword;
