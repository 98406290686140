import React, { useEffect } from 'react';
import './modalNewIndicator.css';
import { Breadcrumb, Spin } from 'antd';
import Close from './x.svg';
import RelatedIndicators from 'pages/Explorer/components/RelatedIndicators/RelatedIndicators';
import { ModuleWithID } from 'models/module';
import { useTranslation } from 'react-i18next';
import { getCurrentLng, indicatorsToHide } from 'utils/helper';
import { getPublicIndicators, getSingleModule } from 'api/public';
import { useQuery } from 'react-query';
import i18next from 'i18next';

interface ModalProps {
  closeModal: () => void;
  module?: ModuleWithID;
}

const ModalNewIndicator: React.FunctionComponent<ModalProps> = ({ closeModal, module }) => {
  if (!module) {
    return <></>;
  }
  useEffect(() => {
    window.scroll({ top: 0 });
  }, []);

  const { t } = useTranslation();
  const currentLng = getCurrentLng();
  const { data, isError, isLoading } = useQuery('singleModule', () => getSingleModule(module.id));
  const { data: allIndicators } = useQuery('indicators', getPublicIndicators);

  if (isError) {
    return <p style={{ height: '200px', display: 'grid', placeContent: 'center' }}>{i18next.t('_COMMON._ERROR')}</p>;
  }

  return (
    <div className="modal-wrapper">
      <div onClick={closeModal} className="empty"></div>
      <div className="bar-container">
        <div className="breadcrumbs">
          <Breadcrumb separator="•">
            <Breadcrumb.Item className="breaditem"> {t('_COMMON._DATA')}</Breadcrumb.Item>
            {/* <Breadcrumb.Item className="breaditem">{module[`name${currentLng}`]}</Breadcrumb.Item> */}
          </Breadcrumb>
        </div>
        <div onClick={closeModal}>
          <img src={Close} />
        </div>
      </div>
      {/* {!data?.submodules && (
        <div style={{ height: '300px', background: 'white', display: 'grid', placeContent: 'center' }}>
          {isLoading && (
            <>
              <Spin />
              <p>{i18next.t('_COMMON._LOADING')}</p>
            </>
          )}
        </div>
      )} */}
      <RelatedIndicators
        module={module}
        allIndicators={allIndicators?.filter((indicator) => {
          return indicatorsToHide.every((key) => key !== indicator.key);
        })}
        submodules={[]}
      />
      <div onClick={closeModal} className="empty-bottom"></div>
    </div>
  );
};

export default ModalNewIndicator;
