import { Link } from 'react-router-dom';
import Flex from 'common/components/Flex/Flex';
import styled from 'styled-components';
import I18nLink from '../I18nLink';

const StyledLink = styled(I18nLink)`
  color: #fff;
  text-decoration: none;
`;

export { StyledLink };
