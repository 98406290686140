import * as React from 'react';
import Input from 'common/components/Input/Input';
import Button from 'common/components/Button/Button';
import { updateModule, createModule } from 'api/module';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { validationSchema, translationBasePath } from './ModulesValidationForm';
import { useMutation } from 'react-query';
import Module from 'models/module';
interface IFormModule {
  onClose: () => void;
  refetch: () => void;
  module?: Module;
}
interface IFormInitialValues {
  nameEN: string;
  nameSQ: string;
}

const ModuleForm: React.FunctionComponent<IFormModule> = (props) => {
  const { onClose, refetch } = props;
  const { t } = useTranslation();
  const id = props.module?.id;

  const mutationHook = id ? useMutation(updateModule) : useMutation(createModule);
  const { isLoading, isError, mutateAsync } = mutationHook;
  const initialValues: IFormInitialValues = {
    nameEN: props.module?.nameEN || '',
    nameSQ: props.module?.nameSQ || '',
  };
  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema(t),
    onSubmit: (values: Module) => {
      mutateAsync({ ...values, id })
        .then(() => {
          onClose();
          formik.resetForm();
          refetch();
        })
        .catch((err) => {
          console.log(err);
          formik.resetForm();
        });
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Input
        name="nameEN"
        value={formik.values.nameEN}
        label={t(`${translationBasePath}._NAMEEN`)}
        onChange={formik.handleChange}
        error={formik.errors.nameEN}
      />

      <Input
        name="nameSQ"
        value={formik.values.nameSQ}
        label={t(`${translationBasePath}._NAMESQ`)}
        onChange={formik.handleChange}
        error={formik.errors.nameSQ}
      />

      <Button htmlType="submit" style={{ marginTop: 10 }}>
        {t(`${translationBasePath}._SAVE`)}
      </Button>
      <div style={{ color: 'gray', background: '#234' }}>
        {isLoading ? t(`${translationBasePath}._SAVING`) : ''}
        {isError ? t(`${translationBasePath}._ERROR`) : ''}
      </div>
    </form>
  );
};

export default ModuleForm;
