import React, { Dispatch, SetStateAction } from 'react';
import { PublicationFilters } from 'models/publication';
import { Button, Select } from 'antd';
import { translationBasePath } from '../helpers';
import { useTranslation } from 'react-i18next';

import { FilterFilled } from '@ant-design/icons';
import useWindowDimensions from 'common/hooks/useWindowDimensions';

const { Option } = Select;
interface IFilterLabels {
  selectedItems: PublicationFilters;
  setSelectedItems: Dispatch<SetStateAction<PublicationFilters>>;
  setDrawerOpen: Dispatch<SetStateAction<boolean>>;
}

const FilterSort: React.FunctionComponent<IFilterLabels> = ({ selectedItems, setSelectedItems, setDrawerOpen }) => {
  const { width } = useWindowDimensions();
  const { t } = useTranslation();
  const availableFilters = {
    sort: ['date', 'relevance'],
  };
  const handleSelected = (value) => {
    selectedItems['sort'] = value;
    setSelectedItems({
      ...selectedItems,
      sort: selectedItems['sort'],
    });
  };

  return (
    <div className="filter-sort">
      {Object.keys(availableFilters).map((key) => {
        const firstElement = availableFilters[key][0];
        return (
          <div className="comp" key={key} style={{ display: 'flex', alignItems: 'center' }}>
            <section style={{ marginRight: '10px', fontWeight: 700, fontFamily: 'Source Sans Pro', fontSize: '20px' }}>
              {t(`${translationBasePath}._SORT`)}
            </section>
            <Select style={{ width: 150 }} defaultValue={firstElement} onChange={handleSelected}>
              {availableFilters[key].map((filterValue, idx) => {
                return (
                  <Option value={filterValue} key={idx}>
                    {t(`${translationBasePath}._${filterValue.toUpperCase()}`)}
                  </Option>
                );
              })}
            </Select>
          </div>
        );
      })}

      {width < 1024 && (
        <span role="button" onClick={() => setDrawerOpen(true)} className="filter-drawer">
          <span>{t('_COMMON._FILTER')}</span>
          <FilterFilled />
        </span>
      )}
    </div>
  );
};

export default FilterSort;
