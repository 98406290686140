import * as React from 'react';
import { Select, Spin } from 'antd';
import CustomSubheader from '../CustomSubheader';
import ThemeNess from './ThemeNess';
import i18next from 'i18next';
import { generateNESSCsv, generateNessIndicators, getYearFromDate } from './NessHelper';
import ThemeSubheader from 'pages/Theme/IntegrationTheme/components/ThemeSubheader';
import { useQuery } from 'react-query';
import { getNessTheme, getSingleTheme, getThemes } from 'api/public';
import CsvDownload from 'react-json-to-csv';
import { getCurrentLng } from 'utils/helper';
import download from 'pages/Explorer/components/Indicator/components/icons/download.svg';
import { NessThemeModel } from 'models/indicator';

const themeKey = 'ness';
const { Option } = Select;

const Index = () => {
  const currentLng = getCurrentLng();

  const optionValues = [
    {
      value: '2019-12-31',
    },
    {
      value: '2020-12-31',
    },
    {
      value: '2021-12-31',
    },
    {
      value: '2022-12-31',
    },
  ];
  const [selectedYear, setSelectedYear] = React.useState(optionValues[optionValues.length - 1].value); //default selected year

  const {
    data: themes,
    isLoading: isLoadingThemes,
    isError: isErrorThemes,
  } = useQuery(['getThemes'], () => getThemes(), {
    refetchOnWindowFocus: false,
  });

  const [nessData, setNessData] = React.useState<any[]>();

  const buttonStyles = {
    border: 'none',
    marginTop: 15,
    padding: 3,
    display: 'flex',
    alignItems: 'center',
    gap: 4,
    cursor: 'pointer',
    fontWeight: 600,
  };

  const nessTheme = themes?.find((theme) => theme.key === themeKey);

  if (isLoadingThemes) {
    return (
      <div style={{ height: '300px', display: 'grid', placeContent: 'center' }}>
        <Spin size="large" />
        {i18next.t('_COMMON._LOADING')}
      </div>
    );
  }

  if (isErrorThemes || !nessTheme) {
    return <p style={{ height: '200px', fontSize: '20px', textAlign: 'center' }}> {i18next.t('_COMMON._ERROR')}</p>;
  }

  return (
    <>
      <ThemeSubheader
        ness
        themeKey={nessTheme.key}
        title={nessTheme[`name${currentLng}`]}
        description={nessTheme[`description${currentLng}`]}
      />
      <section className="ness-content">
        <div className="ness-info">
          <div>
            <main>
              {i18next.t('_NESS._YEAR')}
              <Select
                defaultValue={selectedYear}
                style={{ width: '80px' }}
                onChange={(value) => setSelectedYear(value)}>
                {optionValues.map((option) => {
                  return <Option value={option.value}>{getYearFromDate(option.value)}</Option>;
                })}
              </Select>
            </main>
          </div>
          <CsvDownload
            data={generateNESSCsv(nessData)}
            style={buttonStyles}
            filename="ness.csv"
            className="ness-download-csv">
            <span>{i18next.t('_COMMON._DOWNLOAD_DATA')}</span>
            <img src={download} />
          </CsvDownload>
          {/* <CsvDownload data={{}} style={buttonStyles} filename="ness.csv" className="ness-download-csv">
            <span>{i18next.t('_COMMON._DOWNLOAD_DATA')}</span>
            <img src={download} />
          </CsvDownload>=> SHOULD WE DO 2 downloads button: 1-current shown data and one for all the data? */}
        </div>
      </section>
      <div className="ness-container">
        <ThemeNess selectedYear={selectedYear} setThemeData={setNessData} />
      </div>
    </>
  );
};

export default Index;
