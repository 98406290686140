import { AnalyticalChange, Indicator, IndicatorValues } from 'models/indicator';
import moment from 'moment';
import { getCurrentLng } from 'utils/helper';
import i18next from 'i18next';
import { addCommas } from './helpers';
//FUNCTION GENERATING THE INDICATOR TITLE
export const generateIndicatorTitle = (
  indicator: Indicator,
  indicatorValues: IndicatorValues[],
  unitType: string
): { text: string; color: string } => {
  // Find last indicatorValue based on date
  // Find comparison indicatorValue based on indicator.analyticalChange
  // Find the delta (increase / decrease / steady)
  // Build the final string
  if (!indicator) {
    return { text: '', color: 'black' };
  }
  if (indicatorValues?.length === 0) {
    return { text: '', color: 'black' };
  }
  const lastIndicatorValue = findLastIndicatorValue(indicatorValues);
  const comparableIndicatorValue = findComparableIndicatorValue(indicator, indicatorValues);

  const delta = comparableIndicatorValue ? lastIndicatorValue.value - comparableIndicatorValue.value : 0;
  switch (indicator.analyticalChange) {
    case AnalyticalChange.YOY:
      return {
        text: buildText(indicator, lastIndicatorValue, comparableIndicatorValue, delta, unitType),
        color: generateColor(indicator, delta),
      };

    case AnalyticalChange.MOM:
      return {
        text: buildText(indicator, lastIndicatorValue, comparableIndicatorValue, delta, unitType),
        color: generateColor(indicator, delta),
      };

    case AnalyticalChange.QOQ:
      return {
        text: buildText(indicator, lastIndicatorValue, comparableIndicatorValue, delta, unitType),
        color: generateColor(indicator, delta),
      };

    default:
      return {
        text: '',
        color: '',
      };
  }
};

export const findLastIndicatorValue = (indicatorValues: IndicatorValues[]): IndicatorValues => {
  let sorted: IndicatorValues[];
  //sort the indicators based on date descending order
  sorted = indicatorValues.sort((a, b) => {
    if (new Date(b.date).getTime() == new Date(a.date).getTime()) {
      if (a.id < b.id) {
        return -1;
      } else return 1;
    } else return new Date(b.date).getTime() - new Date(a.date).getTime();
  });
  return sorted[0];
};

export const findComparableIndicatorValue = (
  indicator: Indicator,
  indicatorValues: IndicatorValues[]
): IndicatorValues | undefined => {
  const sortedIndicators = indicatorValues?.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
  switch (indicator.analyticalChange) {
    case AnalyticalChange.YOY: {
      return sortedIndicators
        .filter((dt) => new Date(dt.date).getFullYear() !== new Date(sortedIndicators[0].date).getFullYear())
        .find((dt) => new Date(dt.date).getMonth() === new Date(sortedIndicators[0].date).getMonth());
    }

    case AnalyticalChange.MOM:
      let firstElement = sortedIndicators[0];
      return sortedIndicators.find((indicator) => {
        //if the date is the same as the first el date
        if (new Date(firstElement.date).getFullYear() === new Date(indicator.date).getFullYear()) {
          //if the month is the same, skip else return the indicator
          if (new Date(firstElement.date).getMonth() === new Date(indicator.date).getMonth()) {
            return;
          } else {
            return indicator;
          }
          //if the date is not the same, return the month value
        } else {
          return indicator;
        }
      });

    case AnalyticalChange.QOQ:
      const firstElementQuarter = sortedIndicators[0];
      return sortedIndicators.find((indicator) => {
        if (new Date(firstElementQuarter.date).getFullYear() === new Date(indicator.date).getFullYear()) {
          if (quarterOfTheYear(indicator.date) === quarterOfTheYear(firstElementQuarter.date)) {
            return;
          } else {
            return indicator;
          }
        } else {
          return indicator;
        }
      });

    default:
      return sortedIndicators[0];
  }
};

//utility function to get the quarter of the year given the date
export const quarterOfTheYear = (d: string) => {
  let date = new Date(d);
  return moment(date).quarter();
};
//generate text based on unit type
const unitTypeText = (unitType: string): string => {
  const lang = getCurrentLng();
  if (lang === 'EN') {
    switch (unitType) {
      case 'cumulative':
        return '';
      case 'rate':
        return 'Rate of ';
      case 'share':
        return 'Share of';
      case 'growth':
        return 'Growth rate of';
      default:
        return '';
    }
  } else {
    switch (unitType) {
      case 'cumulative':
        return '';
      case 'rate':
        return 'Norma e  ';
      case 'share':
        return 'Shperndarja e ';
      case 'growth':
        return 'Rritja në përqindje e ';
      default:
        return '';
    }
  }
};

//DELTA CHANGE TEXT OUTOUT
const deltaChange = (delta: number, unitType: string): string => {
  const finalDelta = Math.floor(delta) === delta ? delta : delta.toFixed(1);

  const lang = getCurrentLng();

  const deltaFormated =
    unitType === 'share' || unitType === 'rate' || unitType === 'growth'
      ? delta.toFixed(1)
      : addCommas(finalDelta.toString());
  const showPercentage = unitType === 'share' || unitType === 'rate' || unitType === 'growth' ? ' %' : '';
  switch (lang) {
    case 'EN':
      if (delta > 0) {
        return ` increasing by ${deltaFormated} ${showPercentage} `;
      } else if (delta < 0) {
        return ` decreasing by ${deltaFormated} ${showPercentage}`;
      } else if (delta == 0) {
        return ` not changed`;
      }
      return '';
    case 'SQ':
      if (delta > 0) {
        return `duke u rritur me ${deltaFormated} ${showPercentage}`;
      } else if (delta < 0) {
        return `duke u ulur me ${deltaFormated} ${showPercentage}`;
      } else if (delta == 0) {
        return ` duke mos ndryshuar`;
      }
      return '';
    default:
      return '';
  }
};

//GENERATED COLOR BASED ON INDICATOR.ISPOSITIVENUMBERPOSITIVETREND && DELTA
const generateColor = (indicator: Indicator, delta: number): string => {
  if (indicator.isPositiveNumberPositiveTrend) {
    if (delta > 0) {
      return 'green';
    } else if (delta < 0) {
      return 'red';
    }
    return 'black';
  } else {
    if (delta > 0) {
      return 'red';
    } else if (delta < 0) {
      return 'green';
    } else {
      return 'black';
    }
  }
};

const buildText = (
  indicator: Indicator,
  lastIndicatorValue: IndicatorValues,
  comparableIndicatorValue: IndicatorValues | undefined,
  delta: number,
  unitType: string
): string => {
  const lang = getCurrentLng();
  const unitText = unitType === 'cumulative' ? '_IN_NUMBER' : '_IN_PERCENTAGE';
  const translatedUnitType = i18next.t(`_COMMON.${unitText}`);

  const indLastValue =
    unitType === 'cumulative'
      ? addCommas(lastIndicatorValue.value.toString())
      : lastIndicatorValue.value.toFixed(1) + '%';

  switch (indicator.analyticalChange) {
    case AnalyticalChange.YOY:
      if (lang === 'EN') {
        return comparableIndicatorValue
          ? `As of ${new Date(lastIndicatorValue.date).getFullYear()},  ${
              indicator.nameEN
            }, ${translatedUnitType} , resulted ${indLastValue}, <span style='color:${generateColor(
              indicator,
              delta
            )}'> ${deltaChange(delta, unitType)}</span> compared to ${new Date(
              comparableIndicatorValue.date
            ).getFullYear()}!`
          : '';
      } else {
        return comparableIndicatorValue
          ? `Në ${new Date(lastIndicatorValue.date).getFullYear()}, ${
              indicator.nameSQ
            }, ${translatedUnitType},  rezultoi ${indLastValue}, <span style='color:${generateColor(
              indicator,
              delta
            )}'>${deltaChange(delta, unitType)} </span> krahasuar me ${new Date(
              comparableIndicatorValue.date
            ).getFullYear()}!`
          : '';
      }

    case AnalyticalChange.MOM:
      const monthsSQ = [
        'Janar',
        'Shkurt',
        'Mars',
        'Prill',
        'Maj',
        'Qershor',
        'Korrik',
        'Gusht',
        'Shtator',
        'Tetor',
        'Nëntor',
        'Dhjetor',
      ];
      const monthsEN = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ];
      if (lang === 'EN') {
        return comparableIndicatorValue
          ? `As of ${monthsEN[new Date(lastIndicatorValue.date).getMonth()]} ${new Date(
              lastIndicatorValue.date
            ).getFullYear()}, ${
              indicator?.nameEN
            }, ${translatedUnitType}, resulted ${indLastValue}, <span style='color:${generateColor(
              indicator,
              delta
            )}'> ${deltaChange(delta, unitType)}</span> compared to ${
              monthsEN[new Date(comparableIndicatorValue.date).getMonth()]
            } ${new Date(comparableIndicatorValue.date).getFullYear()}!`
          : '';
      } else {
        return comparableIndicatorValue
          ? `Në  ${monthsSQ[new Date(lastIndicatorValue.date).getMonth()]} ${new Date(
              lastIndicatorValue.date
            ).getFullYear()}, ${
              indicator.nameSQ
            }, ${translatedUnitType},  rezultoi ${indLastValue},  <span style='color:${generateColor(
              indicator,
              delta
            )}'>${deltaChange(delta, unitType)}</span> krahasuar me ${
              monthsSQ[new Date(comparableIndicatorValue.date).getMonth()]
            } ${new Date(comparableIndicatorValue.date).getFullYear()}!`
          : '';
      }

    case AnalyticalChange.QOQ:
      const romanNumber = ['I', 'II', 'III', 'IV'];
      if (lang === 'EN') {
        return comparableIndicatorValue
          ? `As of Quarter ${romanNumber[quarterOfTheYear(lastIndicatorValue.date) - 1]} of ${new Date(
              lastIndicatorValue.date
            ).getFullYear()}, ${
              indicator.nameEN
            }, ${translatedUnitType},resulted ${indLastValue} , <span style='color:${generateColor(
              indicator,
              delta
            )}'> ${deltaChange(delta, unitType)}</span> compared to Quarter ${
              romanNumber[quarterOfTheYear(comparableIndicatorValue.date) - 1]
            } of ${new Date(comparableIndicatorValue.date).getFullYear()}!`
          : '';
      } else {
        return comparableIndicatorValue
          ? `Në tremujorin  ${romanNumber[quarterOfTheYear(lastIndicatorValue.date) - 1]} të ${new Date(
              lastIndicatorValue.date
            ).getFullYear()}, ${indicator.nameSQ}, ${translatedUnitType},  rezultoi ${
              lastIndicatorValue.value
            },  <span style='color:${generateColor(indicator, delta)}'> ${deltaChange(
              delta,
              unitType
            )} </span> krahasuar me tremujorin e  ${
              romanNumber[quarterOfTheYear(comparableIndicatorValue.date) - 1]
            } të ${new Date(comparableIndicatorValue.date).getFullYear()}!`
          : '';
      }
    default:
      return '';
  }
};
