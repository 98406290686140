import React, { useEffect } from 'react';
import InstitutionSubheader from './InstitutionSubheader';
import './institution.css';
import { getInstitutions, getPublicIndicators } from 'api/public';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import InstitutionIndicators from './InstitutionIndicators';
import { Col, Row, Spin } from 'antd';
import i18next from 'i18next';
import { getCurrentLng, indicatorsToHide } from 'utils/helper';
import { getAllSubmodules, getSubmodules } from 'api/submodule';
import { Indicator } from 'models/indicator';
import useWindowDimensions from 'common/hooks/useWindowDimensions';
import IndicatorCard from 'common/components/IndicatorCard/IndicatorCard';
import bufferToImage from 'utils/bufferToImage';
import Submodule from 'models/submodule';
import { useTranslation } from 'react-i18next';
import I18nLink from 'common/components/I18nLink';
import { RightOutlined } from '@ant-design/icons';
import { Institution } from 'models/institution';

const index = () => {
  const { data: institutions, isLoading: loadingInstitutions } = useQuery('allInstitutions', getInstitutions);
  const { data: allIndicators, isLoading: loadingIndicators } = useQuery('indicators', getPublicIndicators);
  const { id } = useParams<{ id: string }>();
  const lang = getCurrentLng();
  const institution = institutions?.find((inst) => inst.id === parseInt(id));
  const institutionIndicators = allIndicators
    ?.filter((indicator) => {
      return indicatorsToHide.every((key) => key !== indicator.key);
    })
    .filter((ind) => ind.institutionId === parseInt(id));
  const { data: submodules, isLoading: loadingSubmodules } = useQuery('submodules', getAllSubmodules);
  const organizedIndicators = organizeIndicatorsindicators(institutionIndicators, submodules);
  const institutionTranslatedName = institution?.[`name${lang}`] || '';

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { t } = useTranslation();
  if (loadingIndicators || loadingInstitutions || loadingSubmodules) {
    return (
      <div style={{ padding: '3.5rem', display: 'grid', placeContent: 'center', height: 300 }}>
        <Spin size="large" />
        {i18next.t('_COMMON._LOADING')}
      </div>
    );
  }

  return (
    <section className="institution-container">
      {institution && <InstitutionSubheader institution={institution} />}
      {organizedIndicators &&
        allIndicators &&
        submodules &&
        institution &&
        organizedIndicators.map((item) => {
          return (
            <InsitutionIndicatorsCategorized
              institution={institution}
              subcategory={item?.submodule}
              indicators={item?.allIndicators}
            />
          );
        })}
      {organizedIndicators && organizedIndicators.length === 0 && (
        <p className="no-ind">
          <h4>{t('_INDICATORS._NO_INDICATORS')}!</h4>
        </p>
      )}
      {/* {allIndicators && institutionIndicators && <InstitutionIndicators indicators={institutionIndicators} />} */}
    </section>
  );
};

export default index;

interface InsitutionIndicatorsCategorizedProps {
  subcategory: Submodule;
  indicators: Indicator[];
  showAll?: boolean;
  institution: Institution;
}

export const InsitutionIndicatorsCategorized: React.FC<InsitutionIndicatorsCategorizedProps> = ({
  indicators,
  subcategory,
  showAll,
  institution,
}) => {
  const { width } = useWindowDimensions();
  const currLang = getCurrentLng();
  const { t } = useTranslation();

  const indicatorsToRender = showAll ? indicators : indicators?.slice(0, 6);
  return (
    <section>
      <div className="inst-header-container">
        <h1>{subcategory?.[`name${currLang}`]}</h1>
        <h1>
          {!showAll && indicators?.length > 6 && (
            <I18nLink to={`/ministry/${institution.id}/indicators/${subcategory.id}`} className="view-all-btn">
              {t('_COMMON._VIEW_ALL')} <RightOutlined />
            </I18nLink>
          )}
        </h1>
      </div>
      <Row gutter={width > 1200 ? [0, 20] : width > 700 ? [0, 80] : width > 400 ? [0, 20] : 0} className="key-data-row">
        {indicatorsToRender.map((indicator, i) => {
          return (
            <Col
              className="key-data-col"
              key={`${indicator}-${i}`}
              xs={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
              xl={{ span: 4 }}>
              <IndicatorCard
                indicator={indicator}
                height={width > 700 ? 320 : 400}
                chartTitle={indicator[`name${currLang}`]}
                path={`/indicator/${indicator.key}`}
                image={bufferToImage(indicator.graph_image?.data)}
              />
            </Col>
          );
        })}
      </Row>
    </section>
  );
};

export function organizeIndicatorsindicators(indicators: Indicator[] | undefined, submodules: Submodule[] | undefined) {
  if (!indicators) return [];
  if (!submodules) return indicators;
  const organizedIndicators = submodules.map((submodule) => {
    const allIndicators = indicators
      .map((indicator) => {
        if (indicator.submoduleId === submodule.id) {
          return indicator;
        }
        return;
      })
      .filter((x) => x);
    return {
      submodule,
      allIndicators,
    };
  });

  return organizedIndicators
    .filter((item) => item.allIndicators.length > 0)
    .sort((a, b) => b.allIndicators.length - a.allIndicators.length);
}
