export const processedData = (data: any, indicatorData, module) => {
  if (indicatorData && module && data) {
    const {
      submodule: { nameEN: nameOfSubmodule },
      nameEN: nameOfIndicator,
    } = indicatorData;
    const { nameEN: moduleName } = module;

    const finalData = data?.map((serie) => {
      const processed = serie?.map((dt) => {
        return {
          ...dt,
          ...dt.variables,
        };
      });
      const removedVariables = processed?.map(
        ({ variables, id, indicatorId, createdBy, updatedBy, createdAt, updatedAt, ...rest }) => {
          return {
            Subtheme: nameOfSubmodule,
            Theme: moduleName,
            'Indicator Name': nameOfIndicator,
            ...rest,
          };
        }
      );
      return removedVariables;
    });
    return finalData?.flat();
  }
  return;
};
