import React from 'react';
import { FlexContainer } from './styled';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  direction?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
  justify?: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around' | 'space-evenly';
  align?: 'flex-start' | 'flex-end' | 'center' | 'baseline' | 'stretch';
  gap?: string;
  children?: React.ReactNode;
}

const Flex = ({ direction = 'row', justify = 'flex-start', align = 'flex-start', gap, ...rest }: Props) => {
  return <FlexContainer direction={direction} justify={justify} align={align} {...rest} gap={gap} />;
};

export default Flex;
