import I18nLink from '../I18nLink';

import './themecard.css';

interface ThemeCardProps {
  image?: string;
  acronym?: string;
  name?: string;
  path: string;
  setShowMenu?: any;
  setDrawerOpen?: any;
}

const ThemeCard: React.FunctionComponent<ThemeCardProps> = ({
  image,
  acronym,
  name,
  path,
  setDrawerOpen,
  setShowMenu,
}) => {
  const hasImage = image ? image.length > 0 : false;
  const hide = () => {
    setDrawerOpen && setDrawerOpen(false);
    setShowMenu && setShowMenu(false);
  };
  return (
    <I18nLink onClick={hide} to={path}>
      <div className="theme-card-wrapper">
        <div className={hasImage ? 'theme-card-text theme-card-text-with-background' : 'theme-card-text'}>
          <h3>{acronym}</h3>
          <h2>{name}</h2>
        </div>
      </div>
    </I18nLink>
  );
};

export default ThemeCard;
