import React from 'react';
import i18next from 'i18next';
import twitter from '../../../Explorer/components/Indicator/components/icons/twitter.svg';
import instagram from '../../../Explorer/components/Indicator/components/icons/instagram.svg';
import message from '../../../Explorer/components/Indicator/components/icons/message.svg';
import linkedin from '../../../Explorer/components/Indicator/components/icons/linkedin.svg';
import download from '../../../Explorer/components/Indicator/components/icons/download.svg';

interface ThemeIframeProps {
  IFrame: string;
  footnote: string;
  dataSource: string;
  institution: string;
}

function Share() {
  return (
    <div style={{ padding: '0' }} className="indicator-footer">
      <div className="txt"></div>
      <div className="share">
        <div>{i18next.t('_THEMES._SHARE')}</div>
        <div>
          <img src={twitter} />
        </div>
        <div>
          <img src={message} />
        </div>
        <div>
          <img src={linkedin} />
        </div>
        <div>
          <img src={instagram} />
        </div>
        <div>
          <img src={download} />
        </div>
      </div>
    </div>
  );
}

const ThemeIframe: React.FunctionComponent<ThemeIframeProps> = ({ IFrame, footnote, dataSource, institution }) => {
  return (
    <div className="theme-iframe">
      <div style={{ height: '100%' }} dangerouslySetInnerHTML={{ __html: IFrame }} />

      <div className="iframe-footer">
        <ul>
          <li>
            {i18next.t('_THEMES._DATA_SOURCE')} :<span> {dataSource}</span>
          </li>
          <li>
            {i18next.t('_THEMES._INSTITUTION')} : <span>{institution}</span>
          </li>
          <li>
            {i18next.t('_THEMES._FOOTNOTE')} :<span> {footnote}</span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ThemeIframe;
