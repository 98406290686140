import * as React from 'react';

export interface Payload {
  nameEN: string;
  nameSQ: string;
  briefs: {
    indicator_id: number;
    storyline: string;
  }[];
}
export interface AddedBriefModel {
  indicator?: string;
  indicatorId: number;
  graph_image?: { type: string; data: Buffer };
  storyline: string;
}

interface IndicatorContextInterface {
  addedBriefs: AddedBriefModel[];
  setAddedBriefs: React.Dispatch<React.SetStateAction<AddedBriefModel[]>>;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export const contextValue: IndicatorContextInterface = {
  addedBriefs: [],
  setAddedBriefs: () => {},
  loading: false,
  setLoading: () => {},
};

export const BriefContext = React.createContext<IndicatorContextInterface>(contextValue);
