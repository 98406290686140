import styled from 'styled-components';
import Input from 'antd/lib/input';
import { InputProps } from './Input';

const StyledInput = styled(Input)`
  border-color: ${(props: InputProps) => props.error && 'red'};
`;

const Label = styled.p`
  margin: 0;
`;

const InputWrapper = styled.div`
  width: ${(props: InputProps) => props.width || '100%'};
`;

export { StyledInput, Label, InputWrapper };
