import './keydata.css';
import { Col, Row, Spin } from 'antd';
import i18n from 'i18next';
import { useQuery } from 'react-query';
import { getKeyIndicators } from 'api/public';
import { getCurrentLng } from 'utils/helper';
import bufferToImage from 'utils/bufferToImage';
import IndicatorCard from 'common/components/IndicatorCard/IndicatorCard';
import useWindowDimensions from 'common/hooks/useWindowDimensions';
import IndicatorCardResponsive from 'common/components/IndicatorCard/IndicatorResponsiveCard';

const KeyData = () => {
  const { width } = useWindowDimensions();
  const currLang = getCurrentLng();
  const {
    data: keyIndicators,
    isLoading,
    isError,
  } = useQuery(['keyIndicators'], getKeyIndicators, {
    retry: 1,
  });
  if (isLoading) {
    return (
      <div
        style={{
          background: 'white',
          display: 'grid',
          placeContent: 'center',
          fontSize: '14px',
          fontWeight: 600,
          height: '300px',
        }}>
        <Spin size="large" />
        {i18n.t('_COMMON._LOADING_KEY_DATA')}
      </div>
    );
  }

  if (width < 700) {
    return (
      <div className="key-data-wrapper">
        <div
          className="explore-ind-test"
          style={{
            paddingLeft: 0,
          }}>
          <span>{i18n.t('_HOME._KEY_DATA._TITLE')}</span>
        </div>
        <section className="section-key">
          {keyIndicators?.slice(0, 4).map((indicator, i) => {
            return (
              <article>
                <IndicatorCardResponsive
                  indicator={indicator}
                  height={width > 700 ? 320 : 100}
                  chartTitle={indicator[`name${currLang}`]}
                  path={`/indicator/${indicator.key}`}
                  image={bufferToImage(indicator.graph_image?.data)}
                />
              </article>
            );
          })}
        </section>
      </div>
    );
  }

  return (
    <div className="key-data-wrapper">
      <div
        className="explore-ind-test"
        style={{
          paddingLeft: 0,
        }}>
        <span>{i18n.t('_HOME._KEY_DATA._TITLE')}</span>
      </div>
      <Row gutter={width > 700 ? [0, 80] : width > 400 ? [0, 20] : 0} justify="space-between" className="key-data-row">
        {keyIndicators?.slice(0, 4).map((indicator, i) => {
          return (
            <Col
              className="key-data-col"
              key={`${indicator}-${i}`}
              xs={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
              xl={{ span: 6 }}>
              <IndicatorCard
                indicator={indicator}
                height={width > 700 ? 420 : 400}
                chartTitle={indicator[`name${currLang}`]}
                path={`/indicator/${indicator.key}`}
                image={bufferToImage(indicator.graph_image?.data)}
              />
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

export default KeyData;
