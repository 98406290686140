import React, { useState } from 'react';
import someImg from 'common/components/ChartCard/chartImage.png';
import { Breadcrumb, notification, Spin } from 'antd';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { deleteBrief, getBriefs } from 'api/public';
import { Button } from 'antd';
import bufferToImage from 'utils/bufferToImage';
import { DownloadOutlined, DeleteOutlined, PlusCircleFilled } from '@ant-design/icons';
import { formatDate } from './helpers';
import { Popconfirm } from 'antd';
import i18n from 'i18next';
import { getCurrentLng } from 'utils/helper';
import I18nLink from 'common/components/I18nLink';

const AllBriefs = () => {
  const lang = getCurrentLng();
  const { data, isError, isLoading, refetch } = useQuery('allbriefs', getBriefs);
  const deleteMutation = useMutation(deleteBrief);
  if (isLoading) {
    return (
      <div style={{ height: '300px', display: 'grid', placeContent: 'center' }}>
        <Spin size="large" />
        <p>{i18n.t('_COMMON._LOADING')}</p>
      </div>
    );
  }

  if (isError) {
    return (
      <p style={{ height: '300px', display: 'grid', placeContent: 'center', fontSize: '25px' }}>
        {i18n.t('_COMMON._ERROR')}
      </p>
    );
  }

  return (
    <>
      {/**BRIEF SUBHEADER*/}
      <div className="brief-subheader">
        <Breadcrumb className="bread-crumb" separator="•" style={{ fontSize: '14px' }}>
          <Breadcrumb.Item>
            <I18nLink style={{ fontWeight: 700 }} to={'/'} className="brief-subheader-link">
              {i18n.t('_COMMON._HOME')}
            </I18nLink>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span style={{ color: 'black', fontWeight: 400 }}>{i18n.t('_BRIEFS._ALL_BRIEFS')}</span>
          </Breadcrumb.Item>
        </Breadcrumb>
        <h1> {i18n.t('_BRIEFS._LIST_ALL_BRIEFS')}</h1>
      </div>

      {/**END OF Brief subheader */}

      {/**ADD BRIEF BUTTON */}
      <div className="add-brief-btn">
        <div
          className="report-view-btn"
          style={{
            width: '100%',
            margin: 'auto',
            padding: '0',
            textAlign: 'center',
          }}>
          <I18nLink
            style={{
              display: 'inline-flex',
              flexDirection: 'column-reverse',
              justifyContent: 'center',
              alignItems: 'center',
              fontFamily: 'Source Sans Pro',
              fontWeight: 700,
            }}
            to={'/add-brief'}>
            <span>{i18n.t('_BRIEFS._ADD_BRIEF')}</span>
            <span
              style={{
                fontSize: '20px',
                marginBottom: '5px',
              }}>
              <PlusCircleFilled />
            </span>
          </I18nLink>
        </div>
      </div>

      {/**ALL BRIEFS LIST  */}
      {data && data.length === 0 && <p className="no-briefs-added">{i18n.t('_BRIEFS._NO_ALL_BRIEFS')}</p>}
      <div className="brief-card-list">
        {data &&
          data
            ?.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
            .map((brief) => {
              return (
                <div className="brief-card">
                  <div className="brief-img-12">
                    <img
                      src={brief.pdfImageThumbnail ? bufferToImage(brief.pdfImageThumbnail.data) : someImg}
                      alt="Brief image"
                    />
                  </div>
                  <div className="date">{formatDate(brief.createdAt)}</div>
                  <div className="category">{brief?.[`name${lang}`]}</div>
                  <div style={{ display: 'flex', justifyContent: 'space-between', padding: '10px' }}>
                    <Button
                      disabled={brief.pdfRepresantation ? false : true}
                      style={{ padding: '3px' }}
                      type="primary"
                      className="dwn-btn"
                      download
                      href={brief.pdfRepresantation}
                      icon={<DownloadOutlined />}>
                      {i18n.t('_BRIEFS._DOWNLOAD')}
                    </Button>

                    <Popconfirm
                      title={i18n.t('_BRIEFS._ARE_YOU_SURE_DELETE')}
                      onConfirm={() =>
                        deleteMutation
                          .mutateAsync(brief.id)
                          .then(() => {
                            refetch();
                          })
                          .catch((err) =>
                            notification['error']({
                              message: i18n.t('_COMMON._ERROR'),
                              duration: 2,
                            })
                          )
                      }
                      okText="Yes"
                      cancelText="No">
                      <Button
                        icon={<DeleteOutlined />}
                        className="delete-btn"
                        style={{ float: 'right' }}
                        type="primary"
                        danger>
                        {i18n.t('_BRIEFS._DELETE')}
                      </Button>
                    </Popconfirm>
                  </div>
                </div>
              );
            })}
      </div>
    </>
  );
};

export default AllBriefs;
