import * as yup from 'yup';
import { translationBasePath, TranslationKeys } from './constants';

export const validationSchema = (t) =>
  yup.object({
    key: yup
      .string()
      .max(1000, t(`${translationBasePath}.${TranslationKeys.textFieldMaxLength}}`))
      .required(t(`${translationBasePath}.${TranslationKeys.fieldRequired}`)),
    acronymEN: yup
      .string()
      .max(50, t(`${translationBasePath}.${TranslationKeys.textFieldMaxLength}}`))
      .required(t(`${translationBasePath}.${TranslationKeys.fieldRequired}`)),
    acronymSQ: yup
      .string()
      .max(50, t(`${translationBasePath}.${TranslationKeys.textFieldMaxLength}}`))
      .required(t(`${translationBasePath}.${TranslationKeys.fieldRequired}`)),
    nameEN: yup
      .string()
      .max(1000, t(`${translationBasePath}.${TranslationKeys.textFieldMaxLength}}`))
      .required(t(`${translationBasePath}.${TranslationKeys.fieldRequired}`)),
    nameSQ: yup
      .string()
      .max(1000, t(`${translationBasePath}.${TranslationKeys.textFieldMaxLength}}`))
      .required(t(`${translationBasePath}.${TranslationKeys.fieldRequired}`)),
    iFrameEN: yup
      .string()
      .max(2000, t(`${translationBasePath}.${TranslationKeys.textFieldMaxLength}}`))
      .required(t(`${translationBasePath}.${TranslationKeys.fieldRequired}`)),
    iFrameSQ: yup
      .string()
      .max(2000, t(`${translationBasePath}.${TranslationKeys.textFieldMaxLength}}`))
      .required(t(`${translationBasePath}.${TranslationKeys.fieldRequired}`)),
    descriptionEN: yup
      .string()
      .max(10000, t(`${translationBasePath}.${TranslationKeys.textFieldMaxLength}}`))
      .required(t(`${translationBasePath}.${TranslationKeys.fieldRequired}`)),
    descriptionSQ: yup
      .string()
      .max(10000, t(`${translationBasePath}.${TranslationKeys.textFieldMaxLength}}`))
      .required(t(`${translationBasePath}.${TranslationKeys.fieldRequired}`)),
    footnoteEN: yup
      .string()
      .max(2000, t(`${translationBasePath}.${TranslationKeys.textFieldMaxLength}}`))
      .required(t(`${translationBasePath}.${TranslationKeys.fieldRequired}`)),
    footnoteSQ: yup
      .string()
      .max(2000, t(`${translationBasePath}.${TranslationKeys.textFieldMaxLength}}`))
      .required(t(`${translationBasePath}.${TranslationKeys.fieldRequired}`)),
    image: yup.string().required(t(`${translationBasePath}.${TranslationKeys.fieldRequired}`)),
    source: yup
      .string()
      .max(2000, t(`${translationBasePath}.${TranslationKeys.textFieldMaxLength}}`))
      .required(t(`${translationBasePath}.${TranslationKeys.fieldRequired}`)),
    institutionId: yup.number().required(t(`${translationBasePath}.${TranslationKeys.fieldRequired}`)),
    isPublic: yup.boolean().required(t(`${translationBasePath}.${TranslationKeys.fieldRequired}`)),
  });
