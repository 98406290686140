import React from 'react';
import { useTranslation } from 'react-i18next';
import { Breadcrumb, Radio, Spin } from 'antd';
import Table from 'common/components/Table/Table';
import { getCurrentLng } from 'utils/helper';
import { useMutation } from 'react-query';
import { useQuery } from 'react-query';
import { getAllInstitutionUsers } from 'api/institution-user';
import { useParams } from 'react-router';
import I18nLink from 'common/components/I18nLink';
import { createThemeUser, getThemeUsers, removeThemeUser } from 'api/theme';
import { CreateThemeUserBody } from 'models/theme';
import { getThemes } from 'api/public';
import { ColumnProps } from 'antd/lib/table';

export const translationBasePath = '_DASHBOARD._MANAGE_THEME_ACCESS_USERS';

const ManageThemeUsers: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const currentLanguage = getCurrentLng();

  const themeId = parseInt(useParams<{ themeId: string }>().themeId, 10);

  const { data: themesData } = useQuery('themes', getThemes);
  const themeTitle =
    themesData && themesData.length > 0
      ? themesData.find((theme) => theme.id === themeId)?.[`name${currentLanguage}`]
      : '';

  const { data, isLoading } = useQuery('getAllInstitutionUsers', getAllInstitutionUsers);
  const {
    data: themeUsersData,
    refetch: themeUsersRefetch,
    isLoading: themeUsersIsLoading,
  } = useQuery(['themeUsers', themeId], () => getThemeUsers(themeId));

  const removeThemeUserMutation = useMutation(['removeThemeUser', themeId], (userId: number) =>
    removeThemeUser(themeId, userId)
  );
  const assignThemeUserMutation = useMutation(['createThemeUser', themeId], (body: CreateThemeUserBody) =>
    createThemeUser(themeId, body)
  );

  /**
   * Transform data for the table
   */
  const dataSource = data?.map((object) => ({
    institution: object.institution[`name${currentLanguage}`],
    email: object.user.email,
    userId: object.user.id,
    institutionRole: object.role,
    role: themeUsersData?.find((o) => o.userId === object.userId && o.themeId === themeId)?.accessType || 'noaccess',
  }));

  /**
   * Unique institutions that are used in the table filter
   */
  const uniqueInstitutions = [...new Set(dataSource?.map((item) => item.institution))];

  const columns: ColumnProps<any>[] = [
    {
      title: t(`${translationBasePath}._COLUMN_INSTITUTION`),
      dataIndex: 'institution',
      key: 'institution_name',
      filters: uniqueInstitutions.map((insitutionName) => ({
        text: insitutionName,
        value: insitutionName,
      })),
      onFilter: (value, record) => record.institution.indexOf(value) === 0,
    },
    {
      title: t(`${translationBasePath}._COLUMN_EMAIL`),
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: t(`${translationBasePath}._COLUMN_ROLE`),
      dataIndex: 'institutionRole',
      key: 'institutionRole',
    },
    {
      title: t(`${translationBasePath}._COLUMN_ACCESS`),
      key: 'role',
      width: 400,
      render: (item) => {
        if (removeThemeUserMutation.isLoading || assignThemeUserMutation.isLoading) {
          return <Spin />;
        }
        return (
          <Radio.Group
            onChange={(variable) => {
              const role = variable.target.value;
              if (role === 'noaccess') {
                removeThemeUserMutation.mutateAsync(item.userId).then(() => {
                  themeUsersRefetch();
                });
              } else {
                assignThemeUserMutation.mutateAsync({ userId: item.userId, accessType: role }).then(() => {
                  themeUsersRefetch();
                });
              }
            }}
            value={item.role}>
            <Radio value="read">{t(`${translationBasePath}._ACCESS_READ`)}</Radio>
            <Radio value="noaccess">{t(`${translationBasePath}._ACCESS_NO_ACCESS`)}</Radio>
          </Radio.Group>
        );
      },
    },
  ];

  return (
    <>
      <Breadcrumb style={{ padding: '10px', fontSize: 20, marginBottom: 10, fontWeight: 'bold' }}>
        <Breadcrumb.Item>
          <I18nLink to="/dashboard/themes">{t('_DASHBOARD._MANAGE_THEMES._MANAGE_THEMES')}</I18nLink>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{themeTitle}</Breadcrumb.Item>
        <Breadcrumb.Item>{t(`${translationBasePath}._TITLE`)}</Breadcrumb.Item>
      </Breadcrumb>
      <Table
        rowClassName="admin-table-row"
        dataSource={dataSource}
        columns={columns}
        loading={isLoading || themeUsersIsLoading}
        pagination={false}
      />
    </>
  );
};

export default ManageThemeUsers;
