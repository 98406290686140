import * as yup from 'yup';

export const translationBasePath = '_DASHBOARD._MANAGE_USERS';
export const translationRolePath = '_USER._ROLE';

const phoneRegExp = /^[+][0-9]{7,}$/;

export const validationSchema = (t) =>
  yup.object({
    email: yup
      .string()
      .email(t(`${translationBasePath}._EMAIL_NOT_VALID`))
      .required(t(`${translationBasePath}._EMAIL_REQUIRED`)),
    phoneNumber: yup
      .string()
      .matches(phoneRegExp, t(`${translationBasePath}._PHONE_NOT_VALID`))
      .required(t(`${translationBasePath}._PHONE_REQUIRED`)),
    role: yup.string().required(t(`${translationBasePath}._ROLE_REQUIRED`)),
  });
