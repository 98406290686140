import { Card, List, Spin, Table } from 'antd';
import { getCurrentUserInfo } from 'api/public';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { getCurrentLng, isCurrentUserAdmin } from 'utils/helper';
import { term } from './common';
import { isNormalUser } from 'utils/helper';

export default () => {
  const { data, isLoading, isError } = useQuery<any>('myInfo', getCurrentUserInfo);
  const t = (title: string) => term(`_account_details._${title}`);
  const { t: _t } = useTranslation();
  if (isError) return <p>{t('error_getting_data')}</p>;

  const lang = getCurrentLng();
  const name = `name${lang}`;
  const columns = [
    {
      title: _t('_INSTITUTION_NAME'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: _t('_ROLE'),
      dataIndex: 'role',
      key: 'role',
    },
  ];
  const tabledata = data?.data?.userInstitutions.map((_user: any) => ({
    key: _user.id,
    name: <a href={_user.institution.url}>{_user.institution[name]}</a>,
    role: _t(`_USER_ROLES._${_user.role.toUpperCase()}`),
  }));

  const Content = () => {
    if (isLoading) {
      return (
        <div
          style={{
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '300px',
          }}>
          <Spin size="large" />
        </div>
      );
    }

    return (
      <>
        <Card title={t('title')} style={{ marginBottom: '2em' }}>
          <p>Email: {data.data.email}</p>
          <p>
            {t('phone')}: {data.data.phoneNumber}
          </p>
          <p>
            {t('role')}: <strong>{_t(`_USER_ROLES._${data.data.role.toUpperCase()}`)}</strong>
          </p>
        </Card>
        {!isNormalUser() && !isCurrentUserAdmin() && (
          <Card title={t('your_institutions')}>
            <Table
              columns={columns}
              dataSource={tabledata}
              locale={{ emptyText: t('no_institutions_found') }}
              pagination={false}
            />
          </Card>
        )}
      </>
    );
  };

  return <Content />;
};
