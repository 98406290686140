import './singleReport.css';
import { DownloadOutlined } from '@ant-design/icons';
import noData from 'common/images/NoData/noData.png';
import { CSSProperties } from 'react';

interface ReportProps {
  image: string | undefined;
  title: string;
  author: string;
  href: string;
  date: Date;
  style?: CSSProperties;
}

const SingleReport: React.FunctionComponent<ReportProps> = ({ style, image, title, href, date, author }) => {
  const formatPublicationDate = (date: Date): string => {
    const dateTimeFormat1 = new Intl.DateTimeFormat('en-GB', { year: 'numeric', day: 'numeric', month: 'short' });
    return dateTimeFormat1.format(new Date(date));
  };
  return (
    <div className="single-report-wrapper" style={style}>
      <a href={href}>
        <img src={image ? image : noData} />
        <div className="txt-content">
          <p className="subtitle">{formatPublicationDate(date)}</p>
          <p className="author">{author}</p>
          <p className="title">
            <span>{title}</span>
            <span style={{ marginLeft: '10px', fontWeight: 'bold' }}>
              <DownloadOutlined />
            </span>
          </p>
        </div>
      </a>
    </div>
  );
};

export default SingleReport;
