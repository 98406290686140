import * as React from 'react';
import Input from 'common/components/Input/Input';
import Button from 'common/components/Button/Button';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { validationSchema } from './themeFormValidation';
import { useMutation } from 'react-query';
import { Col, message, notification, Row, Radio, Space } from 'antd';
import AntForm from 'antd/lib/form';
import Form from 'common/components/Form/Form';
import Select from 'common/components/Select/Select';
import { getBase64, getCurrentLng } from 'utils/helper';
import { TranslationKeys } from './constants';
import { ThemeType, Theme } from 'models/theme';
import { translationBasePath } from './constants';
import { createTheme, updateTheme } from 'api/theme';
import Dragger from 'antd/lib/upload/Dragger';
import { Label } from 'common/components/Input/styled';
import { getAuthData } from 'utils/tokenMgmt';
import { useState } from 'react';
import { InboxOutlined } from '@ant-design/icons';
import { deleteFile, uploadFileUrl } from 'api/file-upload';

interface IFormPublication {
  onClose: () => void;
  refetch: () => void;
  institutions?: any;
  uniqueKeys: string[];
  theme: Theme | null;
}

interface IFormInitialValues {
  key: string;
  type: ThemeType;
  acronymEN: string;
  acronymSQ: string;
  nameEN: string;
  nameSQ: string;
  iFrameEN: string;
  iFrameSQ: string;
  descriptionEN: string;
  descriptionSQ: string;
  footnoteEN: string;
  footnoteSQ: string;
  source: string;
  institutionId?: number;
  image: string;
  isPublic: boolean;
}

const ThemeForm: React.FunctionComponent<IFormPublication> = (props) => {
  const { onClose, refetch, institutions, theme, uniqueKeys } = props;

  const { t } = useTranslation();
  const mutationHook = theme ? useMutation(updateTheme) : useMutation(createTheme);
  const [imageLink, setImageLink] = useState<string>(theme?.image || '');
  const { mutateAsync } = mutationHook;
  const currentLanguage: string = getCurrentLng();
  const nameLngKey = `name${currentLanguage}`;

  const initialValues: IFormInitialValues = {
    key: theme?.key || '',
    type: ThemeType.INTEGRATION,
    acronymEN: theme?.acronymEN || '',
    acronymSQ: theme?.acronymSQ || '',
    nameEN: theme?.nameEN || '',
    nameSQ: theme?.nameSQ || '',
    iFrameEN: theme?.iFrameEN || '',
    iFrameSQ: theme?.iFrameSQ || '',
    descriptionEN: theme?.descriptionEN || '',
    descriptionSQ: theme?.descriptionSQ || '',
    footnoteEN: theme?.footnoteEN || '',
    footnoteSQ: theme?.footnoteSQ || '',
    source: theme?.source || '',
    institutionId: theme?.institutionId,
    image: theme?.image || '',
    isPublic: theme ? theme.isPublic : true,
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema(t),
    onSubmit: (values: Theme) => {
      mutateAsync(values)
        .then(() => {
          onClose();
          formik.resetForm();
          refetch();
        })
        .catch((err) => {
          notification.error({
            message: err.message,
          });
        });
    },
  });

  const uploadThumbnailProps = {
    name: 'file',
    accept: '.jpg,.png',
    multiple: false,
    action: uploadFileUrl,
    headers: { Authorization: `Bearer ${getAuthData()}` },
    maxCount: 1,
    beforeUpload: async (file) => {
      const maxSize = file.size / 1024 / 1024;
      if (maxSize > 1) {
        // 1mb max
        message.error(t(`${translationBasePath}._FILE_TOO_LARGE_PHOTO`));
        return false;
      }
      const base64 = await getBase64(file);

      if (typeof base64 === 'string') {
        setImageLink(base64);
        formik.setFieldValue('image', base64);
      }

      return false;
    },
  };

  const selectInstitutions: any =
    institutions?.map((i) => {
      return {
        value: i ? i.id : '',
        label: i ? i[nameLngKey] : '',
      };
    }) || [];

  const selectType = [
    { value: ThemeType.INTEGRATION, label: t(`${translationBasePath}.${TranslationKeys.integration}`) },
  ];

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Row justify="space-between" gutter={24}>
        <Col span={12} key={1}>
          <Input
            name="key"
            value={formik.values.key}
            label={t(`${translationBasePath}._KEY`) + '*'}
            onChange={(e) => {
              if (!uniqueKeys.includes(e.target.value)) {
                formik.setFieldValue('key', e.target.value);
              } else {
                message.error(t(`${translationBasePath}._UNIQUE_KEY`));
              }
            }}
            error={formik.errors.key}
          />
        </Col>

        <Col span={12} key={2}>
          <AntForm.Item name="type">
            <Select
              data={selectType}
              disabled
              label={t(`${translationBasePath}.${TranslationKeys.themeType}`) + '*'}
              onChange={(e) => {
                formik.setFieldValue('type', e);
              }}
              defaultValue={formik.values.type}
              value={formik.values.type}
              error={formik.errors.type}
            />
          </AntForm.Item>
        </Col>
      </Row>
      <Row justify="space-between" gutter={24}>
        <Col span={12} key={3}>
          <Input
            name="acronymEN"
            value={formik.values.acronymEN}
            label={t(`${translationBasePath}.${TranslationKeys.acronymEN}`) + '*'}
            onChange={formik.handleChange}
            error={formik.errors.acronymEN}
          />
        </Col>
        <Col span={12} key={4}>
          <Input
            name="acronymSQ"
            value={formik.values.acronymSQ}
            label={t(`${translationBasePath}.${TranslationKeys.acronymSQ}`) + '*'}
            onChange={formik.handleChange}
            error={formik.errors.acronymSQ}
          />
        </Col>
      </Row>
      <Row justify="space-between" gutter={24}>
        <Col span={12} key={5}>
          <Input
            name="nameEN"
            value={formik.values.nameEN}
            label={t(`${translationBasePath}.${TranslationKeys.nameEN}`) + '*'}
            onChange={formik.handleChange}
            error={formik.errors.nameEN}
          />
        </Col>
        <Col span={12} key={6}>
          <Input
            name="nameSQ"
            value={formik.values.nameSQ}
            label={t(`${translationBasePath}.${TranslationKeys.nameSQ}`) + '*'}
            onChange={formik.handleChange}
            error={formik.errors.nameSQ}
          />
        </Col>
      </Row>
      <Row justify="space-between" gutter={24}>
        <Col span={12} key={7}>
          <Input
            name="iFrameEN"
            value={formik.values.iFrameEN}
            label={t(`${translationBasePath}.${TranslationKeys.iFrameEN}`) + '*'}
            onChange={formik.handleChange}
            error={formik.errors.iFrameEN}
          />
        </Col>
        <Col span={12} key={8}>
          <Input
            name="iFrameSQ"
            value={formik.values.iFrameSQ}
            label={t(`${translationBasePath}.${TranslationKeys.iFrameSQ}`) + '*'}
            onChange={formik.handleChange}
            error={formik.errors.iFrameSQ}
          />
        </Col>
      </Row>

      <Row justify="space-between" gutter={24}>
        <Col span={12} key={9}>
          <Input
            name="descriptionEN"
            value={formik.values.descriptionEN}
            label={t(`${translationBasePath}.${TranslationKeys.descriptionEN}`) + '*'}
            onChange={formik.handleChange}
            error={formik.errors.descriptionEN}
          />
        </Col>
        <Col span={12} key={10}>
          <Input
            name="descriptionSQ"
            value={formik.values.descriptionSQ}
            label={t(`${translationBasePath}.${TranslationKeys.descriptionSQ}`) + '*'}
            onChange={formik.handleChange}
            error={formik.errors.descriptionSQ}
          />
        </Col>
      </Row>

      <Row justify="space-between" gutter={24}>
        <Col span={12} key={11}>
          <Input
            name="footnoteEN"
            value={formik.values.footnoteEN}
            label={t(`${translationBasePath}.${TranslationKeys.footnoteEN}`) + '*'}
            onChange={formik.handleChange}
            error={formik.errors.footnoteSQ}
          />
        </Col>
        <Col span={12} key={12}>
          <Input
            name="footnoteSQ"
            value={formik.values.footnoteSQ}
            label={t(`${translationBasePath}.${TranslationKeys.footnoteSQ}`) + '*'}
            onChange={formik.handleChange}
            error={formik.errors.footnoteSQ}
          />
        </Col>
      </Row>

      <Row justify="space-between" gutter={24}>
        <Col span={12} key={13}>
          <Input
            name="source"
            value={formik.values.source}
            label={t(`${translationBasePath}.${TranslationKeys.source}`) + '*'}
            onChange={formik.handleChange}
            error={formik.errors.source}
          />
        </Col>
        <Col span={12} key={14}>
          <AntForm.Item name="institutionId">
            <Select
              showSearch
              data={selectInstitutions}
              label={t(`${translationBasePath}._INSTITUTION`) + '*'}
              onChange={(e) => {
                const institution = JSON.parse(JSON.stringify(e));
                formik.setFieldValue('institutionId', parseInt(institution.value));
              }}
              defaultValue={selectInstitutions.find((i) => i.value === formik.values.institutionId)}
              value={selectInstitutions.find((i) => i.value === formik.values.institutionId)}
              filterOption={(input, option) =>
                option && option.children
                  ? option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                  : false
              }
              labelInValue
            />
          </AntForm.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <AntForm.Item label={t(`${translationBasePath}.${TranslationKeys.isPublic}`) + '*'}>
            <Radio.Group
              defaultValue={theme ? theme.isPublic : true}
              onChange={(e) => {
                formik.setFieldValue('isPublic', e.target.value);
              }}>
              <Space direction="horizontal">
                <Radio value={true}>{t(`${translationBasePath}.${TranslationKeys.Public}`)}</Radio>
                <Radio value={false}>{t(`${translationBasePath}.${TranslationKeys.noPublic}`)}</Radio>
              </Space>
            </Radio.Group>
          </AntForm.Item>
        </Col>
      </Row>

      <Row gutter={24}>
        <Col span={24} key={15}>
          <AntForm.Item>
            <Label>{t(`${translationBasePath}.${TranslationKeys.image}`) + '*'}</Label>
            <Dragger {...uploadThumbnailProps}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">{t(`${translationBasePath}.${TranslationKeys.clickToUploadImage}`)}</p>
            </Dragger>
          </AntForm.Item>
        </Col>
      </Row>
      <Button
        htmlType="submit"
        type="primary"
        style={{ marginTop: 10 }}
        disabled={!formik.isValid || !imageLink || formik.values === initialValues}>
        {theme
          ? t(`${translationBasePath}.${TranslationKeys.updateTheme}`)
          : t(`${translationBasePath}.${TranslationKeys.createTheme}`)}
      </Button>
    </Form>
  );
};

export default ThemeForm;
