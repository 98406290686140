import { Publication } from './../models/publication';
import { apiUrl, get, post, put, remove } from 'utils/apiMgmt';
import { deleteFile } from './file-upload';

export const getPublications = async (): Promise<Publication[]> => {
  const { data } = await get(apiUrl + '/publications');
  return data;
};

export const createPublication = async (publication: Publication): Promise<Publication> => {
  publication.type = publication.type?.replace(' ', '_');
  publication.date = new Date(publication.date);
  const { data } = await post(apiUrl + '/publications', publication);
  return data;
};

export const deletePublication = async (publication: Publication): Promise<Publication> => {
  if (publication?.file) {
    await deleteFile({ fileUrl: publication.file });
  }
  const { data } = await remove(apiUrl + '/publications/' + publication.id);
  return data;
};

export const updatePublication = async ({ id, ...payload }: Publication): Promise<Publication> => {
  payload.date = new Date(payload.date);
  payload.type = payload.type?.replace(' ', '_');
  const { data } = await put(apiUrl + '/publications/' + id, payload);
  return data;
};
