import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { getCurrentLng } from 'utils/helper';
import { getReports } from 'api/public';
import { Card, Pagination, Image, Spin } from 'antd';
import './ReportsScreen.css';
import { applyFilter } from '../helpers';
import i18next from 'i18next';
import { PublicationFilters } from 'models/publication';
import NoData from 'common/images/NoData/noData.png';
import { getPublications } from 'api/publication';
import SingleReport from 'pages/Home/components/Reports/SingleReport';
import { useTranslation } from 'react-i18next';
interface IPublications {
  selectedFilters: PublicationFilters;
}
const Publications: React.FunctionComponent<IPublications> = ({ selectedFilters }) => {
  const { t } = useTranslation();

  const currentLang = getCurrentLng();
  const nameTranslated = `name${currentLang}`;
  const { data, isLoading } = useQuery('publications', getPublications);

  const [pagination, setPagination] = useState({
    minValue: 0,
    maxValue: 15,
  });

  const filteredData = applyFilter(data, selectedFilters);
  const showData = filteredData && filteredData.length > 0;
  const handlePaginationChange = (value) => {
    if (value <= 1) {
      setPagination({
        minValue: 0,
        maxValue: 15,
      });
    } else {
      setPagination({
        minValue: pagination.maxValue,
        maxValue: value * 15,
      });
    }
  };

  return (
    <>
      <section className="screen-reports">
        {isLoading && (
          <p style={{ width: '100%', height: '200px', display: 'grid', placeContent: 'center', paddingTop: '1rem' }}>
            <Spin size="large" />
          </p>
        )}
        {!showData && !isLoading && (
          <div className="noReports-image">
            <h3>{t('_COMMON._NO_DATA_FOUND')}</h3>
          </div>
        )}
        <div className="reports-publications">
          {showData &&
            filteredData
              .slice(pagination.minValue, pagination.maxValue)
              .map((val) => (
                <SingleReport
                  key={val.id}
                  image={val.thumbnail}
                  title={val[nameTranslated]}
                  author={val.author}
                  href={val.file || ''}
                  date={val.date}
                />
              ))}
        </div>

        {showData && (
          <div className="publications-pagination">
            <Pagination defaultCurrent={1} defaultPageSize={15} onChange={handlePaginationChange} total={15} />
          </div>
        )}
      </section>
    </>
  );
};

export default Publications;
