import React, { useState } from 'react';
import { deleteModule, getModules } from 'api/module';
import { useQuery, useMutation } from 'react-query';
import Table from 'common/components/Table/Table';
import { Button, Modal, Popconfirm, Space, notification, Row } from 'antd';
import ModuleCreate from 'pages/Dashboard/ManageModules/ModuleCreate';
import Module from 'models/module';
import ModuleUpdate from './ModuleUpdate';
import { translationBasePath } from './ModulesValidationForm';
import { useTranslation } from 'react-i18next';
import { Title } from '../ManageUsers/styled';
import I18nLink from 'common/components/I18nLink';
import { getCurrentLng } from 'utils/helper';

const Modules: React.FunctionComponent = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const { t } = useTranslation();
  const [updating, setUpdating] = useState<Module | null>();
  const { data, isLoading, refetch } = useQuery('modules', getModules);
  const deleteMutation = useMutation(deleteModule);

  const moduleForm = updating ? (
    <ModuleUpdate module={updating} onClose={() => setModalOpen(false)} refetch={() => refetch()} />
  ) : (
    <ModuleCreate onClose={() => setModalOpen(false)} refetch={() => refetch()} />
  );
  const modal = modalOpen && (
    <Modal
      maskClosable={false}
      footer={null}
      visible
      title={updating ? t(`${translationBasePath}._UPDATE_MODULE`) : t(`${translationBasePath}._CREATE_MODULE`)}
      onCancel={() => {
        setModalOpen(false);
        setUpdating(null);
      }}>
      {moduleForm}
    </Modal>
  );

  const nameLngKey = `name${getCurrentLng()}`;

  const columns = [
    {
      key: nameLngKey,
      title: t(`${translationBasePath}._${nameLngKey.toUpperCase()}`),
      dataIndex: nameLngKey,
      render: (text: string, _record: any) => <span>{text}</span>,
    },
    {
      key: 'submodules',
      title: t(`${translationBasePath}._SUBMODULES`),
      dataIndex: 'submodules',
      render: (_text: string, record: any) => <span>{record.submodules.length}</span>,
    },
    {
      title: t(`${translationBasePath}._ACTION`),
      key: 'action',
      dataIndex: 'action',
      render: (_text, record) => (
        <Space size="middle">
          <Button type="link" shape="round" style={{ fontSize: '15px' }}>
            <I18nLink to={`/dashboard/modules/${record.id}/submodules`}>
              {t(`${translationBasePath}._SUBMODULES`)}
            </I18nLink>
          </Button>
          <Button
            style={{ fontSize: '15px' }}
            onClick={() => {
              setModalOpen(true);
              setUpdating(record);
            }}
            shape="round"
            type="link">
            {t(`${translationBasePath}._UPDATE`)}
          </Button>
          <Popconfirm
            title={t(`${translationBasePath}._DELETE_CONFIRM`)}
            onConfirm={() => {
              deleteMutation
                .mutateAsync(Number(record.id))
                .then(() => refetch())
                .catch((err) => {
                  notification.error({
                    message: t(`${translationBasePath}._SUBMODULES_FORBIDDEN_DELETION_ALERT`),
                  });
                });
            }}>
            <Button style={{ fontSize: '15px' }} type="link" shape="round" danger>
              {t(`${translationBasePath}._DELETE`)}
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <Row justify="space-between" style={{ marginBottom: 10 }}>
        <Title>
          <I18nLink style={{ textDecoration: 'none', color: 'black' }} to="/dashboard/modules">
            {t(`${translationBasePath}._MODULES`)}
          </I18nLink>
        </Title>
        <Button
          type="primary"
          onClick={() => {
            setModalOpen(true);
            setUpdating(null);
          }}>
          {t(`${translationBasePath}._ADD_MODULE_BTN`)}
        </Button>
      </Row>
      {modal}
      <Table
        rowClassName="admin-table-row"
        loading={isLoading}
        dataSource={data}
        columns={columns}
        pagination={false}
      />
    </div>
  );
};

export default Modules;
