import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { Row, Table } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { Link } from 'react-router-dom';
import { getCurrentLng } from 'utils/helper';
import { Logs } from 'models/logs';
import { Title } from '../ManageUsers/styled';
import { getLogs } from 'api/logs';
import { messageTranslationEN, messageTranslationSQ } from './MessageTranslator';
import { routeTranslationEN, routeTranslationSQ } from './RouteTranslator';
import { formatDate } from 'utils/helper';
const ManageLogs: React.FunctionComponent = () => {
  const currentLanguage = getCurrentLng();
  const { t } = useTranslation();
  const { data, isLoading } = useQuery('logs', getLogs);

  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const prepareMessages = (record) => {
    if (record.statusCode === 401) {
      return {
        EN: `Endpoint of ${routeTranslationEN[record.route]} was tried to be accessed by a non authenticated user`,
        SQ: `Endpoint of ${routeTranslationSQ[record.route]} u tentua te aksesohej nga nje user i pa-authentikuar`,
      };
    }
    const message = {
      EN: messageTranslationEN(record.User.email)[record.method + ' ' + record.route + ' ' + record.statusCode],
      SQ: messageTranslationSQ(record.User.email)[record.method + ' ' + record.route + ' ' + record.statusCode],
    };
    return message;
  };

  const columns: ColumnProps<any>[] = [
    {
      title: t('_DASHBOARD._MANAGE_LOGS._LOGS'),
      key: `name${currentLanguage}`,
      render: (text, _record: Logs) => {
        return <span>{prepareMessages(_record)[currentLanguage]}</span>;
      },
    },
    {
      title: t('Time'),
      key: `name${currentLanguage}`,
      render: (text, _record: Logs) => {
        return <span>{formatDate(_record.createdAt)}</span>;
      },
    },
  ];
  return (
    <>
      <Row justify="space-between" style={{ marginBottom: 10 }}>
        <Title>
          <Link style={{ color: 'black' }} to="/dashboard/logs">
            {t('_DASHBOARD._MANAGE_LOGS._LOGS')}
          </Link>
        </Title>
      </Row>
      <Table
        rowClassName="admin-table-row"
        loading={isLoading}
        dataSource={data?.filter((dt) => !dt.route.includes('/file-upload/'))}
        columns={columns}
        pagination={{
          current: pageNumber,
          pageSize: pageSize,
          onChange: (page, pageSize) => {
            setPageNumber(page);
            setPageSize(pageSize!);
          },
        }}
      />
    </>
  );
};
export default ManageLogs;
