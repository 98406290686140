import axios from 'axios';
import { getAuthData } from './tokenMgmt';

const apiUrl = process.env.REACT_APP_API_URL;

axios.interceptors.response.use(undefined, (error) => {
  if (error.response.status === 401) {
    localStorage.removeItem('token');
    if (window.location.pathname.includes('login')) {
      return;
    } else {
      window.location.href = '/login';
      return;
    }
  } else {
    return Promise.reject(error);
  }
});

const getHeaders = () => {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: '',
  };

  const authData = getAuthData();
  if (authData) {
    headers.Authorization = `Bearer ${authData}`;
  }
  return headers;
};

const get = (url: string, params: {} = {}, additionalHeaders: {} = {}) =>
  axios.get(url, {
    params,
    headers: Object.assign(getHeaders(), additionalHeaders),
  });

const post = (url: string, params: {} = {}, additionalHeaders: {} = {}) =>
  axios.post(url, params, {
    headers: Object.assign(getHeaders(), additionalHeaders),
  });

const put = (url: string, params: {} = {}, additionalHeaders: {} = {}) =>
  axios.put(url, params, {
    headers: Object.assign(getHeaders(), additionalHeaders),
  });

const patch = (url: string, params: {} = {}, additionalHeaders: {} = {}) =>
  axios.patch(url, params, {
    headers: Object.assign(getHeaders(), additionalHeaders),
  });

const remove = (url: string, data: {} = {}) =>
  axios.delete(url, {
    data: JSON.stringify({ data }),
    headers: getHeaders(),
  });

export { get, post, put, patch, remove, getHeaders, apiUrl };
