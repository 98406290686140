import React from 'react';
import { Content } from 'antd/lib/layout/layout';
import Indicator from 'pages/Explorer/components/Indicator/Indicator';
import { useParams } from 'react-router-dom';

const GraphSnapshot: React.FunctionComponent = () => {
  const { indicatorId } = useParams<{ indicatorId: string }>();

  return (
    <Content className="home-content">
      <Indicator indicatorId={parseInt(indicatorId, 10)} graphSnapshot={true} />
    </Content>
  );
};

export default GraphSnapshot;
