import styled from 'styled-components';
import { Header } from 'antd/lib/layout/layout';
import { Menu } from 'antd';

export const MenuStyled = styled(Menu)`
  justify-content: end;
  border: none;
  font-size: 18px;

  li {
    color: black;
  }
`;
export const HeaderStyled = styled(Header)`
  background: white;
  width: 100%;
  height: auto;
  position: relative;
  margin: auto;
  .ant-layout-header {
    line-height: 10px;
  }
`;

export const TopMenuStyled = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: start;
  align-items: center;

  a {
    color: inherit;
  }
  > li {
    box-sizing: border-box;
    height: 100px;
    padding: 0 1.6em;

    > a {
      background: white;
      display: flex;
      height: 100%;
      color: black;
      text-transform: uppercase;

      font-size: 14px;
      font-weight: 900;
      line-height: 21px;
      letter-spacing: 2.8px;
      font-style: normal;
      font-variant: normal;
      background: white;
      > span {
        display: flex;
        align-items: center;
        position: relative;
        height: 100%;
      }
    }

    .submenu {
      position: absolute;
      left: 0;
      right: 0;
      z-index: 9999;
      top: 100%;
      background: white;
      display: none;
      line-height: 1.6;
      box-shadow: 6px 44px 49px -38px rgba(0, 0, 0, 0.75);
      -webkit-box-shadow: 6px 44px 49px -38px rgba(0, 0, 0, 0.75);
      -moz-box-shadow: 6px 44px 49px -38px rgba(0, 0, 0, 0.75);
    }
    > a > span {
      &::before {
        content: '';
        position: absolute;
        background: black;
        display: block;
        height: 3px;
        top: 0;
        width: 210%;
      }
    }

    &:hover {
      > a > span {
        color: #0e9898;
        &::after {
          content: '';
          position: absolute;
          background: #0e9898;
          display: block;
          height: 3px;
          top: 0;
          width: 100%;
        }
      }
      .submenu {
        display: block;
      }
    }
  }
`;

export const SubmenuStyled = styled.div`
  font-weight: normal;
  padding: 40px;
  padding-top: 10px;
  padding-bottom: 10px;

  @media screen and (max-width: 1280px) {
    padding: 0px;
    transform: translateY(-0px);
    z-index: 20000;
  }
  h2 {
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 16px;
  }
  .submenu-list {
    list-style: none;
    margin: 0;
    padding: 0;
    font-weight: 400;
    > li {
      &:not(:last-child) {
        margin-bottom: 16px;
      }
      a {
        color: inherit;
        display: block;
        flex: 1;
      }
      height: 34px;
      display: flex;
      align-items: center;
    }
  }
`;

export const ListItem = styled.li<{ icon?: any }>`
  padding-left: ${({ icon }) => (icon ? '44px' : '0')};
  ${(props) => (props.icon ? `background: url(${props.icon}) no-repeat center left;` : null)}
`;

export const TopMenuListItemNoHover = styled((props) => <li className={'top-menu-item'}>{props.children}</li>)`
  > a > span {
    position: relative;
    z-index: 20;
  }
`;

export const TopMenuListItem = styled((props) => {
  return (
    <li className={'top-menu-item ' + props.className}>
      <div className="overlay"></div>
      {props.children}
    </li>
  );
})`
  > a > span {
    position: relative;
    z-index: 20;
    line-height: 21px;
    color: black;
  }

  &:hover > .overlay {
    position: fixed;
    background: #15151585;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    &:hover {
      & ~ .submenu {
        display: none;
      }
      display: none;
    }
  }
`;
