import React from 'react';
import { Breadcrumb } from 'antd';
import I18nLink from 'common/components/I18nLink';
import { getUserPayload } from 'utils/helper';
import i18 from 'i18next';
const BriefSubheader = () => {
  return (
    <div className="brief-subheader">
      <Breadcrumb separator="•" style={{ fontSize: '14px' }}>
        <Breadcrumb.Item>
          <I18nLink style={{ fontWeight: 700 }} to={'/'}>
            {i18.t('_COMMON._HOME')}
          </I18nLink>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <I18nLink style={{ color: 'black', fontWeight: 700 }} to={`/${getUserPayload()?.userId || ''}/my-briefs`}>
            {i18.t('_BRIEFS._MY_BRIEFS')}
          </I18nLink>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <I18nLink style={{ fontWeight: 400 }} to={'/add-brief'}>
            {i18.t('_BRIEFS._ADD_BRIEF')}
          </I18nLink>
        </Breadcrumb.Item>
      </Breadcrumb>
      <h1> {i18.t('_BRIEFS._ADD_BRIEF')}</h1>
    </div>
  );
};

export default BriefSubheader;
