import React, { useState } from 'react';
import Modal from 'common/components/Modal/Modal';
import Button from 'common/components/Button/Button';
import { useTranslation } from 'react-i18next';
import AddInstitutionUserForm from './AddInstitutionUserForm';
import { Breadcrumb, notification, Popconfirm, Row, Space } from 'antd';
import Table from 'common/components/Table/Table';
import { getCurrentLng, getUserPayload, isCurrentUserAdmin, isInstitutionUserDirector } from 'utils/helper';
import { useMutation } from 'react-query';
import { useQuery } from 'react-query';
import { getInstitutionUsers, getNotAssignedUsers, removeInstitutionUser } from 'api/institution-user';
import { translationBasePath } from './AddInstitutionUserFormValidation';
import { useParams } from 'react-router';
import I18nLink from 'common/components/I18nLink';
import { getInstitution } from 'api/institution';
import { ColumnProps } from 'antd/lib/table';

const InstitutionUsers: React.FunctionComponent = () => {
  const isAdmin = isCurrentUserAdmin();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const loggedUser = getUserPayload();
  const institutionId = parseInt(useParams<{ institutionId: string }>().institutionId);
  const deleteMutation = useMutation(removeInstitutionUser);
  const currentLanguage = getCurrentLng();
  const nameLngKey = `name${currentLanguage}`;
  const { data, refetch, isLoading } = useQuery(
    ['institutionUsers', institutionId],
    () => getInstitutionUsers(institutionId),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
    }
  );
  const {
    data: nonInstitutionUsers,
    isLoading: isLoadingNotAssignedUsers,
    refetch: refetchNotAssignedUsers,
  } = useQuery('notAssignedUsers', getNotAssignedUsers);

  const { data: institution, isLoading: isLoadingInstitution } = useQuery(
    ['institution', institutionId],
    () => getInstitution(institutionId),
    {
      //perform the query only if the logged user is an admin
      enabled: isAdmin,
      refetchOnWindowFocus: false,
    }
  );

  const currentInstitutionUser = data?.find((institutionUser) => institutionUser.userId === loggedUser.userId);
  const isCurrentUserDirector = currentInstitutionUser ? isInstitutionUserDirector(currentInstitutionUser) : false;

  const modal = open && (
    <Modal
      maskClosable={false}
      footer={null}
      visible
      title={t(`${translationBasePath}._ASSIGN_USER`)}
      onCancel={() => {
        setOpen(false);
      }}>
      <AddInstitutionUserForm
        refetch={refetch}
        users={nonInstitutionUsers || []}
        onClose={() => setOpen(false)}
        institutionId={institutionId}
        refreshUserList={refetchNotAssignedUsers}
      />
    </Modal>
  );

  const header = (
    <Row justify="space-between" style={{ marginBottom: 10 }}>
      <Breadcrumb style={{ fontSize: 20, fontWeight: 'bold' }}>
        <Breadcrumb.Item>
          <I18nLink to="/dashboard/institutions">{t(`${translationBasePath}._INSTITUTIONS`)}</I18nLink>
        </Breadcrumb.Item>
        {institution && <Breadcrumb.Item>{institution[nameLngKey]} </Breadcrumb.Item>}
        <Breadcrumb.Item> {t(`${translationBasePath}._MANAGE_INSTITUTION_USERS`)}</Breadcrumb.Item>
      </Breadcrumb>
      {isAdmin && (
        <Button
          onClick={() => setOpen(true)}
          disabled={!nonInstitutionUsers?.length || !(isAdmin || isCurrentUserDirector)}>
          {t(`${translationBasePath}._ASSIGN_USER`)}
        </Button>
      )}
    </Row>
  );
  const columns: ColumnProps<any>[] = [
    {
      key: 'id',
      title: t(`${translationBasePath}._ID`),
      dataIndex: 'id',
      render: (text: string, _record) => {
        return <span>{text}</span>;
      },
    },
    {
      key: 'email',
      title: t(`${translationBasePath}._EMAIL`),
      dataIndex: 'user',
      render: (_text: string, record) => {
        return <span>{record.user.email}</span>;
      },
    },
    {
      key: 'role',
      title: t(`${translationBasePath}._ROLE`),
      dataIndex: 'role',
      render: (text: string, _record) => {
        return <span>{text}</span>;
      },
    },
    isAdmin
      ? {
          title: t(`${translationBasePath}._ACTION`),
          key: 'action',
          dataIndex: 'action',
          render: (_text, record) => (
            <Space size="middle">
              {isAdmin && (
                <>
                  {(isAdmin || record.role !== 'director') && ( // Don't allow directors to delete other directors or themselves
                    <Popconfirm
                      title={t(`${translationBasePath}._REMOVE_INSTITUTION_USER_CONFIRM`)}
                      onConfirm={() => {
                        deleteMutation
                          .mutateAsync(Number(record.userId))
                          .then(() => {
                            refetch();
                            refetchNotAssignedUsers();
                          })
                          .catch((err) => notification.error({ message: err.message }));
                      }}>
                      <Button type="link" shape="round" danger>
                        {t(`${translationBasePath}._REMOVE_BTN`)}
                      </Button>
                    </Popconfirm>
                  )}
                </>
              )}
            </Space>
          ),
        }
      : {},
  ];
  return (
    <>
      {header}
      {modal}
      <Table
        loading={isLoading || isLoadingInstitution || isLoadingNotAssignedUsers}
        dataSource={data}
        columns={columns}
        pagination={false}
      />
    </>
  );
};

export default InstitutionUsers;
