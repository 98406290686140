import React from 'react';
import { Breadcrumb } from 'antd';
import i18next from 'i18next';
import { Link } from 'react-router-dom';
import I18nLink from 'common/components/I18nLink';

interface ThemeSubheaderParams {
  title: string;
  description: string;
  themeKey: string;
  ness?: boolean;
}
I18nLink;
const ThemeSubheader: React.FunctionComponent<ThemeSubheaderParams> = ({ title, description, themeKey, ness }) => {
  return (
    <div className={ness ? 'theme-subheader-wrapper ness' : 'theme-subheader-wrapper'}>
      <Breadcrumb separator="•">
        <Breadcrumb.Item>
          <I18nLink to="/">{i18next.t('_COMMON._HOME')}</I18nLink>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <I18nLink to="/themes">{i18next.t('_COMMON._THEMES')}</I18nLink>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <I18nLink to={`/themes/${themeKey}`}>{title}</I18nLink>
        </Breadcrumb.Item>
      </Breadcrumb>
      <div className="theme-subheader-title">{title}</div>
      <div className="theme-subheader-content">{description}</div>
    </div>
  );
};

export default ThemeSubheader;
