import PlatformAbout from 'pages/Platform/About';
import PlatformCiting from 'pages/Platform/Citing';
import PlatformContact from 'pages/Platform/Contact';
import PlatformDataSources from 'pages/Platform/DataSources';
import PlatformFAQ from 'pages/Platform/FAQ';
import PlatformPrinciples from 'pages/Platform/Principles';
import PlatformSocialMedia from 'pages/Platform/SocialMedia';

export const platformPages = [
  {
    title: '_FOOTER._ABOUT_LINK',
    path: '/platform/about',
    component: PlatformAbout,
  },
  {
    title: '_FOOTER._CITING',
    path: '/platform/citing',
    component: PlatformCiting,
  },
  {
    title: '_FOOTER._CONTACT_US',
    path: '/platform/contact',
    component: PlatformContact,
  },
  {
    title: '_FOOTER._DATA_SOURCES',
    path: '/platform/data-sources',
    component: PlatformDataSources,
  },
  {
    title: '_FOOTER._FAQ',
    path: '/platform/faq',
    component: PlatformFAQ,
  },
  {
    title: '_FOOTER._PRINCIPLES',
    path: '/platform/principles',
    component: PlatformPrinciples,
  },
  {
    title: '_FOOTER._SOCIAL_MEDIA',
    path: '/platform/social-media',
    component: PlatformSocialMedia,
  },
];
