import * as React from 'react';
import { Redirect, Route, RouteProps } from 'react-router';
import { Menu, Layout } from 'antd';
import { MailOutlined, AppstoreOutlined, SettingOutlined } from '@ant-design/icons';
import useWindowDimensions from 'common/hooks/useWindowDimensions';
import PageHeader from 'common/components/Header/Header';
import PageFooter from 'common/components/Footer/Footer';
import { Content } from 'antd/lib/layout/layout';
import AdminSidebar from 'common/components/AdminSidebar';
import { isCurrentUserAdmin, isSessionActive, isInstitutionUser } from 'utils/helper';

interface IProps extends RouteProps {
  component: any;
  hideAdminSidebar?: boolean;
}

const adminRoute = ({ component: Component, hideAdminSidebar, ...rest }: IProps) => {
  const { width } = useWindowDimensions();
  const isAuthenticated = isSessionActive();
  const isAdminORInstitutionuser = isCurrentUserAdmin() || isInstitutionUser();
  //current location
  const location = rest.location?.pathname;
  //allowed routes for an institution user
  const allowedInstitutionRoutes = ['dashboard/institutions', 'dashboard/indicator-values', 'graph_snapshot'];
  //check if current route includes one of allowed routes-> institution user has access
  const instUserhasAccess = allowedInstitutionRoutes.some((route) => {
    return location?.includes(route);
  });

  if (!isAuthenticated) {
    return (
      <Route
        {...rest}
        render={(props) => {
          return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />;
        }}
      />
    );
  }
  return (
    <div style={{ width: '100%', position: 'relative', background: 'white', overflowX: 'hidden' }}>
      <PageHeader />
      <Layout style={{ maxWidth: '1500px', margin: 'auto', backgroundColor: 'white' }}>
        <Content style={{ padding: width > 700 ? '0 3.5em' : '0 1.5rem', minHeight: '700px' }}>
          <AdminSidebar />
          {/*IF IT IS AN INSTITUTIONAL USER, CHECK FOR ACCESS,  OTHERIWISE=>ADMIN, ALL ARE ACCESSIBLE*/}
          {isInstitutionUser() && !instUserhasAccess ? (
            <Route
              render={(props) => {
                return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />;
              }}
            />
          ) : (
            <Route
              {...rest}
              render={(props) => {
                if (isAuthenticated && isAdminORInstitutionuser) {
                  return <Component {...props} />;
                } else {
                  return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />;
                }
              }}
            />
          )}
        </Content>
        <PageFooter />
      </Layout>
    </div>
  );
};

export default adminRoute;
