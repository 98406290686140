import * as yup from 'yup';

export const translationBasePath = '_LOGIN';

export const validationSchema = (t) =>
  yup.object({
    email: yup
      .string()
      .email(t(`${translationBasePath}._EMAIL_NOT_VALID`))
      .required(t(`${translationBasePath}._EMAIL_REQUIRED`)),
  });
