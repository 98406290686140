import { useState } from 'react';
import Modal from 'common/components/Modal/Modal';
import { getUsers, resendInvite } from 'api/user';
import { useQuery } from 'react-query';
import Button from 'common/components/Button/Button';
import { useTranslation } from 'react-i18next';
import AddUserForm from './AddUserForm';
import { notification, Popconfirm, Space, Tag } from 'antd';
import Table from 'common/components/Table/Table';
import { isCurrentUserAdmin } from 'utils/helper';
import { User } from 'models/user';
import { useMutation } from 'react-query';
import { deleteUser } from 'api/user';
import { TitleBar, Title } from './styled';
import { ColumnProps } from 'antd/lib/table';
import { translationBasePath, translationRolePath } from './AddUserFormValidation';

const UserList: React.FunctionComponent = () => {
  const isAdmin = isCurrentUserAdmin();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [updating, setUpdating] = useState<User | null>(null);
  const { data, isLoading, refetch } = useQuery('users', getUsers);
  const title = <Title>{t(`${translationBasePath}._TITLE`)}</Title>;
  const deleteMutation = useMutation(deleteUser);
  const resendInviteMutation = useMutation(resendInvite);

  const columns: ColumnProps<any>[] = [
    {
      key: 'id',
      title: t(`${translationBasePath}._ID`),
      dataIndex: 'id',
      render: (text, _record) => {
        return <span>{text}</span>;
      },
    },
    {
      key: 'email',
      title: t(`${translationBasePath}._EMAIL`),
      dataIndex: 'email',
      render: (text, _record) => {
        return <span>{text}</span>;
      },
    },
    {
      key: 'phoneNumber',
      title: t(`${translationBasePath}._PHONENUMBER`),
      dataIndex: 'phoneNumber',
      render: (text, _record) => {
        return <span>{text}</span>;
      },
    },
    {
      key: 'role',
      title: t(`${translationBasePath}._ROLE`),
      dataIndex: 'role',
      render: (text, _record) => {
        return t(`${translationRolePath}._${text.toUpperCase()}`);
      },
    },
    {
      key: 'status',
      title: t(`${translationBasePath}._STATUS`),
      dataIndex: 'role',
      render: (text, _record) => {
        const status = text ? 'ACTIVE' : 'PENDING';
        return (
          <Tag color={status === 'ACTIVE' ? 'green' : 'orange'}>
            {t(`${translationBasePath}._${status.toUpperCase()}`)}
          </Tag>
        );
      },
    },
    {
      title: t(`${translationBasePath}._ACTION`),
      key: 'action',
      dataIndex: 'action',
      render: (_text, record: User) => (
        <Space size="middle">
          {isAdmin && (
            <>
              {
                <Button
                  style={{ fontSize: '15px' }}
                  disabled={record.lastLogin ? true : false}
                  onClick={() => {
                    resendInviteMutation
                      .mutateAsync(Number(record.id))
                      .then(() => {
                        notification.success({
                          message: t(`${translationBasePath}._ALERT_SUCCESS_MESSAGE`),
                        });
                      })
                      .catch((error) => {
                        notification.error({
                          message: t(`${translationBasePath}._ALERT_ERROR_MESSAGE`),
                        });
                        console.log(error);
                      });
                  }}
                  type="link"
                  shape="round">
                  {t(`${translationBasePath}._RESEND_INVITE`)}
                </Button>
              }
              <Button
                onClick={() => {
                  setOpen(true);
                  setUpdating(record);
                }}
                style={{ fontSize: '15px' }}
                type="link"
                shape="round">
                {t(`${translationBasePath}._UPDATE_BTN`)}
              </Button>
              {
                <Popconfirm
                  title={t(`${translationBasePath}._DELETE_USER_CONFIRM`)}
                  onConfirm={() => {
                    deleteMutation
                      .mutateAsync(Number(record.id))
                      .then(() => refetch())
                      .catch((err) => console.log(err));
                  }}>
                  <Button
                    type="link"
                    shape="round"
                    disabled={record.role !== 'admin' ? false : true}
                    style={{ fontSize: '15px' }}
                    danger>
                    {t(`${translationBasePath}._DELETE_BTN`)}
                  </Button>
                </Popconfirm>
              }
            </>
          )}
        </Space>
      ),
    },
  ];
  return (
    <>
      <TitleBar align="center" justify="space-between">
        {title}
        <Button
          onClick={() => {
            setOpen(true);
            setUpdating(null);
          }}>
          {t(`${translationBasePath}._INVITE_USER`)}
        </Button>
      </TitleBar>
      {open && (
        <Modal
          maskClosable={false}
          footer={null}
          visible
          title={updating ? t(`${translationBasePath}._UPDATE_USER`) : t(`${translationBasePath}._INVITE_USER`)}
          onCancel={() => {
            setOpen(false);
            setUpdating(null);
          }}>
          <AddUserForm
            uniqueUsers={data ? data.map((i) => i?.email || '') : []}
            updating={updating}
            refetch={refetch}
            onClose={() => setOpen(false)}
          />
        </Modal>
      )}
      <Table
        rowClassName="admin-table-row"
        loading={isLoading}
        dataSource={data
          ?.sort((a, b) => a.id - b.id)
          ?.filter((user) => user.email !== 'system@softup.co')
          ?.filter((user) => user.role !== 'technical')}
        columns={columns}
        pagination={false}
      />
    </>
  );
};

export default UserList;
