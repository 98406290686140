/* eslint-disable max-len */
export default {
  translation: {
    _USER_ROLES: {
      _INSTITUTION_USER: 'Institution User',
      _ADMIN: 'Admin',
      _DIRECTOR: 'Director',
      _NORMAL: 'Normal',
    },
    _CLOSE: 'Close',
    _UPDATE: 'Update',
    _UPDATING: 'Updating...',
    _INSTITUTION_NAME: 'Institution Name',
    _WEAK_PASSWORD: `The password must be at least 8 characters long, contain an uppercase letter, a number and a symbol`,
    _ROLE: 'Role',
    _HOME: {
      _LOADING: 'Loading ...',
      _VIEW_ALL_THEMES: 'View All Themes',
      _EXPLORE_DATA: 'Explore Data',
      _MINISTRIES: 'Ministries',
      _CATEGORIES: 'Categories',
      _INSTITUTIONS: 'Institutions',
      _THEMES: 'Themes',
      _SOURCE: 'Source',
      _INTRO: {
        _DIGITAL: 'A Digital Data Platform',
        _INSIGHTS: 'That provide readable insights into albanian institutions data',
        _ABOUT_US: 'About us',
        _LEARN_MORE: 'Learn more about project',
      },
      _KEY_DATA: {
        _TITLE: 'Key Data',
        _SUBTITLE: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry',
      },
      _EXPLORE: {
        _TITLE: 'Economic development tracker',
        _SUBTITLE: '',
      },
      _PARTNERS: {
        _PARTNERS: 'PARTNERS',
        _SUBTITLE: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry',
      },
      _REPORTS: {
        _REPORTS: 'Reports',
        _SUBTITLE: '',
        _MAIN_REPORT_TITLE: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry',
        _MAIN_REPORT_SUBTITLE: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry',
      },
    },
    _FOOTER: {
      _PLATFORM: 'Platform',
      _INSTITUTIONS: 'Institutions',
      _PARTNERS: 'Partners',
      _DIGITAL_PLATFORM: 'A digital data platform',
      _DIGITAL_PLATFORM_TEXT: 'That provide readable insights into albanian institutions',
      _ABOUT_LINK: 'ABOUT EDT',
      _FAQ: 'FAQ',
      _DATA_SOURCES: 'Data Sources',
      _PRINCIPLES: 'Principles',
      _CITING: 'Citing',
      _SOCIAL_MEDIA: 'Social Media',
      _CONTACT_US: 'Contact Us',
      _MFE: 'MFE',
      _INSTAT: 'INSTAT',
      _NAES: 'NAES',
      _NAVETQ: 'NAVETQ',
      _GDT: 'GDT',
      _SII: 'SII',
      _MESY: 'MESY',
    },
    _HEADER: {
      _DATA: 'Data',
      _THEMES: 'Themes',
      _STRATEGIES: 'Strategies',
      _EXPLORE: 'Explore',
      _ABOUT: 'About',
      _GOALS: 'Goals',
      _LOGIN: 'Log In',
      _LOGOUT: 'Log Out',
      _REPORTS: 'Reports',
      _ALBANIAN: 'Albanian',
      _BY_TOPIC: 'By Topic',
      _BY_INSTITUTION: 'By Institution',
      _ALL_INSTITUTIONS: 'All Institutions',
    },
    _SUBHEADER: {
      _DATA: 'Data',
      _EMPLOYMENT: 'Employment',
      _EMPLOYMENT_CATEGORY: 'Employment and Unemployment from LFS',
    },
    _LOGIN: {
      _SUCCESS: 'Link to reset password was successfully sent!',
      _EMAIL: 'Email',
      _PASSWORD: 'Password',
      _EMAIL_NOT_VALID: 'Enter a valid email',
      _EMAIL_REQUIRED: 'Please enter your email',
      _PASSWORD_REQUIRED: 'Please enter your password',
      _FORGOT_PASSWORD_LINK: 'Forgot password?',
      _SUBMIT: 'Login',
      _WRONG_CREDENTIALS: 'Wrong password and email combination. Try again!',
      _WRONG_CODE: 'Wrong code inserted! Try again',
      _MFA: {
        _CODE: 'Code',
        _CODE_REQUIRED: 'Please enter the code',
        _SUBMIT: 'Submit',
        _DIDNT_RECEIVE_A_CODE: "Didn't receive a code via SMS?",
        _RESEND_CODE: 'Resend Code',
      },
      _RESET_PASSWORD: {
        _NEW_PASSWORD: 'New password',
        _NEW_PASSWORD_REQUIRED: 'Please enter your new password',
        _CONFIRM_PASSWORD: 'Confirm password',
        _CONFIRM_PASSWORD_REQUIRED: 'Please confirm your password',
        _CONFIRM_PASSWORD_NOT_MATCH: 'Passwords do not match',
        _SUCCESS: 'Password reset successfully',
        _SUBMIT: 'Submit',
      },
      _FORGOT_PASSWORD: {
        _EMAIL: 'Email',
        _SUCCESS: 'Please check your email. We have sent to you the reset password link.',
        _SUBMIT: 'Submit',
        _REQUEST_RESET_PWD_LINK: 'Request reset password link',
        _ENTER_EMAIL: 'Enter your platform email',
      },
    },
    _DASHBOARD: {
      _HEADER: {
        _MANAGE_USERS: 'Manage users',
      },
      _MANAGE_LOGS: {
        _LOGS: 'Logs',
        _MANAGE_LOGS: 'Manage Logs',
      },
      _SIDEBAR: {
        _MANAGE_USERS: 'Users',
        _MANAGE_PUBLICATIONS: 'Publications',
        _MANAGE_INDICATORS: 'Indicators',
        _MANAGE_INDICATOR_VALUES: 'Indicator Values',
        _MANAGE_MODULES: 'Modules',
        _MANAGE_INSTITUTIONS: 'Institutions',
        _MANAGE_THEMES: 'Themes',
      },
      _MANAGE_THEMES: {
        _CREATE_THEME: 'Create Theme',
        _MANAGE_THEMES: 'Manage Themes',
        _MANAGE_USERS: 'Manage Access',
        _UPDATE_THEME: 'Update Theme',
        _DELETE_CONFIRM: 'Are you sure you want to delete this theme?',
        _KEY: 'Key',
        _ACTION: 'Action',
        _TYPE: 'Type',
        _UPDATE: 'Update',
        _PREVIEW: 'Preview',
        _DELETE: 'Delete',
        _ACRONYMEN: 'Acronym EN',
        _ACRONYMSQ: 'Acronym SQ',
        _NAMEEN: 'Name (EN)',
        _NAMESQ: 'Name (SQ)',
        _DESCRIPTIONEN: 'Description (EN)',
        _DESCRIPTIONSQ: 'Description (SQ)',
        _INSTITUTION: 'Institution',
        _FOOTNOTEEN: 'Footnote (EN)',
        _FOOTNOTESQ: 'Footnote (SQ)',
        _SOURCE: 'Source',
        _TEXT_FIELD_MAX_LENGTH: 'Write a shorter text',
        _FIELD_REQUIRED: 'This field is required',
        _THEMETYPE: 'Theme Type',
        _CUSTOM: 'Custom',
        _INTEGRATION: 'Integration',
        _IFRAMEEN: 'IFrame (EN)',
        _IFRAMESQ: 'IFrame (SQ)',
        _LOADING_THEMES: 'Loading themes...',
        _IMAGE: 'Image',
        _CLICK_OR_DRAG_TO_UPLOAD_IMAGE: 'Click or drag to upload an image',
        _IS_PUBLIC: 'Is Theme public',
        _NO_PUBLIC: 'Not Public',
        _PUBLIC: 'Public',
        _FILE_TOO_LARGE_PHOTO: 'Your photo is too large. Provide a photo smaller than 1MB.',
        _FILE_TOO_LARGE: 'File too large',
        _UNIQUE_KEY: 'Key should be unique',
      },
      _MANAGE_INSTITUTIONS_USERS: {
        _MANAGE_INSTITUTION_USERS: 'Institution Users',
        _ID: 'ID',
        _INSTITUTIONS: 'Institutions',
        _ASSIGN_USER: 'Assign User',
        _REMOVE_BTN: 'Remove',
        _FIELD_REQUIRED: 'This field is required',
        _REMOVE_INSTITUTION_USER_CONFIRM: 'Are you sure you want to remove this user from the institution?',
        _ACTION: 'Action',
        _ROLE: 'Role',
        _EMAIL: 'User',
        _DIRECTOR: 'Director',
        _NORMAL: 'Normal',
        _NO_USERS: ' There are no Institutions Users to show!',
      },
      _MANAGE_USERS: {
        _ID: 'ID',
        _TITLE: 'Manage Users',
        _INVITE_USER: 'Invite User',
        _UPDATE_USER: 'Update User',
        _EMAIL: 'Email',
        _PHONENUMBER: 'Phone number',
        _ROLE: 'Role',
        _EMAIL_NOT_VALID: 'Enter a valid email',
        _EMAIL_REQUIRED: 'Please enter the email',
        _PHONE_REQUIRED: 'Please enter the phone number',
        _PHONE_NOT_VALID: 'Please enter a valid phone number',
        _ROLE_REQUIRED: 'Please select your role',
        _INVITE_USER_BTN: 'Invite User',
        _UPDATE_USER_BTN: 'Update User',
        _INSTITUTION_USER: 'Institution User',
        _ADMIN: 'Admin',
        _TECHNICAL: 'Technical',
        _NORMAL: 'Normal',
        _UPDATE_BTN: 'Update',
        _DELETE_BTN: 'Delete',
        _DELETE_USER_CONFIRM: 'Are you sure you want to delete this user?',
        _STATUS: 'Status',
        _RESEND_INVITE: 'Resend Invite',
        _ACTIVE: 'Active',
        _PENDING: 'Pending',
        _ALERT_SUCCESS_MESSAGE: 'Invitation sent',
        _ALERT_ERROR_MESSAGE: 'Something went wrong',
        _LOADING_USERS: 'Loading users...',
        _ACTION: 'Action',
        _ERROR_EMAIL: 'Email already exists on the platform!',
      },
      _MANAGE_SUBMODULES: {
        _NAME_MAX_LENGTH: 'Write a shorter name',
        _FIELD_REQUIRED: 'This field is required',
        _NAMEEN: 'Name (EN)',
        _NAMESQ: 'Name (SQ)',
        _ID: 'ID',
        _SUBMODULES: 'Submodules',
        _ADD_SUBMODULE_BTN: 'Add a submodule',
        _DELETE_CONFIRM: 'Are you sure you want to delete this submodule?',
        _CREATE_SUBMODULE: 'Create submodule',
        _UPDATE_SUBMODULE: 'Update submodule',
        _SAVING: 'Saving...',
        _ERROR: 'Error during save',
        _SAVE: 'Save',
        _DELETE: 'Delete',
        _UPDATE: 'Update',
        _ACTION: 'Action',
        _INDICATORS: 'Indicators',
        _LOADING_SUBMODULES: 'Loading Submodules...',
      },
      _MANAGE_INDICATORS: {
        _MANAGE_INDICATORS: 'Manage Indicators',
        _DETAILS: 'Details',
        _ID: 'ID',
        _KEY: 'Key',
        _INDICATOR_DETAILS: 'Indicator Details',
        _VARIABLES_MIN_LENGTH: 'You should have at least one variable',
        _FREQUENCY_MIN_LENGTH: 'You should select at least one frequency',
        _TEXT_FIELD_MAX_LENGTH: 'Write a shorter name',
        _FIELD_REQUIRED: 'This field is required',
        FREQUENCY_REQUIRED: 'The `frequency` field should be completed first',
        _NAMEEN: 'Name (EN)',
        _NAMESQ: 'Name (SQ)',
        _NAME_EN: 'Name (EN)',
        _NAME_SQ: 'Name (SQ)',
        _DESCRIPTIONEN: 'Description (EN)',
        _DESCRIPTIONSQ: 'Description (SQ)',
        _SUBMODULE: 'Submodule',
        _INSTITUTION: 'Institution',
        _FREQUENCY: 'Frequency',
        _UNIT_TYPE: 'Unit Type',
        _UNIT_METHOD: 'Unit Method',
        _MONTHLY: 'Monthly',
        _YEARLY: 'Yearly',
        _QUARTERLY: 'Quarterly',
        _INT: 'INT',
        _FLOAT: 'FLOAT',
        _NUMBER: 'Number',
        _PERCENTAGE: 'Percentage',
        _ACC: 'ACC',
        _AVG: 'AVG',
        _NONE: 'NONE',
        _CREATE_INDICATOR: 'Create Indicator',
        _DELETE_CONFIRM: 'Are you sure you want to delete this indicator?',
        _VIEW_DETAILS: 'Details',
        _SAVING: 'Saving...',
        _ERROR: 'Error during save',
        _SAVE: 'Save',
        _DELETE: 'Delete',
        _ACTION: 'Action',
        _INDICATORS: 'Manage Indicators',
        _CSV_TEMPLATE: 'CSV Template',
        _MANAGE_USERS: 'Manage Access',
        _VARIABLES: 'Variables',
        _ANALYTICAL_CHANGE: 'Analytical Change',
        _IS_NUMBER_POSITIVE_TREND: 'Is Number Positive Trend',
        _IS_INDICATOR_PUBLIC: 'Is Indicator Public',
        _PUBLIC: 'Public',
        _NOT_PUBLIC: 'Not Public',
        _DATASET: 'Dataset',
        _DESCRIPTION_EN: 'DESCRIPTION (EN)',
        _DESCRIPTION_SQ: 'DESCRIPTION (SQ)',
        _YOY: 'YoY',
        _QOQ: 'QoQ',
        _MOM: 'MoM',
        _CREATE: 'CREATE',
        _UPDATE: 'Update',
        _POSITIVE: 'Positive',
        _NEGATIVE: 'Negative',
        _UNIT: 'UNIT',
        _PERIODICITY: 'PERIODICITY',
        _VARIABLE_KEY: 'Variable Key',
        _VARIABLE_NAME: 'Variable Name',
        _VARIABLE_OPTIONS: 'Variable Options',
        _LOADING_INDICATORS: 'Loading indicators...',
        _MANAGE_VARIABLES: 'Manage Variables',
        _VARIABLES_SAVED: 'The variables were saved successfully',
        _ERROR_KEY: 'Key should be unique!',
        _CREATED_INDICATOR_SUCCESS: 'Indicator created successfully!',
        _UPDATED_INDICATOR_SUCCESS: 'Indicator updated successfully!',
        _INDICATOR_DELETED: 'Indicator Deleted',
        _DEFAULT_FREQUENCY: 'Default Currency',
        _DEFAULT_UNIT_TYPE: 'Default Unit type',
        _CUMULATIVE: 'Cumulative',
        _SHARE: 'Share',
        _RATE: 'Rate',
        _GROWTH: 'Growth',
        UNPROCESSABLE_ENTITY_ERROR: 'Indicator can not be deleted',
        UNPROCESSABLE_ENTITY_DESCRIPTION:
          'You cannot delete an indicator that has data reported on it. If your intention is to do that, please contact your service provider',
      },
      _MANAGE_INDICATOR_ACCESS_USERS: {
        _TITLE: 'Manage Access Users',
        _COLUMN_ACCESS: 'Access',
        _COLUMN_INSTITUTION: 'Institution',
        _COLUMN_EMAIL: 'Email',
        _COLUMN_ROLE: 'Role',
        _ACCESS_READ: 'Read',
        _ACCESS_READ_WRITE: 'Read+Write',
        _ACCESS_NO_ACCESS: 'No Access',
      },
      _MANAGE_THEME_ACCESS_USERS: {
        _TITLE: 'Manage Access Users',
        _COLUMN_ACCESS: 'Access',
        _COLUMN_INSTITUTION: 'Institution',
        _COLUMN_EMAIL: 'Email',
        _COLUMN_ROLE: 'Role',
        _ACCESS_READ: 'Read',
        _ACCESS_READ_WRITE: 'Read+Write',
        _ACCESS_NO_ACCESS: 'No Access',
      },
      _MANAGE_INDICATOR_VALUES: {
        _FILE_PROCESSED_SUCCESSFULLY: 'File is sent for processing',
        _SAMPLE_XLSX: 'Download Sample (XLSX)',
        _SYNC_LAST_VALUES: 'Sync all indicator values',
        _ID: 'ID',
        _CHECK_UPLOAD_PROGRESS: 'View Upload Progress',
        _CLICK_OR_DRAG_TO_UPLOAD: 'Click or drag to upload CSV',
        _INITIATE_FILE_PROCESSING: 'PROCESS FILE',
        _ABOUT_TO_UPLOAD: "You're about to upload",
        _FILE_NOT_FOUND: 'No file found! Are you sure you uploaded a file before?',
        _ROWS: 'ROWS',
        _UPLOAD_A_CSV_FILE: 'Upload a CSV file',
        _UPDATEDAT: 'Last Updated',
        _DOWNLOAD_DATA: 'Download Data',
        _UPLOAD_CSV: 'Upload CSV',
        _ACTION: 'Action',
        _NAMEEN: 'Name (EN)',
        _NAMESQ: 'Name (SQ)',
        _VIEW_JOBS: 'View Jobs',
        _FILE_TOO_LARGE: 'File size must be smaller than 100MB!',
        _INDICATOR_VALUES: 'Manage Indicator Values',
        _KEY: 'Indicator Key',
        _UPLOADS: 'Uploads',
        _PROCESSEDROWS: 'Processed Rows',
        _SUCCESS: 'Success',
        _STATUS: 'Status',
        _CREATEDBY: 'Created By',
        _INDICATORKEY: 'Indicator Key',
        _FILENAME: 'File Name',
        _JOBSTART: 'Job Start',
        _JOBEND: 'Job End Time',
        _LOADING_INDICATOR_VALUES: 'Loading Indicator Values...',
        _NO_UPLOADS: 'No uploads are available!',
        _UPLOAD_PROGRESS: 'Upload still in progress',
        _FAILED_PROCESSING: 'Failed processing at row number',
        _FAILED: 'Failed',
      },
      _MANAGE_MODULES: {
        _NAME_MAX_LENGTH: 'Write a shorter name',
        _FIELD_REQUIRED: 'This field is required',
        _NAMEEN: 'Name (EN)',
        _NAMESQ: 'Name (SQ)',
        _ADD_MODULE_BTN: 'Add a module',
        _MODULES: 'Manage Modules',
        _DELETE_CONFIRM: 'Are you sure you want to delete this module?',
        _CREATE_MODULE: 'Create module',
        _UPDATE_MODULE: 'Update module',
        _SAVING: 'Saving...',
        _ERROR: 'Error during save',
        _SAVE: 'Save',
        _DELETE: 'Delete',
        _UPDATE: 'Update',
        _ACTION: 'Action',
        _ID: 'ID',
        _SUBMODULES: 'Submodules',
        _SUBMODULES_FORBIDDEN_DELETION_ALERT:
          'To delete this module, you have to delete the children submodules first!',
      },
      _MANAGE_INSTITUTIONS: {
        _TITLE: 'Manage Institutions',
        _CREATE_INSTITUTION: 'Create Institution',
        _NAMEEN: 'Name (EN)',
        _NAMESQ: 'Name (SQ)',
        _ACRONYMEN: 'Acronym (EN)',
        _ACRONYMSQ: 'Acronym (SQ)',
        _URL: 'URL',
        _NAME_EN_REQUIRED: 'Please enter the name in English',
        _NAME_SQ_REQUIRED: 'Please enter the name in Albanian',
        _ACRONYM_EN_REQUIRED: 'Please enter the acronym in English',
        _ACRONYM_SQ_REQUIRED: 'Please enter the acronym in Albanian',
        _ACTIONS: 'Actions',
        _UPDATE_BTN: 'Update',
        _DELETE_BTN: 'Delete',
        _MANAGE_INSTITUTION_USERS: 'Institution Users',
        _ASSIGN_USER: 'Assign User',
        _UPDATE_INSTITUTION_BTN: 'Update Institution',
        _DELETE_INSTITUTION_CONFIRM: 'Are you sure you want to delete this institution?',
        _CORRECT_URL: 'Enter correct url!',
        _ENTER_WEBSITE: 'Please enter website',
      },
      _MANAGE_PUBLICATIONS: {
        _NAME_MAX_LENGTH: 'Write a shorter name',
        _FIELD_REQUIRED: 'This field is required',
        _FILE_REQUIRED: 'You need to provide a file',
        _FILE_SIZE: 'File Size',
        _FILE_TOO_LARGE: 'Your file is too large. Provide a file smaller than 10MB.',
        _FILE_TOO_LARGE_PHOTO: 'Your photo is too large. Provide a photo smaller than 1MB.',
        _FILE_TYPE: 'File type',
        _CREATE_PUBLICATION: 'Create publication',
        _UNSUPPORTED_FILE_FORMAT: 'Unsupported File Format',
        _NAMEEN: 'Name (EN)',
        _NAMESQ: 'Name (SQ)',
        _AUTHOR: 'Author',
        _ADD_PUBLICATION_BTN: 'Add a publication',
        _PUBLICATIONS: 'Manage Publications',
        _THUMBNAIL: 'Thumbnail',
        _LANG: 'Language',
        _ENGLISH: 'English',
        _ALBANIAN: 'Albanian',
        _DELETE_CONFIRM: 'Are you sure you want to delete this publication?',
        _UPDATE_PUBLICATION: 'Update publication',
        _SAVING: 'Saving...',
        _ERROR: 'Error during save',
        _SAVE: 'Save',
        _TYPE: 'Type',
        _DELETE: 'Delete',
        _UPDATE: 'Update',
        _ACTION: 'Action',
        _ID: 'ID',
        _LINK: 'File',
        _FILE: 'Link',
        _FILE_LINK: 'File Link',
        _THUMBNAIL_LINK: 'Thumbnail Link',
        _DATE: 'Date',
        _PUBLISHINGDATE: 'Publishing Date',
        _MODULE: 'Module',
        _TYPE_BOOKLET: 'Booklet',
        _TYPE_BROCHURE: 'Brochure',
        _TYPE_CASE_STUDY: 'Case Study',
        _TYPE_FACE_FACTS: 'Face Facts',
        _TYPE_GUIDANCE_NOTE: 'Guidance note',
        _TYPE_ISSUE_BRIEF: 'Issue brief',
        _TYPE_LESSONS_LEARNED: 'Lessons learned Comparative experience',
        _TYPE_NEWSLETTER: 'Newsletter',
        _TYPE_POLICY_BRIEF: 'Policy brief',
        _TYPE_REPORT: 'Report',
        _TYPE_RESEARCH_DISCUSSION_PAPERS: 'Research Discussion papers',
        _TYPE_LESSONS_LEARNED_COMPARATIVE_EXPERIENCE: 'Lessons learned Comparative experience',
        _TYPE_STRATEGY: 'Strategy',
        _TYPE_TOOLKIT_HOW_TO_MANUAL: 'Toolkit how-to manual',
        _CLICK_OR_DRAG_TO_UPLOAD_PDF: 'Click or drag PDF file to upload',
        _CLICK_OR_DRAG_TO_UPLOAD_THUMBNAIL: 'Click or drag image file to upload',
        _CLICK_OR_DRAG_TO_UPDATE_PDF: 'Click or drag PDF file to update current file',
        _CLICK_OR_DRAG_TO_UPDATE_THUMBNAIL: 'Click or drag image file to update current image',
        _UPLOAD_FAILED: 'Upload failed',
        _UPLOADED_FILE_URL: 'Uploaded File URL',
        _NO_PUBLICATIONS: 'There are no publications to show!',
        _CREATED: 'Publication created successfully',
        _UPDATED: 'Publication updated successfully',
      },
      _MANAGE_ACCOUNT: {
        _TITLE: 'Manage Account',
        _ACCOUNT_DETAILS: {
          _TITLE: 'Account Details',
          _YOUR_INSTITUTIONS: 'Your Institutions',
          _NO_INSTITUTIONS_FOUND: 'No institutions found',
          _ERROR_GETTING_DATA: 'Oops! There was an error getting your data. Try again later',
          _PHONE: 'Phone',
          _ROLE: 'Role',
        },
        _CHANGE_PASSWORD: {
          _TITLE: 'Change Password',
          _CURRENT_PASSWORD: 'Current Password',
          _NEW_PASSWORD: 'New Password',
          _CONFIRM_PASSWORD: 'Confirm Password',
          _CHANGING_PASSWORD: 'Changing Password...',
          _REQUIRED_MESSAGE: 'This field is required',
          _PASSWORDS_DONT_MATCH: `Passwords don't match`,
          _PASSWORD_IS_INCORRECT: 'The current password is incorrect',
          _ERROR_CHANGING_PASSWORD: 'There was an error changing the password',
          _PASSWORD_CHANGED_SUCCESSFULLY: 'The password was changed successfully',
        },
      },
    },
    _USER: {
      _ROLE: {
        _ADMIN: 'Admin',
        _TECHNICAL: 'Technical',
        _INSTITUTION_USER: 'Institution User',
        _NORMAL: 'Normal',
      },
    },
    _COMMON: {
      _HOME: 'Home',
      _TITLE: 'Title',
      _DESCRIPTION: 'Description',
      _UNDP: 'UNITED NATIONS DEVELOPMENT PROGRAMME',
      _EDT: 'ECONOMIC DEVELOPMENT TRACKER',
      _INDICATOR_PREVIEW: 'Indicator Preview',
      _CHART_CARD: 'Self Employment',
      _EXPLORE_BTN: 'Explore Data',
      _VIEW_ALL: 'View all',
      _FILTERS: 'Filters',
      _CATEGORY: 'Category',
      _EMPLOYMENT: 'Employment',
      _DATA: 'Data',
      _LOADING: 'Loading',
      _ERROR: 'Something went wrong! Try again!',
      _LOADING_KEY_DATA: 'Loading Key Data',
      _NO_DATA: 'No data',
      _WRONG_PAGE: 'Wrong page!',
      _DATA_NOT_AVAILABLE: 'Data is not available :(',
      _SHARE: 'Share',
      _THEMES: 'Themes',
      _DOWNLOAD: 'Download',
      _DONE: 'Done',
      _FILTER: 'Filter',
      _NO_DATA_FOUND: 'No data found!',
      _RESET: 'Reset',
      _DOWNLOAD_DATA: 'Download data',
      _IN_NUMBER: 'in number',
      _IN_PERCENTAGE: 'in percentage',
      _NUMBER: 'Number',
      _PERCENTAGE: 'Percentage',
      _NUMBER_TEXT: 'Number',
    },

    _THEMES: {
      _ALL_THEMES: 'All themes',
      _ALL_STRATEGIES: 'All strategies',
      _THEME_SUBHEADER_DESC: 'Lorem ipsum dolore',
      _FOOTNOTE: 'Footnote',
      _INSTITUTION: 'Institution',
      _DATA_SOURCE: 'Data Source',
      _NO_AVAILABLE: 'Not available',
      _SHARE: 'Share',
    },
    _EXPLORER: {
      _DATA: 'Data explorer',
      _NO_SUBMODULES: 'There are no submodules for this module',
      _SUBTITLE: 'Lorem ipsum dolore',
      _ADD: 'Add Indicator Chart',
      _RATE_OF: 'Rate of',
      _SHARE_OF: 'Share of',
    },
    _REPORTS: {
      _REPORTS: 'Reports',
      _FILTERS: 'Filter by',
      _TYPE: 'Document Type',
      _LANG: 'Language',
      _SORT: 'Sort by',
      _BROCHURE: 'Brochure',
      _CASE_STUDY: 'Case study',
      _FACE_FACTS: 'Face facts',
      _BOOKLET: 'Booklet',
      _GUIDANCE_NOTE: 'Guidance note',
      _ISSUE_BRIEF: 'Issue brief',
      _LESSONS_LEARNED_COMPARATIVE_EXPERIENCE: 'Lessons learned Comparative experience',
      _POLICY_BRIEF: 'Policy brief',
      _STRATEGY: 'Strategy',
      _REPORT: 'Report',
      _RESEARCH_DISCUSSION_PAPERS: 'Research Discussion papers',
      _NEWSLETTER: 'Newsletter',
      _TOOLKIT_HOWTO_MANUAL: 'Toolkit how-to manual',
      _DATE: 'Publication date',
      _RELEVANCE: 'Most Relevant',
      _ENGLISH: 'English',
      _ALBANIAN: 'Albanian',
      _SUBTITLE: 'Reports',
      _POLICY_BRIEF_REPORT: 'Policy brief report',
      _REPORT_DESC: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry',
      _CREATED_BY: 'Created By : ',
    },
    _INDICATORS: {
      _INDICATORS: 'Indicators',
      _LOADING_INDICATOR: 'Loading Indicator',
      _NO_DATA_INDICATOR: 'No data for indicator',
      _ADD_INDICATORS: 'Add indicators',
      _NO_INDICATORS: ' No indicators are available for this selection',
      _RELATED_INDICATORS: 'Related Indicators',
      _EMPLOYMENT: 'Employment',
      _BY: 'by',
      _DOWNLOAD_DATA: 'Download data',
      _FILTER: 'Filter',
      _SEARCH_INDICATOR: 'Search for an indicator',
      _ALL: 'All Indicators',
      _OR: 'or',
      _LAST_UPDATED: 'Updated at',
      _VISIT_EDT: 'visit',
      _SUBHEADER_DESC:
        'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt utlaoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tationullamcorper suscipit lobortis nisl ut aliquip ex ea comm lorem ipsum dolor sit amet,.',
      _BY_REGION: 'By region',
      _NO_INSTITUTIONS_INDICATORS: 'No indicators for this institution!',
      _SOURCE: 'Source',
    },
    _FILTERS: {
      _FREQUENCY: 'Frequency',
      _MONTH: 'Month',
      _QUARTER: 'Quarter',
      _YEAR: 'Year',
      _UNIT_TYPE: 'Unit Type',
      _CUMULATIVE: 'Cumulative',
      _SHARE: 'Share',
      _RATE: 'Rate',
      _GROWTH: 'Growth Rate',
      _TIME_RANGE: 'Time range',
      _NO_FILTERS: 'No available filters',
      _START_YEAR: 'Start year',
      _END_YEAR: 'End year',
    },
    _RELATED_METRICS: {
      _METRICS: 'Related metrics',
      _REPORTS: 'Related reports',
      _THEMES: 'Related themes',
      _SDG: 'Related SDG',
      _NO_INDICATORS: 'No indicators available :(',
      _NO_REPORTS: 'No reports available :(',
      _NO_THEMES: 'No themes available :(',
    },
    _EMPLOYMENT_GLANCE: {
      _TITLE: 'Employment at a glance',
      _THEMES: 'Themes',
      _THEME: 'Theme',
      _HOME: 'Home',
      _CURRENT: 'Current',
      _BASELINE: 'Baseline',
      _COMPARE: 'Compare',
      _UNIT: 'Unit',
      _CHANGE: 'Change',
    },
    _NESS: {
      _ACRONYM: 'NESS',
      _INDICATOR_NAME: 'Indicator name',
      _BASELINE: 'Baseline',
      _TARGET: 'Target',
      _CURRENT: 'Current',
      _PROGRESS: 'Progress',
      _YEAR: 'Year',
      _STATUS: 'Status',
      _TITLE: ' NATIONAL EMPLOYMENT AND SKILLS STRATEGY',
      _NO_DATA: 'No data',
    },
    _BRIEFS: {
      _ADD_NEW_INDICATOR: 'Add a new indicator',
      _SUBMIT: 'Submit',
      _MY_BRIEFS: 'My briefs',
      _ADD_BRIEF: 'Add Brief',
      _CREATING_BRIEFS: 'Creating Brief',
      _THEME: 'Theme',
      _FREQUENCY: 'Frequency',
      _YEARLY: 'Yearly',
      _DATE_GENERATED: 'Date generated',
      _NO_BRIEFS: 'No Brief indicators added yet!',
      _MODULE_LOADING: 'Loading module',
      _NO_MODULES: 'No modules available',
      _DELETE: 'Delete',
      _DELETED: 'Deleted',
      _DOWNLOAD: 'Download',
      _DELETED_SUCCESSFULLY: 'The brief was deleted successfully',
      _CREATE_STORYLINE: 'Add an additional comment (optional)',
      _NO_ALL_BRIEFS: 'No briefs added yet!',
      _ALL_BRIEFS: 'All Briefs',
      _LIST_ALL_BRIEFS: 'List of all briefs',
      _ERROR_CREATING_BRIEF: 'Error creating the brief!',
      _INDICATOR_BRIEF_MISSING: 'Please add at least one indicator to create the brief',
      _SUCCESS: 'Success!',
      _SUCCESS_MESSAGE: 'Brief was created successfully!',
      _ARE_YOU_SURE_DELETE: 'Are you sure to delete this brief?',
    },
    _SEARCH: {
      _THEME: 'Theme',
      _KEY_INDICATOR: 'Key Indicator',
      _INSTITUTION: 'Institution',
      _SUBCATEGORY: 'Subcategory',
      _MODULE: 'Module',
      _INDICATOR: 'Indicator',
      _PUBLICATION: 'Publication',
      _SEE_ALL: 'See all results',
      _SEARCH_FOR: 'Search for ',
      _NO_RESULTS: 'No results',
      _SEARCH_DATA: 'Search Data',
    },
  },
};
