import React, { useContext } from 'react';
import noData from 'common/images/NoData/noData.png';
import './IndicatorChartCard.css';
import addIcon from './addIcon.svg';
import { ExploreContext } from 'pages/Explorer/screens/store/exploreContext';

interface IndicatorChartProps {
  name: string;
  indicatorId?: number;
  image: string | undefined;
  lastValueFormatted: string;
}

const IndicatorChartCard: React.FunctionComponent<IndicatorChartProps> = ({
  name,
  indicatorId,
  image,
  lastValueFormatted,
}) => {
  const context = useContext(ExploreContext);

  return (
    <div
      onClick={() => {
        context?.setRemoving(false);
        context?.setAddedIndicators([
          ...context?.addedIndicators,
          {
            id: indicatorId!,
          },
        ]);
        context?.setModalIsOpen(false);
        window.scrollTo({ top: 300, behavior: 'smooth' });
      }}
      className="indicator-chart-card-wrapper">
      <div>
        <img src={image ? image : noData} />
      </div>
      <div className="content">
        <div className="text">
          <h3>{name}</h3>
          <h3>{lastValueFormatted || '-'}</h3>
        </div>
        <div
          className="icon"
          onClick={() => {
            //close the modal
            context?.setAddedIndicators([
              ...context?.addedIndicators,
              {
                id: indicatorId!,
              },
            ]);
            context?.setModalIsOpen(false);
            window.scrollTo({ top: 300, behavior: 'smooth' });
          }}>
          <img src={addIcon} />
        </div>
      </div>
    </div>
  );
};

export default IndicatorChartCard;
