import { Link, LinkProps, useParams } from 'react-router-dom';

export default ({ to, children, ...props }: LinkProps & { children?: any }) => {
  const params: any = useParams();
  return (
    <Link to={`/${params.locale ?? 'sq'}${to}`} {...props}>
      {children}
    </Link>
  );
};
