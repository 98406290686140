import React from 'react';
import ModuleForm from './ModuleForm';

interface IProps {
  onClose: () => void;
  refetch: () => void;
}
const ModuleCreate: React.FunctionComponent<IProps> = (props) => {
  const { onClose, refetch } = props;
  return <ModuleForm onClose={onClose} refetch={refetch} />;
};

export default ModuleCreate;
