import Flex from 'common/components/Flex/Flex';
import styled from 'styled-components';
import Modal from 'antd/lib/modal';

const Backdrop = styled(Flex)`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
`;

const Card = styled.div`
  padding: 20px;
  background-color: white;
  border-radius: 5px;
  min-width: 300px;
  z-index: 11;
  position: absolute;
`;

const Title = styled.p`
  font-size: 20px;
  font-weight: bold;
  margin: 0;
  margin-bottom: 10px;
`;

const CardContent = styled.div``;

const StyledModal = styled(Modal)``;

export { Backdrop, Card, Title, CardContent, StyledModal };
