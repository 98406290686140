import { getFilteredResults } from 'common/components/Search/FilterResults';
import i18next from 'i18next';
import { useQuery, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import { formatDescription } from 'common/components/Search/helper';
import { LinkOutlined, DownloadOutlined } from '@ant-design/icons';
import { getPublicIndicators } from 'api/public';
import { Spin } from 'antd';
import './searchContent.css';

const useCachedQueries = () => {
  useQuery('modules');
  useQuery('themes');
  useQuery('institutions');
  useQuery('keyIndicators');
  useQuery('publications');
};

const SearchContent = () => {
  const history = useHistory();

  const searchParam = new URLSearchParams(window.location.search).get('search');
  const queryClient = useQueryClient();
  const queryCache = queryClient.getQueryCache();
  useCachedQueries();
  const results = searchParam ? getFilteredResults(searchParam, queryCache, true).filter((r) => !!r) : [];

  const { isLoading } = useQuery('indicators', getPublicIndicators);
  const resultsArray = results
    ?.map((res) => {
      return res?.options;
    })
    .flat()
    .filter((x) => x);

  return (
    <div className="search-content">
      <p className="title">
        {i18next.t('_SEARCH._SEARCH_FOR')} '{searchParam}'
      </p>
      {isLoading && (
        <div className="indicator-page-spinner">
          <Spin size="large" />
          <p> {i18next.t('_HOME._LOADING')}</p>
        </div>
      )}
      <section>
        {resultsArray?.map((searchResult, index) => {
          return (
            <div
              key={searchResult?.value + index}
              onClick={() =>
                searchResult?.categorygroup === 'Publication' || searchResult?.categorygroup === 'Institution'
                  ? window.open(searchResult?.additionaldata?.link, '_blank')
                  : history.push(searchResult?.additionaldata?.link || '/')
              }
              className="search-link">
              <div className="search-item">
                <p>
                  <span>
                    {searchResult?.categorygroup === 'Publication' && (
                      <LinkOutlined style={{ fontSize: '20px', marginRight: '5px', marginTop: '5px' }} />
                    )}
                    {searchResult?.additionaldata?.title}
                  </span>
                  <span>{formatDescription(searchResult?.additionaldata?.description || '')}</span>
                </p>
                <p className="search-category">
                  {searchResult?.categorygroup?.toUpperCase()}
                  {searchResult?.categorygroup === 'Publication' && (
                    <DownloadOutlined style={{ fontSize: '25px', marginLeft: '5px' }} />
                  )}
                </p>
              </div>
            </div>
          );
        })}
        {resultsArray?.length === 0 && !isLoading && (
          <p style={{ textAlign: 'center', display: 'grid', placeContent: 'center', minHeight: '300px' }}>
            {i18next.t('_SEARCH._NO_RESULTS')}
          </p>
        )}
      </section>
    </div>
  );
};

export default SearchContent;
