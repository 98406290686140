import _, { first } from 'lodash';
import { IndicatorFilters, IndicatorValues, IndicatorVariable, IndicatorWithId } from 'models/indicator';
import moment from 'moment';
import html2canvas from 'html2canvas';
import i18next from 'i18next';
/**
 * This function gets the filters from GET /indicator-value/{id}/filters
 * and combines those with all available filters, to get the interesction
 * and display in the component
 * @param indicatorFilters
 * @param allFilters
 * @returns
 */
export const getAvailableFilters = (
  indicatorFilters: IndicatorFilters,
  allFilters: IndicatorVariable[]
): IndicatorVariable[] => {
  const availableFilters = allFilters?.filter((filter) => indicatorFilters?.variables[filter.key]);

  return availableFilters.map((filter) => ({
    ...filter,
    variables: filter.variables
      .filter((variable) => indicatorFilters.variables[filter.key].includes(variable.key))
      .sort((a, b) => a.order - b.order),
  }));
};

/**
 * Based on the available filters, it returns an object with initial state that the filters should have.
 * The initial state is either the option "total", or the 1st available option
 * @param availableFilters
 * @returns
 */
export const getInitialStateForFilters = (availableFilters: IndicatorVariable[]): { [key: string]: string[] } => {
  const initState = {};

  availableFilters.forEach((filter) => {
    initState[filter.key] = filter.variables.find((item) => item.key === 'total')
      ? [filter.variables.find((item) => item.key === 'total')?.key]
      : [filter.variables[0].key];
  });

  return initState;
};

export const findAllPossibleCombinationsForSearch = (
  selectedVariables: {
    [key: string]: string[];
  },
  filter?: string
): { [key: string]: string }[] => {
  const objects: { [key: string]: string }[] = [];

  // Find the key that has more than 1 options available
  let keyWithMoreOptions;

  // If selected filter is passed as argument, no need to figure out the key with more options.
  if (filter) {
    keyWithMoreOptions = filter;
  } else {
    Object.keys(selectedVariables).forEach((key) => {
      if (selectedVariables[key]?.length > 1) {
        keyWithMoreOptions = key;
      }
    });
  }

  if (keyWithMoreOptions) {
    selectedVariables[keyWithMoreOptions].forEach((option) => {
      // Convert others from object to array
      const otherKeys = {};

      Object.keys(selectedVariables).forEach((key) => {
        otherKeys[key] = selectedVariables[key][0];
      });

      objects.push({
        ...otherKeys,
        [keyWithMoreOptions]: option,
      });
    });
  } else {
    const finalObject = {};
    Object.keys(selectedVariables).forEach((key) => {
      finalObject[key] = selectedVariables?.[key]?.[0];
    });

    objects.push(finalObject);
  }

  return objects;
};

export const generateSeries = (
  data: IndicatorValues[],
  selectedVariables: any
): { series: IndicatorValues[][]; xAxis: string[] } => {
  // if (Object.keys(selectedVariables).length === 0) {
  //   return { series: [], xAxis: [] };
  // }

  const allVariablesCombinations = findAllPossibleCombinationsForSearch(selectedVariables);

  const series = allVariablesCombinations
    .map((variableCombination) => {
      return data.filter((indicatorValue) => {
        let foundMatch = false;

        if (_.isEqual(indicatorValue.variables, variableCombination)) {
          foundMatch = true;
        }
        return foundMatch;
      });
    })
    .filter((serie) => serie.length !== 0);

  return fillEmptySeries(series);
};

export const getDateString = (dateValue) => {
  return new Date(dateValue).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
};

export const downloadIndicator = async (indicator) => {
  const indicatorContainer = document.getElementById(indicator.id);
  if (indicatorContainer) {
    const canvas = await html2canvas(indicatorContainer);
    const image = canvas.toDataURL('image/jpg', 1.0);
    const imageFileName = `${indicator.key}_${indicator.id}`;
    const link = document.createElement('a');
    link.download = imageFileName;
    link.href = image;
    link.click();
    link.remove();
  }
};

/**
 * Defines what is the active filter currently in the screen
 * @param selectedVariables
 * @returns
 */
export const getCurrentActiveFilter = (selectedVariables: { [key: string]: string[] }, allAvailableFilters) => {
  let currentAppliedFilter: { filter: string; options: string[] } = {
    filter: 'total',
    options: [],
  };

  // If any of the selected variables has more than 1 option selected, make that the currentAppliedFilter
  Object.keys(selectedVariables).forEach((key) => {
    if (selectedVariables[key].length > 1) {
      currentAppliedFilter = {
        filter: key,
        options: selectedVariables[key],
      };
    }
  });

  // If no selected variable had more than 2 options, then we need to check
  // the cases that one key has a selected option that differs from "total"
  if (currentAppliedFilter.options.length === 0) {
    Object.keys(selectedVariables).forEach((key) => {
      const options = selectedVariables[key];

      if (options.length === 1 && options[0] !== 'total') {
        const firstOption: string = options[0];
        const filterFromAllVariables = allAvailableFilters.find((filter) => filter.key === key);

        const findVariableOption = filterFromAllVariables.variables.find((variable) => variable.key === firstOption);

        // If the variable is the first in the array, we should not consider this as the element being filter
        const indexOfObjectInArray = filterFromAllVariables.variables.findIndex((obj) => obj.key === firstOption);

        if (findVariableOption && filterFromAllVariables.variables.length !== 1 && indexOfObjectInArray !== 0) {
          currentAppliedFilter = {
            filter: key,
            options: [firstOption],
          };
        }
      }
    });
  }

  return currentAppliedFilter;
};

export const fillEmptySeries = (series: IndicatorValues[][]): { series: IndicatorValues[][]; xAxis: string[] } => {
  // Sort series based on date and conver them to the same format (YYYY-MM-DD)
  const sortedDates = series.map((serie) =>
    serie
      ?.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
      .map((indicatorValue) => {
        const date = new Date(indicatorValue.date).toISOString().slice(0, 10);

        return {
          ...indicatorValue,
          date: date,
        };
      })
  );

  // Figure out all unique dates from all the series
  const allDates: string[] = [];

  sortedDates.forEach((serie) => {
    serie.forEach((indicatorValue) => {
      allDates.push(indicatorValue.date);
    });
  });

  const allUniqueDates = _.uniq(allDates.sort((a, b) => new Date(a).getTime() - new Date(b).getTime()));

  // Adjust other arrays to add "-" where the values are missing
  const finalArray: IndicatorValues[][] = [];

  sortedDates.forEach((serie) => {
    const adjustedSeries: any[] = allUniqueDates
      .map((date) => ({
        date,
        unmatched: true,
      }))
      .map((obj) => {
        const indicatorValueObject = serie.find((iv) => iv.date === obj.date);

        if (indicatorValueObject) {
          return indicatorValueObject;
        }
        return '-'; // Placeholder for no data
      });
    finalArray.push(adjustedSeries);
  });

  return {
    series: finalArray,
    xAxis: allUniqueDates,
  };
};

/**
 * Generate the indicator name that would be displayed in the interface
 * @param indicator
 * @param unitType
 * @param currentLng
 * @returns
 */
export const generateIndicatorName = (indicator: IndicatorWithId, unitType: string, currentLng: string): string => {
  switch (unitType) {
    case 'cumulative':
      return `${indicator[`name${currentLng}`]} - ${i18next.t('_COMMON._NUMBER')}`;
    case 'rate':
      return `${indicator[`name${currentLng}`]} - ${i18next.t('_COMMON._PERCENTAGE')}`;
    case 'share':
      return `${indicator[`name${currentLng}`]} - ${i18next.t('_COMMON._PERCENTAGE')}`;
    default:
      return indicator[`name${currentLng}`];
  }
};

/**
 * Transform the format of x axis
 * @param xAxis
 * @param selectedFrequency
 * @returns
 */
export const transformXAxisBasedOnFrequency = (xAxis: string[], selectedFrequency: string): string[] => {
  switch (selectedFrequency) {
    case 'year':
      return xAxis.map((value) => moment(value, 'YYYY-MM-DD').format('YYYY'));
    case 'quarter':
      return xAxis.map((value) => `Q${moment(value, 'YYYY-MM-DD').quarter()}-${moment(value, 'YYYY-MM-DD').year()}`);
    case 'month':
      return xAxis.map((value) => `${moment(value, 'YYYY-MM-DD').month() + 1}-${moment(value, 'YYYY-MM-DD').year()}`);
    default:
      return xAxis.map((value) => moment(value, 'YYYY-MM-DD').format('YYYY-MM-DD'));
  }
};

export const showMinimumAndMaxValues = (xAxis: string[]): string[] => {
  const sortedDates = xAxis
    .map((value) => new Date(value).getFullYear())
    .sort((a, b) => a - b)
    .map((date) => date.toString());

  if (sortedDates.length === 0) {
    return [];
  } else if (sortedDates.length === 1) {
    return [sortedDates[0]];
  } else if (xAxis.length === 2) {
    return [sortedDates[0], sortedDates[1]];
  } else {
    const emptyArray = new Array(xAxis.length - 2).fill('');
    return [sortedDates[0], ...emptyArray, sortedDates[sortedDates.length - 1]];
  }
};

export const addCommas = (nStr: string): string => {
  nStr += '';
  var x = nStr.split('.');
  var x1 = x[0];
  var x2 = x.length > 1 ? '.' + x[1] : '';
  var rgx = /(\d+)(\d{3})/;
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, '$1' + ',' + '$2');
  }
  return x1 + x2;
};

/**
 * Format X Axis for each value
 * @param value
 * @param unitType
 * @returns
 */
export const formatYAxis = (value: string, unitType: string): string => {
  switch (unitType) {
    case 'rate':
      return `${value}%`;
    case 'growth':
      return `${value}%`;
    default:
      return `${addCommas(value)}`;
  }
};

/**
 * Generate a specific color for string. Used in graph!
 * @param str
 * @returns
 */
export const generateColorForString = (str: string): string => {
  if (!str) return '#24323';
  if (str === 'Total') return '#0e9898'; // Default
  var hash = 0;
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  var colour = '#';
  for (var i = 0; i < 3; i++) {
    var value = (hash >> (i * 8)) & 0xff;
    colour += ('00' + value.toString(16)).substr(-2);
  }
  return colour;
};

const capitalizeFirstLetter = (str: string): string => {
  if (!str) return '';
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const generateSerieName = (serie: IndicatorValues[], activeFilter: any): string => {
  if (activeFilter.filter === 'total') {
    return 'Total';
  }
  return serie.length > 0
    ? formatSerieName(serie.find((iv) => iv.variables)?.variables[activeFilter.filter.split(' ').join('_')])
    : '';
};

export const formatDate = (dateString: string, frequency?: string): string => {
  let year, month;

  if (frequency == 'year') {
    year = dateString.split('-')[1];
    month = dateString.split('-')[0];
    if (month.length < 2) {
      if (month === '0') {
        month = '01';
      } else {
        month = `0${month}`;
      }
    }
  } else {
    year = dateString.split('-')[1];
    month = `0${dateString.split('-')[0].replace('Q', '')}`;
  }

  let formattedDate = new Date(`${year}-${month}`) || new Date('2000-01');
  if (formattedDate.toString() !== 'Invalid Date') {
    const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(formattedDate);
    const mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(formattedDate);
    return `${mo} ${ye}`;
  } else {
    return '';
  }
};

export const sortDataByTwoValues = (data: IndicatorValues[] | undefined): IndicatorValues[] => {
  if (!data) {
    return [];
  }
  return data?.sort((a, b) => {
    if (new Date(b.date).getTime() == new Date(a.date).getTime()) {
      if (a.id < b.id) {
        return -1;
      } else return 1;
    } else return new Date(b.date).getTime() - new Date(a.date).getTime();
  });
};

export const formatSerieName = (str: string) => {
  if (!str) return '';
  return capitalizeFirstLetter(str);
};
