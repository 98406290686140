export enum Role {
  ADMIN = 'admin',
  INSTITUTION_USER = 'institution_user',
  TECHNICAL = 'technical',
  NORMAL = 'normal',
}

export interface User {
  id?: number;
  email: string;
  phoneNumber: string;
  role: Role;
  lastLogin?: Date;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface UserWithId extends User {
  id: number;
}

export interface UpdateUser extends User {
  userId?: number;
}
export interface CreateUser extends User {
  baseUrl?: string;
}
