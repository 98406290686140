import * as React from 'react';
import { Collapse, Select, Spin } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
import NessTable from './NessTable';
import { useQuery } from 'react-query';
import { getNessTheme } from 'api/public';
import { generateNessIndicatorName, generateNessIndicators, getYearFromDate } from './NessHelper';
import i18 from 'i18next';
import ThemeRow from './ThemeRow';
import { getCurrentLng } from 'utils/helper';
import i18next from 'i18next';
import { NessThemeModel } from 'models/indicator';
const { Panel } = Collapse;

interface ThemeNessProps {
  selectedYear: string;
  setThemeData: React.Dispatch<React.SetStateAction<any>>;
}

const ThemeNess: React.FunctionComponent<ThemeNessProps> = ({ selectedYear, setThemeData }) => {
  const lang = getCurrentLng();
  const {
    data: themeData,
    isLoading,
    isError,
  } = useQuery(['nessTheme', selectedYear], () => getNessTheme(selectedYear), {
    refetchOnWindowFocus: false,
  });

  const data = generateNessIndicators(themeData, selectedYear);

  React.useEffect(() => {
    setThemeData(data);
  }, [themeData]);

  const selectedYearVal = getYearFromDate(selectedYear);
  //informative header component
  const themeinfo = (
    <div style={{ paddingLeft: '38px' }}>
      <div className="name" style={{ flex: '0.4' }}>
        {i18.t('_NESS._INDICATOR_NAME')}
      </div>
      <div style={{ display: 'flex', flex: '0.6', paddingLeft: '10px', textAlign: 'center', alignItems: 'center' }}>
        <div className="baseline-year-value" style={{ flex: '.2', display: 'flex', flexDirection: 'column' }}>
          <span style={{ marginLeft: '-12px' }}> {i18.t('_NESS._BASELINE')}</span>
          <span style={{ marginLeft: '-10px' }}>2018</span>
        </div>
        <div className="target-value" style={{ flex: '.2', transform: 'translateX(10px)' }}>
          {i18.t('_NESS._TARGET')}
          <br />
          {selectedYearVal}
        </div>
        <div className="progress" style={{ flex: '.2' }}>
          {i18.t('_NESS._PROGRESS')}
        </div>
        <div className="current" style={{ flex: '.2', marginLeft: '2px' }}>
          {i18.t('_NESS._CURRENT')} <br />
          {selectedYearVal}
        </div>
        <div className="status" style={{ flex: '.2' }}>
          <span style={{ marginRight: '13px' }}> {i18.t('_NESS._STATUS')}</span>
        </div>
      </div>
    </div>
  );

  if (isError) {
    return <div style={{ height: '500px', display: 'grid', placeContent: 'center' }}>{i18.t('_COMMON._ERROR')}</div>;
  }

  if (isLoading) {
    return (
      <div style={{ height: '500px', display: 'grid', placeContent: 'center' }}>
        <Spin />
      </div>
    );
  }

  if (!data?.length) {
    return (
      <div style={{ height: '500px', display: 'grid', placeContent: 'center' }}>{i18next.t('_NESS._NO_DATA')}</div>
    );
  }

  return (
    <main>
      <>
        <div className="theme-info-row">{themeinfo}</div>
        <Collapse
          accordion
          expandIconPosition="right"
          style={{ cursor: 'pointer' }}
          expandIcon={({ isActive }) => (
            <CaretRightOutlined style={{ fontSize: '20px', marginRight: '10px' }} rotate={isActive ? 90 : 0} />
          )}>
          {data?.map((indicatorData, index) => {
            return (
              <Panel
                header={
                  <ThemeRow
                    baselineYearValue={indicatorData?.selectedBaseline?.value}
                    target={indicatorData?.selectedYearValue?.target}
                    unit={indicatorData?.selectedYearValue?.unit || indicatorData?.selectedBaseline?.unit || 'number'}
                    selectedYearValue={indicatorData?.selectedYearValue?.value}
                    progress={indicatorData?.progressData ? indicatorData?.progressData : undefined}
                    change={indicatorData?.change}
                    indicatorName={generateNessIndicatorName(index)[getCurrentLng().toLowerCase()]}
                  />
                }
                key={`${indicatorData?.key}+${index}` || new Date().toString()}>
                <NessTable
                  baseline={indicatorData?.selectedBaseline?.value}
                  current={indicatorData?.selectedYearValue}
                  selectedYear={selectedYear}
                />
              </Panel>
            );
          })}
        </Collapse>
      </>
    </main>
  );
};
export default ThemeNess;
