import { Button, Card, Col, Form, Input, notification, Row } from 'antd';
import { changePassword } from 'api/manage-account';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { passwordPattern, useTranslationTerm } from 'utils/helper';
import { term } from './common';

export default () => {
  const t = (title: string) => term(`_change_password.${title}`);
  const { t: _t } = useTranslation();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const passwordsDontMatchMsg = t('_passwords_dont_match');
  const errorChangingPassword = t('_ERROR_CHANGING_PASSWORD');
  const incorrectPassword = t('_PASSWORD_IS_INCORRECT');
  const passwordChangedSuccessfully = t('_PASSWORD_CHANGED_SUCCESSFULLY');
  const weakPassword = _t('_WEAK_PASSWORD');

  const [form] = Form.useForm();
  const onFormFinish = (formData: any) => {
    setIsSaving(true);
    changePassword(formData)
      .then(() =>
        notification.success({
          placement: 'topRight',
          duration: 3,
          message: passwordChangedSuccessfully,
        })
      )
      .then(() => form.resetFields())
      .catch((error) => {
        let errorMessage = incorrectPassword;
        if (error.response.data.message === 'weak_password') {
          errorMessage = weakPassword;
        }
        notification.error({
          placement: 'topRight',
          message: <strong>{errorChangingPassword}</strong>,
          description: errorMessage,
          duration: 6,
        });
      })
      .finally(() => {
        setIsSaving(false);
      });
  };
  return (
    <Card title={t('_title')}>
      <Row justify="center">
        <Col md={10} sm={10} xs={24}>
          <Form layout="vertical" size="large" onFinish={onFormFinish} form={form}>
            <Form.Item
              label={t('_current_password')}
              name="currentPassword"
              rules={[{ required: true, message: t('_required_message') }]}
              hasFeedback>
              <Input.Password />
            </Form.Item>
            <Form.Item
              label={t('_new_password')}
              name="password"
              rules={[
                { required: true, message: t('_required_message') },
                () => ({
                  validator(_, value) {
                    if (!value) {
                      return Promise.resolve();
                    }
                    if (passwordPattern.test(value)) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error(weakPassword));
                  },
                }),
              ]}
              hasFeedback>
              <Input.Password />
            </Form.Item>
            <Form.Item
              label={t('_confirm_password')}
              name="confirm_password"
              dependencies={['password']}
              rules={[
                { required: true, message: t('_required_message') },
                ({ getFieldValue }) => {
                  return {
                    validator: (_, value) => {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error(passwordsDontMatchMsg));
                    },
                  };
                },
              ]}
              hasFeedback>
              <Input.Password />
            </Form.Item>
            <Form.Item style={{ paddingTop: '1.3em', textAlign: 'center' }}>
              <Button type="primary" htmlType="submit" loading={isSaving}>
                {t(isSaving ? '_CHANGING_PASSWORD' : '_title')}
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </Card>
  );
};
