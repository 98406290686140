import { getCurrentLng } from 'utils/helper';

export const formatDate = (str: string) => {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const monthsSQ = [
    'Janar',
    'Shkurt',
    'Mars',
    'Prill',
    'Maj',
    'Qershor',
    'Korrik',
    'Gusht',
    'Shtator',
    'Tetor',
    'Nentor',
    'Dhjetor',
  ];
  const date = new Date(str);
  const [month, day, year] = [date.getMonth(), date.getDate(), date.getFullYear()];
  if (getCurrentLng() === 'EN') {
    return `${day} ${months[month]} ${year}`;
  } else {
    return `${day} ${monthsSQ[month]} ${year}`;
  }
};
