import React from 'react';
import Flex from 'common/components/Flex/Flex';
import { MFAForm } from './MFAForm';
import { FormContainerStyled } from 'common/components/Form/styled';

interface Props {
  history: any;
  match: any;
}

const ForgotPassword: React.FunctionComponent<Props> = (props: Props) => {
  return (
    <FormContainerStyled>
      <MFAForm match={props.match} history={props.history} />
    </FormContainerStyled>
  );
};

export default ForgotPassword;
