import { platformPages } from 'data/platform';
import AllThemes from 'pages/AllThemes/Index';
import Category from 'pages/Category/Category';
import UserList from 'pages/Dashboard//ManageUsers/UsersList';
import Indicators from 'pages/Dashboard/ManageIndicators/Indicators';
import ManageIndicatorUsers from 'pages/Dashboard/ManageIndicatorUsers';
import IndicatorValues from 'pages/Dashboard/ManageIndicatorValues/IndicatorValues';
import IndicatorValueUploads from 'pages/Dashboard/ManageIndicatorValues/IndicatorValueUploads';
import ManageInstitutions from 'pages/Dashboard/ManageInstitutions/ManageInstitutions';
import InstitutionUsers from 'pages/Dashboard/ManageInstitutionUsers/InstitutionUsers';
import Modules from 'pages/Dashboard/ManageModules/Modules';
import Publications from 'pages/Dashboard/ManagePublications/Publications';
import Submodules from 'pages/Dashboard/ManageSubmodules/Submodules';
import ManageThemes from 'pages/Dashboard/ManageThemes/ManageThemes';
import ManageThemeUsers from 'pages/Dashboard/ManageThemeUsers';
import ExplorerStart from 'pages/Explorer/screens/ExplorerStart/ExplorerStart';
import ForgotPassword from 'pages/ForgotPassword/ForgotPassword';
import GraphSnapshot from 'pages/GraphSnapshot';
import Home from 'pages/Home/Home';
import IndicatorPage from 'pages/Indicator/';
import Login from 'pages/Login/Login';
import ManageAccount from 'pages/ManageAccount';
import MFA from 'pages/MFA/MFA';
import NotFound from 'pages/NotFound';
import ReportsScreen from 'pages/Reports/components/ReportsScreen';
import ResetPassword from 'pages/ResetPassword/ResetPassword';
import Subcategory from 'pages/Subcategory/components/Subcategory';
import Theme from 'pages/Theme';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, RouteComponentProps, Switch, useRouteMatch, withRouter } from 'react-router-dom';
import AdminRoute from './adminRoute';
import PrivateRoute from './privateRoute';
import AddBrief from 'pages/Briefs/BriefMain';
import AllBriefs from 'pages/Briefs/AllBriefs';
import SingleBrief from 'pages/Briefs/SingleBrief';
import ManageLogs from 'pages/Dashboard/ManageLogs/Logs';
import Insitution from 'pages/Institution/index';
import SearchContent from 'pages/SearchContent/SearchContent';
import InstitutionsLisst from 'pages/InstitutionsList/InstitutionsLisst';
import InstitutionAllIndicators from 'pages/Institution/InstitutionAllIndicators';
import Strategy from 'pages/Strategy';

const RouteSwitch = () => {
  const { i18n } = useTranslation();
  const { params, path } = useRouteMatch();
  useEffect(() => {
    async function changeLanguage() {
      await i18n.changeLanguage((params as any).locale || 'sq');
    }
    changeLanguage();
  }, [(params as any).locale]);
  return (
    <Route>
      <Switch>
        {/** Auth routes */}
        <Route exact path={`/login`} component={Login} />
        <Route exact path={`${path}/login/mfa/:token`} component={MFA} />
        <Route exact path={`${path}/login/forgot-password`} component={ForgotPassword} />
        <Route exact path={`${path}/login/reset-password/:token`} component={ResetPassword} />

        {/** Front facing routes */}
        <PrivateRoute path={`${path}`} exact component={Home} />

        {/** Platform pages */}
        {platformPages.map((page) => (
          <PrivateRoute key={page.path} path={page.path} exact component={page.component} />
        ))}

        <PrivateRoute path={`${path}/category/:moduleId`} exact component={Category} />
        <PrivateRoute path={`${path}/category/:moduleId/subcategory/:submoduleId`} exact component={Subcategory} />
        <PrivateRoute path={`${path}/indicator/:indicatorKey/`} exact component={IndicatorPage} />
        <PrivateRoute path={`${path}/themes/:themeKey`} exact component={Theme} />
        <PrivateRoute path={`${path}/explore`} exact component={ExplorerStart} />
        <PrivateRoute path={`${path}/themes`} exact component={AllThemes} />
        <PrivateRoute path={`${path}/search-content`} exact component={SearchContent} />
        <PrivateRoute path={`${path}/ministry/:id`} exact component={Insitution} />
        <PrivateRoute path={`${path}/strategies`} exact component={Strategy} />
        <PrivateRoute
          path={`${path}/ministry/:id/indicators/:submoduleId`}
          exact
          component={InstitutionAllIndicators}
        />
        <PrivateRoute path={`${path}/ministries`} exact component={InstitutionsLisst} />
        <PrivateRoute
          exact
          path={`${path}/dashboard/manage-account`}
          component={() => <ManageAccount menuItem="account-details" />}
        />

        <PrivateRoute
          exact
          path={`${path}/dashboard/manage-account/change-password`}
          component={() => <ManageAccount menuItem="change-password" />}
        />
        <PrivateRoute path={`${path}/reports`} exact component={ReportsScreen} />
        <PrivateRoute path={`${path}/add-brief`} exact component={AddBrief} />
        <PrivateRoute path={`${path}/:userId/my-briefs`} exact component={AllBriefs} />
        <PrivateRoute removeHeaderAndFooter path={`${path}/briefs/:briefId`} exact component={SingleBrief} />
        {/** Dashboard routes */}
        <AdminRoute exact path={`${path}/dashboard/users`} component={UserList} />
        <AdminRoute exact path={`${path}/dashboard/institutions`} component={ManageInstitutions} />
        <AdminRoute exact path={`${path}/dashboard/indicators`} component={Indicators} />
        <AdminRoute exact path={`${path}/dashboard/indicators/:indicatorId/users`} component={ManageIndicatorUsers} />
        <AdminRoute exact path={`${path}/dashboard/modules`} component={Modules} />
        <AdminRoute exact path={`${path}/dashboard/modules/:moduleId/submodules`} component={Submodules} />
        <AdminRoute exact path={`${path}/dashboard/publications`} component={Publications} />
        <AdminRoute exact path={`${path}/dashboard/indicator-values`} component={IndicatorValues} />
        <AdminRoute exact path={`${path}/dashboard/logs`} component={ManageLogs} />
        <AdminRoute
          exact
          path={`${path}/dashboard/indicator-values/:indicatorId/uploads`}
          component={IndicatorValueUploads}
        />
        <AdminRoute exact path={`${path}/dashboard/institutions/:institutionId/users`} component={InstitutionUsers} />
        <AdminRoute exact path={`${path}/dashboard/themes`} component={ManageThemes} />
        <AdminRoute exact path={`${path}/dashboard/themes/:themeId/users`} component={ManageThemeUsers} />
        <AdminRoute path={`${path}/graph_snapshot/:indicatorId`} exact component={GraphSnapshot} />
        {/** General */}
        <PrivateRoute path={`${path}*`} component={NotFound} />
      </Switch>
    </Route>
  );
};

const Routes: React.FunctionComponent<RouteComponentProps> = (props: RouteComponentProps) => {
  return (
    <Route>
      <Route path="/:locale(en|sq)?" render={(_props) => <RouteSwitch />} />
      <Route exact path="/" render={() => <Redirect to="/sq" />} />
    </Route>
  );
};

export default withRouter(Routes);
