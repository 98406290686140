import Layout from 'antd/lib/layout/layout';
import { Redirect, Route, RouteProps } from 'react-router';
import PageHeader from 'common/components/Header/Header';
import PageFooter from 'common/components/Footer/Footer';
import { isSessionActive } from 'utils/helper';
import useWindowDimensions from 'common/hooks/useWindowDimensions';

interface IProps extends RouteProps {
  component: any;
  removeHeaderAndFooter?: boolean;
}

const privateRoute = ({ component: Component, removeHeaderAndFooter = false, ...rest }: IProps) => {
  const { height } = useWindowDimensions();
  const isAuthenticated = isSessionActive();

  if (!isAuthenticated) {
    return (
      <Route
        {...rest}
        render={(props) => {
          return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />;
        }}
      />
    );
  }
  return (
    <div style={{ width: '100%', position: 'relative', background: 'white', overflowX: 'hidden' }}>
      {!removeHeaderAndFooter && <PageHeader />}
      <Layout style={{ maxWidth: '1580px', margin: 'auto', minHeight: `${height}px`, background: 'white' }}>
        <div style={{ minHeight: '480px', position: 'relative', width: '100%', background: 'white' }}>
          <Route
            {...rest}
            render={(props) => {
              return <Component {...props} />;
            }}
          />
        </div>
        {!removeHeaderAndFooter && <PageFooter />}
      </Layout>
    </div>
  );
};

export default privateRoute;
