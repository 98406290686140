import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Layout, Menu, Row } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import { GS, H1, HeroMenu } from './styled';
import ThemesSection from './components/ThemesSection/ThemesSection';
import KeyData from './components/KeyData/KeyData';
import EmploymentIndicators from './components/EmploymentIndicators/EmploymentIndicators';
import Partners from './components/Partners/Partners';
import Explore from './components/Explore/Explore';
import Reports from './components/Reports/Reports';
import './home-global.css';
import Tabs from './components/Tabs/Tabs';

const Home: React.FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <Content className="home-content">
      <Row className="home-intro-content">
        <Col xs={24} lg={24}>
          <H1> {t('_HOME._INTRO._DIGITAL')}</H1>
          <p className="home-intro-insights">{t('_HOME._INTRO._INSIGHTS')}</p>
        </Col>
        {/* <Col lg={8} xs={24}>
          <HeroMenu>
            <li>{t('_HOME._INTRO._ABOUT_US')}</li>
            <li> {t('_HOME._INTRO._LEARN_MORE')}</li>
          </HeroMenu>
        </Col> */}
      </Row>
      <Tabs />
      <ThemesSection />
      <KeyData />
      <EmploymentIndicators />
      <Explore />
      {/* <Partners /> */}
      <Reports />
    </Content>
  );
};

export default Home;
