import { Breadcrumb, Col, Row, Space } from 'antd';
import I18nLink from 'common/components/I18nLink';
import i18next from 'i18next';
import { Institution } from 'models/institution';
import React from 'react';
import { getCurrentLng } from 'utils/helper';

interface Props {
  institution: Institution;
  submoduleName?: string;
}

const InstitutionSubheader: React.FunctionComponent<Props> = ({ institution, submoduleName }) => {
  const lang = getCurrentLng();

  return (
    <div className="subheader-wrapper">
      <Row>
        <Col sm={{ span: 24 }} md={{ span: 24 }}>
          <Space direction="vertical" size={24}>
            <Breadcrumb separator="•">
              <Breadcrumb.Item>
                <I18nLink to="/">{i18next.t('_COMMON._HOME')}</I18nLink>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <I18nLink to="/ministries">{i18next.t('_HOME._MINISTRIES')}</I18nLink>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <I18nLink to={`/ministry/${institution.id}`}>{institution[`name${lang}`]}</I18nLink>
              </Breadcrumb.Item>
              {submoduleName && (
                <Breadcrumb.Item>
                  <a>{submoduleName || ''}</a>
                </Breadcrumb.Item>
              )}
            </Breadcrumb>
          </Space>
        </Col>
      </Row>
    </div>
  );
};

export default InstitutionSubheader;
