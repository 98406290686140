import React from 'react';
import { User } from 'models/user';
import { Role } from 'models/institution-user';
import Button from 'common/components/Button/Button';
import Form from 'common/components/Form/Form';
import { useFormik } from 'formik';
import { translationBasePath, validationSchema } from './AddInstitutionUserFormValidation';
import { useTranslation } from 'react-i18next';
import Select from 'common/components/Select/Select';
import AntForm from 'antd/lib/form';
import { useMutation } from 'react-query';
import { createInstitutionUser } from 'api/institution-user';
import { notification } from 'antd';

interface Props {
  onClose: () => void;
  refetch: () => void;
  users: User[];
  institutionId: number;
  refreshUserList: () => void;
}

export interface IFormInitialValues {
  role: Role;
  user: {
    label: string;
    value: string | number;
  };
  userId: number;
}

const InstitutionUserForm: React.FunctionComponent<Props> = (props) => {
  const { onClose, refetch, refreshUserList } = props;
  const { t } = useTranslation();
  const mutationHook = useMutation(createInstitutionUser);
  const initialValues: IFormInitialValues = {
    role: Role.NORMAL,
    user: { value: props.users[0].id || '', label: props.users[0].email },
    userId: props.users[0].id || NaN,
  };
  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema(t),
    onSubmit: (values) => {
      const { mutateAsync } = mutationHook;
      mutateAsync({ role: values.role, userId: values.userId, institutionId: props.institutionId })
        .then(() => {
          onClose();
          formik.resetForm();
          refreshUserList();
          refetch();
        })
        .catch((err) => {
          notification.error({ message: err.message, description: err.description });
          formik.resetForm();
        });
    },
  });
  const roles = [
    { value: Role.DIRECTOR, label: t(`${translationBasePath}._DIRECTOR`) },
    { value: Role.NORMAL, label: t(`${translationBasePath}._NORMAL`) },
  ];

  const selectUsers = props.users.map((user) => {
    return {
      value: `${user.id}` || '',
      label: user.email || '',
    };
  });
  return (
    <Form onSubmit={formik.handleSubmit}>
      <AntForm.Item name="role">
        <Select
          data={roles}
          label={t(`${translationBasePath}._ROLE`)}
          onChange={(e) => {
            formik.setFieldValue('role', e);
          }}
          value={formik.values.role}
          error={formik.errors.role}
        />
      </AntForm.Item>
      <AntForm.Item name="user">
        <Select
          showSearch
          data={selectUsers}
          label={t(`${translationBasePath}._EMAIL`)}
          onChange={(e) => {
            const user = JSON.parse(JSON.stringify(e));
            formik.setFieldValue('userId', parseInt(user.value));
          }}
          value={formik.values.user}
          filterOption={(input, option) =>
            option && option.children
              ? option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
              : false
          }
          labelInValue
        />
      </AntForm.Item>
      <Button loading={mutationHook.isLoading} htmlType="submit">
        {t(`${translationBasePath}._ASSIGN_USER`)}
      </Button>
    </Form>
  );
};

export default InstitutionUserForm;
