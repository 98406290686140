import { Spin } from 'antd';
import { getInstitutions } from 'api/public';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { getCurrentLng } from 'utils/helper';
import './institutionsList.css';

import { useHistory } from 'react-router-dom';

const InstitutionsLisst = () => {
  const { t } = useTranslation();
  const { data, isLoading, isError } = useQuery('institutionsList', getInstitutions);
  const currentLanguage = getCurrentLng();

  if (isError) {
    return <p style={{ height: '300px', display: 'grid', placeContent: 'center' }}>{t('_COMMON._ERROR')}</p>;
  }

  if (isLoading) {
    return (
      <div style={{ height: '300px', display: 'grid', placeContent: 'center' }}>
        <Spin delay={200} size="large" />
        <h4 style={{ paddingTop: '20px' }}>{t('_HOME._LOADING')}</h4>
      </div>
    );
  }

  return (
    <div className="institutions-list">
      <h1>{t('_HOME._MINISTRIES')}</h1>
      <section>
        {data?.map((institution) => {
          return (
            <InstitutionCard
              name={institution[`name${currentLanguage}`]}
              acronym={institution[`acronym${currentLanguage}`]}
              id={institution.id || 1}
            />
          );
        })}
      </section>
    </div>
  );
};

export default InstitutionsLisst;

interface InstitutionCardProps {
  name: string;
  acronym: string;
  id: number;
}

const InstitutionCard: React.FC<InstitutionCardProps> = ({ name, acronym, id }) => {
  const history = useHistory();
  return (
    <article onClick={() => history.push(`/ministry/${id}`)} className="institution-list">
      <h3>{acronym}</h3>
      <h2 style={{ color: 'white' }}>{name}</h2>
    </article>
  );
};
