import React from 'react';
import ModuleForm from './ModuleForm';
import Module from 'models/module';

interface IProps {
  onClose: () => void;
  refetch: () => void;
  module: Module;
}

const ModuleUpdate: React.FunctionComponent<IProps> = (props) => {
  const { onClose, refetch } = props;
  return <ModuleForm module={props.module} onClose={onClose} refetch={refetch} />;
};

export default ModuleUpdate;
