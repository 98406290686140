import { Button, Checkbox, Col, Modal, notification, Popconfirm, Row, Space } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { ColumnProps } from 'antd/lib/table';
import { deleteIndicator, getIndicators, getIndicatorVariables, updateIndicator } from 'api/indicator';
import { getInstitutions } from 'api/institution';
import { getAllSubmodules } from 'api/submodule';
import Table from 'common/components/Table/Table';
import { Indicator } from 'models/indicator';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import I18nLink from 'common/components/I18nLink';
import { getCurrentLng } from 'utils/helper';
import { Title } from '../ManageUsers/styled';
import IndicatorForm from './IndicatorForm';
import { translationBasePath } from './IndicatorFormValidation';

const Indicators: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const [view, setView] = useState<Indicator | undefined>();
  const { data, isLoading, refetch } = useQuery('dashboardIndicators', getIndicators);
  const { data: institutions, isLoading: isLoadingInstitutions } = useQuery('institutions', getInstitutions);
  const { data: submodules, isLoading: isLoadingSubmodules } = useQuery('submodules', getAllSubmodules);
  const { data: variables } = useQuery('variables', getIndicatorVariables);
  const deleteMutation = useMutation(deleteIndicator);
  const updateIndicatorMutation = useMutation(updateIndicator);
  const title = t(`${translationBasePath}._MANAGE_INDICATORS`);
  const [variableModalVisible, setVariableModalVisible] = useState<boolean>(false);
  const [selectedIndicator, setSelectedIndicator] = useState<any>(null);
  const [isSavingVariables, setIsSavingVariables] = useState<boolean>(false);

  const indicatorHeader = (
    <Row justify="space-between" style={{ marginBottom: 10 }}>
      <Title>{title}</Title>

      <Button
        type="primary"
        onClick={() => {
          setModalOpen(true);
          setView(undefined);
        }}>
        {t(`${translationBasePath}._CREATE_INDICATOR`)}
      </Button>
    </Row>
  );

  const indicatorForm = (
    <IndicatorForm
      submodules={submodules}
      institutions={institutions}
      indicator={view}
      uniqueKeys={data ? data.map((i) => i?.key || '') : []}
      onClose={() => setModalOpen(false)}
      refetch={refetch}
    />
  );
  const modal = modalOpen && (
    <Modal
      maskClosable={false}
      footer={null}
      visible
      width="700px"
      title={view ? t(`${translationBasePath}._INDICATOR_DETAILS`) : t(`${translationBasePath}._CREATE_INDICATOR`)}
      onCancel={() => {
        setModalOpen(false);
        setView(undefined);
      }}>
      {indicatorForm}
    </Modal>
  );

  const currentLanguage: string = getCurrentLng();
  const nameLngKey = `name${currentLanguage}`;
  const columns: ColumnProps<any>[] = [
    {
      key: 'key',
      title: t(`${translationBasePath}._KEY`),
      dataIndex: 'key',
      render: (text: string, _record) => <span>{text}</span>,
    },
    {
      key: nameLngKey,
      title: t(`${translationBasePath}._${nameLngKey.toUpperCase()}`),
      dataIndex: nameLngKey,
      render: (text: string, _record) => <span>{text}</span>,
    },
    {
      key: 'submodule',
      title: t(`${translationBasePath}._SUBMODULE`),
      dataIndex: 'submodule',
      render: (_text: string, record) => <span>{record.submodule[nameLngKey]}</span>,
    },
    {
      key: 'institution',
      title: t(`${translationBasePath}._INSTITUTION`),
      dataIndex: 'institution',
      render: (_text: string, record) => <span>{record.institution[nameLngKey]}</span>,
    },
    {
      key: 'action',
      title: t(`${translationBasePath}._ACTION`),
      render: (_text, record) => (
        <Space size="middle">
          <Button
            style={{ fontSize: '15px' }}
            shape="round"
            type="link"
            onClick={() => {
              setModalOpen(true);
              setView(record);
            }}>
            {t(`${translationBasePath}._UPDATE`)}
          </Button>
          <Button style={{ fontSize: '15px' }} shape="round" type="link">
            <I18nLink to={`/dashboard/indicators/${record.id}/users`}>
              {t(`${translationBasePath}._MANAGE_USERS`)}
            </I18nLink>
          </Button>
          <Button
            style={{ fontSize: '15px' }}
            shape="round"
            type="link"
            onClick={() => {
              setVariableModalVisible(!variableModalVisible);
              setSelectedIndicator(record);
              setVariableModalVisible(true);
            }}>
            {t(`${translationBasePath}._MANAGE_VARIABLES`)}
          </Button>
          <Popconfirm
            title={t(`${translationBasePath}._DELETE_CONFIRM`)}
            onConfirm={() => {
              deleteMutation
                .mutateAsync(record.id)
                .then(
                  () =>
                    deleteMutation.isSuccess &&
                    notification.success({ message: t(`${translationBasePath}._INDICATOR_DELETED`), duration: 2 })
                )
                .then(() => refetch())
                .catch((err) => {
                  notification.error({
                    message: t(`${translationBasePath}.UNPROCESSABLE_ENTITY_ERROR`),
                    description: t(`${translationBasePath}.UNPROCESSABLE_ENTITY_DESCRIPTION`),
                  });
                });
            }}>
            <Button style={{ fontSize: '15px' }} shape="round" type="link" danger>
              {t(`${translationBasePath}._DELETE`)}
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const onVariableCBChange = (value: string) => (e: CheckboxChangeEvent) => {
    if (e.target.checked) {
      selectedIndicator.variables.push(value);
    } else {
      selectedIndicator.variables = selectedIndicator.variables.filter((_item) => _item.trim() !== value.trim());
    }
  };

  return (
    <div>
      {indicatorHeader}
      {modal}
      {variables?.length && selectedIndicator && (
        <Modal
          title={t(`${translationBasePath}._MANAGE_VARIABLES`)}
          visible={variableModalVisible}
          width="940px"
          okText={t(!isSavingVariables ? '_UPDATE' : '_UPDATING')}
          cancelText={t('_CLOSE')}
          confirmLoading={isSavingVariables}
          onOk={async () => {
            setIsSavingVariables(true);
            updateIndicatorMutation
              .mutateAsync({
                id: selectedIndicator.id,
                variables: selectedIndicator.variables,
              })
              .then((data) => {
                refetch()
                  .then((_) => setIsSavingVariables(false))
                  .then((_) =>
                    notification.success({
                      message: t(`${translationBasePath}._VARIABLES_SAVED`),
                      duration: 1.7,
                    })
                  )
                  .then((_) => setVariableModalVisible(false));
              });
          }}
          onCancel={() => {
            setVariableModalVisible(false);
            setSelectedIndicator(null);
            setIsSavingVariables(false);
          }}>
          <Row>
            <Col span={24}>
              <h3 style={{ paddingBottom: '2em' }}>{selectedIndicator[nameLngKey]}</h3>
            </Col>

            {variables.map((_var, i) => (
              <Col span={6} key={_var.key + i.toString()}>
                <p key={`_variable_cb_${i}_${Date.now() + i}`}>
                  <Checkbox
                    disabled={isSavingVariables}
                    defaultChecked={
                      !!(selectedIndicator.variables || []).map((_i) => _i.trim()).find((_i) => _i === _var.key)
                    }
                    onChange={onVariableCBChange(_var.key)}>
                    <span style={{ textTransform: 'capitalize' }}>{_var[currentLanguage.toLocaleLowerCase()]}</span>
                  </Checkbox>
                </p>
              </Col>
            ))}
          </Row>
        </Modal>
      )}
      <Table
        rowClassName="admin-table-row"
        loading={isLoading || isLoadingSubmodules || isLoadingInstitutions}
        dataSource={data}
        columns={columns}
        pagination={false}
      />
    </div>
  );
};

export default Indicators;
