import { apiUrl, get, post, remove } from 'utils/apiMgmt';
import { Theme } from 'models/theme';
import { ModuleWithID } from 'models/module';
import {
  ModuleWithIndicators,
  IndicatorValues,
  IndicatorFilters,
  IndicatorWithId,
  Indicator,
  NessThemeModel,
  RelatedMetrics,
} from 'models/indicator';
import { Institution } from 'models/institution';
import { Publication } from 'models/publication';
import { EmploymentGlanceModel } from 'models/submodule';
import { allBrief, Brief, BriefPayload } from 'models/briefs';

export const getThemes = async (): Promise<Theme[]> => {
  const { data } = await get(apiUrl + '/public/themes');
  return data;
};
export const getSingleTheme = async (key: string): Promise<Theme> => {
  const { data } = await get(apiUrl + `/public/themes/${key}`);
  return data;
};
export const getModules = async (): Promise<ModuleWithID[]> => {
  const { data } = await get(apiUrl + '/public/modules');
  return data;
};

export const getReports = async (): Promise<Publication[]> => {
  const { data } = await get(apiUrl + '/public/reports');
  return data;
};

export const getFirstEmploymentModuleData = async () => {
  const data = await getModules();
  return data[0];
};

export const getSingleModule = async (id: number): Promise<ModuleWithIndicators> => {
  const { data } = await get(apiUrl + `/public/modules/${id}`);
  return data;
};

export const getInstitutions = async (): Promise<Institution[]> => {
  const { data } = await get(apiUrl + '/public/institutions');
  return data;
};

export const getIndicatorValues = async (
  id: number,
  frequency: string,
  unitType: string,
  startDate: string,
  endDate: string
): Promise<IndicatorValues[]> => {
  const { data } = await get(
    apiUrl +
      `/public/indicator-value/${id}?frequency=${frequency}&unitType=${unitType}&startDate=${startDate}&endDate=${endDate}`
  );
  return data;
};

export const getIndicatorFilters = async (id: number): Promise<IndicatorFilters> => {
  const { data } = await get(apiUrl + `/public/indicator-value/${id}/filters`);
  return data;
};

export const getOneIndicatorByIdOrKey = async (idOrKey: number | string): Promise<IndicatorWithId> => {
  const { data } = await get(apiUrl + `/public/indicators/${idOrKey}`);
  return data;
};
export const getKeyIndicators = async (): Promise<Indicator[]> => {
  const { data } = await get(apiUrl + `/public/top-indicators`);
  return data;
};

export const getPublicIndicators = async (): Promise<Indicator[]> => {
  const { data } = await get(apiUrl + `/public/indicators`);
  return data;
};
export const getCurrentUserInfo = async () => {
  return await get(apiUrl + `/public/me`);
};

export const getEmploymentGlanceData = async (
  baselineYear: string,
  compareYear: string
): Promise<EmploymentGlanceModel[]> => {
  const { data } = await get(
    apiUrl + `/public/themes/employment-at-a-glance?baseline_year=${baselineYear}&compare_to_year=${compareYear}`
  );

  return data;
};

export const getNessTheme = async (date: string): Promise<NessThemeModel[]> => {
  const { data } = await get(apiUrl + `/public/themes/ness/?currentYear=${date}`);
  return data;
};
export const createBrief = async (payload: BriefPayload): Promise<BriefPayload[]> => {
  const { data } = await post(apiUrl + `/public/briefs`, payload);
  return data;
};
export const getBriefs = async (): Promise<allBrief[]> => {
  const { data } = await get(apiUrl + `/public/briefs`);
  return data;
};
export const getOneBrief = async (briefId: number): Promise<Brief> => {
  const { data } = await get(apiUrl + `/public/briefs/${briefId}`);
  return data;
};

export const deleteBrief = async (briefId: number): Promise<Brief[]> => {
  const { data } = await remove(apiUrl + `/public/briefs/${briefId}`);
  return data;
};
export const getRelatedMetrics = async (id: number): Promise<RelatedMetrics> => {
  const { data } = await get(apiUrl + `/public/indicators/${id}/related-metrics`);
  return data;
};
