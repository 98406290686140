import Subheader from 'common/components/Subheader/Subheader';
import CategorySection from './components/CategorySection/CategorySection';
import { useQuery } from 'react-query';
import { getSingleModule } from 'api/public';
import { useEffect } from 'react';
import { Spin } from 'antd';
import { getCurrentLng, indicatorsToHide } from 'utils/helper';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface CategoryParams {
  moduleId: string;
}

const Category = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { t } = useTranslation();
  const { moduleId } = useParams<CategoryParams>();
  const currLang = getCurrentLng();
  const { data, isLoading } = useQuery('singleModule', () => getSingleModule(Number(moduleId)));
  const submodules = data?.submodules.filter((submodule) => submodule.indicators.length > 0);

  return (
    <>
      <Subheader />
      {isLoading && (
        <div className="modules-spinner">
          <Spin delay={200} size="large" />
          <h4 style={{ paddingTop: '20px' }}> {t('_HOME._LOADING')}</h4>
        </div>
      )}

      {submodules?.length === 0 && <h2>{t('_INDICATORS._NO_INDICATORS')}!</h2>}
      {submodules?.map((submodule) => {
        const indicators = submodule.indicators.filter((indicator) => {
          return indicatorsToHide.every((key) => key !== indicator.key);
        });

        return (
          <CategorySection
            key={submodule.nameEN}
            id={submodule.id}
            title={submodule[`name${currLang}`]}
            indicators={indicators}
          />
        );
      })}
      {isLoading && (
        <div className="modules-spinner">
          <Spin delay={200} size="large" />
          <h4 style={{ paddingTop: '20px' }}> {t('_HOME._LOADING')}</h4>
        </div>
      )}
    </>
  );
};

export default Category;
