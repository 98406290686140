import React, { useEffect, useState } from 'react';
import { Tabs, Radio } from 'antd';
import { ArrowRightOutlined, CloseOutlined } from '@ant-design/icons';
import FullLogo from 'common/images/adp-horizontal-01.svg';
import { DataSubmenu, ThemesSubmenu } from './Submenu';
import I18nLink from '../I18nLink';
import { Drawer, Button } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import useWindowDimensions from 'common/hooks/useWindowDimensions';
import { getCurrentLng } from 'utils/helper';

const MobileNav = ({ showMenu, setShowMenu }) => {
  const lang = getCurrentLng();
  const { width } = useWindowDimensions();
  const { TabPane } = Tabs;
  const history = useHistory();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selected, setSelected] = useState('');
  const [activeKey, setActiveKey] = useState('data');

  useEffect(() => {
    setActiveKey(lang === 'EN' ? 'data' : 'te dhena');
  }, [showMenu]);
  const handleTabClick = (menu: string) => {
    if (width < 900 && ['reports', 'explore', 'raporte', 'eksploroni'].includes(menu)) {
      const route = menu === 'raporte' ? 'reports' : menu === 'eksploroni' ? 'explore' : menu;
      setShowMenu(false);
      history.push(`/${route}`);
    } else {
      if (['reports', 'explore', 'raporte', 'eksploroni'].includes(menu)) {
        const route = menu === 'raporte' ? 'reports' : menu === 'eksploroni' ? 'explore' : menu;

        setShowMenu(false);
        history.push(`/${route}`);
      } else {
        setDrawerOpen(true);
        setSelected(menu);
      }
    }
  };

  const menus = [
    { nameEN: 'data', nameSQ: 'te dhena', render: <DataSubmenu setShowMenu={setShowMenu} /> },
    { nameEN: 'explore', nameSQ: 'eksploroni', render: null },
    { nameEN: 'themes', nameSQ: 'themes', render: <ThemesSubmenu setShowMenu={setShowMenu} /> },
    { nameEN: 'reports', nameSQ: 'raporte', render: null },
  ];

  const drawerRender =
    selected === 'themes' ? (
      <ThemesSubmenu setShowMenu={setShowMenu} setDrawerOpen={setDrawerOpen} />
    ) : selected === 'data' || selected === 'te dhena' ? (
      <DataSubmenu setShowMenu={setShowMenu} setDrawerOpen={setDrawerOpen} />
    ) : null;
  const tabcontent = (menu: string) => {
    return (
      <>
        <div
          onClick={() => {
            ['reports', 'explore', 'raporte', 'eksploroni'].includes(menu) && history.push(`/${menu}`);
            handleTabClick(menu);
          }}
          className="tabcontent">
          <p
            style={{ textTransform: 'uppercase', fontWeight: 600, letterSpacing: 1 }}
            className={menu === 'reports' ? 'tab-mobile-menu reports-tab' : 'tab-mobile-menu'}>
            {menu}
          </p>
          <p style={{ fontWeight: 600 }}>
            <ArrowRightOutlined />
          </p>
        </div>
      </>
    );
  };

  const hideMenu = () => {
    setShowMenu(false);
    setDrawerOpen(false);
  };
  return (
    <nav className={showMenu ? `mobile-nav active` : 'mobile-nav'}>
      <div className="mobile-nav-wrapper">
        {/**Drawer content*/}
        {width < 900 && (
          <Drawer
            width={'100%'}
            onClose={() => setDrawerOpen(false)}
            style={{ zIndex: 2001, overflowY: 'visible' }}
            placement="right"
            visible={drawerOpen}
            closeIcon={<LeftOutlined />}>
            {drawerRender}
          </Drawer>
        )}
        <div className="mobile-logo">
          <I18nLink to="/" onClick={hideMenu}>
            <img src={FullLogo} alt="edt logo" />
          </I18nLink>

          <CloseOutlined
            onClick={() => setShowMenu(false)}
            style={{ fontSize: width > 400 ? '25px' : 20, cursor: 'pointer' }}
          />
        </div>
        <Tabs
          onChange={(e) => setActiveKey(e)}
          className="tab"
          defaultActiveKey={'data'}
          activeKey={activeKey}
          tabPosition="left">
          {menus.map((menu) => (
            <TabPane tab={tabcontent(menu[`name${lang}`])} key={menu[`name${lang}`]}>
              {width > 900 && <div className="tab-content">{menu.render}</div>}
            </TabPane>
          ))}
        </Tabs>
      </div>
    </nav>
  );
};

export default MobileNav;
