import * as React from 'react';
import Input from 'common/components/Input/Input';
import Button from 'common/components/Button/Button';
import { createSubmodule, updateSubmodule } from 'api/submodule';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { validationSchema, translationBasePath } from './SubmoduleValidationForm';
import { useMutation, useQuery } from 'react-query';
import Submodule from 'models/submodule';
import Select from 'common/components/Select/Select';
import { getThemes } from 'api/public';
import { Spin } from 'antd';
import { getCurrentLng } from 'utils/helper';

interface IFormSubmodule {
  onClose: () => void;
  refetch: () => void;
  submodule: Submodule;
}
interface IFormInitialValues {
  nameEN: string;
  nameSQ: string;
  themes: string[];
}

const SubmoduleForm: React.FunctionComponent<IFormSubmodule> = (props) => {
  const { onClose, refetch } = props;
  const { t } = useTranslation();
  const currentLng = getCurrentLng();
  const { moduleId, id } = props.submodule;
  const { data: themesData, isFetched: isFetchedThemeData } = useQuery('themes', getThemes);

  if (!isFetchedThemeData) {
    return <Spin />;
  }

  const mutationHook = id ? useMutation(updateSubmodule) : useMutation(createSubmodule);
  const { isLoading, isError, mutateAsync } = mutationHook;

  const existingThemeIds = props.submodule.themes?.map((theme) => `${theme.themeId}`) || [];

  const initialValues: IFormInitialValues = {
    nameEN: props.submodule.nameEN || '',
    nameSQ: props.submodule.nameSQ || '',
    themes: id ? existingThemeIds : [],
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema(t),
    onSubmit: (values: Submodule) => {
      mutateAsync({ ...values, moduleId, id })
        .then(() => {
          onClose();
          formik.resetForm();
          refetch();
        })
        .catch((err) => {
          console.log(err);
          formik.resetForm();
        });
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Input
        name="nameEN"
        value={formik.values.nameEN}
        label={t(`${translationBasePath}._NAMEEN`)}
        onChange={formik.handleChange}
        error={formik.errors.nameEN}
      />

      <Input
        name="nameSQ"
        value={formik.values.nameSQ}
        label={t(`${translationBasePath}._NAMESQ`)}
        onChange={formik.handleChange}
        error={formik.errors.nameSQ}
      />
      {id && (
        <Select
          label={'Themes'}
          mode="multiple"
          showSearch={false}
          allowClear
          style={{ width: '100%' }}
          placeholder="Please select"
          onChange={(e) => {
            formik.setFieldValue('themes', e);
          }}
          defaultValue={existingThemeIds.map((themeId) => `${themeId}`)}
          data={themesData?.map((theme) => ({
            value: `${theme.id}`,
            label: theme[`name${currentLng}`],
          }))}
        />
      )}

      <Button htmlType="submit" type="primary" style={{ marginTop: 10 }}>
        {t(`${translationBasePath}._SAVE`)}
      </Button>
      <div style={{ color: 'gray', background: '#234' }}>
        {isLoading ? t(`${translationBasePath}._SAVING`) : ''}
        {isError ? t(`${translationBasePath}._ERROR`) : ''}
      </div>
    </form>
  );
};

export default SubmoduleForm;
