import React from 'react';
import PublicationForm from './PublicationForm';
import Module from 'models/module';
import { Publication } from 'models/publication';

interface IProps {
  onClose: () => void;
  refetch: () => void;
  modules: Module[];
  publication: Publication;
}
const PublicationUpdate: React.FunctionComponent<IProps> = (props) => {
  const { onClose, refetch, modules, publication } = props;
  return <PublicationForm onClose={onClose} refetch={refetch} modules={modules} publication={publication} />;
};

export default PublicationUpdate;
