import React, { useEffect } from 'react';
import someImg from 'common/components/ChartCard/chartImage.png';
import { useQuery } from 'react-query';
import { getOneBrief } from 'api/public';
import { useParams } from 'react-router-dom';
import { formatDate } from './helpers';
import { Spin } from 'antd';
import { getCurrentLng } from 'utils/helper';
import bufferToImage from 'utils/bufferToImage';
import Indicator from 'pages/Explorer/components/Indicator/Indicator';
import FullLogo from 'common/images/adp-horizontal-01.svg';

function SingleBriefComponent({ storyline, img, indicatorName, indicatorId }) {
  return (
    <div>
      <p
        style={{
          width: '90%',
          textAlign: 'center',
          padding: '20px',
          paddingBottom: '0px',
          fontSize: '18px',
          marginBottom: 0,
        }}>
        {indicatorName}
      </p>
      <div className="single-brief-parent">
        <div className="single-brief-component">
          <div className="single-brief-img">
            <img src={img ? img : someImg} />
          </div>
          <section>
            <div>{<Indicator showText indicatorId={indicatorId} />}</div>
          </section>
        </div>
        <div className="brief-storyline-text">{storyline}</div>
      </div>
    </div>
  );
}
const SingleBrief = () => {
  const lang = getCurrentLng();
  const { briefId } = useParams<{ briefId: string }>();
  const { data, isLoading } = useQuery('briefs', () => getOneBrief(Number(briefId)));
  if (isLoading) {
    return (
      <div style={{ height: '300px', display: 'grid', placeContent: 'center' }}>
        <Spin size="large" />
      </div>
    );
  }
  return (
    <>
      <div className="single-brief-container" style={{ padding: '50px 16px' }}>
        <header>
          <div className="brief-logo">
            <img src={FullLogo} />
            <span>BRIEF</span>
          </div>
          <div className="all-brief-date">{data ? formatDate(data?.createdAt) : ''}</div>
        </header>
        <div className="all-brief-category">{data ? data[`name${lang}`] : ''}</div>
        {data?.briefIndicators.map((brief) => {
          const { indicatorId } = brief;
          return (
            <SingleBriefComponent
              storyline={brief.storyline}
              img={bufferToImage(brief.graph_image?.data)}
              indicatorName={brief.indicator[`name${lang}`]}
              indicatorId={indicatorId}
            />
          );
        })}
      </div>
    </>
  );
};
export default SingleBrief;
