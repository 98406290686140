import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './en';
import sq from './sq';

i18next.languages = ['en', 'sq'];

i18next
  .use(initReactI18next)
  .init({
    interpolation: {
      escapeValue: false,
    },
    lng: 'sq',
    fallbackLng: 'en',
    initImmediate: false,
    resources: {
      en,
      sq,
    },
  })
  .catch((err: any) => console.error(err));

export default i18next;
