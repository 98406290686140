import { Theme } from 'models/theme';

const sortAlphabetically = (a: Theme, b: Theme) => {
  if (a.key < b.key) {
    return -1;
  } else if (a.key > b.key) {
    return 1;
  }
  return 0;
};

export const sortThemes = (data: Theme[]): Theme[] => {
  return [
    ...data.filter((t) => t.type === 'custom').sort(sortAlphabetically),
    ...data.filter((t) => t.type === 'integration').sort(sortAlphabetically),
  ];
};
