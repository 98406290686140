import { login } from 'api/auth';
import Button from 'common/components/Button/Button';
import Form from 'common/components/Form/Form';
import Input from 'common/components/Input/Input';
import { useFormik } from 'formik';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { AuthFormContainer } from '../../common/components/Form/styled';
import I18nLink from 'common/components/I18nLink';
import { translationBasePath, validationSchema } from './LoginFormValidation';
import { notification } from 'antd';

interface IFormInitialValues {
  email: string;
  password: string;
}

interface LoginFormProps {
  history: {
    push: (path: string) => void;
  };
}

export const LoginForm: React.FunctionComponent<LoginFormProps> = (props: LoginFormProps) => {
  const initialValues: IFormInitialValues = { email: '', password: '' };
  const { t } = useTranslation();
  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema(t),
    onSubmit: (values) => {
      login(values.email, values.password)
        .then((res) => {
          props.history.push(`/login/mfa/${res.data.accessToken}`);
        })
        .catch((error) => {
          return notification.error({ message: t('_LOGIN._WRONG_CREDENTIALS') });
        });
    },
  });
  return (
    <AuthFormContainer>
      <div className="form-wrapper">
        <Form onSubmit={formik.handleSubmit}>
          <Input
            name="email"
            label={t(`${translationBasePath}._EMAIL`)}
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.errors.email}
            autoFocus
          />
          <Input
            name="password"
            label={t(`${translationBasePath}._PASSWORD`)}
            type="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.errors.password}
          />
          <Button htmlType="submit">{t(`${translationBasePath}._SUBMIT`)}</Button>
          <p>
            <I18nLink to="/login/forgot-password">{t(`${translationBasePath}._FORGOT_PASSWORD_LINK`)}</I18nLink>
          </p>
        </Form>
      </div>
    </AuthFormContainer>
  );
};
