import { Col, Divider, Row, Spin } from 'antd';
import { useQuery } from 'react-query';
import { useParams, useHistory } from 'react-router';
import ChartCard from 'common/components/ChartCard/ChartCard';
import Subheader from 'common/components/Subheader/Subheader';
import subImg from '../subcategoryImg.png';
import { useEffect } from 'react';
import { getSingleModule } from 'api/public';
import { getCurrentLng, indicatorsToHide } from 'utils/helper';
import '../subcategory.css';
import i18n from 'i18next';
import bufferToImage from 'utils/bufferToImage';

import IndicatorCard from 'common/components/IndicatorCard/IndicatorCard';
import useWindowDimensions from 'common/hooks/useWindowDimensions';
import IndicatorCardResponsive from 'common/components/IndicatorCard/IndicatorResponsiveCard';

interface subcategoryParams {
  moduleId: string;
  submoduleId: string;
}

const Subcategory = () => {
  const currLang = getCurrentLng();
  const { moduleId, submoduleId } = useParams<subcategoryParams>();
  const { data, isLoading } = useQuery('singleModule', () => getSingleModule(parseInt(moduleId)));
  const submodule = data?.submodules?.filter((submodule) => submodule.id === Number(submoduleId));
  const indicators = submodule?.[0]?.indicators;
  const { width } = useWindowDimensions();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Subheader image={subImg} />
      <div className="subcategory-wrapper">
        {indicators
          ?.filter((indicator) => {
            return indicatorsToHide.every((key) => key !== indicator.key);
          })
          .filter((x) => x).length === 0 && <p className="noIndicator-error">{i18n.t('_INDICATORS._NO_INDICATORS')}</p>}

        {isLoading && (
          <p style={{ height: '300px', display: 'grid', placeContent: 'center' }}>
            <Spin />
          </p>
        )}

        {width > 700 ? (
          <Row>
            {indicators
              ?.filter((indicator) => {
                return indicatorsToHide.every((key) => key !== indicator.key);
              })
              .map((indicator) => {
                return (
                  <Col
                    style={{ display: 'flex', justifyItems: 'center' }}
                    key={indicator.key}
                    xs={{ span: 24 }}
                    sm={{ span: 12 }}
                    md={{ span: 8 }}
                    lg={{ span: 6 }}
                    xl={{ span: 4 }}>
                    <IndicatorCard
                      indicator={indicator}
                      path={`/indicator/${indicator.key}`}
                      chartTitle={indicator[`name${currLang}`]}
                      image={bufferToImage(indicator.graph_image?.data)}
                    />
                  </Col>
                );
              })}
          </Row>
        ) : (
          <section className="subcategory-indicator-cards-res">
            {indicators
              ?.filter((indicator) => {
                return indicatorsToHide.every((key) => key !== indicator.key);
              })
              .map((indicator) => {
                return (
                  <article key={indicator.id}>
                    <IndicatorCardResponsive
                      indicator={indicator}
                      path={`/indicator/${indicator.key}`}
                      button
                      chartTitle={indicator[`name${currLang}`]}
                      responsive={width < 700}
                      image={bufferToImage(indicator.graph_image?.data)}
                    />
                  </article>
                );
              })}
          </section>
        )}

        <Divider />
      </div>
    </>
  );
};

export default Subcategory;
