import React from 'react';
import { useTranslation } from 'react-i18next';
import { Breadcrumb, Radio, Spin } from 'antd';
import Table from 'common/components/Table/Table';
import { getCurrentLng } from 'utils/helper';
import { useMutation } from 'react-query';
import { useQuery } from 'react-query';
import { getAllInstitutionUsers } from 'api/institution-user';
import { useParams } from 'react-router';
import I18nLink from 'common/components/I18nLink';
import { createIndicatorUser, getIndicator, getIndicatorUsers, removeIndicatorUser } from 'api/indicator';
import { CreateIndicatorUserBody } from 'models/indicator';

export const translationBasePath = '_DASHBOARD._MANAGE_INDICATOR_ACCESS_USERS';

const ManageIndicatorUsers: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const currentLanguage = getCurrentLng();

  const indicatorId = useParams<{ indicatorId: string }>().indicatorId;
  const { data, isLoading } = useQuery('getAllInstitutionUsers', getAllInstitutionUsers);
  const {
    data: indicatorUsersData,
    refetch: indicatorUsersRefetch,
    isLoading: indicatorUsersIsLoading,
  } = useQuery(['indicatorUsers', indicatorId], () => getIndicatorUsers(indicatorId));

  const { data: indicator } = useQuery('indicator', () => getIndicator(parseInt(indicatorId)));
  const removeIndicatorUserMutation = useMutation(['removeIndicatorUser', indicatorId], (userId: number) =>
    removeIndicatorUser(indicatorId, userId)
  );
  const assignIndicatorUserMutation = useMutation(
    ['createIndicatorUser', indicatorId],
    (body: CreateIndicatorUserBody) => createIndicatorUser(indicatorId, body)
  );

  /**
   * Transform data for the table
   */
  const dataSource = data?.map((object) => ({
    institution: object.institution[`name${currentLanguage}`],
    email: object.user.email,
    userId: object.user.id,
    institutionRole: object.role,
    role:
      indicatorUsersData?.find((o) => o.userId === object.user.id && o.indicatorId === parseInt(indicatorId, 10))
        ?.accessType || 'noaccess',
  }));

  /**
   * Unique institutions that are used in the table filter
   */
  const uniqueInstitutions = [...new Set(dataSource?.map((item) => item.institution))];

  const columns = [
    {
      title: t(`${translationBasePath}._COLUMN_INSTITUTION`),
      dataIndex: 'institution',
      key: 'institution_name',
      filters: uniqueInstitutions.map((insitutionName) => ({
        text: insitutionName,
        value: insitutionName,
      })),
      onFilter: (value, record) => record.institution.indexOf(value) === 0,
    },
    {
      title: t(`${translationBasePath}._COLUMN_EMAIL`),
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: t(`${translationBasePath}._COLUMN_ROLE`),
      dataIndex: 'institutionRole',
      key: 'institutionRole',
    },
    {
      title: t(`${translationBasePath}._COLUMN_ACCESS`),
      key: 'role',
      width: 400,
      render: (item) => {
        if (removeIndicatorUserMutation.isLoading || assignIndicatorUserMutation.isLoading) {
          return <Spin />;
        }
        return (
          <Radio.Group
            onChange={(variable) => {
              const role = variable.target.value;
              if (role === 'noaccess') {
                removeIndicatorUserMutation.mutateAsync(item.userId).then(() => {
                  indicatorUsersRefetch();
                });
              } else {
                assignIndicatorUserMutation.mutateAsync({ userId: item.userId, accessType: role }).then(() => {
                  indicatorUsersRefetch();
                });
              }
            }}
            value={item.role}>
            <Radio value="read">{t(`${translationBasePath}._ACCESS_READ`)}</Radio>
            <Radio value="readwrite">{t(`${translationBasePath}._ACCESS_READ_WRITE`)}</Radio>
            <Radio value="noaccess">{t(`${translationBasePath}._ACCESS_NO_ACCESS`)}</Radio>
          </Radio.Group>
        );
      },
    },
  ];

  return (
    <>
      <Breadcrumb style={{ padding: '10px', fontSize: 20, marginBottom: 10, fontWeight: 'bold' }}>
        <Breadcrumb.Item>
          <I18nLink to="/dashboard/indicators">{t('_DASHBOARD._MANAGE_INDICATORS._MANAGE_INDICATORS')}</I18nLink>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{indicator?.[`name${currentLanguage}`]}</Breadcrumb.Item>
        <Breadcrumb.Item>{t(`${translationBasePath}._TITLE`)}</Breadcrumb.Item>
      </Breadcrumb>
      <Table
        dataSource={dataSource}
        columns={columns}
        loading={isLoading || indicatorUsersIsLoading}
        pagination={false}
        rowClassName="admin-table-row"
      />
    </>
  );
};

export default ManageIndicatorUsers;
