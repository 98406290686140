import React from 'react';
import { StyledSelect, Label, SelectWrapper } from './styled';
import { SelectProps as AntSelectProps, SelectValue } from 'antd/lib/select';
import AntSelect from 'antd/lib/select';
import ErrorMessage from '../ErrorMessage/ErrorMessage';

interface Option {
  value: string;
  label: string;
}

export interface SelectProps extends AntSelectProps<SelectValue> {
  data?: Option[];
  label?: string;
  error?: string;
  width?: string;
}

const Select = ({ data = [], label, error, width = '100%', ...rest }: SelectProps) => {
  return (
    <SelectWrapper width={width}>
      {label && <Label>{label}</Label>}
      <StyledSelect {...rest} error={error}>
        {data.map((item, index) => (
          <AntSelect.Option key={index} value={item.value}>
            {item.label}
          </AntSelect.Option>
        ))}
      </StyledSelect>
      <ErrorMessage message={error} />
    </SelectWrapper>
  );
};

export default Select;
