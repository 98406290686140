import React from 'react';
import PublicationForm from './PublicationForm';
import Module from 'models/module';

interface IProps {
  onClose: () => void;
  refetch: () => void;
  modules: Module[];
}
const PublicationCreate: React.FunctionComponent<IProps> = (props) => {
  const { onClose, refetch, modules } = props;
  return <PublicationForm onClose={onClose} refetch={refetch} modules={modules} />;
};

export default PublicationCreate;
