import * as React from 'react';

interface IndicatorContextInterface {
  frequency: string;
  unitType: string;
  allAvailableFilters: [];
  changeFrequency: React.Dispatch<React.SetStateAction<string>>;
  changeUnitType: React.Dispatch<React.SetStateAction<string>>;
  changeAllAvailableFilters: React.Dispatch<React.SetStateAction<any>>;
  startYearFilter: number | string | undefined;
  setStartYearFilter: React.Dispatch<React.SetStateAction<number | string | undefined>>;
  endYearFilter: number | string | undefined;
  setEndYearFilter: React.Dispatch<React.SetStateAction<number | string | undefined>>;
}

export const contextValue: IndicatorContextInterface = {
  frequency: '',
  unitType: '',
  allAvailableFilters: [],
  changeFrequency: () => {},
  changeUnitType: () => {},
  changeAllAvailableFilters: () => {},
  startYearFilter: undefined,
  setStartYearFilter: () => {},
  endYearFilter: undefined,
  setEndYearFilter: () => {},
};

export const IndicatorPageContext = React.createContext<IndicatorContextInterface>(contextValue);
