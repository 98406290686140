import React, { useState, useContext, useEffect, useRef } from 'react';
import { IndicatorContext } from '../store/indicatorContext';
import './filters.css';
import { Checkbox, Row, Col, Radio, Space, Spin, Collapse, DatePicker, Divider } from 'antd';
import { useQuery } from 'react-query';
import { getIndicatorVariables } from 'api/indicator';
import { IndicatorWithId } from 'models/indicator';
import { getCurrentLng } from 'utils/helper';
import { useTranslation } from 'react-i18next';
import { getIndicatorFilters } from 'api/public';
import { formatSerieName, getAvailableFilters, getInitialStateForFilters } from './helpers';
import moment from 'moment';
const { Panel } = Collapse;
const { RangePicker } = DatePicker;

interface FilterProps {
  indicator: IndicatorWithId;
}

const Filters: React.FunctionComponent<FilterProps> = ({ indicator }) => {
  const indicatorContext = useContext(IndicatorContext);
  const currLang = getCurrentLng();
  const rangePickerRef = useRef(null as any);
  const [selectedItems, setSelectedItems] = useState({});
  const { t } = useTranslation();
  const {
    data: filters,
    isLoading: isLoadingFilters,
    isError: isErrorIndicatorFilters,
  } = useQuery(['getIndicatorFilters', indicator.id], () => getIndicatorFilters(indicator.id));

  const {
    data: generalVariables,
    isLoading: isLoadingAllVariables,
    isError: isErrorGeneralVariables,
  } = useQuery('getIndicatorVariables', getIndicatorVariables);

  const availableFilters = filters && generalVariables ? getAvailableFilters(filters, generalVariables) : [];

  const initState = getInitialStateForFilters(availableFilters);

  const handleSelected = (filter, e) => {
    indicatorContext.changeSelectedVariables({
      ...initState,
      [filter.key]: e,
    });
    setSelectedItems({
      ...initState,
      [filter.key]: e,
    });
  };

  useEffect(() => {
    if (filters && generalVariables) {
      indicatorContext.changeSelectedVariables(initState);
      setSelectedItems(initState);

      if (filters.unitTypes.length > 0) {
        indicatorContext.changeUnitType(filters.defaultUnitType ?? filters.unitTypes[0]);
      }
      if (filters.frequencies.length > 0) {
        indicatorContext.changeFrequency(filters.defaultFrequency ?? filters.frequencies[0]);
      }
    }
  }, [filters, generalVariables]);

  useEffect(() => {
    if (availableFilters && indicatorContext.allAvailableFilters) {
      indicatorContext.changeAllAvailableFilters(availableFilters);
    }
  }, [JSON.stringify(availableFilters)]);

  //disable dates after today in RANGE PICKER
  function disabledDate(current) {
    // Can not select days after today
    return current > moment().endOf('day');
  }

  return (
    <div className="indicator-filters">
      <div className="filters-title">{t('_COMMON._FILTERS')}</div>
      <div className="filter-area">
        <div className="filter-comp">
          <Collapse
            bordered={false}
            className="site-collapse-custom-collapse"
            style={{ padding: '0px', background: 'none' }}>
            <Panel
              className="site-collapse-custom-panel"
              style={{ padding: '0px', borderBottom: '0px' }}
              header={t('_FILTERS._TIME_RANGE')}
              key="date-range">
              <RangePicker
                defaultValue={[moment(indicatorContext.startDate), moment(indicatorContext.endDate)]}
                disabledDate={disabledDate}
                className="filter-range-picker"
                ref={rangePickerRef}
                style={{ padding: '2px', fontSize: '10px' }}
                size="small"
                onChange={(moment, values) => {
                  indicatorContext.changeStartDate(values[0]);
                  indicatorContext.changeEndDate(values[1]);
                  rangePickerRef?.current?.blur();
                }}
              />
            </Panel>
          </Collapse>
        </div>

        <div className="filter-comp">
          <Collapse
            style={{ background: 'none', padding: 0 }}
            bordered={false}
            className="site-collapse-custom-collapse"
            accordion>
            <Panel
              className="site-collapse-custom-panel"
              style={{ padding: '0px', borderBottom: '0px' }}
              header={t('_FILTERS._FREQUENCY')}
              key={'frequency'}>
              <Radio.Group
                onChange={(e) => {
                  indicatorContext.changeFrequency(e.target.value);
                }}
                value={indicatorContext.frequency}>
                <Space direction="vertical">
                  {filters?.frequencies.map((frequency) => (
                    <Radio key={frequency} value={frequency}>
                      {t(`_FILTERS._${frequency.toUpperCase()}`)}
                    </Radio>
                  ))}
                </Space>
              </Radio.Group>
            </Panel>
          </Collapse>
        </div>
        <div className="filter-comp">
          <Collapse style={{ background: 'none' }} bordered={false} className="site-collapse-custom-collapse" accordion>
            <Panel
              className="site-collapse-custom-panel"
              style={{ padding: '0px', borderBottom: '0px' }}
              header={t('_FILTERS._UNIT_TYPE')}
              key={'unitType'}>
              <Radio.Group
                onChange={(e) => {
                  indicatorContext.changeUnitType(e.target.value);
                }}
                value={indicatorContext.unitType}>
                <Space direction="vertical">
                  {filters?.unitTypes.map((unitType) => (
                    <Radio key={unitType} value={unitType}>
                      {t(`_FILTERS._${unitType.toUpperCase()}`)}
                    </Radio>
                  ))}
                </Space>
              </Radio.Group>
            </Panel>
          </Collapse>
        </div>
        {availableFilters
          .filter((filter) => filter.variables.length > 1 && filter.graph_type === 'line')
          .map((filter) => {
            return (
              <Collapse
                key={filter.key}
                style={{ background: 'none' }}
                bordered={false}
                className="site-collapse-custom-collapse"
                accordion>
                <Panel
                  className="site-collapse-custom-panel"
                  style={{ padding: '0px', borderBottom: '0px' }}
                  header={
                    filter[`${currLang.toLowerCase()}`].charAt(0).toUpperCase() +
                    filter[`${currLang.toLowerCase()}`].slice(1)
                  }
                  key={filter.key}>
                  <div key={filter.key} className="filter-comp">
                    <div className="checkboxes">
                      <Checkbox.Group
                        style={{ width: '100%' }}
                        onChange={(e) => handleSelected(filter, e)}
                        value={selectedItems[filter.key]}>
                        <Row justify="center">
                          {filter.variables.map((option) => (
                            <Col span={24} key={option.key}>
                              <Checkbox key={option.key} className="filter-checkbox" value={option.key}>
                                <span className="checkbox-value">
                                  {formatSerieName(option[`${currLang.toLowerCase()}`])}
                                </span>
                              </Checkbox>
                            </Col>
                          ))}
                        </Row>
                      </Checkbox.Group>
                    </div>
                  </div>
                </Panel>
              </Collapse>
            );
          })}

        {isLoadingFilters && isLoadingAllVariables && <Spin />}
        {(isErrorGeneralVariables || isErrorIndicatorFilters) && <h1> {t('_COMMON._ERROR')}</h1>}
      </div>
    </div>
  );
};

export default Filters;
