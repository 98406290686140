import React from 'react';
import { StyledForm } from './styled';

export interface FormProps extends React.FormHTMLAttributes<HTMLFormElement> {
  gap?: string;
  direction?: 'row' | 'column';
}

const Form = ({ gap = '10px', direction = 'column', ...rest }: FormProps) => {
  return <StyledForm direction={direction} gap={gap} {...rest} />;
};

export default Form;
