import { Row, Col, Breadcrumb, Space, Button } from 'antd';
import './subheader.css';
import { useQuery } from 'react-query';
import i18n from 'i18next';
import { getModules } from 'api/public';
import { useLocation, useParams } from 'react-router';
import { getCurrentLng } from 'utils/helper';
import I18nLink from '../I18nLink';

interface LocationParams {
  pathname: string;
}
interface Params {
  moduleId: string;
  submoduleId: string;
}
interface SubheaderProps {
  image?: string;
  title?: string;
  description?: string;
  buttonTitle?: string;
  onButtonClick?: (id: number) => void; // just a mockup
}

const Subheader: React.FunctionComponent<SubheaderProps> = ({
  image,
  title,
  description,
  buttonTitle,
  onButtonClick,
}) => {
  const currLang = getCurrentLng();
  const { data } = useQuery('modules', getModules);
  const { pathname } = useLocation<LocationParams>();
  const { moduleId, submoduleId } = useParams<Params>(); // retrieve the moduleId from path
  const pathnames = pathname.split('/').filter((x) => x); // filter path to remove the id because we do not need to display it in the BreadCrumb
  const category = data?.filter((data) => data.id === Number(moduleId))[0];
  const subcategory = category?.submodules?.filter((subcat) => subcat.id === Number(submoduleId))[0];

  const lastItem = subcategory ? subcategory : category ? category : null;

  // check if last item is a number (id), return the previous item from the path otherwise return the last item
  return (
    <>
      <div className="subheader-wrapper">
        <Row>
          <Col sm={{ span: 24 }} md={{ span: 24 }}>
            <Space direction="vertical" size={24}>
              <Breadcrumb separator="•">
                <Breadcrumb.Item>
                  <I18nLink to="/">{i18n.t('_COMMON._HOME')}</I18nLink>
                </Breadcrumb.Item>
                {pathnames.map((value, index) => {
                  const to = `/${pathnames.slice(0, index + 1).join('/')}`;
                  if (value === 'category') {
                    return (
                      <Breadcrumb.Item key="category-static">
                        <I18nLink to={`/category/${moduleId}`}>{category?.[`name${currLang}`]}</I18nLink>
                      </Breadcrumb.Item>
                    );
                  } else if (value.match(/\d+/) || ['sq', 'en'].includes(value)) {
                    return;
                  } else if (value === 'subcategory') {
                    return (
                      <Breadcrumb.Item key={`${value}${index}`}>
                        <I18nLink to={`/category/${moduleId}/subcategory/${submoduleId}`}>
                          {subcategory?.[`name${currLang}`]}
                        </I18nLink>
                      </Breadcrumb.Item>
                    );
                  } else {
                    if (pathnames[index + 1].match(/\d+/)) {
                      // check whether the path is the id=> If so, do not show in breadcrumb
                      return (
                        <Breadcrumb.Item key={`${value}${index}`}>
                          <I18nLink to={`${to}/${pathnames[index + 1]}`}>
                            {value[0].toUpperCase() + value.slice(1)}
                          </I18nLink>
                        </Breadcrumb.Item>
                      );
                    } else {
                      return (
                        <Breadcrumb.Item key={`${value}${index}`}>
                          <I18nLink to={to}>{value[0].toUpperCase() + value.slice(1)}</I18nLink>
                        </Breadcrumb.Item>
                      );
                    }
                  }
                })}
              </Breadcrumb>
              <div className="title">{lastItem ? lastItem?.[`name${currLang}`] : title}</div>
              {buttonTitle && (
                <div className="subheader-btn">
                  <Button onClick={() => onButtonClick}> {buttonTitle} </Button>
                </div>
              )}
            </Space>
          </Col>
        </Row>
      </div>
      <div className="empty-div"></div>
    </>
  );
};

export default Subheader;
