import { SearchOutlined } from '@ant-design/icons';
import { AutoComplete, Input } from 'antd';
import useWindowDimensions from 'common/hooks/useWindowDimensions';
import i18next from 'i18next';
import React, { useState, useRef, useEffect } from 'react';
import { useQueryClient } from 'react-query';
import I18nLink from '../I18nLink';
import { getFilteredResults } from './FilterResults';
import './Search.css';

interface Props {
  responsive?: boolean;
  setModal?: any;
}

const GlobalSearch: React.FunctionComponent<Props> = ({ responsive, setModal }) => {
  const { height } = useWindowDimensions();

  const handleHide = () => {
    setModal(false);
  };

  const handleClick = (event: any) => {
    if (event.target.classList.contains('certain-category-search-dropdown')) {
      setModal(false);
    }
  };

  const inputRef = useRef<Input | null>(null);
  const [active, setActive] = useState(false);
  const queryClient = useQueryClient();
  const queryCache = queryClient.getQueryCache();
  const [searchValue, setSearchValue] = useState('');
  const [opts, setOpts] = useState<any[]>([]);

  const handleSearch = (value: string) => {
    setSearchValue(value);
    const results = value ? getFilteredResults(value, queryCache, false, setModal).filter((r) => !!r) : [];
    setOpts(results);
  };

  //see all results link, to be appended to options
  const link = {
    options: [
      {
        label: (
          <I18nLink onClick={handleHide} className="edt-all-result" to={`/search-content/?search=${searchValue}`}>
            {i18next.t('_SEARCH._SEE_ALL')}
          </I18nLink>
        ),
        value: 'link',
      },
    ],
  };

  //get options as a single array and show at maximum 5 of results
  const options =
    opts && opts.length
      ? opts
          .map((op) => op.options)
          .flat()
          .filter((x) => x)
          .slice(0, 5)
      : [];

  return (
    <div>
      <AutoComplete
        dropdownClassName="certain-category-search-dropdown"
        dropdownMatchSelectWidth={responsive ? 300 : 250}
        style={{
          width: responsive ? '100%' : 250,
          background: 'gray',
        }}
        options={
          options.filter((op) => op.value !== 'link').length !== 0
            ? [...options, link.options[0]] //add the custom link SEE ALL RESULTS
            : searchValue.length > 0
            ? [{ label: i18next.t('_SEARCH._NO_RESULTS'), value: '' }] //no results show if no results
            : []
        }
        listHeight={height > 620 ? 600 : 400}
        listItemHeight={15}
        value={searchValue}
        onSearch={handleSearch}
        onClick={handleClick}>
        <Input
          style={{ height: 40, fontSize: 14, background: 'gray !important' }}
          ref={inputRef}
          className="input-search"
          size="middle"
          placeholder={i18next.t('_SEARCH._SEARCH_DATA')}
          prefix={<SearchOutlined style={{ color: 'black', fontSize: '20px', fontWeight: 'bolder' }} />}
          onBlur={() => {
            if (searchValue.trim().length === 0) {
              setActive(false);
            }
          }}
        />
      </AutoComplete>
    </div>
  );
};
export default GlobalSearch;
