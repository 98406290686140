import React, { useContext, useRef, useState } from 'react';
import _ from 'lodash';
import { ExploreContext } from 'pages/Explorer/screens/store/exploreContext';
import ReactEcharts from 'echarts-for-react';
import './indicatorChart.css';
import closeIcon from './icons/close.svg';
import twitter from './icons/twitter.svg';
import instagram from './icons/instagram.svg';
import message from './icons/message.svg';
import linkedin from './icons/linkedin.svg';
import download from './icons/download.svg';
import image from './icons/image.png';
import { useQuery } from 'react-query';
import { getCurrentLng } from 'utils/helper';
import CsvDownload from 'react-json-to-csv';
import { getIndicatorValues } from 'api/public';
import { getIndicator, getIndicatorVariables } from 'api/indicator';
import { IndicatorContext } from '../store/indicatorContext';
import { Spin, Table } from 'antd';

import { AnalyticalChange, IndicatorWithId } from 'models/indicator';
import {
  formatYAxis,
  generateColorForString,
  generateIndicatorName,
  generateSerieName,
  generateSeries,
  getCurrentActiveFilter,
  transformXAxisBasedOnFrequency,
  sortDataByTwoValues,
  showMinimumAndMaxValues,
  addCommas,
} from './helpers';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { generateIndicatorTitle } from './generateIndicatorTitle';
import DownloadModal from 'common/components/DownloadModal/DownloadModal';
import useWindowDimensions from 'common/hooks/useWindowDimensions';
import { useTranslation } from 'react-i18next';
import { processedData } from './downloadCsv';
import { getModule } from 'api/module';

interface IndicatorChart {
  indicator: IndicatorWithId;
  graphSnapshot?: boolean;
  text?: boolean;
}

const IndicatorChart: React.FunctionComponent<IndicatorChart> = ({ indicator, graphSnapshot, text }) => {
  //get all general variables, for translation purpose
  const { data: generalVariables } = useQuery('getIndicatorVariables', getIndicatorVariables);

  const currLang = getCurrentLng();
  const location = useLocation();
  const isDebugEnabled = location.search === '?debug=true';
  const eChartsRef = useRef(null as any);
  const renderCounter = useRef(0);
  renderCounter.current = renderCounter.current + 1;
  const exploreContext = useContext(ExploreContext);
  const { frequency, unitType, selectedVariables, allAvailableFilters, startDate, endDate } =
    useContext(IndicatorContext);

  const [intitialFilter, setInitialFilter] = useState({});
  const [previewModal, setPreviewModal] = useState(false);
  const { t } = useTranslation();

  const { data, isError, isLoading } = useQuery(
    ['getIndicatorValues', indicator.id, frequency, unitType, startDate, endDate],
    () => getIndicatorValues(indicator.id, frequency, unitType, startDate, endDate),
    {
      refetchOnWindowFocus: false,
    }
  );

  const translate = (key: string): string => {
    const variables = generalVariables
      ?.map((genVar) => genVar.variables)
      .flat()
      .filter((x) => x);

    const currentVariable = variables?.find((variable) => variable.key === key.toLowerCase());
    if (!currentVariable) return key;
    return currentVariable?.[`${currLang.toLowerCase()}`];
  };

  const { data: indicatorData } = useQuery(['getSingleIndicator', indicator.id], () => getIndicator(indicator.id), {
    enabled: true,
    refetchOnWindowFocus: false,
  });

  const { data: moduleData } = useQuery('module', () => getModule(indicatorData?.submodule?.moduleId || 1), {
    enabled: !!indicatorData,
  });

  const { width } = useWindowDimensions();

  //fetch initial data for the generated text only based on a frequency
  const unChangeableFrequency =
    indicatorData?.analyticalChange === AnalyticalChange.YOY
      ? 'year'
      : indicatorData?.analyticalChange === AnalyticalChange.QOQ
      ? 'quarter'
      : indicatorData?.analyticalChange === AnalyticalChange.MOM
      ? 'month'
      : '';

  const { data: initialData } = useQuery(
    ['getIndicatorValues', indicator.id, startDate, endDate, unitType],
    () => getIndicatorValues(indicator.id, unChangeableFrequency, unitType, startDate, endDate),
    {
      enabled: !!unChangeableFrequency,
      refetchOnWindowFocus: false,
    }
  );
  /**
   *
   * Build the series for all the possible variable combinations
   */
  const { series, xAxis } = generateSeries(data || [], selectedVariables);
  const { series: initialSerie } = generateSeries(
    (initialData && sortDataByTwoValues(initialData)) || [],
    intitialFilter
  );

  const activeFilter = getCurrentActiveFilter(selectedVariables, allAvailableFilters);
  useEffect(() => {
    if (selectedVariables) {
      if (Object.keys(intitialFilter).length === 0 && Object.keys(selectedVariables).length !== 0) {
        setInitialFilter(selectedVariables);
      }
    }
  }, [selectedVariables]);

  const axisLabelFormat = graphSnapshot
    ? {
        showMaxLabel: true,
        fontSize: 20,
        fontWeight: 'bolder',
        lineHeight: 30,
      }
    : {};

  const chartOptions = {
    animation: !graphSnapshot,
    xAxis: {
      show: true,
      type: 'category',
      showGrid: false,
      offset: 10,
      tickAmount: 10,
      splitLine: { show: false },
      data: graphSnapshot ? showMinimumAndMaxValues(xAxis) : transformXAxisBasedOnFrequency(xAxis, frequency),
      axisLine: {
        show: true,
        lineStyle: {
          width: 0.5,
          opacity: 0.5,
        },
      },
      axisTick: {
        show: false,
      },
      boundaryGap: false,
      axisLabel: axisLabelFormat,
    },
    grid: {
      left: '5%',
      right: '10%',
      bottom: '5%',
      containLabel: true,
    },
    tooltip: {
      showContent: true,
      trigger: 'axis',
    },
    yAxis: {
      type: 'value',
      show: graphSnapshot ? false : true,
      max: (value) => {
        return value.max;
      },
      min: (value) => {
        return value.min;
      },
      splitLine: {
        show: true,
      },
      splitNumber: 2,
      axisLabel: {
        formatter: (value) => formatYAxis(value, unitType),
      },
    },

    legend: {
      show: previewModal,
    },
    series: series.map((serie, i) => ({
      name: translate(generateSerieName(serie, activeFilter)),
      color: generateColorForString(generateSerieName(serie, activeFilter)),
      type: 'line',
      data: serie,
      smooth: true,
      symbol: 'circle',
      symbolSize: 12,
      showSymbol: false,
      connectNulls: true,
      smoothMonotone: 'x',
      lineStyle: {
        width: graphSnapshot ? 10 : width > 700 ? 5 : 3,
      },
      itemStyle: {
        borderWidth: 5,
      },
      emphasis: {
        focus: 'series',
      },
      endLabel: {
        show: series.length > 1 ? true : previewModal ? true : false,
        distance: 7,
        formatter: (dt) => {
          if (graphSnapshot || previewModal) {
            return addCommas(dt.value.toString());
          }
          return dt?.seriesName;
        },
        fontFamily: 'Source Sans Pro',
        fontSize: 12,
        overflow: 'break',
        width: 90,
        color: generateColorForString(generateSerieName(serie, activeFilter)),
      },
    })),
  };

  useEffect(() => {
    if (eChartsRef && eChartsRef.current) {
      eChartsRef.current?.getEchartsInstance().setOption(chartOptions, true);
    }
  }, [selectedVariables]);

  const getHtml = () => {
    return {
      __html: generateIndicatorTitle(indicator, initialSerie[0], unitType).text,
    };
  };

  const indicatorContainer = (
    <div>
      <div className="header">
        <div className="text">
          <h5>{generateIndicatorName(indicator, unitType, currLang)}</h5>
          {data && data.length > 0 && indicatorData && initialSerie[0] && <p dangerouslySetInnerHTML={getHtml()}></p>}
          {isDebugEnabled && <p>Number of rendering: {`${renderCounter.current}`}</p>}
          {isDebugEnabled && <p>Selected variables: {JSON.stringify(selectedVariables)}</p>}
          {isDebugEnabled && <p>Active Filter: {JSON.stringify(activeFilter)}</p>}
          {isDebugEnabled && <p>All Available Filter: {JSON.stringify(allAvailableFilters)}</p>}
        </div>
        <div className="icons">
          <div className="items">
            <div>
              {!previewModal && (
                <img
                  src={closeIcon}
                  onClick={() => {
                    exploreContext?.removeIndicator(indicator.id);
                    exploreContext?.setRemoving(true);
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="chart">
        {isError && (
          <p
            style={{
              position: 'relative',
              width: '100%',
              height: '300px',
              display: 'grid',
              placeContent: 'center',
              fontSize: '25px',
              fontWeight: 'bold',
            }}>
            {t('_COMMON._ERROR')}
          </p>
        )}

        {isLoading && !isError && (
          <div className="explore-chart-spin">
            <Spin size="large" />
            {t('_COMMON._LOADING')}
          </div>
        )}

        {data?.length === 0 && !isLoading && (
          <p
            style={{
              position: 'relative',
              width: '100%',
              height: '300px',
              display: 'grid',
              placeContent: 'center',
              fontSize: '25px',
              fontWeight: 'bold',
            }}>
            {t('_COMMON._DATA_NOT_AVAILABLE')}
          </p>
        )}

        {data && data?.length > 0 && (
          <ReactEcharts
            style={{ position: 'relative', width: '100%', height: '400px' }}
            key={`i-${indicator.id}`}
            ref={eChartsRef}
            lazyUpdate={true}
            option={chartOptions}
          />
        )}
      </div>
    </div>
  );

  if (text) {
    return (
      <>
        {data && data.length > 0 && indicatorData && initialSerie[0] && (
          <>
            <div
              className="brief-bar"
              style={{
                backgroundColor: generateIndicatorTitle(indicator, initialSerie[0], unitType).color,
              }}></div>
            <div className="brief-text">
              <div className="text">
                <p style={{ fontSize: '20px' }} dangerouslySetInnerHTML={getHtml()} id="color"></p>
              </div>
            </div>
          </>
        )}
      </>
    );
  }

  return (
    <div className="indicator-chart-wrapper">
      {previewModal && (
        <DownloadModal indicator={indicator} setPreviewModal={setPreviewModal}>
          {indicatorContainer}
        </DownloadModal>
      )}
      {indicatorContainer}
      {isDebugEnabled &&
        series.map((serie) => (
          <div>
            <Table
              columns={[
                {
                  title: 'Date',
                  dataIndex: 'date',
                  key: 'date',
                },
                {
                  title: 'Value',
                  dataIndex: 'value',
                  key: 'value',
                },
                {
                  title: 'Variables',
                  dataIndex: 'variables',
                  key: 'variables',
                  render: (text) => <>{JSON.stringify(text)}</>,
                },
              ]}
              dataSource={serie}
            />
          </div>
        ))}
      <div className="indicator-footer">
        <div className="txt"></div>
        {data && data?.length > 0 && (
          <div className="share" style={{ padding: '5px' }}>
            <div>{t('_COMMON._SHARE')}</div>
            <div>
              <img src={twitter} />
            </div>
            <div>
              <img src={message} />
            </div>
            <div>
              <img src={linkedin} />
            </div>
            <div>
              <img src={instagram} />
            </div>

            <div>
              <img style={{ width: '18px' }} src={image} onClick={() => setPreviewModal(true)} />
            </div>
            <div></div>
            <div>
              <CsvDownload
                data={processedData(series, indicatorData, moduleData)}
                filename={`${indicatorData?.nameEN}.csv`}
                className="dcsv-img"
                style={{
                  border: 'none',
                  backgroundColor: 'white',
                  background: 'white',
                  cursor: 'pointer',
                  marginLeft: '-10px',
                }}>
                <img width={12} src={download} />
              </CsvDownload>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default IndicatorChart;
