import { apiUrl, get, post, remove } from 'utils/apiMgmt';
import { InstitutionUser } from 'models/institution-user';
import { User } from 'models/user';

export const getInstitutionUsers = async (institutionId: number): Promise<InstitutionUser[]> => {
  const { data } = await get(apiUrl + `/institutions/${institutionId}/users`);
  return data;
};

export const getAllInstitutionUsers = async (): Promise<InstitutionUser[]> => {
  const { data } = await get(apiUrl + '/institutions/users/all');
  return data;
};

export const getNotAssignedUsers = async (): Promise<User[]> => {
  const { data } = await get(apiUrl + '/users/institutions/not-assigned');
  return data;
};

export const createInstitutionUser = async (institutionUser: InstitutionUser): Promise<InstitutionUser> => {
  const { data } = await post(apiUrl + '/institutions/user/create', institutionUser);
  return data;
};

export const removeInstitutionUser = async (userId: number): Promise<InstitutionUser> => {
  const { data } = await remove(apiUrl + `/institutions/users/${userId}`);
  return data;
};
