import _ from 'lodash';
import { IndicatorValues, IndicatorUserWithId, IndicatorWithId, Frequency } from 'models/indicator';
import { quarterOfTheYear } from 'pages/Explorer/components/Indicator/components/generateIndicatorTitle';
import { getCurrentLng } from 'utils/helper';
import { findAllPossibleCombinationsForSearch } from 'pages/Explorer/components/Indicator/components/helpers';
import Module from 'models/module';
import i18next from 'i18next';
import { albanianMonthNames, monthNames } from 'data/months';
import { LanguagesAbbreviation } from 'models/publication';

interface GenerateSeriesReturnType {
  filter: any;
  data: {
    option: string;
    values: IndicatorValues[];
  }[];
  xAxis: string[];
}

/**
 * This function takes a filter key and returns all the series for that filter
 * @param selectedFilterKey
 * @param allFilters
 * @param data
 * @returns
 */
export const generateSeriesForFilter = (
  selectedFilterKey: string,
  allFilters: any,
  data: IndicatorValues[]
): GenerateSeriesReturnType | undefined => {
  if (Object.keys(allFilters).length === 0) {
    return;
  }

  const selectedFilters: { [key: string]: string[] } = {};
  allFilters.forEach((filter: any) => {
    selectedFilters[filter.key] = filter.variables.map((variable) => variable.key);
  });

  const allVariablesCombinations = findAllPossibleCombinationsForSearch(selectedFilters, selectedFilterKey);

  const series = allVariablesCombinations
    .map((variableCombination) => {
      return {
        values: data
          .filter((indicatorValue) => {
            let foundMatch = false;
            if (_.isEqual(indicatorValue.variables, variableCombination)) {
              foundMatch = true;
            }
            return foundMatch;
          })
          ?.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
          .map((indicatorValue) => {
            const date = new Date(indicatorValue.date).toISOString().slice(0, 10);

            return {
              ...indicatorValue,
              date: date,
            };
          }),
        option: variableCombination[selectedFilterKey],
      };
    })
    .filter((result) => result.values.length > 0);

  const currentFilter = allFilters.find((f) => f.key === selectedFilterKey);

  return {
    filter: currentFilter,
    data: series,
    xAxis: generateXAxis(series.map((serie) => serie.values)),
  };
};

const generateXAxis = (series: IndicatorValues[][]): string[] => {
  // Sort series based on date and convert them to the same format (YYYY-MM-DD)
  const sortedDates = series.map((serie) => {
    return serie?.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
  });

  // Figure out all unique dates from all the series
  const allDates: string[] = [];

  sortedDates.forEach((serie) => {
    serie.forEach((indicatorValue) => {
      allDates.push(indicatorValue.date);
    });
  });

  const allUniqueDates = _.uniq(allDates.sort((a, b) => new Date(a).getTime() - new Date(b).getTime()));

  // Adjust other arrays to add "-" where the values are missing
  const finalArray: IndicatorValues[][] = [];

  sortedDates.forEach((serie) => {
    const adjustedSeries: any[] = allUniqueDates
      .map((date) => ({
        date,
        unmatched: true,
      }))
      .map((obj) => {
        const indicatorValueObject = serie.find((iv) => iv.date === obj.date);

        if (indicatorValueObject) {
          return indicatorValueObject;
        }
        return '-'; // Placeholder for no data
      });
    finalArray.push(adjustedSeries);
  });

  return allUniqueDates;
};

export const generateFinalResult = (allAvailableFilters: [], data: IndicatorValues[]) => {
  if (data) {
    return allAvailableFilters.map((filter: any) => ({
      ...(generateSeriesForFilter(filter.key, allAvailableFilters, data) || {}),
    }));
  } else {
    return [];
  }
};

//utility function
const capitalizeFirstLetter = (str: string): string => {
  let st = str[0].toUpperCase() + str.slice(1);
  return st.replace('ë', 'e').replace('ç', 'c').replace('_', ''); //english lang, to fit in the map
};

//generate available years for dropdown if frequency==="year"
export const generateAvailableYears = (
  data?:
    | {
        option?: string;
        values: IndicatorValues[];
      }[]
    | undefined
): number[] => {
  //get all possible years from data
  const allYears = data?.map((obj) => {
    return obj.values.map((val) => new Date(val.date).getFullYear());
  });
  //remove duplicates and sort the years ascending
  const allAvailableYears = [...new Set(allYears?.flat().sort((a, b) => a - b))].filter((x) => x);
  return allAvailableYears;
};

//generate available months
export const generateAvailableMonths = (
  data?:
    | {
        option: string;
        values: IndicatorValues[];
      }[]
    | undefined
) => {
  const allMonths = data?.map((obj) => {
    return obj.values.map((val) => {
      let date = formatMonthStrings(val.date);
      return { value: date };
    });
  });

  const finalResult = [...new Set(allMonths?.flat().map((val) => val.value))].filter((x) => x);
  let sortedData = finalResult.sort((a, b) => {
    return new Date(a).getTime() - new Date(b).getTime();
  });

  return sortedData;
  // });
};

const formatMonthStrings = (str: string) => {
  //1991-01-25
  const year = str.split('-')[0];
  const month = str.split('-')[1];
  return `${year}-${month}`;
};

//generate available quartes
export const generateAvailableQuarters = (
  data:
    | {
        option: string;
        values: IndicatorValues[];
      }[]
    | undefined
) => {
  const romanNumber = ['I', 'II', 'III', 'IV'];
  const allQuarters = data?.map((obj) => {
    return obj.values.map((value) => {
      return { value };
    });
  });

  const sortedQuarters = allQuarters
    ?.flat()
    .map((val) => val.value)
    .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
    .map((q) => {
      let year = new Date(q.date).getFullYear();
      let quarter = `Q-${romanNumber[quarterOfTheYear(q.date) - 1]}`;
      return { name: `${quarter} ${year}`, q };
    });

  const availableQuarters = sortedQuarters?.map((val) => val.name).filter((x) => x);
  return [...new Set(availableQuarters)];
};

//utility function
export const formatQuarter = (str: string) => {
  const currLang = getCurrentLng();
  const romanNumber = ['I', 'II', 'III', 'IV'];
  let year = new Date(str).getFullYear();
  let quarter = `Tremujori-${romanNumber[quarterOfTheYear(str) - 1]}`;
  if (currLang === LanguagesAbbreviation.English) {
    quarter = `Quarter-${romanNumber[quarterOfTheYear(str) - 1]}`;
    return `${quarter} ${year}`;
  }

  return `${quarter} ${year}`;
};

//return quarter format to month-year
export const quarterToDate = (str: string): string => {
  //Q-I 2019
  let year = str?.split(' ')[1] || '';
  let quarterString = str?.split(' ')[0].replace('Q-', '') || '';
  let quarterNum;
  switch (quarterString) {
    case 'I':
      quarterNum = '01';
      break;
    case 'II':
      quarterNum = '04';
      break;
    case 'III':
      quarterNum = '07';
      break;
    case 'IV':
      quarterNum = '10';
      break;
  }
  return `${year}-${quarterNum}`;
};

export const formatMonth = (str: string) => {
  const currLang = getCurrentLng();

  //str=1990-01
  let year = str.split('-')[0];
  let month = new Date(str).getMonth();
  if (currLang === LanguagesAbbreviation.English) {
    return `${monthNames[month]} ${year}`;
  }

  return `${albanianMonthNames[month]} ${year}`;
};

//generate map series
export const generateMapSeries = (
  data:
    | {
        option: string;
        values: IndicatorValues[];
      }[]
    | undefined,
  selectedOption: string,
  frequency: string
) => {
  switch (frequency) {
    case 'year':
      return generateYearSeries(data, selectedOption);
    case 'month':
      return generateMonthSeries(data, selectedOption);
    case 'quarter':
      return generateQuarterSeries(data, selectedOption);
    default:
      return;
  }
};

//generate Min Max value for map
export const generateMinMaxValues = (
  data:
    | {
        option: string;
        values: IndicatorValues[];
      }[]
    | undefined,
  selectedOption: string,
  frequency: string
) => {
  const filteredData = data?.filter((obj) => obj.option !== 'total');
  let numericalValues = generateMapSeries(filteredData, selectedOption, frequency)?.map((obj) => obj.value || 0);
  const minValue = numericalValues ? Math.min(...numericalValues) : 0;
  const maxValue = numericalValues ? Math.max(...numericalValues) : 100;
  return {
    minValue,
    maxValue,
  };
};

const generateYearSeries = (
  data:
    | {
        option: string;
        values: IndicatorValues[];
      }[]
    | undefined,
  selectedOption: string
) => {
  //remove total from data;
  const filteredData = data?.filter((obj) => obj.option !== 'total');
  return filteredData?.map((obj) => {
    const selectedObject = obj.values.find(
      (opt) => new Date(opt.date).getFullYear() === new Date(selectedOption).getFullYear()
    );
    return {
      name: capitalizeFirstLetter(obj.option),
      value: selectedObject?.value,
    };
  });
};

export const generateMonthSeries = (
  data:
    | {
        option: string;
        values: IndicatorValues[];
      }[]
    | undefined,
  selectedOption: string
) => {
  const filteredData = data?.filter((obj) => obj.option !== 'total');
  let values: { name: string; value: number }[] = [];
  filteredData?.map((obj) => {
    obj.values.find((value) => {
      if (new Date(value.date).getFullYear() === new Date(selectedOption).getFullYear()) {
        if (new Date(selectedOption).getMonth() === new Date(value.date).getMonth()) {
          return values.push({ name: capitalizeFirstLetter(obj.option), value: value.value });
        } else {
          return;
        }
      }
      return;
    });
  });
  return values;
};

export const generateQuarterSeries = (
  data:
    | {
        option: string;
        values: IndicatorValues[];
      }[]
    | undefined,
  selectedOption: string
) => {
  const filteredData = data?.filter((obj) => obj.option !== 'total');
  let values: { name: string; value: number }[] = [];
  filteredData?.map((obj) => {
    obj.values.find((value) => {
      if (new Date(value.date).getFullYear() === new Date(selectedOption).getFullYear()) {
        if (quarterOfTheYear(selectedOption) === quarterOfTheYear(value.date)) {
          return values.push({ name: capitalizeFirstLetter(obj.option), value: value.value });
        } else {
          return;
        }
      }
      return;
    });
  });

  return values;
};

//function to translate frequencies and unitTypes
export const translateFrequencyUnitType = (input: string): string => {
  const currentLng = getCurrentLng();
  if (!input || !currentLng) return '';

  if (currentLng === 'EN') {
    return input;
  } else {
    switch (input.toLowerCase().trim()) {
      case 'year':
        return 'Vjetore';
      case 'month':
        return 'Mujore';
      case 'quarter':
        return 'tremujore';
      case 'share':
        return 'share';
      case 'rate':
        return 'norma';
      case 'cumulative':
        return 'kumulative';
      case 'growth':
        return 'rritja';

      default:
        return input;
    }
  }
};

//return the unique objects by 'total' property=> used in Map because duplicate options values added up=>

export const returnUniqueObj = (
  data:
    | {
        option: string;
        values: IndicatorValues[];
      }[]
    | undefined
) => {
  if (!data) return;
  return [...new Map(data.map((item) => [item['option'], item])).values()];
};

export const formatCSVData = (
  data: {
    option: string;
    values: IndicatorValues[];
  }[],
  indicatorData: IndicatorWithId,
  module: Module
) => {
  const { nameEN: moduleName } = module;
  const { nameEN, submodule } = indicatorData;
  const submoduleName = submodule?.nameEN || '';

  const processedData = data
    .map((dt) => {
      return dt.values;
    })
    .flat();

  const finalData = processedData.map((singleData) => {
    const { id, indicatorId, createdBy, createdAt, updatedBy, updatedAt, variables, ...rest } = singleData;
    return {
      Theme: moduleName,
      Subtheme: submoduleName,
      Indicator: nameEN,
      ...rest,
      ...variables,
    };
  });

  return finalData;
};

export const generateIndicatorYears = (indicatorValues?: IndicatorValues[]): number[] => {
  //get all possible years from data
  const allYears = indicatorValues?.map((indicator) => {
    return new Date(indicator.date).getFullYear();
  });
  //remove duplicates and sort the years ascending
  const allAvailableYears = [...new Set(allYears?.sort((a, b) => a - b))].filter((x) => x);
  return allAvailableYears;
};

export const getIndicatorStartAndEndYear = (
  startYearFilter: number | string | undefined,
  endYearFilter: number | string | undefined,
  xAxis: string[]
) => {
  const startYear = startYearFilter ? new Date(+startYearFilter, 0, 1, 6).toISOString().slice(0, 10) : xAxis[0];

  const endYear = endYearFilter
    ? new Date(+endYearFilter, 11, 31, 23).toISOString().slice(0, 10)
    : xAxis[xAxis.length - 1];
  return { startYear, endYear };
};

export const filterXAxisByYear = (years: string[], startYear: string, endYear: string): string[] => {
  return years.filter((year) => year >= startYear && year <= endYear);
};

export const filterGeneralGraphData = (startYear: string, endYear: string, initialSerie?: IndicatorValues[]) => {
  if (!initialSerie) {
    return [];
  }
  return initialSerie.filter((indicator) => indicator.date >= startYear && indicator.date <= endYear);
};

export const filterGraphData = (
  finalResult: {
    filter?: any;
    data?:
      | {
          option: string;
          values: IndicatorValues[];
        }[]
      | undefined;
    xAxis?: string[] | undefined;
  }[],
  startYear: string,
  endYear: string
) => {
  return finalResult.map((graph) => {
    const filterdData = graph.data?.map((dataItem) => {
      return {
        ...dataItem,
        values: dataItem.values.filter((indicator) => indicator.date >= startYear && indicator.date <= endYear),
      };
    });
    const filteredXAxis = graph.xAxis?.filter((year) => year >= startYear && year <= endYear);
    return { ...graph, data: filterdData, xAxis: filteredXAxis };
  });
};

export const getStartYear = (startYearFilter: number | string | undefined, listOfYearsOfGraphic: number[]) => {
  if (startYearFilter) {
    return startYearFilter;
  }
  return listOfYearsOfGraphic[0];
};

export const getEndYear = (endYearFilter: number | string | undefined, listOfYearsOfGraphic: number[]) => {
  if (endYearFilter) {
    return endYearFilter;
  }
  return listOfYearsOfGraphic[listOfYearsOfGraphic.length - 1];
};

export const getStartYearLabel = (startYear: number | string | undefined) => {
  return `${i18next.t('_FILTERS._START_YEAR')} : ${startYear}`;
};

export const getEndYearLabel = (endYear: number | string | undefined) => {
  return `${i18next.t('_FILTERS._END_YEAR')} : ${endYear}`;
};

export const generateListOfYears = (
  indicators: IndicatorValues[] | undefined,
  startYearFilter: string | number | undefined,
  endYearFilter: string | number | undefined
) => {
  let listOfYearsOfGraphic: number[] = [];
  if (indicators) {
    listOfYearsOfGraphic = generateIndicatorYears(indicators);
  }

  const filteredStartYears = endYearFilter
    ? listOfYearsOfGraphic.filter((year) => year <= endYearFilter)
    : listOfYearsOfGraphic;

  const filteredEndYears = startYearFilter
    ? listOfYearsOfGraphic.filter((year) => year >= startYearFilter)
    : listOfYearsOfGraphic;
  return { listOfYearsOfGraphic, filteredStartYears, filteredEndYears };
};

export const getLastRegisteredTimeForIndicator = (lastDate: string, frequency: string | undefined) => {
  const year = new Date(lastDate).getFullYear();
  const monthLabel = formatMonth(lastDate);
  const quarterLabel = formatQuarter(lastDate);
  if (!frequency) {
    return year;
  }
  if (frequency === Frequency.YEAR) {
    return new Date(lastDate).getFullYear();
  }
  if (frequency === Frequency.QUARTER) {
    return quarterLabel;
  }
  return monthLabel;
};
