import React from 'react';
import { Role, User } from 'models/user';
import Button from 'common/components/Button/Button';
import Form from 'common/components/Form/Form';
import { useFormik } from 'formik';
import { validationSchema, translationBasePath } from './AddUserFormValidation';
import { useTranslation, WithTranslation, withTranslation } from 'react-i18next';
import Input from 'common/components/Input/Input';
import Select from 'common/components/Select/Select';
import AntForm from 'antd/lib/form';
import { useMutation } from 'react-query';
import { createUser, updateUser } from 'api/user';
import { getUserPayload } from 'utils/helper';
import { message } from 'antd';

interface Props {
  onClose: () => void;
  refetch: () => void;
  updating: User | null;
  uniqueUsers: string[];
}

export interface IFormInitialValues {
  email: string;
  phoneNumber: string;
  role: Role;
}

const UserList: React.FunctionComponent<Props> = (props) => {
  const { onClose, refetch, updating, uniqueUsers } = props;
  const { t } = useTranslation();
  const loggedUser = getUserPayload();

  const initialValues: IFormInitialValues = {
    email: updating?.email || '',
    phoneNumber: updating?.phoneNumber || '',
    role: updating?.role || Role.NORMAL,
  };

  const mutationHook = updating ? useMutation(updateUser) : useMutation(createUser);

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema(t),
    onSubmit: (values: User) => {
      const { mutateAsync } = mutationHook;
      const payload = updating ? { ...values, userId: Number(updating?.id) } : { ...values };
      mutateAsync(payload)
        .then(() => {
          onClose();
          formik.resetForm();
          refetch();
        })
        .catch((err) => {
          console.log(err);
          formik.resetForm();
        });
    },
  });

  const roles = [
    { value: Role.INSTITUTION_USER, label: t(`${translationBasePath}._INSTITUTION_USER`) },
    { value: Role.ADMIN, label: t(`${translationBasePath}._ADMIN`) },
    { value: Role.NORMAL, label: t(`${translationBasePath}._NORMAL`) },
  ];

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Input
        name="email"
        disabled={!!updating?.email}
        label={t(`${translationBasePath}._EMAIL`)}
        value={formik.values.email}
        onChange={(e) => {
          if (!uniqueUsers.includes(e.target.value)) {
            formik.setFieldValue('email', e.target.value);
          } else {
            message.error(t(`${translationBasePath}._ERROR_EMAIL`));
          }
        }}
        error={formik.errors.email}
      />
      <Input
        name="phoneNumber"
        placeholder="+355691010010"
        label={t(`${translationBasePath}._PHONENUMBER`)}
        value={formik.values.phoneNumber}
        onChange={formik.handleChange}
        error={formik.errors.phoneNumber}
      />
      <AntForm.Item name="role">
        <Select
          disabled={updating?.id === loggedUser?.userId}
          defaultValue={formik.values.role}
          data={roles}
          label={t(`${translationBasePath}._ROLE`)}
          onChange={(e) => {
            formik.setFieldValue('role', e);
          }}
          value={formik.values.role}
          error={formik.errors.role}
        />
      </AntForm.Item>
      <Button loading={mutationHook.isLoading} htmlType="submit">
        {updating ? t(`${translationBasePath}._UPDATE_USER_BTN`) : t(`${translationBasePath}._INVITE_USER_BTN`)}
      </Button>
    </Form>
  );
};

export default withTranslation()(UserList);
