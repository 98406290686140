import * as React from 'react';
import useWindowDimensions from 'common/hooks/useWindowDimensions';
import i18next from 'i18next';

const NotFound = () => {
  const { height } = useWindowDimensions();

  return (
    <main
      style={{
        textAlign: 'center',
        padding: '1rem',
        height: `${height - 400}px`,
        lineHeight: `${height - 400}px`,
        backgroundColor: 'white',
      }}>
      <h2>{i18next.t('_COMMON._WRONG_PAGE')}</h2>
    </main>
  );
};

export default NotFound;
