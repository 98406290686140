import React from 'react';
import SubmoduleForm from './SubmoduleForm';
import Submodule from 'models/submodule';
import { WithTranslation, withTranslation } from 'react-i18next';

interface IProps {
  onClose: () => void;
  refetch: () => void;
  submodule: Submodule;
}

const ModuleUpdate: React.FunctionComponent<IProps> = (props) => {
  const { onClose, refetch } = props;
  return <SubmoduleForm submodule={props.submodule} onClose={onClose} refetch={refetch} />;
};

export default ModuleUpdate;
