import { Col, Row } from 'antd';
import styled from 'styled-components';
import { SubmenuStyled } from './styled';
import { useQuery } from 'react-query';
import { getInstitutions, getModules, getThemes } from 'api/public';
import { getCurrentLng } from 'utils/helper';
import React, { SetStateAction, useState } from 'react';
import I18nLink from '../I18nLink';
import i18next from 'i18next';
import ThemeCard from '../ThemeCard/ThemeCard';
import { sortThemes } from 'helpers/themes';
const edtGreen = '#0e9898';

interface props {
  setShowMenu?: React.Dispatch<SetStateAction<boolean>>;
  setDrawerOpen?: React.Dispatch<SetStateAction<boolean>>;
}

export const DataSubmenu: React.FunctionComponent<props> = ({ setShowMenu, setDrawerOpen }) => {
  const handleHide = () => {
    setShowMenu && setShowMenu(false);
    setDrawerOpen && setDrawerOpen(false);
  };

  const handleSwitch = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, menu: 'data' | 'institution') => {
    e.stopPropagation();
    setCurrentShow(menu);
  };
  const { data } = useQuery('modules', getModules);
  const { data: institutions } = useQuery('allInstitutions', getInstitutions);

  const curlang = getCurrentLng();
  const [submenuHidden, setSubmenuHidden] = useState(false);
  if (submenuHidden) {
    setTimeout(() => setSubmenuHidden(false), 300);
  }

  const [currentShow, setCurrentShow] = useState<'data' | 'institution'>('data');
  const name = `name${curlang}`;
  return (
    <SubmenuStyled
      className="submenu"
      style={{ display: submenuHidden ? 'none' : '' }}
      onClick={() => setSubmenuHidden(true)}>
      <div className="data-submenu" style={{ maxWidth: '1600px' }}>
        <section className={`data-submenu-menus ${currentShow}`}>
          <div onClick={(e) => handleSwitch(e, 'data')} className="data-by-topic">
            <p style={{ color: currentShow === 'data' ? '#0e9898' : '#004040' }}>{i18next.t('_HEADER._BY_TOPIC')}</p>
          </div>
          <div onClick={(e) => handleSwitch(e, 'institution')} className="data-by-topic">
            <p style={{ color: currentShow === 'institution' ? '#0e9898' : '#004040' }}>
              {i18next.t('_HEADER._BY_INSTITUTION')}
            </p>
          </div>
        </section>

        {currentShow === 'data' && (
          <div style={{ display: 'flex', flexWrap: 'wrap', paddingRight: ' 1rem' }}>
            {data?.map((module) => (
              <div key={module.id} className="submenu-data-list">
                <I18nLink onClick={handleHide} to={`/category/${module?.id}`} className="category-title">
                  {module?.[`name${curlang}`]}
                </I18nLink>
                <div className="submodule-list">
                  {module.submodules?.map((_item) => {
                    return (
                      <li key={'data-sub-item-1' + _item.id}>
                        <I18nLink onClick={handleHide} to={`/category/${_item.moduleId}/subcategory/${_item.id}`}>
                          {_item[name]}
                        </I18nLink>
                      </li>
                    );
                  })}
                </div>
              </div>
            ))}
          </div>
        )}
        {currentShow === 'institution' && (
          <div className="header-inst">
            <p>{i18next.t('_HEADER._ALL_INSTITUTIONS')}</p>
            <section className="header-inst-list">
              {institutions?.map((inst) => {
                return (
                  <I18nLink onClick={handleHide} to={`/ministry/${inst.id}`}>
                    <span>
                      {inst[`name${curlang}`]} ({inst[`acronym${curlang}`]})
                    </span>
                  </I18nLink>
                );
              })}
            </section>
          </div>
        )}
      </div>
    </SubmenuStyled>
  );
};

export const ThemesSubmenu: React.FunctionComponent<props> = ({ setDrawerOpen, setShowMenu }) => {
  const [submenuHidden, setSubmenuHidden] = useState(false);
  if (submenuHidden) {
    setTimeout(() => setSubmenuHidden(false), 300);
  }
  const handleHide = () => {
    setDrawerOpen && setDrawerOpen(false);
    setShowMenu && setShowMenu(false);
    setSubmenuHidden(false);
  };

  const { data } = useQuery('themes', getThemes);
  const lan = getCurrentLng();
  const sortedThemes = sortThemes(data || []);

  return (
    <SubmenuStyled
      className={' submenu'}
      style={{ display: submenuHidden ? 'none' : '' }}
      onClick={() => setSubmenuHidden(true)}>
      <Row gutter={48}>
        <div className="theme-padding-wrapper">
          <div className="theme-section-wrapper">
            {sortedThemes?.slice(0, 5).map((theme) => (
              <ThemeCard
                setDrawerOpen={setDrawerOpen}
                setShowMenu={setShowMenu}
                image={theme.image}
                key={theme.key}
                path={`/themes/${theme.key}`}
                acronym={theme[`acronym${lan}`]}
                name={theme[`name${lan}`]}
              />
            ))}
          </div>
          <Row className="responsive-btn-row">
            <Col xl={24}>
              <div className="report-view-btn theme-btn-responsive">
                <I18nLink onClick={handleHide} to="/themes">
                  {i18next.t('_HOME._VIEW_ALL_THEMES')}
                </I18nLink>
              </div>
            </Col>
          </Row>
        </div>
      </Row>
    </SubmenuStyled>
  );
};

const reportsSubmenuCols = [
  {
    imgSrc: require('common/images/reports-submenu/policy-brief.jpg'),
    text: 'Policy brief',
  },
  {
    imgSrc: require('common/images/reports-submenu/strategy.jpg'),
    text: 'Strategy',
  },
  {
    imgSrc: require('common/images/reports-submenu/newsletter.jpg'),
    text: 'Newsletter',
  },
  {
    imgSrc: require('common/images/reports-submenu/case-study.jpg'),
    text: 'Case study',
  },
  {
    imgSrc: require('common/images/reports-submenu/issue-brief.jpg'),
    text: 'Issue brief',
  },
  {
    imgSrc: require('common/images/reports-submenu/research-paper.jpg'),
    text: 'Research papers',
  },
  {
    imgSrc: require('common/images/reports-submenu/case-study.jpg'),
    text: 'Fast facts',
  },
  {
    imgSrc: require('common/images/reports-submenu/issue-brief.jpg'),
    text: 'Brochure',
  },
  {
    imgSrc: require('common/images/reports-submenu/research-paper.jpg'),
    text: 'Toolkit',
  },
  {
    imgSrc: require('common/images/reports-submenu/policy-brief.jpg'),
    text: 'Booklet',
  },
  {
    imgSrc: require('common/images/reports-submenu/strategy.jpg'),
    text: 'Guidance note',
  },
  {
    imgSrc: require('common/images/reports-submenu/newsletter.jpg'),
    text: 'Lessons learned',
  },
];

export const ReportsSubmenu = styled(({ className }) => {
  return (
    <SubmenuStyled className={className + ' submenu'}>
      <Row gutter={[0, 32]}>
        {reportsSubmenuCols.map((_col) => (
          <Col span={4}>
            <I18nLink to="/">
              <img src={_col.imgSrc.default} style={{ maxWidth: '100%' }} />
              <span>{_col.text}</span>
            </I18nLink>
          </Col>
        ))}
      </Row>
    </SubmenuStyled>
  );
})`
  a {
    display: block;
    // margin-right: 10px;
    > span {
      margin-left: 16px;
      // padding-bottom: 2px;
      // border-bottom: 2px solid transparent;
    }
    &:hover {
      // background: red;
      > span {
        color: red;
        // border-color: ${edtGreen};
      }
    }
  }
`;

`
  padding: 0 !important;
  .ant-col {
    &:not(:first-child) {
      background: #f8f8fa;
    }
    padding: 40px;
    &:last-child {
      padding-left: 0;
    }
  }
`;
