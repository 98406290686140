import { ArrowRightOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import './Tabs.css';

const Tabs = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const TabsData = [
    {
      label: t('_HOME._MINISTRIES'),
      key: 'ministries',
    },
    {
      label: t('_HOME._CATEGORIES'),
      key: 'categories',
    },
  ];

  const [active, setActive] = useState('categories');

  return (
    <div className="home-tabs-container">
      {TabsData.map((tab) => {
        return (
          <section
            className={active === tab.key ? 'active' : ''}
            onClick={() => {
              setActive(tab.key);
              if (tab.key === 'ministries') {
                history.push('/ministries');
              } else {
                location.href = '#categories';
              }
            }}
            key={tab.key}>
            <p style={{ marginBottom: 0 }}>
              <span>{tab.label}</span>
              <span>
                <ArrowRightOutlined className="explore-arrow" />
              </span>
            </p>
          </section>
        );
      })}
    </div>
  );
};

export default Tabs;
