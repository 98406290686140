import * as React from 'react';
import useWindowDimensions from 'common/hooks/useWindowDimensions';

const ThemeNess = () => {
  const { height } = useWindowDimensions();

  return (
    <main
      style={{
        textAlign: 'center',
        padding: '1rem',
        height: `${height - 400}px`,
        lineHeight: `${height - 400}px`,
        backgroundColor: 'white',
      }}>
      <h2>Labour Force CUSTOM THEME page!</h2>
    </main>
  );
};

export default ThemeNess;
