import { QueryCache } from 'react-query';
import { Link } from 'react-router-dom';
import { getCacheData, getCurrentLng, indicatorsToHide } from 'utils/helper';
import { LinkOutlined, DownloadOutlined } from '@ant-design/icons';
import { formatCategory, formatDescription, formatRaportType } from './helper';
import { AnyObject } from 'yup/lib/object';
export const getFilteredResults = (query: string, queryCache: QueryCache, showAll: boolean, setModal?: any) => {
  const currentLang = getCurrentLng();
  if (!query) {
    return [];
  }
  const allResults = getCacheData(queryCache);

  //slice array depending on the showAll parameter
  const sliceArray = (array: any[]) => {
    if (showAll) {
      return array;
    }
    return array.slice(0, 1);
  };

  let mainPath: string;
  const categories = Object.keys(allResults).filter((key) => {
    return ['themes', 'modules', 'institutions', 'indicators', 'publications'].includes(key);
  });
  const arrayOfResults: any = categories.map((category) => allResults[category]);

  if (categories.includes('modules')) {
    const modulesIndex = categories.findIndex((el) => el === 'modules');
    let submodules: any = [];
    arrayOfResults[modulesIndex].forEach((module) => {
      submodules = submodules.concat(module.submodules);
    });
    arrayOfResults.push(submodules);
    categories.push('subcategories');
  }

  let options: any = [];
  arrayOfResults.forEach((category: any) => {
    category = category.filter((opt: any) => {
      const hasMatches = Object.keys(opt).some((key) => {
        if ([`name${currentLang}`, `description${currentLang}`, 'key', `acronym${currentLang}`].includes(key)) {
          return opt[key].toLowerCase().indexOf(query.toLowerCase()) !== -1;
        }
        return false;
      });
      return hasMatches;
    });
    options.push(category);
  });

  if (options[1] && Array.isArray(options[1])) {
    options[1] = options[1].filter((indicator) => {
      if (indicator.key) {
        return indicatorsToHide.every((key) => key !== indicator.key);
      }
      return indicator;
    });
  }
  return categories.map((label, idx) => {
    const respectiveCategory = categories[idx];
    switch (respectiveCategory) {
      case 'themes':
        mainPath = '/themes/';
        break;
      case 'indicators':
        mainPath = '/indicator/';
        break;
      case 'modules':
        mainPath = '/category/';
        break;
      case 'institutions':
        mainPath = '/institutions/';
        break;
      case 'subcategories':
        mainPath = '/category/moduleId/subcategory/subcategoryId';
        break;
      default:
        mainPath = '/wrongpage';
        break;
    }

    if (options[idx].length) {
      return {
        options: sliceArray(options[idx]).map((option, index) => {
          const path = mainPath.includes('subcategory')
            ? mainPath.replace('moduleId', option.moduleId).replace('subcategoryId', option.id)
            : mainPath + (option.key || option.id);

          if (respectiveCategory === 'publications') {
            return {
              label: (
                <a href={option.file} className="edt-url">
                  <div>
                    {option?.[`name${currentLang}`] && (
                      <p className="title" style={{ marginTop: '12px' }}>
                        <b>
                          <LinkOutlined style={{ fontSize: '20px', marginRight: '5px' }} />
                          {option?.[`name${currentLang}`]}
                        </b>
                      </p>
                    )}
                    {/* {option.type && (
                      <p className="description" style={{ fontSize: '14px', marginTop: '-14px' }}>
                        {formatRaportType(option?.type)}
                      </p>
                    )} */}
                  </div>
                  <div>
                    {formatCategory(label)}
                    <DownloadOutlined style={{ fontSize: '25px', marginLeft: '5px' }} />
                  </div>
                </a>
              ),

              value: option.nameEN + option.id,
              categorygroup: formatCategory(label),
              additionaldata: {
                link: option.file,
                title: option?.[`name${currentLang}`],
                description: '',
              },
            };
          }

          return {
            label: (
              <>
                {respectiveCategory !== 'institutions' ? (
                  <Link onClick={() => setModal(false)} to={path} className="edt-url">
                    <div>
                      {option[`name${currentLang}`] && (
                        <p className="title" style={{ marginTop: '12px' }}>
                          <b>{option[`name${currentLang}`]}</b>
                        </p>
                      )}
                      {/* {option[`description${currentLang}`] && (
                        <p className="description" style={{ fontSize: '14px', marginTop: '-14px' }}>
                          {formatDescription(option[`description${currentLang}`])}
                        </p>
                      )} */}
                    </div>
                    <div>{formatCategory(label)}</div>
                  </Link>
                ) : (
                  <a href={option?.url} className="edt-url" target="_blank">
                    <div>
                      {option[`name${currentLang}`] && (
                        <p className="title" style={{ marginTop: '12px' }}>
                          <b>{option[`name${currentLang}`]}</b>
                        </p>
                      )}
                      {/* {option[`description${currentLang}`] && (
                        <p className="description" style={{ fontSize: '14px', marginTop: '-14px' }}>
                          {formatDescription(option[`description${currentLang}`])}
                        </p>
                      )} */}
                    </div>
                    <div>{formatCategory(label)}</div>
                  </a>
                )}
              </>
            ),
            value: option.key + idx || option[`name${currentLang}`],
            categorygroup: formatCategory(label),
            additionaldata: {
              link: formatCategory(label) === 'Institution' ? option?.url : path,
              title: option?.[`name${currentLang}`],
              description: '',
            },
          };
        }),
      };
    }
    return null;
  });
};
