import React, { memo, useState } from 'react';
import { IndicatorContext } from './store/indicatorContext';
import Filters from './components/Filters';
import IndicatorChart from './components/IndicatorChart';
import './indicator.css';
import { useQuery } from 'react-query';
import i18next from 'i18next';
import { getOneIndicatorByIdOrKey } from 'api/public';
import useWindowDimensions from 'common/hooks/useWindowDimensions';
import { Drawer } from 'antd';
import { FilterFilled } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

interface IndicatorProps {
  related?: boolean;
  indicatorId: number;
  graphSnapshot?: boolean;
  showText?: boolean;
}

const Indicator: React.FunctionComponent<IndicatorProps> = ({ indicatorId, graphSnapshot, showText }) => {
  const [frequencyValue, setFrequencyValue] = React.useState<string>('year');
  const [unitTypeValue, setUnitTypeValue] = React.useState<string>('cumulative');
  const [selectedVariables, setSelectedVariables] = React.useState<{}>({});
  const [allAvailableFilters, setAllAvailableFilters] = React.useState<{}>({});
  const [startDate, setStartDate] = useState(`${new Date().getFullYear() - 10}-01-01`);
  const [endDate, setEndDate] = useState(new Date().toISOString().split('T')[0]);

  //drawer state
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { width } = useWindowDimensions();

  const contextValue = {
    frequency: frequencyValue,
    unitType: unitTypeValue,
    selectedVariables: selectedVariables,
    allAvailableFilters: allAvailableFilters,
    startDate: startDate,
    endDate: endDate,
    changeFrequency: setFrequencyValue,
    changeUnitType: setUnitTypeValue,
    changeSelectedVariables: setSelectedVariables,
    changeAllAvailableFilters: setAllAvailableFilters,
    changeStartDate: setStartDate,
    changeEndDate: setEndDate,
  };
  const { t } = useTranslation();
  const { data: indicator, isError } = useQuery(
    [`indicator-${indicatorId}`, indicatorId],
    () => getOneIndicatorByIdOrKey(indicatorId),
    {
      retry: 1,
    }
  );

  return (
    <IndicatorContext.Provider value={contextValue} key={`index-${indicatorId}`}>
      <div className="indicator-wrapper">
        {/**used  only to trigger graph rendering on initial render on devices<1024 */}
        {width < 1024 && <div style={{ display: 'none' }}>{indicator && <Filters indicator={indicator} />}</div>}
        <div style={{ display: 'none' }}>{indicator && <Filters indicator={indicator} />}</div>
        {indicator && width > 1024 && !showText && <Filters indicator={indicator} />}
        {width < 1024 && !showText && (
          <>
            <section className="filter-button">
              <span onClick={() => setDrawerOpen(true)}>
                <span>{t('_COMMON._FILTER')}</span>
                <FilterFilled />
              </span>
            </section>
            <Drawer
              className="indicators-drawer"
              placement="right"
              visible={drawerOpen}
              onClose={() => setDrawerOpen(false)}
              width="100%"
              style={{ zIndex: 323435346 }}>
              {indicator && <Filters indicator={indicator} />}
            </Drawer>
          </>
        )}

        {indicator && <IndicatorChart text={showText} indicator={indicator} graphSnapshot={graphSnapshot} />}
        {isError && (
          <span style={{ height: '100px', display: 'grid', placeContent: 'center', fontSize: '16px', margin: 'auto' }}>
            {i18next.t('_COMMON._DATA_NOT_AVAILABLE')}
          </span>
        )}
      </div>
    </IndicatorContext.Provider>
  );
};

export default memo(Indicator);
