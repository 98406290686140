import React from 'react';
import { Message } from './styled';

interface Props {
  message?: string;
}

const ErrorMessage = ({ message, ...rest }: Props) => {
  return <div>{message && <Message {...rest}>{message}</Message>}</div>;
};

export default ErrorMessage;
