export const routeTranslationEN = {
  '/indicators': 'Indicator',
  '/indicators/:id': 'Indicator',
  '/indicators/:indicatorId/users': 'Indicator User',
  '/indicators/:indicatorId/users/:userId': 'Indicator User',
  '/indicators_values': 'Indicator Values',
  '/indicators_values/:id': 'Indicator Values',
  '/indicator_values/:indicatorId/uploads': 'Indicator Values Upload',
  '/indicator_values/:indicatorId/process-csv': 'Process CSV',
  '/brief': 'Brief',
  '/brief/:id': 'Brief',
  '/users': 'User',
  '/users/:id': 'User',
  '/modules': 'Module',
  '/modules/:id': 'Module',
  '/submodules': 'Submodule',
  '/submodules/:id': 'Submodule',
  '/publications': 'Publication',
  '/publications/:id': 'Publication',
  '/institutions': 'Institution',
  '/institutions/user/create': 'Institution User',
  '/institutions/:id': 'Institution',
  '/institutions/users/:institutionUserId': 'Institution User',
  '/auth/login': 'Log In',
  '/auth/sms-authentication': 'SMS Authentication',
  '/auth/forgot-password': 'Forgot Password',
  '/auth/reset-password': 'Reset Password',
  '/auth/change-password': 'Change Password',
  '/themes': 'Theme',
  '/themes/:key': 'Theme',
  '/themes/:themeId/users': 'Theme User',
  '/themes/:themeId/users/:userId': 'Theme User',
  '/file-upload/uploadFileToS3': 'Upload File',
  '/file-upload/deleteFileFromS3': 'Delete File',
  '/public/briefs/:briefId': 'Brief',
};
export const routeTranslationSQ = {
  '/indicators': 'Indikator',
  '/indicators/:id': 'Indikator',
  '/indicators/:indicatorId/users': 'Perdoruesi i Indikatorit',
  '/indicators/:indicatorId/users/:userId': 'Perdoruesi i Indikatorit',
  '/indicators_values': 'Te Dhenat e Indicatorit',
  '/indicators_values/:id': 'Te Dhenat e Indicatorit',
  '/indicator_values/:indicatorId/uploads': 'Ngarkim i te dhenat te Indicatorit',
  '/indicator_values/:indicatorId/process-csv': 'Procesimi i CSV',
  '/brief': 'Brief',
  '/brief/:id': 'Brief',
  '/users': 'Perdorues',
  '/users/:id': 'Perdorues',
  '/modules': 'Module',
  '/modules/:id': 'Module',
  '/submodules': 'Submodule',
  '/submodules/:id': 'Submodule',
  '/publications': 'Publikim',
  '/publications/:id': 'Publikim',
  '/institutions': 'Institucion',
  '/institutions/user/create': 'Perdoruesi i Institucionit',
  '/institutions/:id': 'Institucion',
  '/institutions/users/:institutionUserId': 'Perdoruesi i Institucionit',
  '/auth/login': 'Log In',
  '/auth/sms-authentication': 'SMS Authentication',
  '/auth/forgot-password': 'Forgot Password',
  '/auth/reset-password': 'Reset Password',
  '/auth/change-password': 'Change Password',
  '/themes': 'Teme',
  '/themes/:key': 'Teme',
  '/themes/:themeId/users': 'Perdoruesi i Temes',
  '/themes/:themeId/users/:userId': 'Perdoruesi i Temes',
  '/file-upload/uploadFileToS3': 'Ngarkimi i File',
  '/file-upload/deleteFileFromS3': 'Fshirja e File',
  '/public/briefs/:briefId': 'Brief',
};
