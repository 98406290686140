import { Footer } from 'antd/lib/layout/layout';
import './Footer.css';
import FullLogo from 'common/images/adp-logo.svg';
import { useTranslation, withTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { getInstitutions } from 'api/public';
import { getCurrentLng } from 'utils/helper';
import I18nLink from '../I18nLink';
import { platformPages } from 'data/platform';
import useWindowDimensions from 'common/hooks/useWindowDimensions';

enum TranslationKeys {
  PLATFORM = '_PLATFORM',
  INSTITUTIONS = '_INSTITUTIONS',
  DIGITAL_PLATFORM = '_DIGITAL_PLATFORM',
  DIGITAL_PLATFORM_TEXT = '_DIGITAL_PLATFORM_TEXT',
}

const translationBasePath = '_FOOTER';

const PageFooter: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const currentLng = getCurrentLng();

  const { data: institutions } = useQuery('institutionsFooter', getInstitutions);
  const { width } = useWindowDimensions();
  return (
    <footer>
      <div className="footer-container">
        <div className="footer-img">
          <img src={FullLogo} alt="" width={width > 920 ? 230 : width > 800 ? 200 : 150} />
          <div></div>
        </div>
        <div className="footer-content">
          <div className="text">
            <h2>{t(`${translationBasePath}.${TranslationKeys.DIGITAL_PLATFORM}`)}</h2>
            <p>{t(`${translationBasePath}.${TranslationKeys.DIGITAL_PLATFORM_TEXT}`)}</p>
          </div>
          <div className="dynamic-data">
            <div>
              <h4>{t(`${translationBasePath}.${TranslationKeys.PLATFORM}`)}</h4>
              <section>
                {platformPages.map((page) => {
                  return (
                    <I18nLink key={page.title} to={page.path}>
                      {t(page.title)}
                    </I18nLink>
                  );
                })}
              </section>
            </div>

            <div>
              <h4>{t(`${translationBasePath}.${TranslationKeys.INSTITUTIONS}`)}</h4>
              <section>
                {institutions?.map((institution, index) => {
                  return (
                    <a
                      href={institution.url}
                      target="_blank"
                      key={'_footer_' + index + institution[`acronym${currentLng}`]}>
                      {institution[`acronym${currentLng}`]}
                    </a>
                  );
                })}
              </section>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default withTranslation()(PageFooter);
