import Select from 'antd/lib/select';
import styled from 'styled-components';
import { SelectProps } from './Select';

const StyledSelect = styled(Select)`
  & .ant-select-selector {
    border-color: ${(props: SelectProps) => props.error && 'red !important'};
  }
`;

const Label = styled.p`
  margin: 0;
`;

const SelectWrapper = styled.div`
  width: ${(props: SelectProps) => props.width || '100%'};
`;
export { StyledSelect, Label, SelectWrapper };
