import * as React from 'react';
import Input from 'common/components/Input/Input';
import Button from 'common/components/Button/Button';
import { updatePublication, createPublication } from 'api/publication';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { validationSchema, translationBasePath } from './PublicationValidationForm';
import { useMutation } from 'react-query';
import { Languages, Publication, PublicationType } from 'models/publication';
import { notification, message, DatePicker, Row, Col } from 'antd';
import AntForm from 'antd/lib/form';
import Form from 'common/components/Form/Form';
import Select from 'common/components/Select/Select';
import Module from 'models/module';
import { getBase64, getCurrentLng } from 'utils/helper';
import Dragger from 'antd/lib/upload/Dragger';
import { InboxOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { Label } from 'common/components/Input/styled';
import moment from 'moment';
import { getAuthData } from 'utils/tokenMgmt';
import { deleteFile, uploadFileUrl } from 'api/file-upload';

interface IFormPublication {
  onClose: () => void;
  refetch: () => void;
  publication?: Publication;
  modules: Module[];
}
interface IFormInitialValues {
  nameEN: string;
  nameSQ: string;
  file: string;
  thumbnail: string;
  lang: Languages;
  type: string;
  moduleId: string | number;
  date: Date;
  author: string;
}

const PublicationForm: React.FunctionComponent<IFormPublication> = (props) => {
  const [uploadDisabled, setUploadDisabled] = useState(false);
  const [link, setLink] = useState<string>(props.publication?.file || '');
  const [thumbnailLink, setThumbnailLink] = useState<string>(props.publication?.thumbnail || '');
  const { onClose, refetch } = props;
  const { t } = useTranslation();
  const id = props.publication?.id;
  const mutationHook = id ? useMutation(updatePublication) : useMutation(createPublication);
  const { mutateAsync } = mutationHook;

  const currentLanguage: string = getCurrentLng();
  const nameLngKey = `name${currentLanguage}`;

  const allModules: any =
    props.modules?.map((i) => {
      return {
        value: i ? i.id : '',
        label: i ? i[nameLngKey] : '',
      };
    }) || [];

  const initialValues: IFormInitialValues = {
    type: props.publication?.type || PublicationType.BOOKLET,
    nameEN: props.publication?.nameEN || '',
    nameSQ: props.publication?.nameSQ || '',
    file: props.publication?.file || '',
    lang: props.publication?.lang || Languages.English,
    thumbnail: props.publication?.thumbnail || '',
    moduleId: props.publication?.moduleId || '',
    date: props.publication?.date || new Date(),
    author: props.publication?.author || '',
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema(t),
    onSubmit: (values: Publication) => {
      mutateAsync({ ...values, id })
        .then(() => {
          id
            ? message.success(t(`${translationBasePath}._UPDATED`))
            : message.success(t(`${translationBasePath}._CREATED`));

          onClose();
          formik.resetForm();
          refetch();
        })
        .catch((err) => {
          notification.error({
            message: err.message,
          });
          formik.resetForm();
        });
    },
  });

  const uploadProps = {
    name: 'file',
    accept: '.pdf',
    disabled: uploadDisabled,
    multiple: false,
    action: uploadFileUrl,
    headers: { Authorization: `Bearer ${getAuthData()}` },
    maxCount: 1,
    beforeUpload: (file) => {
      const maxSize = file.size / 1024 / 1024; // 10mb max
      if (maxSize > 10) {
        message.error(t(`${translationBasePath}._FILE_TOO_LARGE`));
      }
      return maxSize < 10;
    },
    onChange: async (info) => {
      switch (info.file.status) {
        case 'uploading':
          setUploadDisabled(true);
          break;
        case 'error':
          message.error(t(`${translationBasePath}._UPLOAD_FAILED`));
          setUploadDisabled(false);
          break;
        case 'done':
          if (link) {
            await deleteFile({ fileUrl: link }); // DELETE EXISTING FILE FIRST
          }
          setLink(info.file.response.fileUrl);
          formik.setFieldValue('file', info.file.response.fileUrl);
          setUploadDisabled(false);
          break;
      }
    },
  };

  const uploadThumbnailProps = {
    name: 'file',
    accept: '.jpg,.png',
    multiple: false,
    action: uploadFileUrl,
    headers: { Authorization: `Bearer ${getAuthData()}` },
    maxCount: 1,
    beforeUpload: async (file) => {
      const maxSize = file.size / 1024 / 1024;
      if (maxSize > 1) {
        // 1mb max
        message.error(t(`${translationBasePath}._FILE_TOO_LARGE_PHOTO`));
        return false;
      }
      const base64 = await getBase64(file);

      if (typeof base64 === 'string') {
        setThumbnailLink(base64);
        formik.setFieldValue('thumbnail', base64);
      }

      return false;
    },
  };

  const pubTypes = [
    { value: PublicationType.BOOKLET, label: t(`${translationBasePath}._TYPE_BOOKLET`) },
    { value: PublicationType.BROCHURE, label: t(`${translationBasePath}._TYPE_BROCHURE`) },
    { value: PublicationType.CASE_STUDY, label: t(`${translationBasePath}._TYPE_CASE_STUDY`) },
    { value: PublicationType.FACE_FACTS, label: t(`${translationBasePath}._TYPE_FACE_FACTS`) },
    { value: PublicationType.GUIDANCE_NOTE, label: t(`${translationBasePath}._TYPE_GUIDANCE_NOTE`) },
    { value: PublicationType.ISSUE_BRIEF, label: t(`${translationBasePath}._TYPE_ISSUE_BRIEF`) },
    {
      value: PublicationType.LESSONS_LEARNED_COMPARATIVE_EXPERIENCE,
      label: t(`${translationBasePath}._TYPE_LESSONS_LEARNED`),
    },
    { value: PublicationType.NEWSLETTER, label: t(`${translationBasePath}._TYPE_NEWSLETTER`) },
    { value: PublicationType.POLICY_BRIEF, label: t(`${translationBasePath}._TYPE_POLICY_BRIEF`) },
    { value: PublicationType.REPORT, label: t(`${translationBasePath}._TYPE_REPORT`) },
    {
      value: PublicationType.RESEARCH_DISCUSSION_PAPERS,
      label: t(`${translationBasePath}._TYPE_RESEARCH_DISCUSSION_PAPERS`),
    },
    { value: PublicationType.STRATEGY, label: t(`${translationBasePath}._TYPE_STRATEGY`) },
    { value: PublicationType.TOOLKIT_HOW_TO_MANUAL, label: t(`${translationBasePath}._TYPE_TOOLKIT_HOW_TO_MANUAL`) },
  ];

  const selectLang = [
    { value: Languages.English, label: t(`${translationBasePath}._ENGLISH`) },
    { value: Languages.Albanian, label: t(`${translationBasePath}._ALBANIAN`) },
  ];

  const selectModules = props.modules.map((module?: Module) => {
    let label = '';
    let moduleId;
    if (module) {
      label = module[nameLngKey];
      moduleId = module.id;
    }
    return {
      value: moduleId || '',
      label,
    };
  });
  return (
    <Form onSubmit={formik.handleSubmit}>
      <Row justify="space-between" gutter={24}>
        <Col span={12} key={1}>
          <Input
            name="nameEN"
            value={formik.values.nameEN}
            label={t(`${translationBasePath}._NAMEEN`) + '*'}
            onChange={formik.handleChange}
            error={formik.errors.nameEN}
          />
        </Col>
        <Col span={12} key={2}>
          <Input
            name="nameSQ"
            value={formik.values.nameSQ}
            label={t(`${translationBasePath}._NAMESQ`) + '*'}
            onChange={formik.handleChange}
            error={formik.errors.nameSQ}
          />
        </Col>
      </Row>
      <Row justify="space-between" gutter={24}>
        <Col span={9} key={3}>
          <Input
            name="author"
            value={formik.values.author}
            label={t(`${translationBasePath}._AUTHOR`) + '*'}
            onChange={formik.handleChange}
            error={formik.errors.author}
          />
        </Col>
        <Col span={9} key={4}>
          <AntForm.Item name="type">
            <Select
              data={pubTypes}
              label={t(`${translationBasePath}._TYPE`) + '*'}
              onChange={(e) => {
                formik.setFieldValue('type', e);
              }}
              defaultValue={formik.values.type}
              value={formik.values.type}
              error={formik.errors.type}
            />
          </AntForm.Item>
        </Col>
        <Col span={6} key={5}>
          <AntForm.Item name="date">
            <Label>{t(`${translationBasePath}._PUBLISHINGDATE`) + '*'}</Label>
            <DatePicker
              defaultValue={moment(initialValues.date)}
              onChange={(_date, dateString) => formik.setFieldValue('date', new Date(dateString))}
            />
          </AntForm.Item>
        </Col>
      </Row>
      <Row justify="space-between" gutter={24}>
        <Col span={12} key={6}>
          <AntForm.Item name="lang">
            <Select
              data={selectLang}
              label={t(`${translationBasePath}._LANG`) + '*'}
              onChange={(e) => {
                formik.setFieldValue('lang', e);
              }}
              value={formik.values.lang}
              defaultValue={formik.values.lang}
              error={formik.errors.lang}
            />
          </AntForm.Item>
        </Col>
        <Col span={12} key={7}>
          <AntForm.Item name="module" required>
            <Select
              labelInValue
              data={selectModules}
              label={t(`${translationBasePath}._MODULE`) + '*'}
              onChange={(e) => {
                const selected = JSON.parse(JSON.stringify(e));
                formik.setFieldValue('moduleId', selected.value);
              }}
              defaultValue={allModules.find((i) => i.value === formik.values.moduleId)}
              value={formik.values.moduleId}
              error={formik.errors.moduleId}
            />
          </AntForm.Item>
        </Col>
      </Row>

      <Row justify="space-between" gutter={24}>
        <Col span={12} key={8}>
          <AntForm.Item name="file">
            <Label>{t(`${translationBasePath}._LINK`) + '*'}</Label>
            <Dragger {...uploadProps}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                {props.publication?.file
                  ? t(`${translationBasePath}._CLICK_OR_DRAG_TO_UPDATE_PDF`)
                  : t(`${translationBasePath}._CLICK_OR_DRAG_TO_UPLOAD_PDF`)}
              </p>
            </Dragger>
          </AntForm.Item>
        </Col>
        <Col span={12} key={9}>
          <AntForm.Item name="thumbnail">
            <Label>{t(`${translationBasePath}._THUMBNAIL`) + '*'}</Label>
            <Dragger {...uploadThumbnailProps}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                {props.publication?.file
                  ? t(`${translationBasePath}._CLICK_OR_DRAG_TO_UPDATE_THUMBNAIL`)
                  : t(`${translationBasePath}._CLICK_OR_DRAG_TO_UPLOAD_THUMBNAIL`)}
              </p>
            </Dragger>
          </AntForm.Item>
        </Col>
      </Row>

      <Button
        htmlType="submit"
        disabled={uploadDisabled || !thumbnailLink || !formik.isValid || !formik.values.moduleId}>
        {t(`${translationBasePath}._SAVE`)}
      </Button>
    </Form>
  );
};

export default PublicationForm;
