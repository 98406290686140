import React from 'react';
import { useTranslation } from 'react-i18next';
import { Breadcrumb, Row, Spin } from 'antd';
import Table from 'common/components/Table/Table';
import { useQuery } from 'react-query';
import { getIndicator, getIndicators, getUploadJobs } from 'api/indicator';
import { translationBasePath, TranslationKeys } from './constants';
import { useParams } from 'react-router';
import I18nLink from 'common/components/I18nLink';
import { ColumnProps } from 'antd/lib/table';
import { formatDate, getCurrentLng } from 'utils/helper';

const IndicatorValueUploads: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const currLang = getCurrentLng();
  const indicatorId = useParams<{ indicatorId: string }>().indicatorId;
  const { data, isLoading } = useQuery(['indicator_uploads', indicatorId], () => getUploadJobs(parseInt(indicatorId)), {
    refetchInterval: 3000, // 3s
  });

  const { data: allIndicators, isLoading: isLoadingIndicator } = useQuery(['indicator', indicatorId], getIndicators);

  const indicator = allIndicators?.find((i) => i.id === parseInt(indicatorId));
  const header = (
    <Row justify="space-between" style={{ marginBottom: 10 }}>
      <Breadcrumb style={{ fontSize: 20, fontWeight: 'bold' }}>
        <Breadcrumb.Item>
          <I18nLink to="/dashboard/indicator-values">
            {t(`${translationBasePath}.${TranslationKeys.indicatorValues}`)}
          </I18nLink>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{indicator?.[`name${currLang}`]} </Breadcrumb.Item>{' '}
        <Breadcrumb.Item>{t(`${translationBasePath}.${TranslationKeys.uploads}`)}</Breadcrumb.Item>
      </Breadcrumb>
    </Row>
  );
  const columns: ColumnProps<any>[] = [
    {
      key: 'id',
      title: t(`${translationBasePath}._ID`),
      dataIndex: 'id',
      render: (text: string, _record) => <span>{text}</span>,
    },
    {
      key: 'fileName',
      title: t(`${translationBasePath}._FILENAME`),
      dataIndex: 'fileName',
      render: (text: string, _record) => <span>{text}</span>,
    },
    {
      key: 'jobStart',
      title: t(`${translationBasePath}._JOBSTART`),
      dataIndex: 'jobStart',
      render: (text: string, _record) => <span>{formatDate(text)}</span>,
    },
    {
      key: 'jobEnd',
      title: t(`${translationBasePath}._JOBEND`),
      dataIndex: 'jobEnd',
      render: (_text: string, record) => <span>{record.success ? formatDate(record.jobEnd) : '-'}</span>,
    },
    {
      key: 'processedRows',
      title: t(`${translationBasePath}._PROCESSEDROWS`),
      dataIndex: 'processedRows',
      render: (_text: string, record) => (
        <p style={{ textAlign: 'center' }}>
          {record.success ? (
            record.processedRows
          ) : record.status === 'In progress' ? (
            <p style={{ textAlign: 'center' }}> - </p>
          ) : (
            `${t(`${translationBasePath}._FAILED_PROCESSING`)} ${record.processedRows}`
          )}
        </p>
      ),
    },
    {
      key: 'status',
      title: t(`${translationBasePath}._STATUS`),
      dataIndex: 'status',
      render: (text: string, _record) => (
        <div style={{ maxWidth: '200px', color: _record.success ? 'green' : 'red' }}>
          {_record.jobEnd && _record.status !== 'In progress' ? (
            text
          ) : (
            <div style={{ display: 'grid', placeContent: 'center' }}>
              <Spin />
            </div>
          )}
        </div>
      ),
    },
    {
      key: 'createdBy',
      title: t(`${translationBasePath}._CREATEDBY`),
      dataIndex: 'owner',
      render: (_text: string, record) => <span>{record.owner?.email || 'SYSTEM'}</span>,
    },
  ];

  return (
    <>
      {header}
      <Table
        loading={isLoading || isLoadingIndicator}
        dataSource={data}
        columns={columns}
        rowClassName="admin-table-row"
        pagination={false}
      />
    </>
  );
};

export default IndicatorValueUploads;
