import { apiUrl, post, put, get, remove } from 'utils/apiMgmt';
import Module from 'models/module';

export const createModule = async (body: Module): Promise<Module> => {
  const { data } = await post(apiUrl + '/modules', body);
  return data;
};

export const updateModule = async (body: Module): Promise<Module> => {
  const { id, nameEN, nameSQ } = body;
  const { data } = await put(apiUrl + '/modules/' + id, { nameEN, nameSQ });
  return data;
};

export const deleteModule = async (id: number): Promise<Module> => {
  const { data } = await remove(apiUrl + '/modules/' + id);
  return data;
};

export const getModule = async (id: number): Promise<Module> => {
  const { data } = await get(apiUrl + '/modules/' + id.toString());
  return data;
};

export const getModules = async (): Promise<Module[]> => {
  const { data } = await get(apiUrl + '/modules');
  return data;
};
