import chartImage from './chartImage.png';
import { RightOutlined } from '@ant-design/icons';
import { Typography, Divider, Spin } from 'antd';
import i18n from 'i18next';
import I18nLink from '../I18nLink';
import { Indicator, IndicatorModule } from 'models/indicator';
import { addCommas, getAvailableFilters } from 'pages/Explorer/components/Indicator/components/helpers';
import useWindowDimensions from 'common/hooks/useWindowDimensions';
import './IndicatorCard.css';
import { getLastRegisteredTimeForIndicator } from 'pages/Indicator/helpers';

interface ChartCardProps {
  height?: number;
  chartTitle?: string;
  indexTitle?: string;
  ratio?: number;
  year?: number;
  button?: boolean;
  path: string;
  image: string | undefined;
  indicator: Indicator | IndicatorModule;
  responsive?: boolean;
}

const IndicatorCardResponsive: React.FunctionComponent<ChartCardProps> = ({
  button = false,
  chartTitle,
  path,
  height,
  image,
  indicator,
  responsive = false,
}) => {
  const lastDate = indicator?.last_value?.date?.split('T')[0] || '';
  const time = getLastRegisteredTimeForIndicator(lastDate, indicator.defaultFrequency);
  const unit = indicator?.last_value?.unit;
  const value = indicator?.last_value?.value || 0;
  const valueFormatted =
    unit === 'number' ? addCommas(value.toString()) : unit === 'percentage' ? value.toFixed(2) + ' %' : '';
  const { width } = useWindowDimensions();

  const sliceChartTitle = (title: string): string => {
    if ((width > 700 && width < 850) || (width < 400 && title.length > 40)) {
      return title.slice(0, 40) + '...';
    }
    return title;
  };

  return (
    <div className="card-wrapper-res" style={{}}>
      <I18nLink to={path} style={{ color: `rgba(0, 0, 0, 0.85)` }}>
        <div style={{ width: 100, height: 200 }}>
          <div className="chart-image">
            <img
              src={image ? image : chartImage}
              style={{
                position: 'relative',
              }}
            />
          </div>
          <div className="chart-information">
            <div className="chart-title" style={{ fontSize: chartTitle && chartTitle?.length > 50 ? '12px' : '' }}>
              {chartTitle ? sliceChartTitle(chartTitle) : i18n.t('_COMMON._CHART_CARD')}
            </div>
            <div className="chart-ratio">{value ? valueFormatted : '-'}</div>
            <div className="chart-year">{time ?? '-'}</div>

            <div className="card-empty-div"></div>
            <Divider style={{ margin: '10px 0px', width: '100%' }} />
            <span className="chart-button">
              {i18n.t('_COMMON._EXPLORE_BTN')}
              <RightOutlined />{' '}
            </span>
          </div>
        </div>
      </I18nLink>
    </div>
  );
};

export default IndicatorCardResponsive;
