export enum TranslationKeys {
  fileProcessedSuccessfully = '_FILE_PROCESSED_SUCCESSFULLY',
  clickOrDragToUpload = '_CLICK_OR_DRAG_TO_UPLOAD',
  initiateFileProcessing = '_INITIATE_FILE_PROCESSING',
  downloadData = '_DOWNLOAD_DATA',
  uploadCSV = '_UPLOAD_CSV',
  action = '_ACTION',
  nameEN = '_NAMEEN',
  nameSQ = '_NAMESQ',
  updatedAt = '_UPDATEDAT',
  viewJobs = '_VIEW_JOBS',
  indicatorValues = '_INDICATOR_VALUES',
  fileRequired = '_FILE_REQUIRED',
  uploadCSVFile = '_UPLOAD_A_CSV_FILE',
  fileTooLarge = '_FILE_TOO_LARGE',
  indicatorKey = '_KEY',
  uploads = '_UPLOADS',
  checkUploadProgress = '_CHECK_UPLOAD_PROGRESS',
  aboutToUpload = '_ABOUT_TO_UPLOAD',
  rows = '_ROWS',
  fileNotFound = '_FILE_NOT_FOUND',
}

export const translationBasePath = '_DASHBOARD._MANAGE_INDICATOR_VALUES';
