import React, { Dispatch, SetStateAction } from 'react';
import './filters.css';
import { Checkbox, Collapse, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { Languages, PublicationFilters, PublicationType } from 'models/publication';
import { CheckboxChangeEvent } from 'antd/lib/checkbox/Checkbox';
import { translationBasePath } from '../helpers';

const { Panel } = Collapse;

interface IFilter {
  selectedItems: PublicationFilters;
  setSelectedItems: Dispatch<SetStateAction<PublicationFilters>>;
}
const Filters: React.FunctionComponent<IFilter> = ({ selectedItems, setSelectedItems }) => {
  const { t } = useTranslation();

  const availableFilters = {
    type: Object.values(PublicationType),
    lang: Object.values(Languages),
  };

  const handleSelected = (value: string, key: string) => (e: CheckboxChangeEvent) => {
    if (e.target.checked) {
      selectedItems[key].push(value);
      setSelectedItems({
        ...selectedItems,
        [key]: selectedItems[key],
      });
    } else {
      setSelectedItems({
        ...selectedItems,
        [key]: selectedItems[key].filter((_item) => _item.trim() !== value.trim()),
      });
    }
  };

  return (
    <div className="report-filters">
      <div className="filters-title">{t(`${translationBasePath}._FILTERS`)}</div>
      <div className="filter-area">
        <div className="filter-comp">
          {Object.keys(availableFilters).map((key) => {
            return (
              <Collapse style={{ padding: 0 }} bordered={false} className="site-collapse-custom-collapse" accordion>
                <Panel
                  className="site-collapse-custom-panel"
                  style={{
                    padding: '0px 10px',
                    borderBottom: '0px',
                    fontFamily: 'Source Sans Pro',
                    letterSpacing: '1.6px',
                  }}
                  key={key}
                  header={t(`${translationBasePath}._${key.toUpperCase()}`)}>
                  <div className="checkboxes">
                    {availableFilters[key].map((filterValue, idx) => {
                      return (
                        <Checkbox.Group style={{ width: '100%' }} value={selectedItems[key]}>
                          <Row>
                            <Checkbox
                              key={idx}
                              className="filter-checkbox"
                              value={filterValue}
                              onChange={handleSelected(filterValue, key)}>
                              <span className="checkbox-value">
                                {t(`${translationBasePath}._${filterValue.toUpperCase()}`)}
                              </span>
                            </Checkbox>
                          </Row>
                        </Checkbox.Group>
                      );
                    })}
                  </div>
                </Panel>
              </Collapse>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Filters;
