import { NessThemeModel } from 'models/indicator';

export const getYearFromDate = (date: string) => {
  return new Date(date).getFullYear();
};

export const generateNessIndicators = (data: NessThemeModel[] | undefined, selectedYear: string) => {
  const baselineYear = getYearFromDate('2018');
  const selectedDate = getYearFromDate(selectedYear);

  return data
    ?.map((indicator) => {
      if (!indicator) return null;
      //baseline year indicator value
      const selectedBaseline = indicator.indicatorValues?.find((value) => {
        return getYearFromDate(value.date) === baselineYear;
      });

      //selected year indicator value
      const selectedYearValue = indicator.indicatorValues?.find((value) => {
        return getYearFromDate(value.date) === selectedDate;
      });

      //change of selected year VALUE  vs baseline VALUE
      const change =
        selectedBaseline && selectedYearValue ? selectedYearValue?.value - selectedBaseline?.value : undefined;

      const progressData: number[] = [];

      if (selectedBaseline && selectedYearValue) {
        [selectedBaseline, selectedYearValue]
          ?.sort((a, b) => getYearFromDate(a.date) - getYearFromDate(b.date))
          .map((dt) => {
            if (getYearFromDate(dt.date) <= selectedDate) {
              return dt.value;
            }
            return null;
          })
          .filter((x) => x !== null)
          .forEach((d) => {
            if (d) {
              progressData.push(d);
            }
          });
      }

      return { ...indicator, selectedYearValue, change, selectedBaseline, progressData };
    })
    .filter((x) => x !== null);
};

type MultipleLanguages = {
  en: string;
  sq: string;
};

//to be reviewed
export const generateNessIndicatorName = (index: number): { en: string; sq: string } => {
  switch (index) {
    case 0:
      return {
        en: 'Young people (15-29) outside the labour market as well as outside the school or training premises.',
        sq: 'Të rinjtë (15-29) jashtë tregut të punës si dhe jashtë bankave të shkollës apo formimit.',
      };
    case 1:
      return {
        en: 'Unemployed jobseekers who benefit from employment promotion programs.',
        sq: 'Punëkërkuesit e papunë që përfitojnë nga programet e nxitjes së punësimit dhe formimit profesional',
      };
    case 2:
      return {
        en: 'Incidence of long-term unemployment',
        sq: 'Frekuenca e papunësisë afatgjatë',
      };
    case 3:
      return {
        en: 'Employment - population ratio 15-64 years',
        sq: 'Raporti punësim-popullsi 15-64 vjeç',
      };
    case 4:
      return {
        en: 'Labour force participation rate',
        sq: 'Shkalla e pjesëmarrjes në forcën e punës',
      };
    case 5:
      return {
        en: 'Unemployment rate',
        sq: 'Shkalla e papunësisë',
      };
    case 6:
      return {
        en: 'Young people (15-29) unemployment rate, by gender',
        sq: 'Shkalla e papunësisë së të rinjve (15-29 vjeç), sipas gjinisë.',
      };
    case 7:
      return {
        en: 'Employment rate of vocational education graduates (excluding those who continue higher education)',
        sq: 'Shkalla e punësimit të të diplomuarve nga arsimi profesional (përjashtuar ata që vazhdojnë arsimin e lartë)',
      };
    case 8:
      return {
        en: 'The rate of individuals (25-64 years old) participating in lifelong learning processes',
        sq: 'Shkalla e individëve (25-64 vjeç) që marrin pjesë në procese të të nxënit gjatë gjithë jetës',
      };

    case 9:
      return {
        en: 'Gender gap in employment',
        sq: 'Hendeku gjinor në punësim',
      };

    case 10:
      return {
        en: 'The rate of GDP invested in employment promotion measures',
        sq: 'Shkalla e PBB-së investuar në masat e nxitjes së punësimit.',
      };

    case 11:
      return {
        en: 'Ratio of students attending vocational education and training, by gender',
        sq: 'Përqindja e nxënësve që ndjekin arsimin profesional, sipas gjinisë.',
      };

    case 12:
      return {
        en: 'Employment rate of unemployed jobseekers involved in employment promotion programmes',
        sq: 'Përqindja e punësimit të personave të përfshirë në masat e nxitjes së punësimit.',
      };

    case 13:
      return {
        en: 'Of those employed after participation in employment promotion programmes, 50 % are women',
        sq: 'Nga të punësuarit pas masave të nxitjes së punësimit, 50% janë gra.',
      };

    case 14:
      return {
        en: 'Number of female students enrolled in VET',
        sq: 'Numri i nxënëseve femra që ndjekin AFP-në',
      };

    case 15:
      return {
        en: 'Participation rate in employment employment promotion programmes of former beneficiaries from passive schemes',
        sq: 'Përqindja e pjesëmarrësve në programet e nxitjes së punësimit, që vijnë nga skemat pasive të mbështetjes me të ardhura',
      };

    case 16:
      return {
        en: 'Participation rate in employment promotion programmes of unemployed jobseekers from special groups',
        sq: 'Përqindja e pjesëmarrjes në programe të nxitjes së punësimit të punëkërkuesve të papunë nga grupet e veçanta',
      };

    case 17:
      return {
        en: 'Number of staff of the Agencies responsislbe for the implementation of NESS 2019-2022',
        sq: 'Numri i stafit të agjencive përgjegjëse për zbatimin e SKPA-së ',
      };

    case 18:
      return {
        en: 'Number of persons neither in employment, nor in education or training (NEET) (15-29 years) registered with the employment offices',
        sq: 'Numri i të rinjve jashtë tregut të punës (15-29 vjeç) të regjistruar në zyrat e punës',
      };
    default:
      return { en: '', sq: '' };
  }
};

export const generateNESSCsv = (data: any) => {
  if (data) {
    const proccessedData = data?.map((indicator, index) => {
      const { createdAt, createdBy, id, indicatorId, updatedAt, updatedBy, variables, date, ...baselineYear } =
        indicator?.selectedBaseline || {};

      const {
        certification,
        createdAt: createdAtSelected,
        createdBy: createdBySelected,
        frequency,
        id: idSelected,
        indicatorId: indicatorIdSelected,
        unit,
        unitType,
        updatedAt: updatedAtSelected,
        updatedBy: updatedBySelected,
        variables: variablesSelected,
        date: dateSelected,
        ...targetYear
      } = indicator?.selectedYearValue || {};

      const obj = { ...baselineYear, ...targetYear, ...variables };

      if (Object.keys(obj).length === 0) return obj;

      //the properties may be availables only in one of the objects
      const displayUnit = indicator?.selectedBaseline?.unit || unit;
      const displayUnitType = indicator?.selectedBaseline?.unitType || unitType;
      const displayFrequency = indicator?.selectedBaseline?.frequency || frequency;
      const displayVariables = indicator?.selectedYear?.variables || variables;
      const displayCertification = indicator?.selectedBaseline?.certification || certification;

      return {
        indicatorName: generateNessIndicatorName(index).en,
        ...obj,
        unit: displayUnit,
        unitType: displayUnitType,
        frequency: displayFrequency,
        certification: displayCertification,
        ...displayVariables,

        'baseline year': 2018,
        'target year': new Date(targetYear?.date).getFullYear(),
        'baseline year value': baselineYear?.value,
        'target year value': targetYear?.value,
      };
    });

    const filteredData = proccessedData.filter((element) => {
      if (Object.keys(element).length !== 0) {
        return true;
      }
      return false;
    });

    return filteredData;
  }
  return;
};
