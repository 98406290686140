import * as React from 'react';
import { Spin } from 'antd';
import { getThemes } from 'api/public';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import ThemeNess from './Custom/Ness/index';
import ThemeLabourForce from './Custom/LabourForce';
import ThemeJobs from './Custom/Jobs';
import NotFound from 'pages/NotFound';
import Theme from './IntegrationTheme/Theme';
import i18next from 'i18next';
import EmploymentAtAGlance from './Custom/EmploymentGlance/Index';

const ThemePage = () => {
  const { themeKey } = useParams<{ themeKey: string }>();
  const { data, isLoading, isError } = useQuery('themes', getThemes);
  const currentTheme = data?.find((theme) => theme.key === themeKey);

  if (isLoading) {
    return (
      <div className="themes-spinner">
        <Spin delay={200} size="large" />
      </div>
    );
  }
  if (isError || !currentTheme) {
    return <div className="theme-error">{i18next.t('_COMMON._ERROR')}</div>;
  }
  /**
   * Load custom pages for custom components
   */
  if (currentTheme.type === 'custom') {
    switch (currentTheme.key) {
      case 'ness':
        return <ThemeNess />;
      case 'labour_office':
        return <ThemeLabourForce />;
      case 'jobs':
        return <ThemeJobs />;
      case 'employment_at_a_glance':
        return <EmploymentAtAGlance />;
      default:
        return <NotFound />;
    }
  } else {
    return <Theme />;
  }
};

export default ThemePage;
