import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Filters from './Filters';
import './ReportsScreen.css';
import Publications from './Publications';
import { PublicationFilters } from 'models/publication';
import FilterSort from './FilterSort';
import { Breadcrumb } from 'antd';
import I18nLink from 'common/components/I18nLink';
import i18next from 'i18next';
import { Drawer, Button } from 'antd';
import useWindowDimensions from 'common/hooks/useWindowDimensions';
const ReportsScreen: React.FunctionComponent = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [selectedItems, setSelectedItems] = useState<PublicationFilters>({
    type: [],
    lang: [],
    sort: ['date'], //default value
  });

  const { width } = useWindowDimensions();

  const { t } = useTranslation();

  const [drawerOpen, setDrawerOpen] = useState(false);
  return (
    <>
      <div className="theme-subheader-wrapper">
        <Breadcrumb separator="•">
          <Breadcrumb.Item>
            <I18nLink to="/">{i18next.t('_COMMON._HOME')}</I18nLink>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <I18nLink to="/reports">{i18next.t('_REPORTS._REPORTS')}</I18nLink>
          </Breadcrumb.Item>
          <Breadcrumb.Item></Breadcrumb.Item>
        </Breadcrumb>
        <div className="theme-subheader-title">{i18next.t('_REPORTS._REPORTS')}</div>
      </div>
      <div className="reports-content">
        {!drawerOpen && width > 1024 && <Filters selectedItems={selectedItems} setSelectedItems={setSelectedItems} />}
        <div className="reports">
          <FilterSort setDrawerOpen={setDrawerOpen} selectedItems={selectedItems} setSelectedItems={setSelectedItems} />
          <Publications selectedFilters={selectedItems} />
        </div>
      </div>

      {/**Drawer for filters in devices < 1024px */}
      {width < 1024 && (
        <Drawer
          className="reports-drawer"
          placement="right"
          visible={drawerOpen}
          onClose={() => setDrawerOpen(false)}
          width="100%"
          style={{ zIndex: 323435346 }}>
          <div>
            <Filters selectedItems={selectedItems} setSelectedItems={setSelectedItems} />
            <p className="done-filter">
              <span onClick={() => setDrawerOpen(false)}>{t('_COMMON._DONE')}</span>
              <span
                onClick={() =>
                  setSelectedItems({
                    type: [],
                    lang: [],
                    sort: ['date'],
                  })
                }>
                {t('_COMMON._RESET')}
              </span>
            </p>
          </div>
        </Drawer>
      )}
    </>
  );
};

export default memo(ReportsScreen);
