import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SearchOutlined, MenuOutlined, CloseOutlined } from '@ant-design/icons';
import UserOutlined from '@ant-design/icons/lib/icons/UserOutlined';
import { Avatar, Col, Drawer, Dropdown, Menu, Row } from 'antd';
import FullLogo from 'common/images/adp-logo.svg';
import I18nLink from '../I18nLink';
import { HeaderStyled, TopMenuListItem, TopMenuStyled } from './styled';
import { DataSubmenu, ThemesSubmenu } from './Submenu';
import GlobalSearch from '../Search/Search';
import MobileNav from './MobileNav';
import { isCurrentUserAdmin, isInstitutionUser, getUserPayload } from 'utils/helper';
import { clearAuthData } from 'utils/tokenMgmt';
import useWindowDimensions from 'common/hooks/useWindowDimensions';
import './Header.css';
import { useQuery } from 'react-query';
import { getPublicIndicators } from 'api/public';

const translationBasePath = '_HEADER';

enum TranslationKeys {
  DATA = '_DATA',
  THEMES = '_THEMES',
  EXPLORE = '_EXPLORE',
  REPORTS = '_REPORTS',
  GOALS = '_GOALS',
  ABOUT = '_ABOUT',
  LOGIN = '_LOGIN',
  LOGOUT = '_LOGOUT',
  ALBANIAN = '_ALBANIAN',
}

const PageHeader: React.FunctionComponent<{}> = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const location = pathname.replace(/\/(en|sq)/, '');
  const { width } = useWindowDimensions();

  const [searchOpen, setSearchOpen] = useState(false);
  const avatarMenu = (
    <Menu>
      {(isCurrentUserAdmin() || isInstitutionUser()) && (
        <>
          <Menu.Item key="Dashboard">
            <I18nLink
              style={{ fontFamily: 'Source Sans Pro', fontWeight: 500, fontSize: 15 }}
              to={isCurrentUserAdmin() ? '/dashboard/users' : '/dashboard/indicator-values'}>
              Dashboard
            </I18nLink>
          </Menu.Item>
        </>
      )}
      <Menu.Item key="Manage Account">
        <I18nLink
          style={{ fontFamily: 'Source Sans Pro', fontWeight: 500, fontSize: 15 }}
          to="/dashboard/manage-account">
          {t('_DASHBOARD._MANAGE_ACCOUNT._TITLE')}
        </I18nLink>
      </Menu.Item>
      {isCurrentUserAdmin() && (
        <Menu.Item key="My briefs">
          <I18nLink
            style={{ fontFamily: 'Source Sans Pro', fontWeight: 500, fontSize: 15 }}
            to={`/${getUserPayload()?.userId || ''}/my-briefs`}>
            {t('_BRIEFS._MY_BRIEFS')}
          </I18nLink>
        </Menu.Item>
      )}
      <Menu.ItemGroup title="Language">
        <Menu.Item key="English">
          <Link style={{ fontFamily: 'Source Sans Pro', fontWeight: 500, fontSize: 15 }} to={`/en${location}`}>
            English
          </Link>
        </Menu.Item>
        <Menu.Item key="Albanian">
          <Link style={{ fontFamily: 'Source Sans Pro', fontWeight: 500, fontSize: 15 }} to={`/sq${location}`}>
            {t(`${translationBasePath}.${TranslationKeys.ALBANIAN}`)}
          </Link>
        </Menu.Item>
      </Menu.ItemGroup>

      <Menu.Item key="LogIn/LogOut" style={{ color: 'red' }}>
        <I18nLink
          style={{ fontFamily: 'Source Sans Pro', fontWeight: 500, fontSize: 15 }}
          to="/"
          onClick={() => clearAuthData()}>
          {t(`${translationBasePath}.${TranslationKeys.LOGOUT}`)}
        </I18nLink>
      </Menu.Item>
    </Menu>
  );
  const [showMenu, setShowMenu] = useState(false);
  const iconsStyle = { fontSize: '25px', cursor: 'pointer' };
  useQuery('indicators', getPublicIndicators);

  useEffect(() => {
    let body = document.getElementsByTagName('body')[0];
    if (showMenu) {
      body.style.height = '100%';
      body.style.overflowY = 'hidden';
    } else {
      body.style.height = '100%';
      body.style.overflowY = 'visible';
    }
  }, [showMenu]);

  return (
    <HeaderStyled className="__header" style={{ padding: '0 1.5rem' }}>
      <div className={showMenu ? `desktop-nav active` : 'desktop-nav'}>
        <Row align="middle" className="row-header-wrapper">
          {width >= 1280 && (
            <Col flex="280px">
              <I18nLink to="/">
                <img style={{ paddingLeft: '20px' }} src={FullLogo} width={230} />
              </I18nLink>
            </Col>
          )}
          {width < 1280 && (
            <div className="header-responsive">
              <section className="responsive-logo-menu">
                <MenuOutlined
                  style={{ ...iconsStyle, zIndex: 323435345, fontSize: width > 400 ? 25 : 20 }}
                  onClick={() => setShowMenu(true)}
                />
                <I18nLink to="/">
                  <img style={{ paddingLeft: '20px' }} src={FullLogo} width={width > 400 ? 200 : 150} />
                </I18nLink>
              </section>
              <section className="responsive-icons">
                {!searchOpen && (
                  <SearchOutlined
                    style={{ ...iconsStyle, fontSize: width > 400 ? 25 : 20 }}
                    onClick={() => setSearchOpen(true)}
                  />
                )}
                {searchOpen && (
                  <section style={{ display: 'flex', alignItems: 'center' }}>
                    <Drawer
                      width={width > 670 ? '50%' : width > 400 ? '80%' : '80%'}
                      placement="right"
                      visible={true}
                      onClose={() => setSearchOpen(false)}
                      closeIcon={<CloseOutlined />}>
                      <GlobalSearch setModal={setSearchOpen} responsive />
                    </Drawer>
                    <CloseOutlined
                      style={{ ...iconsStyle, marginLeft: '3px', marginTop: '4px', fontSize: '20px' }}
                      onClick={() => setSearchOpen(false)}
                    />
                  </section>
                )}
                <div>
                  <Dropdown placement="bottomCenter" overlay={avatarMenu}>
                    <Avatar size={width > 400 ? 38 : 26} icon={<UserOutlined />} style={{ cursor: 'pointer' }} />
                  </Dropdown>
                </div>
              </section>
            </div>
          )}

          {width >= 1280 ? (
            <Col flex={2} style={{ position: 'static' }}>
              <TopMenuStyled>
                <TopMenuListItem key="Data">
                  <I18nLink to="/">
                    <span className="top-menu-item__text">{t(`${translationBasePath}.${TranslationKeys.DATA}`)}</span>
                  </I18nLink>
                  <DataSubmenu />
                </TopMenuListItem>

                <TopMenuListItem key="Explore">
                  <I18nLink to="/explore">
                    <span className="top-menu-item__text">
                      {t(`${translationBasePath}.${TranslationKeys.EXPLORE}`)}
                    </span>
                  </I18nLink>
                </TopMenuListItem>

                <TopMenuListItem key="Themes">
                  <I18nLink to="/themes">
                    <span className="top-menu-item__text">{t(`${translationBasePath}.${TranslationKeys.THEMES}`)}</span>
                  </I18nLink>
                  <ThemesSubmenu />
                </TopMenuListItem>
                <TopMenuListItem key={'Reports'}>
                  <I18nLink to="/reports" style={{ overflow: 'hidden' }}>
                    <span className="top-menu-item__text">
                      {t(`${translationBasePath}.${TranslationKeys.REPORTS}`)}
                    </span>
                  </I18nLink>
                  {/* <ReportsSubmenu /> */}
                </TopMenuListItem>
              </TopMenuStyled>
            </Col>
          ) : null}
          {width > 1280 && (
            <Col flex={3}>
              <div className="col-left-menu">
                {/* <SearchOutlined onClick="" */}
                <div className="menu-dropdowns">
                  <div className="glob-search">
                    <GlobalSearch setModal={setSearchOpen} />
                  </div>
                  <div>
                    <Dropdown placement="bottomCenter" overlay={avatarMenu}>
                      <Avatar size={38} icon={<UserOutlined />} style={{ cursor: 'pointer' }} />
                    </Dropdown>
                  </div>
                  {/* <div>
                <Dropdown placement="bottomCenter" overlay={localeSwitcher}>
                  <GlobalOutlined className="header-dropdown" style={{ fontSize: '32px' }} />
                </Dropdown>
              </div> */}
                </div>
              </div>
            </Col>
          )}
        </Row>
      </div>
      {/**MOBILE NAV  */}
      <MobileNav showMenu={showMenu} setShowMenu={setShowMenu} />
    </HeaderStyled>
  );
};

export default PageHeader;
