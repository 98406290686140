import { AnalyticalChange, Frequency, Indicator, IndicatorFilters, UnitType } from 'models/indicator';
import i18next from '../../../locales/index';
import { TFunction } from 'react-i18next';
import { translationBasePath } from './IndicatorFormValidation';

export const getSelectOptions = (
  t: TFunction<'translation', undefined>,
  institutions: any,
  submodules: any,
  nameLngKey
) => {
  const selectMultipleFrequency = [
    { value: Frequency.MONTH, label: i18next.t(`${translationBasePath}._MONTHLY`) },
    { value: Frequency.QUARTER, label: i18next.t(`${translationBasePath}._QUARTERLY`) },
    { value: Frequency.YEAR, label: i18next.t(`${translationBasePath}._YEARLY`) },
  ];

  const selectUnitTypes = [
    { value: UnitType.CUMULATIVE, label: i18next.t(`${translationBasePath}._CUMULATIVE`) },
    { value: UnitType.SHARE, label: i18next.t(`${translationBasePath}._SHARE`) },
    { value: UnitType.RATE, label: i18next.t(`${translationBasePath}._RATE`) },
    { value: UnitType.GROWTH, label: i18next.t(`${translationBasePath}._GROWTH`) },
  ];

  const selectAnalyticalChange = [
    { value: AnalyticalChange.MOM, label: i18next.t(`${translationBasePath}._MOM`) },
    { value: AnalyticalChange.YOY, label: i18next.t(`${translationBasePath}._YOY`) },
    { value: AnalyticalChange.QOQ, label: i18next.t(`${translationBasePath}._QOQ`) },
  ];

  const selectInstitutions: any =
    institutions?.map((i) => {
      return {
        value: i ? i.id : '',
        label: i ? i[nameLngKey] : '',
      };
    }) || [];

  const selectSubmodules: any =
    submodules?.map((sm) => {
      return {
        value: sm?.id || '',
        label: sm ? sm[nameLngKey] : '',
      };
    }) || [];
  return { selectMultipleFrequency, selectUnitTypes, selectAnalyticalChange, selectInstitutions, selectSubmodules };
};

export const generateUnitTypeOptions = (
  filters: IndicatorFilters | undefined,
  indicator: Indicator
): { value: UnitType; label: string }[] => {
  if (!filters) {
    return [];
  }
  if (!filters.unitTypes.length) {
    // if there are no filters still we have to display the default value as option
    const defaultValues = checkUnitTypeValue(indicator.defaultUnitType);
    return [defaultValues];
  }
  return filters.unitTypes.map((unitType) => {
    return checkUnitTypeValue(unitType);
  });
};

const checkUnitTypeValue = (unitType: string) => {
  let label = '';
  let value = UnitType.CUMULATIVE;
  if (unitType === UnitType.CUMULATIVE) {
    label = i18next.t(`${translationBasePath}._CUMULATIVE`);
    value = UnitType.CUMULATIVE;
  }
  if (unitType === UnitType.SHARE) {
    label = i18next.t(`${translationBasePath}._SHARE`);
    value = UnitType.SHARE;
  }
  if (unitType === UnitType.RATE) {
    label = i18next.t(`${translationBasePath}._RATE`);
    value = UnitType.RATE;
  }
  if (unitType === UnitType.GROWTH) {
    label = i18next.t(`${translationBasePath}._GROWTH`);
    value = UnitType.GROWTH;
  }
  return { value, label };
};
