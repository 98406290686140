import React from 'react';
import { StyledInput, InputWrapper, Label } from './styled';
import { InputProps as AntInputProps } from 'antd/lib/input/Input';
import ErrorMessage from '../ErrorMessage/ErrorMessage';

export interface InputProps extends AntInputProps {
  error?: string;
  label?: string;
  width?: string;
}

const Input = ({ error, label, width = '100%', ...props }: InputProps) => {
  return (
    <InputWrapper>
      {label && <Label>{label}</Label>}
      <StyledInput error={error} {...props} />
      <ErrorMessage message={error} />
    </InputWrapper>
  );
};

export default Input;
