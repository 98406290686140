import React from 'react';
import { Table } from 'antd';
const { Column } = Table;
import i18next from 'i18next';
import { EmploymentGlanceModel, Indicator } from 'models/submodule';
import { generateIndicatorTitle } from 'pages/Explorer/components/Indicator/components/generateIndicatorTitle';
import { addCommas, generateIndicatorName } from 'pages/Explorer/components/Indicator/components/helpers';
import { getCurrentLng } from 'utils/helper';

interface SubcategoryDataProps {
  currentYear: string;
  baselineYear: string;
  indicators: Indicator[] | undefined;
  subcategoryName: string;
}

const SubcategoryData: React.FunctionComponent<SubcategoryDataProps> = ({
  currentYear,
  baselineYear,
  indicators,
  subcategoryName,
}) => {
  const currentLng = getCurrentLng();
  const data = indicators
    ?.map((indicator) => {
      return {
        key: indicator?.indicator_id,
        indicator,
        name: generateIndicatorName(indicator as any, indicator.unit_type, currentLng).split('-')[0],
        unit:
          indicator?.unit == 'number' ? i18next.t('_COMMON._NUMBER_TEXT') : indicator?.unit === 'percentage' ? '%' : '',
        baseline:
          indicator?.unit === 'percentage'
            ? indicator?.baseline_value + '%'
            : addCommas(indicator?.baseline_value.toString()),
        current:
          indicator?.unit === 'percentage'
            ? indicator?.compare_value + '%'
            : addCommas(indicator?.compare_value.toString()),
        change:
          indicator?.unit === 'percentage'
            ? indicator?.difference.toFixed(1) + '%'
            : addCommas(indicator?.difference.toString()),
      };
    })
    .filter((x) => x != null);

  const currentYearTitle = (
    <div
      style={{
        display: 'flex',
        fontWeight: 'bold',
        color: 'black',
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        gap: 0,
      }}>
      <span style={{ display: 'block' }}>{i18next.t('_EMPLOYMENT_GLANCE._CURRENT')}</span>
      <span style={{ display: 'block' }}>{currentYear}</span>
    </div>
  );

  const baselineYearTitle = (
    <div
      style={{
        display: 'flex',
        fontWeight: 'bold',
        color: 'black',
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        gap: 0,
      }}>
      <span style={{ display: 'block' }}>{i18next.t('_EMPLOYMENT_GLANCE._BASELINE')}</span>
      <span style={{ display: 'block' }}>{baselineYear}</span>
    </div>
  );

  return (
    <>
      <div className="subcategory-data">
        <div className="subcategory-name">{subcategoryName}</div>
        <div className="line"></div>
        <div className="data-info-row">
          {data && (
            <Table
              dataSource={data}
              pagination={false}
              bordered={false}
              className="glance-table"
              style={{ height: !data?.length ? '200px' : '' }}>
              <Column title="Indicators" dataIndex="name" key="name" className="glance-col glance-col-name" />
              <Column
                className="glance-col glance-col-unit"
                title={
                  <div style={{ textAlign: 'center', paddingRight: '2px' }}>
                    {i18next.t('_EMPLOYMENT_GLANCE._UNIT')}
                  </div>
                }
                dataIndex="unit"
                key="unit"
              />
              <Column
                className="glance-col glance-col-baseline"
                title={baselineYearTitle}
                dataIndex="baseline"
                key="baseline"
              />
              <Column
                className="glance-col glance-col-current"
                title={currentYearTitle}
                dataIndex="current"
                key="current"
              />
              <Column
                className={'glance-col glance-col-change'}
                title={<div style={{ textAlign: 'center' }}>{i18next.t('_EMPLOYMENT_GLANCE._CHANGE')}</div>}
                dataIndex="indicator"
                key="indicator"
                render={(indicator) => {
                  const change =
                    indicator?.unit === 'percentage'
                      ? indicator?.difference.toFixed(1) + '%'
                      : addCommas(Number(indicator?.difference).toFixed(1));

                  const isPositive = indicator?.is_positive_number_positive_trend && Number(indicator?.difference) > 0;

                  return (
                    <div className={Number(indicator?.difference) === 0 ? '' : isPositive ? 'positive' : 'negative'}>
                      {change}
                    </div>
                  );
                }}
              />
            </Table>
          )}
        </div>
      </div>
    </>
  );
};

export default SubcategoryData;
