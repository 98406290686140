import React from 'react';
import { Sparklines, SparklinesLine, SparklinesSpots } from 'react-sparklines';
import i18next from 'i18next';

interface ThemeRowProps {
  indicatorName: string;
  baselineYearValue: number | undefined;
  selectedYearValue: any;
  unit: string;
  progress: (number | null)[] | undefined;
  change: number | undefined;
  target: number | undefined;
}

const ThemeRow: React.FunctionComponent<ThemeRowProps> = ({
  baselineYearValue,
  target,
  selectedYearValue,
  unit,
  progress,
  change,
  indicatorName,
}) => {
  const unitSymbol = unit === 'percentage' ? '%' : '';
  return (
    <div className="theme-row" style={{ textAlign: 'center' }}>
      <div className="name" style={{ flex: '0.4', textAlign: 'start' }}>
        {indicatorName}
      </div>
      <div className="baseline-year-value" style={{ flex: '0.1' }}>
        {baselineYearValue ? baselineYearValue + unitSymbol : i18next.t('_NESS._NO_DATA')}
      </div>
      <div className="target-value" style={{ flex: '0.1', textAlign: 'center' }}>
        {target ? target + unitSymbol : i18next.t('_COMMON._NO_DATA')}
      </div>
      <div className="progress" style={{ flex: '0.1' }}>
        {change ? (
          <Sparklines data={progress}>
            <SparklinesLine
              style={{ fill: 'none', strokeWidth: '5px' }}
              color={change > 0 ? 'green' : change === 0 ? '#ffc400' : 'red'}
            />
            <SparklinesSpots />
          </Sparklines>
        ) : (
          '-'
        )}
      </div>
      <div className="current" style={{ flex: '0.1', textAlign: 'start' }}>
        <span style={{ display: 'inline-block', width: '80px', textAlign: 'center' }}>
          {selectedYearValue ? selectedYearValue + unitSymbol : i18next.t('_COMMON._NO_DATA')}
        </span>
      </div>
      <div
        style={{ backgroundColor: !change ? 'gray' : '' }}
        className={
          change && change > 0 ? 'status positive' : change === 0 ? 'status no-change' : 'status negative'
        }></div>
    </div>
  );
};
export default ThemeRow;
