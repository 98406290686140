import './explore.css';
import exploreGraph from './exploreGraph.png';
import { Link } from 'react-router-dom';
import { ArrowRightOutlined } from '@ant-design/icons';
import i18n from 'i18next';
import I18nLink from 'common/components/I18nLink';

const Explore = () => {
  return (
    <div className="explore-wrapper">
      {/* <p className="title">{i18n.t('_HOME._EXPLORE._TITLE')}</p>
      <p className="subtitle">{i18n.t('_HOME._EXPLORE._SUBTITLE')}</p> */}
      <div className="img-container">
        <img src={exploreGraph} alt="graph image" />
      </div>
      <div className="btn-container">
        <I18nLink to="/explore">
          <span>{i18n.t('_COMMON._EXPLORE_BTN')}</span>
          <span>
            <ArrowRightOutlined className="explore-arrow" />
          </span>
        </I18nLink>
      </div>
    </div>
  );
};

export default Explore;
