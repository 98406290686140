import * as React from 'react';

interface ExploreContextInterface {
  modalIsOpen: boolean;
  setModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  addedIndicators: { id: number }[];
  setAddedIndicators: React.Dispatch<React.SetStateAction<{ id: number }[]>>;
  removeIndicator: React.Dispatch<React.SetStateAction<number>>;
  removing: boolean;
  setRemoving: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ExploreContext = React.createContext<ExploreContextInterface | null>(null);
