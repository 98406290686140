import { Institution, UpdateInstitution } from './../models/institution';
import { apiUrl, get, post, put, remove } from 'utils/apiMgmt';

export const getInstitutions = async (): Promise<Institution[]> => {
  const { data } = await get(apiUrl + '/institutions');
  return data;
};

export const getInstitution = async (id: number): Promise<Institution> => {
  const { data } = await get(apiUrl + `/institutions/${id}`);
  return data;
};

export const createInstitution = async (institution: Institution): Promise<Institution> => {
  const { data } = await post(apiUrl + '/institutions', institution);
  return data;
};

export const updateInstitution = async ({ institutionId, ...payload }: UpdateInstitution): Promise<Institution> => {
  const { data } = await put(apiUrl + '/institutions/' + institutionId, payload);
  return data;
};

export const deleteInstitution = async (institutionId: number): Promise<Institution> => {
  const { data } = await remove(apiUrl + '/institutions/' + institutionId);
  return data;
};
