import * as React from 'react';
import { useFormik } from 'formik';
import Input from 'common/components/Input/Input';
import { validationSchema, translationBasePath } from './ForgotPasswordFormValidation';
import Form from 'common/components/Form/Form';
import Button from 'common/components/Button/Button';
import { forgotPassword } from 'api/auth';
import { useTranslation } from 'react-i18next';
import { AuthFormContainer } from 'common/components/Form/styled';
import { message } from 'antd';
import { useHistory } from 'react-router-dom';

interface IFormInitialValues {
  email: string;
}

export const ForgotPasswordForm: React.FunctionComponent = () => {
  const history = useHistory();
  const initialValues: IFormInitialValues = { email: '' };
  const { t } = useTranslation();
  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema(t),
    onSubmit: (values, actions) => {
      forgotPassword(values.email)
        .then(() => {
          message.success(t(`${translationBasePath}._SUCCESS`), 2);
          history.push('/login');
        })
        .catch((error) => {
          actions.setErrors({ email: error.response?.data?.message });
        });
    },
  });
  return (
    <AuthFormContainer>
      <div className="form-wrapper">
        <Form onSubmit={formik.handleSubmit}>
          <Input
            name="email"
            label={t(`${translationBasePath}._EMAIL`)}
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.errors.email}
            placeholder={t(`${translationBasePath}._FORGOT_PASSWORD._ENTER_EMAIL`)}
          />
          <Button htmlType="submit">{t(`${translationBasePath}._FORGOT_PASSWORD._REQUEST_RESET_PWD_LINK`)}</Button>
        </Form>
      </div>
    </AuthFormContainer>
  );
};
