import * as React from 'react';
import { Breadcrumb, Spin } from 'antd';
import ThemeCard from 'common/components/ThemeCard/ThemeCard';
import I18nLink from 'common/components/I18nLink';
import i18n from 'i18next';
import { getCurrentLng } from 'utils/helper';
import { useQuery } from 'react-query';
import { getThemes } from 'api/public';
import { sortThemes } from 'helpers/themes';

const Strategy = () => {
  const currLang = getCurrentLng();

  const { data, isLoading, isError } = useQuery('themes', getThemes);
  const sortedThemes = sortThemes(data || []);

  if (isError) {
    return <div className="theme-error">{i18n.t('_COMMON._ERROR')}</div>;
  }

  if (isLoading) {
    return (
      <div className="themes-spinner" style={{ background: 'white' }}>
        <Spin delay={200} size="large" />
        <h4 style={{ paddingTop: '20px' }}>{i18n.t('_HOME._LOADING')}</h4>
      </div>
    );
  }

  return (
    <div className="all-themes-wrapper">
      <div className="theme-subheader-wrapper">
        <Breadcrumb separator="•">
          <Breadcrumb.Item>
            <I18nLink to="/">{i18n.t('_COMMON._HOME')}</I18nLink>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <I18nLink to="/themes">{i18n.t('_HEADER._STRATEGIES')}</I18nLink>
          </Breadcrumb.Item>
          <Breadcrumb.Item></Breadcrumb.Item>
        </Breadcrumb>
        <div className="theme-subheader-title">{i18n.t('_THEMES._ALL_STRATEGIES')}</div>
      </div>
      <section className="all-themes-list">
        {sortedThemes?.map((theme) => {
          return (
            <ThemeCard
              image={theme.image}
              key={theme.key}
              path={`/themes/${theme.key}`}
              acronym={theme[`acronym${currLang}`]}
              name={theme[`name${currLang}`]}
            />
          );
        })}
      </section>
    </div>
  );
};

export default Strategy;
