import { RightOutlined } from '@ant-design/icons';
import { Divider, Row, Col } from 'antd';
import ChartCard from 'common/components/ChartCard/ChartCard';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { IndicatorModule } from 'models/indicator';
import { getCurrentLng } from 'utils/helper';
import './categorysection.css';
import bufferToImage from 'utils/bufferToImage';
import I18nLink from 'common/components/I18nLink';

import IndicatorCard from 'common/components/IndicatorCard/IndicatorCard';
import useWindowDimensions from 'common/hooks/useWindowDimensions';
import IndicatorCardResponsive from 'common/components/IndicatorCard/IndicatorResponsiveCard';

interface CategorySectionProps {
  title: string;
  id: number;
  indicators?: IndicatorModule[];
}
interface CategoryParams {
  submoduleId: string;
  moduleId: string;
}
const CategorySection: React.FunctionComponent<CategorySectionProps> = ({ title, id, indicators }) => {
  const currLang = getCurrentLng();
  const { moduleId } = useParams<CategoryParams>();
  const { t } = useTranslation();
  const { width } = useWindowDimensions();

  return (
    <div className="category-section-wrapper">
      <div className="category-section-header">
        <div className="text">{title}</div>
        <I18nLink to={`/category/${moduleId}/subcategory/${id}`} className="view-all-btn">
          {t('_COMMON._VIEW_ALL')} <RightOutlined />
        </I18nLink>
      </div>
      {width > 700 ? (
        <Row style={{ padding: '0px' }} gutter={width > 700 ? [30, 80] : [20, 30]} className="categ-row">
          {indicators?.slice(0, 6).map((indicator) => (
            <>
              <Col
                key={indicator.key}
                style={{ display: 'flex', justifyItems: 'center' }}
                xs={{ span: 24 }}
                sm={{ span: 12 }}
                md={{ span: 8 }}
                lg={{ span: 6 }}
                xl={{ span: 4 }}>
                <IndicatorCard
                  indicator={indicator}
                  path={`/indicator/${indicator.key}`}
                  chartTitle={indicator[`name${currLang}`]}
                  image={bufferToImage(indicator.graph_image?.data)}
                />
              </Col>
            </>
          ))}
        </Row>
      ) : (
        <section className="subcategory-indicator-cards-res">
          {indicators?.map((indicator) => {
            return (
              <article>
                <IndicatorCardResponsive
                  indicator={indicator}
                  path={`/indicator/${indicator.key}`}
                  button
                  chartTitle={indicator[`name${currLang}`]}
                  responsive={width < 700}
                  image={bufferToImage(indicator.graph_image?.data)}
                />
              </article>
            );
          })}
        </section>
      )}
      <Divider style={{ marginTop: '-10px' }} />
    </div>
  );
};

export default CategorySection;
