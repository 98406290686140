import * as yup from 'yup';

export const translationBasePath = '_DASHBOARD._MANAGE_INDICATORS';

export const validationSchema = (t) =>
  yup.object().shape({
    key: yup
      .string()
      .max(2000, t(`${translationBasePath}._NAME_MAX_LENGTH`))
      .required(t(`${translationBasePath}._FIELD_REQUIRED`)),
    nameEN: yup
      .string()
      .max(2000, t(`${translationBasePath}._NAME_MAX_LENGTH`))
      .required(t(`${translationBasePath}._FIELD_REQUIRED`)),
    nameSQ: yup
      .string()
      .max(2000, t(`${translationBasePath}._NAME_MAX_LENGTH`))
      .required(t(`${translationBasePath}._FIELD_REQUIRED`)),
    descriptionEN: yup
      .string()
      .max(10000, t(`${translationBasePath}._NAME_MAX_LENGTH`))
      .required(t(`${translationBasePath}._FIELD_REQUIRED`)),
    descriptionSQ: yup
      .string()
      .max(10000, t(`${translationBasePath}._NAME_MAX_LENGTH`))
      .required(t(`${translationBasePath}._FIELD_REQUIRED`)),
    frequency: yup
      .array()
      .of(yup.string())
      .min(1, t(`${translationBasePath}._FREQUENCY_MIN_LENGTH`))
      .required(t(`${translationBasePath}._FIELD_REQUIRED`)),
    analyticalChange: yup.string().required(t(`${translationBasePath}._FIELD_REQUIRED`)),
    isPositiveNumberPositiveTrend: yup.boolean().required(t(`${translationBasePath}._FIELD_REQUIRED`)),
    isPublic: yup.boolean().required(t(`${translationBasePath}._FIELD_REQUIRED`)),
    submoduleId: yup.number().required(t(`${translationBasePath}._FIELD_REQUIRED`)),
    institutionId: yup.number().required(t(`${translationBasePath}._FIELD_REQUIRED`)),
    dataset: yup.string().required(t(`${translationBasePath}._FIELD_REQUIRED`)),
    defaultFrequency: yup
      .string()
      .test('test', t(`${translationBasePath}.FREQUENCY_REQUIRED`), function test() {
        const frequency = this.parent.frequency;
        const defaultFrequency = this.parent.defaultFrequency;
        return (
          frequency && frequency.length && (defaultFrequency ? frequency.find((f) => f === defaultFrequency) : true)
        );
      })
      .required(t(`${translationBasePath}._FIELD_REQUIRED`)),
    defaultUnitType: yup.string().required(t(`${translationBasePath}._FIELD_REQUIRED`)),

    // variables: yup
    //   .array()
    //   .of(
    //     yup.object().shape({
    //       variableName: yup.string().required(t(`${translationBasePath}._FIELD_REQUIRED`)),
    //       variableOptions: yup.array().of(yup.string().max(500, t(`${translationBasePath}._TEXT_FIELD_MAX_LENGTH`))),
    //     })
    //   )
    //   .min(1, t(`${translationBasePath}._VARIABLES_MIN_LENGTH`)),
  });
