import * as React from 'react';
import { useFormik } from 'formik';
import Input from 'common/components/Input/Input';
import { validationSchema } from './ResetPasswordFormValidation';
import Button from 'common/components/Button/Button';
import { resetPassword } from 'api/auth';
import Form from 'common/components/Form/Form';
import { useTranslation } from 'react-i18next';
import { AuthFormContainer } from 'common/components/Form/styled';

const translationBasePath = '_LOGIN._RESET_PASSWORD';

interface IFormInitialValues {
  password: string;
  confirmPassword: string;
}

interface ResetPasswordFormProps {
  match: {
    params: {
      token: string;
    };
  };
  history: {
    push: (path: string) => void;
  };
}

export const ResetPasswordForm: React.FunctionComponent<ResetPasswordFormProps> = (props: ResetPasswordFormProps) => {
  const initialValues: IFormInitialValues = { password: '', confirmPassword: '' };
  const { t } = useTranslation();
  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema(t),
    onSubmit: (values) => {
      const token = props.match.params.token;
      resetPassword(values.confirmPassword, token)
        .then(() => {
          alert(t(`${translationBasePath}._SUCCESS`));
          props.history.push('/login');
        })
        .catch((error) => {
          alert(error.response?.data?.message);
        });
    },
  });
  return (
    <AuthFormContainer>
      <div className="form-wrapper">
        <Form onSubmit={formik.handleSubmit}>
          <Input
            name="password"
            label={t(`${translationBasePath}._NEW_PASSWORD`)}
            type="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.errors.password}
          />
          <Input
            name="confirmPassword"
            label={t(`${translationBasePath}._CONFIRM_PASSWORD`)}
            type="password"
            value={formik.values.confirmPassword}
            onChange={formik.handleChange}
            error={formik.errors.confirmPassword}
          />
          <Button htmlType="submit">{t(`${translationBasePath}._SUBMIT`)}</Button>
        </Form>
      </div>
    </AuthFormContainer>
  );
};
