import * as React from 'react';
import Flex from 'common/components/Flex/Flex';
import { LoginForm } from './LoginForm';
import { isSessionActive } from 'utils/helper';
import { clearAuthData } from 'utils/tokenMgmt';
import styled from 'styled-components';
import { FormContainerStyled } from '../../common/components/Form/styled';

interface LoginProps {
  history: any;
}

class Login extends React.Component<LoginProps> {
  public state = {};

  componentDidMount() {
    const isLoggedIn = isSessionActive();
    if (isLoggedIn) {
      this.props.history.push('/');
    } else {
      clearAuthData();
    }
  }

  render() {
    return (
      <FormContainerStyled>
        <LoginForm history={this.props.history} />
      </FormContainerStyled>
    );
  }
}

export default Login;
