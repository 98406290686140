import { Publication } from './publication';
import Submodule from './submodule';
import { Theme } from './theme';
import { User } from './user';

export enum IndicatorUserRole {
  READ = 'read',
  READWRITE = 'readwrite',
}

export interface Indicator {
  id: number;
  key?: string;
  nameEN: string;
  nameSQ: string;
  frequency: Frequency[];
  submoduleId?: number;
  analyticalChange: AnalyticalChange;
  isPositiveNumberPositiveTrend: boolean;
  isPublic: boolean;
  last_value_updated_at?: Date;
  variables?: Object[];
  dataset: string;
  institutionId?: number;
  descriptionEN: string;
  descriptionSQ: string;
  createdAt?: Date;
  updatedAt?: Date;
  submodule?: Submodule;
  institution?: any;
  briefIndicators?: any;
  indicatorValues?: any;
  graph_image?: {
    type: string;
    data: Buffer;
  };
  last_value?: {
    value: number;
    date: string;
    unit: string;
    unitType: string;
    frequency: string;
  };
  defaultUnitType: string;
  defaultFrequency: string;
}

export interface IndicatorWithId extends Indicator {
  id: number;
  key: string;
}
//There is no match with Indicator model, that is why create this from teh data coming from "/modules/{id}"
export interface IndicatorModule {
  id: number;
  key: string;
  nameSQ: string;
  nameEN: string;
  descriptionSQ: string;
  descriptionEN: string;
  frequency: [null];
  analyticalChang: string;
  isPositiveNumberPositiveTrend: true;
  isPublic: boolean;
  dataset: string;
  variables: {};
  createdAt: string;
  updatedAt: string;
  last_value?: {
    value: number;
    date: string;
    unit: string;
    unitType: string;
    frequency: string;
  };
  defaultUnitType?: string;
  defaultFrequency?: string;
}

export interface SubmoduleWithID {
  id: number;
  nameEN: string;
  nameSQ: string;
  createdAt: string;
  updatedAt: string;
  moduleId: number;
  themes: any[];
  indicators: {
    id: number;
    key: string;
    nameSQ: string;
    nameEN: string;
    descriptionSQ: string;
    descriptionEN: string;
    frequency: [null];
    analyticalChang: string;
    isPositiveNumberPositiveTrend: true;
    isPublic: boolean;
    dataset: string;
    variables: {};
    createdAt: string;
    updatedAt: string;
    value: string;
    graph_image?: {
      type: string;
      data: Buffer;
    };
  }[];
}

export interface ModuleWithIndicators {
  id: number;
  nameEN: string;
  nameSQ: string;
  createdAt: string;
  updatedAt: string;
  submodules: SubmoduleWithID[];
}

export interface Variable {
  key: string;
  en: string;
  sq: string;
  order: number;
}

export interface IndicatorVariable {
  key: string;
  graph_type: string;
  en: string;
  sq: string;
  variables: Variable[];
}

// There is no match with Indicator model, that is why create this from teh data coming from "/modules/{id}"
export interface IndicatorModule {
  key: string;
  nameSQ: string;
  nameEN: string;
  descriptionSQ: string;
  descriptionEN: string;
  frequency: [null];
  analyticalChang: string;
  isPositiveNumberPositiveTrend: true;
  isPublic: boolean;
  dataset: string;
  variables: {};
  createdAt: string;
  updatedAt: string;
  graph_image?: {
    type: string;
    data: Buffer;
  };
}

export interface IndicatorValues {
  id: number;
  indicatorId: number;
  date: string;
  value: number;
  target: number;
  unit: string;
  unitType: string;
  frequency: string;
  variables: {};
  createdBy?: number;
  updatedBy: number;
  certification: string;
  createdAt: string;
  updatedAt: string;
}

export interface IndicatorFilters {
  frequencies: string[];
  unitTypes: string[];
  defaultFrequency: Frequency;
  defaultUnitType: UnitType;
  variables: {
    [key: string]: string[];
  };
}

export interface IndicatorUser {
  id?: number;
  userId: number;
  indicatorKey: string;
  accessType: IndicatorUserRole;
  createdAt?: Date;
  updatedAt?: Date;
  user: User;
}

export interface IndicatorUserWithId extends IndicatorUser {
  indicatorId: number;
}

export interface CreateIndicatorUserBody {
  userId: number;
  accessType: IndicatorUserRole;
}

export enum Frequency {
  MONTH = 'month',
  QUARTER = 'quarter',
  YEAR = 'year',
}

export enum UnitType {
  CUMULATIVE = 'cumulative',
  SHARE = 'share',
  RATE = 'rate',
  GROWTH = 'growth',
}

export enum AnalyticalChange {
  YOY = 'YoY',
  MOM = 'MoM',
  QOQ = 'QoQ',
}

export interface NessThemeModel {
  id?: number;
  key?: string;
  nameEN: string;
  nameSQ: string;
  frequency: Frequency[];
  submoduleId?: number;
  analyticalChange: AnalyticalChange;
  isPositiveNumberPositiveTrend: boolean;
  variables?: Object[];
  dataset: string;
  institutionId?: number;
  descriptionEN: string;
  descriptionSQ: string;
  createdAt?: string;
  updatedAt?: string;
  submodule?: Submodule;
  institution?: any;
  briefIndicators?: any;
  indicatorValues?: IndicatorValues[];
  graph_image?: string;
  isPublic: boolean;
  last_value_id: number;
  last_value_updated_at: string;
  version: number;
}

export interface RelatedMetrics {
  indicators: Indicator[];
  publications: Publication[];
  themes: Theme[];
}
