import React, { useRef, useState, useEffect, useContext } from 'react';
import { IndicatorValues, IndicatorWithId } from 'models/indicator';
import ReactEcharts from 'echarts-for-react';
import { Select } from 'antd';
const { Option } = Select;
import {
  generateAvailableMonths,
  generateAvailableQuarters,
  generateAvailableYears,
  generateMapSeries,
  quarterToDate,
  formatMonth,
  getIndicatorStartAndEndYear,
} from '../helpers';
import { useTranslation } from 'react-i18next';
import image from 'pages/Explorer/components/Indicator/components/icons/image.png';
import { getCurrentLng } from 'utils/helper';
import DownloadModal from 'common/components/DownloadModal/DownloadModal';
import i18next from 'i18next';
import { IndicatorPageContext } from '../store/context';

interface BarChartProps {
  filter?: any;
  data?: {
    option: string;
    values: IndicatorValues[];
  }[];
  xAxis?: string[];
  unitType?: string;
  frequency: string;
  indicatorName: string;
  indicator: IndicatorWithId;
}

const Bar: React.FunctionComponent<BarChartProps> = ({
  xAxis,
  data,
  filter,
  unitType,
  indicatorName,
  frequency,
  indicator,
}) => {
  const { t } = useTranslation();
  const currLang = getCurrentLng();
  const [previewModal, setPreviewModal] = useState(false);
  const { startYearFilter, endYearFilter } = useContext(IndicatorPageContext);
  const { startYear, endYear } = getIndicatorStartAndEndYear(startYearFilter, endYearFilter, xAxis || []);
  const [firstSelectedOption, setFirstSelectedOption] = useState(
    frequency === 'year'
      ? String(generateAvailableYears(data)[0])
      : frequency === 'quarter'
      ? quarterToDate(String(generateAvailableQuarters(data)[0]))
      : frequency === 'month'
      ? String(generateAvailableMonths(data)[0])
      : ''
  );

  const [secondSelectedOption, setSecondSelectedOption] = useState(
    frequency === 'year'
      ? String(generateAvailableYears(data)[0])
      : frequency === 'quarter'
      ? quarterToDate(String(generateAvailableQuarters(data)[0]))
      : frequency === 'month'
      ? String(generateAvailableMonths(data)[0])
      : ''
  );

  useEffect(() => {
    setFirstSelectedOption(
      frequency === 'year'
        ? String(generateAvailableYears(data)[0])
        : frequency === 'quarter'
        ? quarterToDate(String(generateAvailableQuarters(data)[0]))
        : frequency === 'month'
        ? String(generateAvailableMonths(data)[0])
        : ''
    );
    const indexOfSecondOption = generateAvailableYears(data).length - 1;

    setSecondSelectedOption(
      frequency === 'year'
        ? String(generateAvailableYears(data)[indexOfSecondOption])
        : frequency === 'quarter'
        ? quarterToDate(String(generateAvailableQuarters(data)[indexOfSecondOption]))
        : frequency === 'month'
        ? String(generateAvailableMonths(data)[indexOfSecondOption])
        : ''
    );
  }, [frequency, startYear, endYear]);

  const chartOptions = {
    barMaxWidth: 50,
    grid: {
      top: 80,
      bottom: 30,
    },
    xAxis: {
      type: 'value',
      position: 'top',
      splitLine: {
        lineStyle: {
          type: 'dashed',
        },
      },
    },
    yAxis: {
      type: 'category',
      axisLine: { show: false },
      axisLabel: { show: false },
      axisTick: { show: false },
      splitLine: { show: false },
      data: generateMapSeries(data, firstSelectedOption, frequency)?.map((data) => data.name),
    },
    series: [
      {
        datasetIndex: 1,
        name: firstSelectedOption,
        type: 'bar',
        colorBy: 'series',
        itemStyle: {
          color: '#0E9898',
        },
        label: {
          show: true,
          formatter: `{b} {c} ${unitType === 'rate' || unitType === 'share' || unitType === 'growth' ? '%' : ''}`,
        },
        data: generateMapSeries(data, firstSelectedOption, frequency),
      },
      {
        datasetIndex: 2,
        colorBy: 'series',
        name: secondSelectedOption,
        type: 'bar',
        itemStyle: {
          color: '#9400D3',
        },
        label: {
          show: true,
          formatter: `{b} {c} ${unitType === 'rate' || unitType === 'share' || unitType === 'growth' ? '%' : ''} `,
        },
        data: generateMapSeries(data, secondSelectedOption, frequency),
      },
    ],
  };

  const indicatorContainer = (
    <div className="bar-chart">
      <ReactEcharts option={chartOptions} style={{ height: '900px' }} />
    </div>
  );
  if (data?.map((val) => val.values).filter((val) => val.length !== 0).length === 0) {
    return <p></p>;
  }

  return (
    <div>
      <div className="map-header">
        <div>
          <div
            style={{
              fontFamily: 'Oswald',
              fontStyle: 'normal',
              fontWeight: 'bold',
              fontSize: 23,
              textTransform: 'uppercase',
            }}>
            {indicatorName}
          </div>
          <div
            style={{
              fontFamily: 'Oswald',
              fontStyle: 'normal',
              fontSize: 17,
            }}>
            {t('_INDICATORS._BY')} {filter?.[`${currLang.toLowerCase()}`]}
          </div>
        </div>
        <div className="select-options">
          {xAxis && (
            <Select showSearch onChange={(value) => {}} defaultValue={'region'} style={{ width: 150 }}>
              <Option key={'region'} value={'region'}>
                {t('_INDICATORS._BY_REGION')}
              </Option>
            </Select>
          )}
          {frequency === 'year' && xAxis && (
            <div className="sel-opts">
              <Select
                showSearch
                onChange={(value) => {
                  setFirstSelectedOption(value.toString());
                }}
                value={firstSelectedOption}
                style={{ width: 150, color: '#0E9898' }}
                optionFilterProp="children"
                filterOption={(input, option) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                {xAxis &&
                  generateAvailableYears(data).map((value) => {
                    return (
                      <Option
                        key={value}
                        value={value}
                        style={{ color: '#0E9898', fontWeight: value === +firstSelectedOption ? 'bold' : 'normal' }}>
                        {value}
                      </Option>
                    );
                  })}
              </Select>

              <span style={{ marginTop: '5px' }}>vs</span>

              <Select
                showSearch
                onChange={(value) => {
                  setSecondSelectedOption(value.toString());
                }}
                value={secondSelectedOption}
                style={{ width: 150, color: '#9400D3' }}
                optionFilterProp="children"
                filterOption={(input, option) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                {xAxis &&
                  generateAvailableYears(data).map((value) => {
                    return (
                      <Option
                        key={value}
                        value={value}
                        style={{ color: '	#9400D3', fontWeight: value === +secondSelectedOption ? 'bold' : 'normal' }}>
                        {value}
                      </Option>
                    );
                  })}
              </Select>
            </div>
          )}
          {frequency === 'quarter' && xAxis && xAxis?.length > 0 && (
            <div className="sel-opts">
              <Select
                showSearch
                onChange={(value) => {
                  setFirstSelectedOption(value.toString());
                }}
                defaultValue={quarterToDate(generateAvailableQuarters(data)?.[0])}
                style={{ width: 150, color: '#0E9898' }}
                optionFilterProp="children"
                filterOption={(input, option) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                {xAxis &&
                  generateAvailableQuarters(data).map((value) => {
                    return (
                      <Option key={value} value={quarterToDate(value)} style={{ color: '#0E9898' }}>
                        {value}
                      </Option>
                    );
                  })}
              </Select>

              <span style={{ marginTop: '5px' }}>vs</span>

              <Select
                showSearch
                onChange={(value) => {
                  setSecondSelectedOption(value.toString());
                }}
                defaultValue={quarterToDate(generateAvailableQuarters(data)?.[0])}
                style={{ width: 150, color: '#9400D3' }}
                optionFilterProp="children"
                filterOption={(input, option) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                {xAxis &&
                  generateAvailableQuarters(data).map((value) => {
                    return (
                      <Option
                        key={value}
                        value={quarterToDate(value)}
                        style={{
                          color: '#9400D3',
                        }}>
                        {value}
                      </Option>
                    );
                  })}
              </Select>
            </div>
          )}
          {frequency === 'month' && xAxis && (
            <div className="sel-opts">
              <Select
                showSearch
                onChange={(value) => {
                  setFirstSelectedOption(value.toString());
                }}
                defaultValue={firstSelectedOption}
                style={{ width: 150, color: '#0E9898' }}
                optionFilterProp="children"
                filterOption={(input, option) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                {xAxis &&
                  generateAvailableMonths(data).map((value) => {
                    return (
                      <Option
                        key={value}
                        value={value}
                        style={{
                          color: '#0E9898',
                        }}>
                        {formatMonth(value)}
                      </Option>
                    );
                  })}
              </Select>

              <span style={{ marginTop: '5px' }}>vs</span>

              <Select
                showSearch
                onChange={(value) => {
                  setSecondSelectedOption(value.toString());
                }}
                defaultValue={secondSelectedOption}
                style={{ width: 150, color: '#9400D3' }}
                optionFilterProp="children"
                filterOption={(input, option) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                {xAxis &&
                  generateAvailableMonths(data)?.map((month) => {
                    return (
                      <Option key={month} value={month}>
                        {formatMonth(month)}
                      </Option>
                    );
                  })}
              </Select>
            </div>
          )}
        </div>
      </div>
      {previewModal && (
        <DownloadModal setPreviewModal={setPreviewModal} indicator={indicator}>
          <div>
            <div
              style={{
                fontFamily: 'Oswald',
                fontStyle: 'normal',
                fontWeight: 'bold',
                fontSize: 23,
                textTransform: 'uppercase',
              }}>
              {indicatorName}
            </div>
            <div
              style={{
                fontFamily: 'Oswald',
                fontStyle: 'normal',
                fontSize: 17,
              }}>
              {t('_INDICATORS._BY')} {filter?.[`${currLang.toLowerCase()}`]}
            </div>
          </div>
          {indicatorContainer}
        </DownloadModal>
      )}
      {indicatorContainer}
      <div className="indicator-graphs-footer">
        <div className="share">
          <div>{i18next.t('_COMMON._SHARE')}</div>
          <div>
            <img style={{ width: '20px' }} src={image} onClick={() => setPreviewModal(true)} />
          </div>
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default Bar;
