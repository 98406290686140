import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Backdrop, Card, Title, CardContent, StyledModal } from './styled';
import { ModalProps } from 'antd/lib/modal/Modal';

type Props = ModalProps;

const Modal = (props: Props) => {
  return <StyledModal {...props} />;
};

export default withTranslation()(Modal);
