import React, { useState } from 'react';
import Modal from 'common/components/Modal/Modal';
import { useQuery } from 'react-query';
import Button from 'common/components/Button/Button';
import { useTranslation } from 'react-i18next';
import { Breadcrumb, Row, Space } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import I18nLink from 'common/components/I18nLink';
import Table from 'common/components/Table/Table';
import { getCurrentLng, isCurrentUserAdmin } from 'utils/helper';
import AddInstitutionForm from './AddInstitutionForm';
import { Institution } from 'models/institution';
import { getInstitutions } from 'api/institution';
import { translationBasePath } from '../ManageInstitutions/AddInstitutionFormValidation';
import { Title } from '../ManageUsers/styled';

const ManageInstitutions: React.FunctionComponent = () => {
  const isAdmin = isCurrentUserAdmin();
  const currentLanguage = getCurrentLng();

  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [updating, setUpdating] = useState<Institution | null>(null);
  const { data, isLoading, refetch } = useQuery('UserInstitutions', getInstitutions);

  const columns: ColumnProps<any>[] = [
    {
      title: t(`${translationBasePath}._NAME${currentLanguage}`),
      dataIndex: `name${currentLanguage}`,
      key: `name${currentLanguage}`,
      render: (text: string, _record: Institution) => {
        return <span>{text}</span>;
      },
    },
    {
      title: t(`${translationBasePath}._ACRONYM${currentLanguage}`),
      dataIndex: `acronym${currentLanguage}`,
      key: `acronym${currentLanguage}`,
      render: (text: string, _record: Institution) => {
        return <span>{text}</span>;
      },
    },
    {
      title: t(`${translationBasePath}._URL`),
      dataIndex: 'url',
      key: 'url',
      render: (text: string, _record: Institution) => {
        return <span>{text}</span>;
      },
    },
    {
      title: t(`${translationBasePath}._ACTIONS`),
      key: 'actions',
      dataIndex: 'actions',
      render: (_, record) => (
        <Space size="middle">
          {isAdmin && (
            <Button
              style={{ fontSize: '15px' }}
              onClick={() => {
                setOpen(true);
                setUpdating(record);
              }}
              type="link"
              shape="round">
              {t(`${translationBasePath}._UPDATE_BTN`)}
            </Button>
          )}
          <Button style={{ fontSize: '15px' }} type="link" shape="round">
            <I18nLink to={`/dashboard/institutions/${record.id}/users`}>
              {t(`${translationBasePath}._MANAGE_INSTITUTION_USERS`)}
            </I18nLink>
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Row justify="space-between" style={{ marginBottom: 10 }}>
        <Title>
          <I18nLink style={{ color: 'black' }} to="/dashboard/institutions">
            {t(`${translationBasePath}._TITLE`)}
          </I18nLink>
        </Title>
        {isAdmin && (
          <Button
            type="primary"
            onClick={() => {
              setOpen(true);
              setUpdating(null);
            }}>
            {t(`${translationBasePath}._CREATE_INSTITUTION`)}
          </Button>
        )}
      </Row>
      {open && (
        <Modal
          maskClosable={false}
          footer={null}
          visible
          title={
            updating
              ? t(`${translationBasePath}._UPDATE_INSTITUTION_BTN`)
              : t(`${translationBasePath}._CREATE_INSTITUTION`)
          }
          onCancel={() => {
            setOpen(false);
            setUpdating(null);
          }}>
          <AddInstitutionForm updating={updating} refetch={refetch} onClose={() => setOpen(false)} />
        </Modal>
      )}
      <Table
        rowClassName="admin-table-row"
        loading={isLoading}
        dataSource={data}
        columns={columns}
        pagination={false}
      />
    </>
  );
};

export default ManageInstitutions;
