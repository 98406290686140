import { passwordPattern } from 'utils/helper';
import * as yup from 'yup';

const translationBasePath = '_LOGIN._RESET_PASSWORD';

export const validationSchema = (t) =>
  yup.object({
    password: yup
      .string()
      .required(t(`${translationBasePath}._NEW_PASSWORD_REQUIRED`))
      .matches(passwordPattern, t('_WEAK_PASSWORD')),
    confirmPassword: yup
      .string()
      .required(t(`${translationBasePath}._CONFIRM_PASSWORD_REQUIRED`))
      .oneOf([yup.ref('password')], t(`${translationBasePath}._CONFIRM_PASSWORD_NOT_MATCH`)),
  });
