import * as React from 'react';

const Component = () => {
  return (
    <main>
      <h2>Under construction</h2>
    </main>
  );
};

export default Component;
