import { FlexProps } from './types';
import styled from 'styled-components';

const FlexContainer = styled.div`
  display: flex;
  flex-direction: ${(props: FlexProps) => props.direction};
  justify-content: ${(props: FlexProps) => props.justify};
  align-items: ${(props: FlexProps) => props.align};
  gap: ${(props: FlexProps) => props.gap};
`;

export { FlexContainer };
