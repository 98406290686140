import React, { useContext, useEffect, useRef, useState } from 'react';
import twitter from 'pages/Explorer/components/Indicator/components/icons/twitter.svg';
import instagram from 'pages//Explorer/components/Indicator/components/icons/instagram.svg';
import message from 'pages/Explorer/components/Indicator/components/icons/message.svg';
import linkedin from 'pages/Explorer/components/Indicator/components/icons/linkedin.svg';
import download from 'pages/Explorer/components/Indicator/components/icons/download.svg';
import { IndicatorValues, IndicatorWithId } from 'models/indicator';
import ReactEcharts from 'echarts-for-react';
import AlbaniaMap from './Albania-Counties.json';
import { Select } from 'antd';
import useWindowDimensions from 'common/hooks/useWindowDimensions';
import { useTranslation } from 'react-i18next';
import {
  formatCSVData,
  formatMonth,
  formatQuarter,
  generateAvailableMonths,
  generateAvailableQuarters,
  generateAvailableYears,
  generateMapSeries,
  generateMinMaxValues,
  getIndicatorStartAndEndYear,
  quarterToDate,
} from '../helpers';
import CsvDownload from 'react-json-to-csv';
import DownloadModal from 'common/components/DownloadModal/DownloadModal';
import { getCurrentLng } from 'utils/helper';
import image from 'pages/Explorer/components/Indicator/components/icons/image.png';
import { useQuery } from 'react-query';
import { getIndicator } from 'api/indicator';
import { getModule } from 'api/module';
import { IndicatorPageContext } from '../store/context';

const { Option } = Select;
interface LineGraphProps {
  indicatorName: string;
  filter: any;
  data?: {
    option: string;
    values: IndicatorValues[];
  }[];
  xAxis?: string[];
  unitType: string;
  frequency: string;
  indicator: IndicatorWithId;
}

const Map: React.FunctionComponent<LineGraphProps> = ({
  data,
  xAxis,
  filter,
  unitType,
  frequency,
  indicatorName,
  indicator,
}) => {
  const { data: indicatorData } = useQuery(['getSingleIndicator', indicator.id], () => getIndicator(indicator.id), {
    enabled: true,
    refetchOnWindowFocus: false,
  });
  const { startYearFilter, endYearFilter } = useContext(IndicatorPageContext);
  const { startYear, endYear } = getIndicatorStartAndEndYear(startYearFilter, endYearFilter, xAxis || []);
  const { data: moduleData } = useQuery('module', () => getModule(indicatorData?.submodule?.moduleId || 1), {
    enabled: !!indicatorData,
  });

  const eChartsRef = useRef(null as any);
  const { t } = useTranslation();
  const currLang = getCurrentLng();
  const [previewModal, setPreviewModal] = useState(false);
  const { width } = useWindowDimensions();
  const [selectedOption, setSelectedOption] = useState(
    frequency === 'year'
      ? String(generateAvailableYears(data)[0])
      : frequency === 'quarter'
      ? String(generateAvailableQuarters(data)[0])
      : frequency === 'month'
      ? String(generateAvailableMonths(data)[0])
      : ''
  );

  const { minValue, maxValue } = generateMinMaxValues(data, selectedOption, frequency);
  useEffect(() => {
    setSelectedOption(
      frequency === 'year'
        ? String(generateAvailableYears(data)[0])
        : frequency === 'quarter'
        ? quarterToDate(String(generateAvailableQuarters(data)[0]))
        : frequency === 'month'
        ? String(generateAvailableMonths(data)[0])
        : ''
    );
  }, [frequency, startYear, endYear]);

  useEffect(() => {
    if (eChartsRef && eChartsRef.current) {
      eChartsRef.current.echarts.registerMap('albania', { geoJSON: AlbaniaMap });
      const chartOptions = {
        geo: {
          show: true,
          map: 'albania',
        },
        visualMap: {
          type: 'continuous',
          right: width > 700 ? 150 : 10,
          bottom: width > 700 ? 63 : 0,
          min: isFinite(minValue) ? minValue : 0,
          max: isFinite(maxValue) ? maxValue : 100,
          text: ['High', 'Low'],
          realtime: false,
          calculable: true,
          color: ['#2A9898', '#56B7B7', '#93D1D1', '#C2E2E1', '#FDF0F2', '#FCC8CF', '#ED6E7D'],
        },
        tooltip: {
          trigger: 'item',
          showDelay: 0,
          transitionDuration: 0.2,
          formatter: function (params) {
            let date =
              frequency === 'quarter'
                ? formatQuarter(selectedOption)
                : frequency === 'year'
                ? selectedOption
                : frequency === 'month'
                ? formatMonth(selectedOption)
                : '';

            const value = (params.value + '').split('.');
            const valueStr = value[0].replace(/(\d{1,3})(?=(?:\d{3})+(?!\d))/g, '$1,');
            if (isNaN(parseFloat(params.value))) {
              return `${params.name}- Not available`;
            }
            return (
              date +
              '<br/>' +
              params.name +
              ': ' +
              params.value +
              `${unitType === 'rate' || unitType === 'growth' ? '%' : ''}`
            );
          },
        },
        series: [
          {
            name: 'Date',
            type: 'map',
            map: 'albania',
            roam: false,
            nameProperty: 'ADM1_EN',
            emphasis: {
              label: {
                show: true,
              },
            },
            data: generateMapSeries(data, selectedOption, frequency),
            label: {
              show: true,
              formatter: (params) => {
                return isNaN(parseFloat(params.value))
                  ? '-'
                  : `${params.value}${unitType === 'rate' || unitType === 'growth' ? '%' : ''}`;
              },
              position: '',
              offset: [-10, -20],
            },
          },
        ],
      };
      eChartsRef.current?.getEchartsInstance().setOption({ ...chartOptions }, true);
    }
  }, [eChartsRef, selectedOption, previewModal, unitType, frequency]);

  const buttonStyles = {
    border: 'none',
    backgroundColor: 'white',
  };
  if (data?.map((val) => val.values).filter((val) => val.length !== 0).length === 0) {
    return <p></p>;
  }

  const indicatorContainer = (
    <div className="indicator-chart">
      {data && data?.length > 0 && data.map((val) => val.values.length).length > 0 && (
        <>
          <ReactEcharts style={{ height: width > 700 ? 800 : 700 }} ref={eChartsRef} lazyUpdate={true} option={{}} />
        </>
      )}
    </div>
  );
  const header = (
    <div>
      <div
        style={{
          fontFamily: 'Oswald',
          fontStyle: 'normal',
          fontWeight: 'bold',
          fontSize: 23,
          textTransform: 'uppercase',
        }}>
        {indicatorName ? indicatorName : 'Not available'}
      </div>
      <div
        style={{
          fontFamily: 'Oswald',
          fontStyle: 'normal',
          fontSize: 17,
        }}>
        {t('_INDICATORS._BY')} {filter?.[`${currLang.toLowerCase()}`]}
      </div>
    </div>
  );

  return (
    <div id={filter.key.split('_').join('')} className="indicator-chart-section">
      <div className="map-header">
        {header}
        {/**Select field for frequency=YEAR */}

        {frequency === 'year' && xAxis && (
          <Select
            showSearch
            onChange={(value) => {
              setSelectedOption(value.toString());
            }}
            value={selectedOption}
            style={{ width: 150, color: '#0E9898' }}>
            {xAxis &&
              data &&
              generateAvailableYears(data).map((value) => {
                return (
                  <Option
                    key={value}
                    value={value}
                    style={{ color: '#0E9898', fontWeight: value === +selectedOption ? 'bold' : 'normal' }}>
                    {value}
                  </Option>
                );
              })}
          </Select>
        )}

        {/**Select field for frequency=QUARTER */}

        {frequency === 'quarter' && xAxis && xAxis?.length > 0 && data && data.length > 0 && (
          <Select
            showSearch
            onChange={(value) => setSelectedOption(value?.toString())}
            defaultValue={quarterToDate(generateAvailableQuarters(data)[0])}
            style={{ width: 150 }}
            optionFilterProp="children"
            filterOption={(input, option) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
            {xAxis &&
              data &&
              generateAvailableQuarters(data)?.map((quarter) => {
                return (
                  <Option key={quarter} value={quarterToDate(quarter)}>
                    {quarter}
                  </Option>
                );
              })}
          </Select>
        )}
        {/**Select field for frequency=MONTH */}

        {frequency === 'month' && data && data.length > 0 && xAxis && (
          <Select
            showSearch
            onChange={(value) => setSelectedOption(value.toString())}
            defaultValue={selectedOption}
            style={{ width: 150 }}
            optionFilterProp="children"
            filterOption={(input, option) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
            {xAxis &&
              data &&
              generateAvailableMonths(data)?.map((month) => {
                return (
                  <Option key={month} value={month}>
                    {formatMonth(month)}
                  </Option>
                );
              })}
          </Select>
        )}
      </div>

      <div className="indicator-chart">
        {previewModal && (
          <DownloadModal setPreviewModal={setPreviewModal} indicator={indicator}>
            {header}
            {indicatorContainer}
          </DownloadModal>
        )}
        {indicatorContainer}
      </div>
      <div className="indicator-footer">
        <div className="txt"></div>
        <div className="share" style={{ padding: '10px' }}>
          <div>{t('_COMMON._SHARE')}</div>
          <div>
            <img src={twitter} />
          </div>
          <div>
            <img src={message} />
          </div>
          <div>
            <img src={linkedin} />
          </div>
          <div>
            <img src={instagram} />
          </div>

          <div onClick={() => setPreviewModal(true)}>
            <img style={{ width: '16px' }} src={image} />
          </div>
          <div>
            {data && indicatorData && moduleData && (
              <CsvDownload
                data={formatCSVData(data, indicatorData, moduleData)}
                style={buttonStyles}
                className="download-csv-btn-map"
                filename={`${indicatorData.nameEN}.csv`}>
                <img src={download} />
              </CsvDownload>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Map;
