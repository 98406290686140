import { apiUrl, post, put, get, remove } from 'utils/apiMgmt';
import Submodule, { SubmoduleWithID } from 'models/submodule';

export const createSubmodule = async (body: Submodule): Promise<Submodule> => {
  const { data } = await post(apiUrl + '/submodules', body);
  return data;
};

export const updateSubmodule = async (body: Submodule): Promise<Submodule> => {
  const { id, nameEN, nameSQ, themes } = body;
  const { data } = await put(apiUrl + '/submodules/' + id, { nameEN, nameSQ, themeIds: themes });
  return data;
};

export const deleteSubmodule = async (id: number): Promise<Submodule> => {
  const { data } = await remove(apiUrl + '/submodules/' + id);
  return data;
};

export const getSubmodule = async (id: number): Promise<Submodule> => {
  const { data } = await get(apiUrl + '/submodules/' + id);
  return data;
};
export const getSubmoduleWithID = async (id: number): Promise<SubmoduleWithID> => {
  const { data } = await get(apiUrl + '/submodules/' + id);
  return data;
};

export const getSubmodules = async (moduleId: string): Promise<Submodule[]> => {
  const { data } = await get(apiUrl + `/modules/${moduleId}/submodules`);
  return data;
};

export const getAllSubmodules = async (): Promise<Submodule[]> => {
  const { data } = await get(apiUrl + '/submodules');
  return data;
};
