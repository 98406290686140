import React, { useState } from 'react';
import { deletePublication, getPublications } from 'api/publication';
import { useQuery, useMutation } from 'react-query';
import Table from 'common/components/Table/Table';
import { Button, Modal, Popconfirm, Space, notification, Row } from 'antd';
import PublicationCreate from './PublicationCreate';
import PublicationUpdate from './PublicationUpdate';
import { useTranslation } from 'react-i18next';
import { translationBasePath } from './PublicationValidationForm';
import { getModules } from 'api/module';
import { formatDate, getCurrentLng } from 'utils/helper';
import { Publication } from 'models/publication';
import { Title } from '../ManageUsers/styled';
import { ColumnProps } from 'antd/lib/table';
import I18nLink from 'common/components/I18nLink';

const Publications: React.FunctionComponent = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const { t } = useTranslation();
  const [updating, setUpdating] = useState<Publication | null>();
  const { data, isLoading, refetch, isRefetching } = useQuery('dashboardPublications', getPublications, {
    refetchOnReconnect: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
  const { isLoading: modulesLoading, data: modules } = useQuery('modules', getModules, { staleTime: 180 * 1000 }); // ALLOW REFETCHING AFTER 3 MINS
  const deleteMutation = useMutation(deletePublication);

  const publicationsHeader = (
    <Row justify="space-between" style={{ marginBottom: 10 }}>
      <Title>
        <I18nLink style={{ color: 'black' }} to="/dashboard/publications">
          {t(`${translationBasePath}._PUBLICATIONS`)}
        </I18nLink>
      </Title>
      <Button
        type="primary"
        onClick={() => {
          setModalOpen(true);
          setUpdating(null);
        }}>
        {t(`${translationBasePath}._ADD_PUBLICATION_BTN`)}
      </Button>
    </Row>
  );
  const currentLanguage: string = getCurrentLng();
  const nameLngKey = `name${currentLanguage}`;
  const publicationForm = updating ? (
    <PublicationUpdate
      onClose={() => setModalOpen(false)}
      refetch={() => refetch()}
      modules={modules ? modules : []}
      publication={updating}
    />
  ) : (
    <PublicationCreate onClose={() => setModalOpen(false)} refetch={() => refetch()} modules={modules ? modules : []} />
  );
  const modal = modalOpen && (
    <Modal
      maskClosable={false}
      footer={null}
      width="800px"
      visible
      title={
        updating ? t(`${translationBasePath}._UPDATE_PUBLICATION`) : t(`${translationBasePath}._CREATE_PUBLICATION`)
      }
      onCancel={() => {
        setModalOpen(false);
        setUpdating(null);
      }}>
      {publicationForm}
    </Modal>
  );

  const columns: ColumnProps<any>[] = [
    {
      key: nameLngKey,
      title: t(`${translationBasePath}._${nameLngKey.toUpperCase()}`),
      dataIndex: nameLngKey,
      render: (text: string, _record) => {
        return <span>{text}</span>;
      },
    },
    {
      key: 'type',
      title: t(`${translationBasePath}._TYPE`),
      dataIndex: 'type',
      render: (text: string, _record) => {
        return <span>{t(`${translationBasePath}._TYPE_${text.toUpperCase()}`)}</span>;
      },
    },
    {
      key: 'author',
      title: t(`${translationBasePath}._AUTHOR`),
      dataIndex: 'author',
      render: (text: string, _record) => {
        return <span>{text}</span>;
      },
    },
    {
      key: 'link',
      title: t(`${translationBasePath}._LINK`),
      dataIndex: 'file',
      render: (text: string, _record) => {
        return (
          <span>
            {
              <a href={text} style={{ fontSize: '15px' }} target="_blank">
                {t(`${translationBasePath}._FILE`)}
              </a>
            }
          </span>
        );
      },
    },
    {
      key: 'date',
      title: t(`${translationBasePath}._DATE`),
      dataIndex: 'date',
      render: (text: string, _record) => {
        return <span>{formatDate(text)}</span>;
      },
    },
    {
      key: 'module',
      title: t(`${translationBasePath}._MODULE`),
      dataIndex: 'module',
      render: (_text: string, record) => {
        return <span>{record.module[nameLngKey]}</span>;
      },
    },
    {
      title: t(`${translationBasePath}._ACTION`),
      key: 'action',
      dataIndex: 'action',
      render: (_text, record) => (
        <Space size="middle">
          <Button
            onClick={() => {
              setModalOpen(true);
              setUpdating(record);
            }}
            type="link"
            style={{ fontSize: '15px' }}
            shape="round">
            {t(`${translationBasePath}._UPDATE`)}
          </Button>
          <Popconfirm
            title={t(`${translationBasePath}._DELETE_CONFIRM`)}
            onConfirm={() => {
              deleteMutation
                .mutateAsync(record)
                .then(() => refetch())
                .catch((err) => {
                  notification.error({
                    message: err.message,
                  });
                });
            }}>
            <Button style={{ fontSize: '15px' }} type="link" shape="round" danger>
              {t(`${translationBasePath}._DELETE`)}
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div>
      {publicationsHeader}
      {modal}
      <Table
        rowClassName="admin-table-row"
        loading={isLoading || modulesLoading || isRefetching}
        dataSource={data}
        columns={columns}
        pagination={false}
      />
    </div>
  );
};

export default Publications;
