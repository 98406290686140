import React from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import someImg from 'common/components/ChartCard/chartImage.png';
import { BriefContext } from './BriefContext';
const { TextArea } = Input;
import i18n from 'i18next';
import useWindowDimensions from 'common/hooks/useWindowDimensions';

const AddBriefComponent = ({ id, indicator, image }) => {
  const { addedBriefs, setAddedBriefs } = React.useContext(BriefContext);
  const { width } = useWindowDimensions();
  //handle text area change
  const handleStoryline = (e: string) => {
    setAddedBriefs((briefs) =>
      briefs.map((brief) => {
        if (brief.indicatorId === id) {
          return {
            ...brief,
            storyline: e,
          };
        }
        return brief;
      })
    );
  };

  //handle delete brief indicator
  const handleDelete = (id: number) => {
    const newBriefs = addedBriefs.filter((brief) => brief.indicatorId !== id);
    setAddedBriefs(newBriefs);
  };

  return (
    <>
      <h4 style={{ textAlign: 'center', marginBottom: '-10px' }}>{indicator}</h4>
      <div className="brief-add">
        <div className="brief-img">
          <img src={image ? image : someImg} />
        </div>
        <div style={{ flex: '0.8' }}>
          <TextArea
            onChange={(e) => handleStoryline(e.target.value)}
            placeholder={i18n.t('_BRIEFS._CREATE_STORYLINE')}
            className="textarea"
            style={{
              width: '100%',
              resize: 'none',
              borderRadius: '10px',
              background: '#fffcfc',
              border: '1px solid #b3b3b3',
            }}
            rows={width > 700 ? 7 : 5}
          />
        </div>
        <div className="brief-delete" onClick={() => handleDelete(id)}>
          <span>{i18n.t('_BRIEFS._DELETE')}</span>
          <span>
            <DeleteOutlined />
          </span>
        </div>
      </div>
    </>
  );
};

export default AddBriefComponent;
