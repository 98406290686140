import { Theme, ThemeType } from 'models/theme';

export const StrategyTheme: Theme = {
  key: 'strategies',
  image: '',
  acronymEN: 'NESS',
  acronymSQ: 'Eksploro',
  nameEN: 'NESS',
  nameSQ: 'Strategjitë',
  descriptionEN:
    '"The Employment and Skills Strategy 2019-2022 Vision remains the same as the strategy that was implemented during 2014-2018 and builds upon the latter. Thus, it preserves the same vision and the same strategic priorities. According to the monitoring indicators of the employment and skills strategy implemented for 2014-2018, almost all of the indicators have progressed positively, compared to the baseline year. However, there are some indicators for which progress has not been to the point of meeting targets set for 2020. The new Action Plan for the Employment and Skills Strategy 2019- 2022 has reviewed all the objectives and current reforms, and addresses all with the purpose of finalizing them. It also presents a review of policies that have not been completed in the 4-year period of implementation. Based on the mid-term review report, the 4 strategic priorities of this strategy that were implemented during 2014-2018 are still relevant and will not change in the National Employment and Skills Strategy 2019-2022."',
  descriptionSQ:
    '"Vizioni i Strategjisë për Punësim dhe Aftësi 2019-2022 është vlerësuar të jetë i njëjtë dhe vazhdim i strategjisë që është zbatuar në periudhën 2014-2018, duke ruajtur të njëjtin vizion dhe të njëjtat prioritete strategjike. Duke iu referuar treguesve të monitorimit të strategjisë së punësimit dhe aftësive të zbatuar në vitet 2014-2018 mund të vërehet se pothuajse të gjithë treguesit kanë përparuar pozitivisht, në krahasim me vitin bazë. Megjithatë, për disa tregues, progresi nuk ka qenë i mjaftueshëm për të arritur objektivat e vitit 2020. Plani i ri i veprimit të Strategjisë Kombëtare për Punësim dhe Aftësi 2019- 2022 ka rishikuar dhe adreson të gjithë objektivat dhe reformat e nisura me qëllim finalizimin e tyre si dhe rishikimin e politikave të parealizuara gjatë 4 viteve të zbatimit. Nga raporti i vlerësimit afatmesëm u vlerësua se të 4 prioritetet strategjikë të strategjisë, të zbatuara në vitet 2014-2018, janë ende relevante dhe nuk do të ndryshojnë në Strategjinë Kombëtare për Punësim dhe Aftësi 2019-2022."',
  iFrameEN: '',
  iFrameSQ: '',
  footnoteEN: '',
  footnoteSQ: '',
  source: '',
  isPublic: true,
  type: ThemeType.CUSTOM,
};
