import * as React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import IndicatorRelated from './IndicatorRelated';
import IndicatorFilters from './IndicatorFilters';
import Subheader from './IndicatorSubheader/IndicatorSubheader';
import { Spin } from 'antd';
import { getOneIndicatorByIdOrKey } from 'api/public';
import { IndicatorPageContext } from './store/context';
import i18n from 'i18next';
import GraphsContainer from './GraphsContainer';
import './style.css';

const IndicatorPage = () => {
  const indicatorKey = useParams<{ indicatorKey: string }>().indicatorKey;
  const [frequencyValue, setFrequencyValue] = React.useState<string>('quarter');
  const [unitTypeValue, setUnitTypeValue] = React.useState<string>('cumulative');
  const [allAvailableFilters, setAllAvailableFilters] = React.useState<[]>([]);
  const [startYearFilter, setStartYearFilter] = React.useState<number | string>();
  const [endYearFilter, setEndYearFilter] = React.useState<number | string>();

  const contextValue = {
    frequency: frequencyValue,
    unitType: unitTypeValue,
    allAvailableFilters: allAvailableFilters,
    changeFrequency: setFrequencyValue,
    changeUnitType: setUnitTypeValue,
    changeAllAvailableFilters: setAllAvailableFilters,
    startYearFilter: startYearFilter,
    setStartYearFilter: setStartYearFilter,
    endYearFilter: endYearFilter,
    setEndYearFilter: setEndYearFilter,
  };

  React.useLayoutEffect(() => {
    window.scroll(0, 0);
  }, []);
  const {
    data: indicator,
    isError,
    isLoading,
  } = useQuery(['indicatorByKey', indicatorKey], () => getOneIndicatorByIdOrKey(indicatorKey), {
    refetchOnWindowFocus: false,
  });

  if (isError) {
    return (
      <div className="indicator-page-error">
        <p> {i18n.t('_INDICATORS._ERROR_INDICATOR')}</p>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="indicator-page-spinner">
        <Spin size="large" />
        <p> {i18n.t('_INDICATORS._LOADING_INDICATOR')}</p>
      </div>
    );
  }

  return (
    <IndicatorPageContext.Provider value={contextValue} key={`index-${indicator?.id}`}>
      {indicator && <Subheader indicatorKey={indicator.key} />}
      {indicator && <IndicatorFilters indicator={indicator} />}
      <div className="indicator-content">
        <div className="indicator-graphs">
          <GraphsContainer indicator={indicator} />
        </div>
        {indicator && <IndicatorRelated indicator={indicator} />}
      </div>
    </IndicatorPageContext.Provider>
  );
};

export default IndicatorPage;
