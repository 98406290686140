import jwt_decode from 'jwt-decode';
import { getAuthData } from 'utils/tokenMgmt';
import i18next from 'locales/index';
import { Role } from 'models/user';
import { Role as InstitutionUserRole } from 'models/institution-user';
import { useTranslation } from 'react-i18next';
import { QueryCache } from 'react-query';
import { Indicator } from 'models/indicator';

export const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/;

export const isCurrentUserAdmin = () => {
  const user = getUserPayload();
  return user?.role === Role.ADMIN;
};

export const isInstitutionUser = () => {
  const user = getUserPayload();
  return user?.role === Role.INSTITUTION_USER;
};

export const isNormalUser = () => {
  const user = getUserPayload();
  return user?.role === Role.NORMAL;
};

export const isInstitutionUserDirector = (institutionUser) => {
  return institutionUser?.role === InstitutionUserRole.DIRECTOR;
};

export const getUserPayload = () => {
  try {
    const token = getAuthData();
    const decoded: any = jwt_decode(token);
    return decoded;
  } catch (err) {
    console.log(err);
  }
};

export const getCacheData = (queryCacheInstance: QueryCache) => {
  const allQueries = queryCacheInstance.findAll();
  const allResults = {};
  allQueries.forEach((query: any) => {
    if (Array.isArray(query.state.data)) {
      const key = query.queryKey.toString();
      allResults[key.replace('Indicators', ' indicators')] = query.state.data;
      allResults[key] = query.state.data;
    }
  });

  return allResults;
};

export const isSessionActive = () => {
  const decoded = getUserPayload();
  return decoded?.exp && decoded.exp > new Date().getSeconds();
};

// needs to be updated after work is done on resolved language(i18next)
export const getCurrentLng = (): string => {
  return i18next.resolvedLanguage?.toUpperCase() || 'EN';
};

export const useTranslationTerm = (term: string) => (title: string) => {
  const { t } = useTranslation();
  return t(`${term.toUpperCase()}.${title.toUpperCase()}`);
};

export const getBase64 = (file): Promise<string | ArrayBuffer | null> => {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result);
    };

    reader.onerror = reject;

    reader.readAsDataURL(file);
  });
};

export const formatDate = (dt: string) => {
  const lang = getCurrentLng();
  if (!dt) {
    return null;
  }
  const date = new Intl.DateTimeFormat('en-GB').format(new Date(dt));
  const time = new Intl.DateTimeFormat('en-GB', { timeStyle: 'short' }).format(new Date(dt));
  return lang === 'EN' ? `${date} at ${time}` : `${date} në ${time}`;
};

export const indicatorsToHide = [
  'pupils_enrolled_us',
  'pupils_enrolled',
  'pupils_graduated',
  'pupils_graduated_us',
  'gender_pay_gap',
  'average_monthly_wage',
  'long_term_unemployment',
  'registered_unemployment',
  'gdp_epp',
  'almp_job',
  'working_age_population',
  'jobseekers_employment_epp',
  'jobseekers_epp',
  'part_time_employment',
  'employment_public_sector',
  'employment_by_sectors',
  'temporary_employees',
  'ratio_employ',
  'staff_ness',
  'life_long_learning',
  'youth_employment_rate',
  'self_employed',
  'vet_graduates_employment',
  'Vepra Penale ',
  'deficit_finanncing',
  'llogaria_korrente',
  'narcotics',
  'deaths_mshms',
  'sip_ujitshme',
  'students_rome',
];
