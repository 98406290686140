import React, { useContext, useEffect } from 'react';
import { Button, Select } from 'antd';
import { FilterOutlined } from '@ant-design/icons';
import { Tabs, Menu, Dropdown } from 'antd';
import { useQuery } from 'react-query';
import { getIndicatorFilters, getIndicatorValues } from 'api/public';
import { getIndicatorVariables } from 'api/indicator';
import { getAvailableFilters } from 'pages/Explorer/components/Indicator/components/helpers';
import { getCurrentLng } from 'utils/helper';
import { IndicatorPageContext } from './store/context';
import i18next from 'i18next';
import {
  translateFrequencyUnitType,
  getStartYearLabel,
  getEndYearLabel,
  generateListOfYears,
  getStartYear,
  getEndYear,
} from './helpers';
import './style.css';
const { Option } = Select;
const { TabPane } = Tabs;

const IndicatorFilters = ({ indicator }) => {
  const indicatorContext = useContext(IndicatorPageContext);
  const currentLng = getCurrentLng();
  const { data: filters } = useQuery([`getIndicatorFilters-${indicator.id}`], () => getIndicatorFilters(indicator.id));
  const { data: generalVariables } = useQuery(['getIndicatorVariables', indicator.id], getIndicatorVariables);
  const availableFilters = filters && generalVariables ? getAvailableFilters(filters, generalVariables) : [];
  const { data: indicators } = useQuery(
    ['getIndicatorValues', indicator?.key, indicatorContext.frequency, indicatorContext.unitType],
    () =>
      getIndicatorValues(
        indicator?.id || 0,
        indicatorContext.frequency,
        indicatorContext.unitType,
        `${new Date().getFullYear() - 100}-01-01`,
        new Date().toISOString().split('T')[0]
      )
  );

  const { startYearFilter, endYearFilter } = indicatorContext;
  const { listOfYearsOfGraphic, filteredStartYears, filteredEndYears } = generateListOfYears(
    indicators,
    startYearFilter,
    endYearFilter
  );
  const startYear = getStartYear(startYearFilter, listOfYearsOfGraphic);
  const endYear = getEndYear(endYearFilter, listOfYearsOfGraphic);
  const startYearLabel = getStartYearLabel(startYear);
  const endYearLabel = getEndYearLabel(endYear);

  const frequencyMenu = (
    <Menu className="filters-dropdown">
      {filters?.frequencies.map((filter) => {
        const selected = indicatorContext.frequency === filter;
        return (
          <Menu.Item
            style={{ color: '#0e9898', fontWeight: 400, background: `${selected ? '#fcf7f7' : ''}` }}
            key={filter}
            onClick={() => indicatorContext.changeFrequency(filter)}>
            {translateFrequencyUnitType(filter).toUpperCase()}
          </Menu.Item>
        );
      })}
    </Menu>
  );

  const unitTypeMenu = (
    <Menu>
      {filters?.unitTypes.map((filter) => {
        const selected = indicatorContext.unitType === filter;
        return (
          <Menu.Item
            style={{ color: '#0e9898', fontWeight: 500, background: `${selected ? '#fcf7f7' : ''}` }}
            key={filter}
            onClick={() => indicatorContext.changeUnitType(filter)}>
            {translateFrequencyUnitType(filter).toUpperCase()}
          </Menu.Item>
        );
      })}
    </Menu>
  );

  // Send all the available filters to subscribers via context
  useEffect(() => {
    if (availableFilters && indicatorContext.allAvailableFilters) {
      indicatorContext.changeAllAvailableFilters(availableFilters);
    }
  }, [JSON.stringify(availableFilters)]);

  // Set the default unit type and frequency, and send that to the all subscribers via context
  useEffect(() => {
    if (filters && generalVariables) {
      if (filters.unitTypes.length > 0) {
        indicatorContext.changeUnitType(filters.defaultUnitType);
      }
      if (filters.frequencies.length > 0) {
        indicatorContext.changeFrequency(filters.defaultFrequency);
      }
    }
  }, [filters, generalVariables]);

  return (
    <div className="indicator-filters-wrapper">
      <div className="button-filters">
        <div className="dropdown-button-wrapper">
          <Dropdown overlay={frequencyMenu}>
            <Button>
              <FilterOutlined className="dropdown-filter" />
              <div className="btn-filter-title">
                {i18next.t('_FILTERS._FREQUENCY')}
                {' : '}
                <span>{translateFrequencyUnitType(indicatorContext.frequency).toUpperCase()}</span>
              </div>
            </Button>
          </Dropdown>

          <Dropdown overlay={unitTypeMenu}>
            <Button>
              <FilterOutlined className="dropdown-filter" />
              <div className="btn-filter-title">
                {i18next.t('_FILTERS._UNIT_TYPE')}
                {' : '}
                <span>{translateFrequencyUnitType(indicatorContext.unitType).toUpperCase()}</span>
              </div>
            </Button>
          </Dropdown>
        </div>
        {!!(listOfYearsOfGraphic && indicators && startYear && endYear) && (
          <div className="year-filters">
            <Select
              className="start-year"
              value={startYearLabel}
              onChange={(selectedYear) => indicatorContext.setStartYearFilter(selectedYear)}>
              {filteredStartYears.map((year) => {
                return (
                  <Option
                    className="start-year-option"
                    key={year}
                    value={year}
                    style={{
                      color: '#0E9898',
                      fontWeight: year === startYear ? 'bold' : 'normal',
                    }}>
                    {year}
                  </Option>
                );
              })}
            </Select>

            <Select
              className="end-year"
              value={endYearLabel}
              onChange={(selectedYear) => indicatorContext.setEndYearFilter(selectedYear)}>
              {filteredEndYears.reverse().map((year) => {
                return (
                  <Option
                    className="end-year-option"
                    key={year}
                    value={year}
                    style={{
                      color: '#0E9898',
                      fontWeight: year === endYear ? 'bold' : 'normal',
                    }}>
                    {year}
                  </Option>
                );
              })}
            </Select>
          </div>
        )}
      </div>
      <div className="indicator-tab-filters">
        <Tabs
          style={{ padding: '0 6px' }}
          onChange={(key) => {
            window.location.href = '#' + key.toLowerCase().replace(/ /g, '');
          }}>
          <TabPane className="filters-tabpane" key={'total'} tab={'Total'} />
          {availableFilters.map((object) => {
            return <TabPane className="filters-tabpane" key={object.key} tab={`${object[currentLng.toLowerCase()]}`} />;
          })}
        </Tabs>
      </div>
    </div>
  );
};

export default IndicatorFilters;
