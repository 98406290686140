import React, { memo, useEffect, useState } from 'react';
import IndicatorChartCard from '../common/IndicatorChartCard/IndicatorChartCard';
import { Checkbox, Input, Drawer } from 'antd';
import { getCurrentLng } from 'utils/helper';
import i18n from 'i18next';
import './relatedIndicators.css';
import { ModuleWithID } from 'models/module';
import { Indicator } from 'models/indicator';
import noData from 'common/images/NoData/noData.png';
import { FilterFilled, SearchOutlined } from '@ant-design/icons';
import { CheckboxChangeEvent } from 'antd/lib/checkbox/Checkbox';
import i18next from 'i18next';
import bufferToImage from 'utils/bufferToImage';
import useWindowDimensions from 'common/hooks/useWindowDimensions';
import { addCommas } from '../Indicator/components/helpers';
import { useTranslation } from 'react-i18next';

interface RelatedIndicatorProps {
  title?: string; //used for reusability in modal component
  module: ModuleWithID;
  allIndicators?: Indicator[];
  submodules: any[];
}

const RelatedIndicators: React.FunctionComponent<RelatedIndicatorProps> = ({ title, allIndicators, submodules }) => {
  const currLang = getCurrentLng();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { width } = useWindowDimensions();
  const { t } = useTranslation();
  //handle search state
  const [searchValue, setSearchValue] = useState<string | undefined>(undefined);
  //handle checkbox group state
  const [checkboxGroupVal, setcheckboxGroupVal] = useState<string[]>(submodules?.map((val) => String(val.id)));

  //skip [,(,),* etc beacuse of Unterminated character class  RegExp
  const chars = ['(', ')', '[', ']', '*', '=', '+', `\\`, '?'];
  const includesChars = chars.some((char) => searchValue?.includes(char));

  //searchRegex
  let searchReg;
  if (searchValue && !includesChars) {
    searchReg = new RegExp(searchValue.trim().toLowerCase(), 'gi');
  }

  useEffect(() => {
    setcheckboxGroupVal(submodules?.map((val) => String(val.id)));
  }, submodules);

  const generateFilteredIndicators = (): Indicator[] | any | undefined => {
    //if nothing selected from subcategories, return empty array
    return allIndicators;
    // const filteredIndicatorsArr = checkboxGroupVal.map((val) => {
    //   return allIndicators?.filter((indicator) => indicator.submoduleId === Number(val));
    // });
    // return filteredIndicatorsArr.flat().filter((x) => x);
  };
  const filteredIndicators = generateFilteredIndicators();
  const currentIndicators = filteredIndicators?.slice(0, 5);

  //handle onChangeSearch
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  //filter indicator function based on search value
  const filterSearchIndicators = (indicators: Indicator[]) => {
    return indicators?.filter((indicator) => {
      const nameFound = indicator?.[`name${currLang}`].trim().toLowerCase().match(searchReg)?.length;
      const keyFound = indicator?.key?.trim().toLowerCase().match(searchReg)?.length;
      return nameFound || keyFound;
    });
  };

  //filters

  const filters = (
    <div className="indicatorsCharts-filters">
      <span style={{ marginTop: '0px' }} className="subcategory-filter">
        {i18n.t('_INDICATORS._FILTER')}
      </span>
      <section>
        <Checkbox.Group className="indicators-checkbox" value={checkboxGroupVal}>
          {submodules === undefined && <span className="no-subcategories-modal">{i18next.t('_COMMON._NO_DATA')}</span>}
          {submodules?.map((submodule) => {
            return (
              <Checkbox
                key={submodule?.id}
                onChange={(e) => handleChecked(e)}
                value={submodule.id.toString()}
                style={{ marginLeft: '8px', marginTop: '15px' }}>
                {submodule[`name${currLang}`]}
              </Checkbox>
            );
          })}
        </Checkbox.Group>
      </section>
    </div>
  );

  //search content as JSX
  const searchingContent = (
    <>
      {/**search value for inital indicators- no subcategory checked */}
      {searchValue && searchReg && (
        <>
          {filterSearchIndicators(filteredIndicators)
            ?.slice(0, 5)
            .map((filteredIndicator) => {
              const unit = filteredIndicator?.last_value?.unit;
              const value = filteredIndicator?.last_value?.value || 0;
              const valueFormatted =
                unit === 'number' ? addCommas(value.toString()) : unit === 'percentage' ? value + ' %' : '';
              return (
                <IndicatorChartCard
                  image={bufferToImage(filteredIndicator?.graph_image?.data)}
                  name={filteredIndicator?.[`name${currLang}`]}
                  indicatorId={filteredIndicator?.id}
                  lastValueFormatted={valueFormatted}
                />
              );
            })}

          {filterSearchIndicators(filteredIndicators)?.length === 0 && (
            <div className="modal-search">
              <img src={noData} />
            </div>
          )}
        </>
      )}
    </>
  );

  //handle checkbox click
  const handleChecked = (e: CheckboxChangeEvent) => {
    //if already checked, unchecked
    if (checkboxGroupVal.includes(e.target.value)) {
      const filteredArray = checkboxGroupVal.filter((val) => val !== e.target.value);
      setcheckboxGroupVal(filteredArray);
    } else {
      setcheckboxGroupVal([...checkboxGroupVal, e.target.value]);
    }
  };

  return (
    <div>
      <div className="related-indicators-wrapper">
        <div className="header">
          <h1>{title} </h1>
          {/* {width < 700 && (
            <span role="button" onClick={() => setDrawerOpen(true)}>
              <span className="filt">
                {t('_COMMON._FILTER')} <FilterFilled />
              </span>
            </span>
          )} */}
          <div className="indicator-search-btn">
            <Input
              placeholder={i18n.t('_INDICATORS._SEARCH_INDICATOR')}
              value={searchValue}
              suffix={<SearchOutlined />}
              allowClear
              onChange={handleSearch}
              disabled={currentIndicators?.length == 0}
              style={{ padding: width > 700 ? '10px' : '5px', borderRadius: width > 700 ? '20px' : '10px' }}
            />
          </div>
        </div>
        <div className="indicatorsCharts-wrapper">
          {/* {width > 700 && filters} */}
          <Drawer
            className="reports-drawer"
            placement="right"
            visible={drawerOpen}
            onClose={() => setDrawerOpen(false)}
            width="100%"
            style={{ zIndex: 323435346 }}>
            {/* {filters} */}
            <span className="reset-filt" onClick={() => setcheckboxGroupVal(submodules?.map((val) => String(val.id)))}>
              Reset
            </span>
          </Drawer>

          <div className="indicatorsCharts">
            <div style={{ padding: '20px', paddingTop: '5px' }} className="pagination-wrapper">
              {!searchValue && currentIndicators?.length === 0 && (
                <div className="modal-search">
                  <img src={noData} />
                </div>
              )}
              {!searchValue
                ? currentIndicators?.map((indicator) => {
                    const unit = indicator?.last_value?.unit;
                    const value = indicator?.last_value?.value || 0;
                    const valueFormatted =
                      unit === 'number' ? addCommas(value.toString()) : unit === 'percentage' ? value + ' %' : '';

                    return (
                      <IndicatorChartCard
                        key={indicator.id}
                        name={indicator?.[`name${currLang}`]}
                        indicatorId={indicator?.id}
                        lastValueFormatted={valueFormatted}
                        image={bufferToImage(indicator?.graph_image?.data)}
                      />
                    );
                  })
                : searchingContent}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(RelatedIndicators);
