import { UpdateUser, User, UserWithId } from './../models/user';
import { apiUrl, get, post, put, remove } from 'utils/apiMgmt';

export const getUsers = async (): Promise<UserWithId[]> => {
  const { data } = await get(apiUrl + '/users');
  return data;
};

export const getCurrentUser = async (userId: number): Promise<User> => {
  const { data } = await get(apiUrl + `/users/${userId}`);
  return data;
};

export const createUser = async (user: User): Promise<User> => {
  const { data } = await post(apiUrl + '/users', user);
  return data;
};

export const deleteUser = async (userId: number): Promise<User> => {
  const { data } = await remove(apiUrl + '/users/' + userId);
  return data;
};

export const updateUser = async ({ userId, ...payload }: UpdateUser): Promise<User> => {
  const { data } = await put(apiUrl + '/users/' + userId, payload);
  return data;
};

export const resendInvite = async (userId: number): Promise<boolean> => {
  await post(apiUrl + '/users/resend-invite', { userId });
  return true;
};
