import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledLink } from './styled';
import { isCurrentUserAdmin, isInstitutionUser } from 'utils/helper';
import { Menu } from 'antd';
import { useLocation, useParams } from 'react-router-dom';

const translationBasePath = '_DASHBOARD._SIDEBAR';

const AdminSidebar: React.FunctionComponent = () => {
  const isAdmin = isCurrentUserAdmin();

  const isAdminrOrInstitutionUser = isCurrentUserAdmin() || isInstitutionUser();
  const { t } = useTranslation();
  const location = useLocation();
  const params: any = useParams();
  const MenuItem: FC<any> = ({ key, children, ...props }) => {
    const params: any = useParams();
    return (
      <Menu.Item key={`${params.locale ? `/${params.locale}` : ''}${key}`} {...props}>
        {children}
      </Menu.Item>
    );
  };

  return (
    <Menu
      selectedKeys={[location.pathname]}
      mode="horizontal"
      style={{
        padding: '2em 0',
        borderBottom: '0px',
        marginLeft: -20,
        fontWeight: 600,
        letterSpacing: '1.3px',
        fontSize: '17px ',
      }}>
      <>
        {isAdmin && (
          <MenuItem key={`${params.locale ? `/${params.locale}` : ''}/dashboard/users`}>
            <StyledLink to="/dashboard/users">{t(`${translationBasePath}._MANAGE_USERS`)}</StyledLink>
          </MenuItem>
        )}
        {isAdmin && (
          <MenuItem key={`${params.locale ? `/${params.locale}` : ''}/dashboard/indicators`}>
            <StyledLink to="/dashboard/indicators">{t(`${translationBasePath}._MANAGE_INDICATORS`)}</StyledLink>
          </MenuItem>
        )}
        {isAdmin && (
          <MenuItem key={`${params.locale ? `/${params.locale}` : ''}/dashboard/modules`}>
            <StyledLink to="/dashboard/modules">{t(`${translationBasePath}._MANAGE_MODULES`)}</StyledLink>
          </MenuItem>
        )}
        {isAdmin && (
          <MenuItem key={`${params.locale ? `/${params.locale}` : ''}/dashboard/publications`}>
            <StyledLink to="/dashboard/publications">{t(`${translationBasePath}._MANAGE_PUBLICATIONS`)}</StyledLink>
          </MenuItem>
        )}
        {isAdminrOrInstitutionUser && (
          <MenuItem key={`${params.locale ? `/${params.locale}` : ''}/dashboard/indicator-values`}>
            <StyledLink to="/dashboard/indicator-values">
              {t(`${translationBasePath}._MANAGE_INDICATOR_VALUES`)}
            </StyledLink>
          </MenuItem>
        )}
        {isAdmin && (
          <MenuItem key={`${params.locale ? `/${params.locale}` : ''}/dashboard/themes`}>
            <StyledLink to="/dashboard/themes">{t(`${translationBasePath}._MANAGE_THEMES`)}</StyledLink>
          </MenuItem>
        )}

        {isAdminrOrInstitutionUser && (
          <MenuItem key={`${params.locale ? `/${params.locale}` : ''}/dashboard/institutions`}>
            <StyledLink to="/dashboard/institutions">{t(`${translationBasePath}._MANAGE_INSTITUTIONS`)}</StyledLink>
          </MenuItem>
        )}
        {isAdmin && (
          <MenuItem key={`${params.locale ? `/${params.locale}` : ''}/dashboard/logs`}>
            <StyledLink to="/dashboard/logs"> {t('_DASHBOARD._MANAGE_LOGS._LOGS')}</StyledLink>
          </MenuItem>
        )}
      </>
    </Menu>
  );
};

export default AdminSidebar;
