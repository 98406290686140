import React, { useContext } from 'react';
import { useQuery } from 'react-query';
import LineGraph from './Graphs/Line';
import Map from './Graphs/Map';
import Bar from './Graphs/Bar';
import { getIndicatorValues } from 'api/public';
import { IndicatorPageContext } from './store/context';
import { getCurrentLng } from 'utils/helper';
import i18n from 'i18next';
import noDataimg from 'common/images/NoData/noData.png';
import {
  filterGeneralGraphData,
  filterGraphData,
  filterXAxisByYear,
  generateFinalResult,
  getIndicatorStartAndEndYear,
  returnUniqueObj,
} from './helpers';
import { Spin } from 'antd';
import {
  generateSeries,
  getCurrentActiveFilter,
  getInitialStateForFilters,
  sortDataByTwoValues,
} from 'pages/Explorer/components/Indicator/components/helpers';

const GraphsContainer = ({ indicator }) => {
  const lang = getCurrentLng();
  const { frequency, unitType, allAvailableFilters, startYearFilter, endYearFilter } = useContext(IndicatorPageContext);
  const { data: result, isLoading } = useQuery(
    ['getIndicatorValues', indicator?.key, frequency, unitType],
    () =>
      getIndicatorValues(
        indicator?.id || 0,
        frequency,
        unitType,
        `${new Date().getFullYear() - 100}-01-01`,
        new Date().toISOString().split('T')[0]
      ),
    {
      enabled: !!indicator?.id,
      refetchOnWindowFocus: false,
    }
  );

  const finalResult = result ? generateFinalResult(allAvailableFilters, result) : [];

  /**
   * Prepare data for the first graph
   */
  const intitialVariables = getInitialStateForFilters(allAvailableFilters);

  const { series: initialSerie, xAxis } = generateSeries(sortDataByTwoValues(result), intitialVariables);

  const activeFilter = getCurrentActiveFilter(intitialVariables, allAvailableFilters);

  const { startYear, endYear } = getIndicatorStartAndEndYear(startYearFilter, endYearFilter, xAxis);
  const filteredFinalResult = filterGraphData(finalResult, startYear, endYear);

  if (isLoading && !startYear && !endYear) {
    return (
      <div className="indicator-page-spinner">
        <Spin size="large" />
        <p> {i18n.t('_INDICATORS._LOADING_INDICATOR')}</p>
      </div>
    );
  }

  if (filteredFinalResult.length === 0 && initialSerie.length === 0) {
    return (
      <div className="indicator-page-no-data">
        <img src={noDataimg} />
        <p style={{ display: 'grid', placeContent: 'center', height: 100, fontWeight: 600 }}>
          {i18n.t('_INDICATORS._NO_DATA_INDICATOR')}
        </p>
      </div>
    );
  }

  return (
    <>
      <LineGraph
        key={'-'}
        isTotal
        indicator={indicator}
        filter={activeFilter}
        data={[
          {
            option: 'Total',
            values: filterGeneralGraphData(startYear, endYear, initialSerie[0]),
          },
        ]}
        xAxis={filterXAxisByYear(xAxis, startYear, endYear)}
        frequency={frequency}
        unitType={unitType}
      />
      {filteredFinalResult?.map((result, index) => {
        switch (result.filter?.graph_type) {
          case 'line':
            if (result.xAxis && result.xAxis?.length > 0) {
              return (
                <LineGraph
                  key={index}
                  indicator={indicator}
                  filter={result.filter}
                  data={result.data}
                  xAxis={result.xAxis}
                  frequency={frequency}
                  unitType={unitType}
                />
              );
            } else return;
          case 'map':
            //if it is a graph type of map but has only 'total' data, do not show the map
            if (result.data?.filter((dt) => dt.option !== 'total')?.length || 0 > 0) {
              return (
                <>
                  <Map
                    key="map"
                    indicator={indicator}
                    filter={result.filter}
                    data={returnUniqueObj(result.data?.filter((dt) => dt.option !== 'total'))}
                    xAxis={result.xAxis}
                    frequency={frequency}
                    unitType={unitType}
                    indicatorName={indicator?.[`name${lang}`]}
                  />
                  {/**Even though graph type=="map", the data can be used for bar chart */}
                  <Bar
                    indicator={indicator}
                    key="bar"
                    filter={result.filter}
                    data={returnUniqueObj(result.data?.filter((dt) => dt.option !== 'total'))}
                    xAxis={result.xAxis}
                    frequency={frequency}
                    unitType={unitType}
                    indicatorName={indicator?.[`name${lang}`]}
                  />
                </>
              );
            } else {
              return;
            }
          default:
            return <></>;
        }
      })}
    </>
  );
};
export default GraphsContainer;
