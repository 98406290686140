export enum Role {
  DIRECTOR = 'director',
  NORMAL = 'normal',
}

export interface InstitutionUser {
  id?: number;
  user?: any;
  institution?: any;
  userId: number;
  role: Role;
  institutionId: number;
  createdAt?: Date;
  updatedAt?: Date;
}
