import * as yup from 'yup';
import i18next from 'i18next';
export const translationBasePath = '_DASHBOARD._MANAGE_INSTITUTIONS';

export const validationSchema = (t) =>
  yup.object({
    nameEN: yup.string().required(t(`${translationBasePath}._NAME_EN_REQUIRED`)),
    nameSQ: yup.string().required(t(`${translationBasePath}._NAME_SQ_REQUIRED`)),
    acronymEN: yup.string().required(t(`${translationBasePath}._NAME_EN_REQUIRED`)),
    acronymSQ: yup.string().required(t(`${translationBasePath}._NAME_SQ_REQUIRED`)),
    url: yup
      .string()
      .matches(
        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
        i18next.t('_DASHBOARD._MANAGE_INSTITUTIONS._CORRECT_URL')
      )
      .required(i18next.t('_DASHBOARD._MANAGE_INSTITUTIONS._ENTER_WEBSITE')),
  });
