import * as React from 'react';

interface IndicatorContextInterface {
  frequency: string;
  unitType: string;
  selectedVariables: {};
  allAvailableFilters: {};
  startDate: string;
  endDate: string;
  changeFrequency: React.Dispatch<React.SetStateAction<string>>;
  changeUnitType: React.Dispatch<React.SetStateAction<string>>;
  changeSelectedVariables: React.Dispatch<React.SetStateAction<any>>;
  changeAllAvailableFilters: React.Dispatch<React.SetStateAction<any>>;
  changeStartDate: React.Dispatch<React.SetStateAction<string>>;
  changeEndDate: React.Dispatch<React.SetStateAction<string>>;
}

export const contextValue: IndicatorContextInterface = {
  frequency: '',
  unitType: '',
  startDate: '',
  endDate: '',
  selectedVariables: {},
  allAvailableFilters: {},
  changeFrequency: () => {},
  changeUnitType: () => {},
  changeSelectedVariables: () => {},
  changeAllAvailableFilters: () => {},
  changeEndDate: () => {},
  changeStartDate: () => {},
};

export const IndicatorContext = React.createContext<IndicatorContextInterface>(contextValue);
