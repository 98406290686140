import React from 'react';
import Button from 'common/components/Button/Button';
import Form from 'common/components/Form/Form';
import { useFormik } from 'formik';
import { validationSchema, translationBasePath } from './AddInstitutionFormValidation';
import { useTranslation } from 'react-i18next';
import Input from 'common/components/Input/Input';
import { useMutation } from 'react-query';
import { Institution } from 'models/institution';
import { createInstitution, updateInstitution } from 'api/institution';
import { notification } from 'antd';

interface Props {
  onClose: () => void;
  refetch: () => void;
  updating: Institution | null;
}

export interface IFormInitialValues {
  nameEN: string;
  nameSQ: string;
  acronymEN: string;
  acronymSQ: string;
  url: string;
}

const AddInstitutionForm: React.FunctionComponent<Props> = (props: Props) => {
  const { onClose, refetch, updating } = props;
  const { t } = useTranslation();
  const initialValues: IFormInitialValues = {
    nameEN: updating?.nameEN || '',
    nameSQ: updating?.nameSQ || '',
    acronymEN: updating?.acronymEN || '',
    acronymSQ: updating?.acronymSQ || '',
    url: updating?.url || '',
  };
  const mutationHook = updating ? useMutation(updateInstitution) : useMutation(createInstitution);
  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema(t),
    onSubmit: (values: Institution) => {
      const { mutateAsync } = mutationHook;
      const payload = updating ? { ...values, institutionId: updating.id } : values;
      mutateAsync(payload)
        .then(() => {
          onClose();
          formik.resetForm();
          refetch();
        })
        .catch((err) => {
          notification.error({ message: err.message, description: err.description });
          formik.resetForm();
        });
    },
  });

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Input
        name="nameEN"
        label={t(`${translationBasePath}._NAMEEN`)}
        value={formik.values.nameEN}
        onChange={formik.handleChange}
        error={formik.errors.nameEN}
      />
      <Input
        name="nameSQ"
        label={t(`${translationBasePath}._NAMESQ`)}
        value={formik.values.nameSQ}
        onChange={formik.handleChange}
        error={formik.errors.nameSQ}
      />
      <Input
        name="acronymEN"
        label={t(`${translationBasePath}._ACRONYMEN`)}
        value={formik.values.acronymEN}
        onChange={formik.handleChange}
        error={formik.errors.acronymEN}
      />
      <Input
        name="acronymSQ"
        label={t(`${translationBasePath}._ACRONYMSQ`)}
        value={formik.values.acronymSQ}
        onChange={formik.handleChange}
        error={formik.errors.acronymSQ}
      />
      <Input
        name="url"
        label={t(`${translationBasePath}._URL`)}
        value={formik.values.url}
        onChange={formik.handleChange}
        error={formik.errors.url}
      />
      <Button loading={mutationHook.isLoading} htmlType="submit">
        {updating
          ? t(`${translationBasePath}._UPDATE_INSTITUTION_BTN`)
          : t(`${translationBasePath}._CREATE_INSTITUTION`)}
      </Button>
    </Form>
  );
};

export default AddInstitutionForm;
