export const messageTranslationEN = (userEmail: string) => ({
  'POST /auth/login 200': `User ${userEmail} logged in successfully`,
  'POST /auth/login 401': `User ${userEmail} failed to log in`,
  'POST /auth/login 500': `User ${userEmail} failed to log in`,
  'POST /auth/sms-authentication 200': `User ${userEmail} passed successfully sms authentication`,
  'POST /auth/sms-authentication 401': `User ${userEmail} failed sms authentication`,
  'POST /auth/sms-authentication 500': `User ${userEmail} failed sms authentication`,
  'POST /auth/forgot-password 200': `User ${userEmail}  failed to initiate forgot password successfully`,
  'POST /auth/forgot-password 401': `User ${userEmail} failed to initiate forgot password`,
  'POST /auth/forgot-password 500': `User ${userEmail} failed to initiate forgot password`,
  'POST /auth/reset-password 200': `User ${userEmail} initiated to reset password successfully`,
  'POST /auth/reset-password 401': `User ${userEmail} failed to reset password`,
  'POST /auth/reset-password 500': `User ${userEmail} failed to reset password`,
  //Themes
  'POST /themes 201': `User ${userEmail} created a new theme successfully`,
  'POST /themes 400': `User ${userEmail} failed to create a new theme`,
  'POST /themes 500': `User ${userEmail} failed to create a new theme`,
  'PUT /themes/:key 200': `User ${userEmail} updated theme successfully`,
  'PUT /themes/:key 403': `User ${userEmail} tried to update theme unsuccessfully`,
  'PUT /themes/:key 404': `User ${userEmail} tried to update theme unsuccessfully`,
  'PUT /themes/:key 500': `User ${userEmail} tried to update theme unsuccessfully`,
  'DELETE /themes/:key 204': `User ${userEmail} deleted theme successfully`,
  'DELETE /themes/:key 403': `User ${userEmail} tried to delete theme unsuccessfully`,
  'DELETE /themes/:key 404': `User ${userEmail} tried to delete theme unsuccessfully`,
  'DELETE /themes/:key 500': `User ${userEmail} tried to delete theme unsuccessfully`,
  //Theme Users
  'POST /themes/:themeId/users 201': `User ${userEmail} created a new theme user successfully`,
  'POST /themes/:themeId/users 403': `User ${userEmail} tried to create a new theme user unsuccessfully`,
  'POST /themes/:themeId/users 500': `User ${userEmail} tried to create a new theme user unsuccessfully`,
  'DELETE /themes/:themeId/users/:userId 204': `User ${userEmail} deleted theme user successfully`,
  'DELETE /themes/:themeId/users/:userId 403': `User ${userEmail} tried to delete theme user unsuccessfully`,
  'DELETE /themes/:themeId/users/:userId 500': `User ${userEmail} tried to delete theme user unsuccessfully`,
  //Institutions
  'POST /institutions 201': `User ${userEmail} created a new institution successfully`,
  'PUT /institutions/:id 200': `User ${userEmail} updated an institution successfully`,
  'PUT /institutions/:id 403': `User ${userEmail} tried to update an institution unsuccessfully`,
  'PUT /institutions/:id 404': `User ${userEmail} tried to update an institution unsuccessfully`,
  'PUT /institutions/:id 500': `User ${userEmail} tried to update an institution unsuccessfully`,
  'DELETE /institutions/:id 204': `User ${userEmail} deleted an institution successfully`,
  'DELETE /institutions/:id 403': `User ${userEmail} tried to delete an institution unsuccessfully`,
  'DELETE /institutions/:id 404': `User ${userEmail} tried to delete an institution unsuccessfully`,
  'DELETE /institutions/:id 500': `User ${userEmail} tried to delete an institution unsuccessfully`,
  //Institutions User
  'POST /institutions/user/create 201': `User ${userEmail} created a new institution user successfully`,
  'POST /institutions/user/create 500': `User ${userEmail} tried to create a new institution user unsuccessfully`,
  'DELETE /institutions/user/institutionUserId 204': `User ${userEmail} deleted an institution user successfully`,
  'DELETE /institutions/user/institutionUserId 403': `User ${userEmail} tried to delete an institution user unsuccessfully`,
  'DELETE /institutions/user/institutionUserId 404': `User ${userEmail} tried to delete an institution user unsuccessfully`,
  'DELETE /institutions/user/institutionUserId 500': `User ${userEmail} tried to delete an institution user unsuccessfully`,
  //Indicators
  'POST /indicators 201': `User ${userEmail} created a new indicator successfully`,
  'POST /indicators 400': `User ${userEmail} tried to create a new indicator unsuccessfully`,
  'POST /indicators 403': `User ${userEmail} tried to create a new indicator unsuccessfully`,
  'POST /indicators 500': `User ${userEmail} tried to create a new indicator unsuccessfully`,
  'PUT /indicators/:id 200': `User ${userEmail} updated indicator successfully`,
  'PUT /indicators/:id 400': `User ${userEmail} tried to update indicator unsuccessfully`,
  'PUT /indicators/:id 403': `User ${userEmail} tried to update indicator unsuccessfully`,
  'PUT /indicators/:id 404': `User ${userEmail} tried to update indicator unsuccessfully`,
  'PUT /indicators/:id 500': `User ${userEmail} tried to update indicator unsuccessfully`,
  'DELETE /indicators/:id 200': `User ${userEmail} deleted indicator successfully`,
  'DELETE /indicators/:id 403': `User ${userEmail} tried to delete indicator unsuccessfully`,
  'DELETE /indicators/:id 404': `User ${userEmail} tried to delete indicator unsuccessfully`,
  'DELETE /indicators/:id 422': `User ${userEmail} tried to delete indicator unsuccessfully`,
  'DELETE /indicators/:id 500': `User ${userEmail} tried to delete indicator unsuccessfully`,
  //Indicator User
  'POST /indicators/:indicatorId/users 201': `User ${userEmail} changed the indicator access of a user sucessfully!`,
  'POST /indicators/:indicatorId/users 403': `User ${userEmail} tried to change the indicator access of a user unsuccessfully`,
  'POST /indicators/:indicatorId/users 404': `User ${userEmail} tried to change the indicator access of a user unsuccessfully`,
  'POST /indicators/:indicatorId/users 500': `User ${userEmail} tried to change the indicator access of a user unsuccessfully`,
  'DELETE /indicators/:indicatorId/users/:userId 204': `User ${userEmail} deleted an indicator user successfully`,
  'DELETE /indicators/:indicatorId/users/:userId 403': `User ${userEmail} tried to delete an indicator user unsuccessfully`,
  'DELETE /indicators/:indicatorId/users/:userId 404': `User ${userEmail} tried to delete an indicator user unsuccessfully`,
  'DELETE /indicators/:indicatorId/users/:userId 500': `User ${userEmail} tried to delete an indicator user unsuccessfully`,
  //Indicator Values
  'POST /indicator_values/:indicatorId/process-csv 201': `User ${userEmail} uploaded indicator values successfully `,
  // //file-upload
  // method: "POST"
  // route: "/file-upload/uploadFileToS3" =>Do we need to include it?
  // statusCode: 201
  //Briefs
  'POST /public/briefs 201': `User ${userEmail} created a new brief successfully`,
  'POST /public/briefs 400': `User ${userEmail} tried to create a new brief unsuccessfully`,
  'POST /public/briefs 500': `User ${userEmail} tried to create a new brief unsuccessfully`,
  'PUT /public/briefs/:query 200': `User ${userEmail} updated brief successfully`,
  'PUT /public/briefs/:query 400': `User ${userEmail} tried to update brief unsuccessfully`,
  'PUT /public/briefs/:query 404': `User ${userEmail} tried to update brief unsuccessfully`,
  'PUT /public/briefs/:query 500': `User ${userEmail} tried to update brief unsuccessfully`,
  'DELETE /public/briefs/:query 204': `User ${userEmail} deleted brief successfully`,
  'DELETE /public/briefs/:query 400': `User ${userEmail} tried to delete brief unsuccessfully`,
  'DELETE /public/briefs/:query 404': `User ${userEmail} tried to delete brief unsuccessfully`,
  'DELETE /public/briefs/:query 500': `User ${userEmail} tried to delete brief unsuccessfully`,
  //Users
  'POST /users 201': `User ${userEmail} created a new user successfully`,
  'POST /users 400': `User ${userEmail} tried to create a new user unsuccessfully`,
  'POST /users 403': `User ${userEmail} tried to create a new user unsuccessfully`,
  'POST /users 500': `User ${userEmail} tried to create a new user unsuccessfully`,
  'PUT /users/:id 200': `User ${userEmail} updated an user successfully`,
  'PUT /users/:id 400': `User ${userEmail} tried to update an user unsuccessfully`,
  'PUT /users/:id 403': `User ${userEmail} tried to update an user unsuccessfully`,
  'PUT /users/:id 404': `User ${userEmail} tried to update an user unsuccessfully`,
  'PUT /users/:id 500': `User ${userEmail} tried to update an user unsuccessfully`,
  'DELETE /users/:id 200': `User ${userEmail} deleted an user successfully`,
  'DELETE /users/:id 403': `User ${userEmail} tried to delete an user unsuccessfully`,
  'DELETE /users/:id 404': `User ${userEmail} tried to delete an user unsuccessfully`,
  'DELETE /users/:id 500': `User ${userEmail} tried to delete an user unsuccessfully`,
  //User Invite
  'POST /users/resend-invite 204': `User ${userEmail} resent invite to user successfully`,
  'POST /users/resend-invite 500': `User ${userEmail} tried to resent invite to user unsuccessfully`,
  //Modules
  'POST /modules 201': `User ${userEmail} created a new module successfully`,
  'POST /modules 400': `User ${userEmail} tried to create a new module unsuccessfully`,
  'POST /modules 403': `User ${userEmail} tried to create a new module unsuccessfully`,
  'POST /modules 500': `User ${userEmail} tried to create a new module unsuccessfully`,
  'PUT /modules/:id 200': `User ${userEmail} updated a module successfully`,
  'PUT /modules/:id 400': `User ${userEmail} tried to update a module unsuccessfully`,
  'PUT /modules/:id 403': `User ${userEmail} tried to update a module unsuccessfully`,
  'PUT /modules/:id 404': `User ${userEmail} tried to update a module unsuccessfully`,
  'PUT /modules/:id 500': `User ${userEmail} tried to update a module unsuccessfully`,
  'DELETE /modules/:id 200': `User ${userEmail} deleted a module successfully`,
  'DELETE /modules/:id 403': `User ${userEmail} tried to delete a module unsuccessfully`,
  'DELETE /modules/:id 404': `User ${userEmail} tried to delete a module unsuccessfully`,
  'DELETE /modules/:id 500': `User ${userEmail} tried to delete a module unsuccessfully`,
  //Submodules
  'POST /submodules 201': `User ${userEmail} created a new submodule successfully`,
  'POST /submodules 400': `User ${userEmail} tried to create a new submodule unsuccessfully`,
  'POST /submodules 403': `User ${userEmail} tried to create a new submodule unsuccessfully`,
  'POST /submodules 500': `User ${userEmail} tried to create a new submodule unsuccessfully`,
  'PUT /submodules/:id 200': `User ${userEmail} updated a submodule successfully`,
  'PUT /submodules/:id 403': `User ${userEmail} tried to update a submodule unsuccessfully`,
  'PUT /submodules/:id 400': `User ${userEmail} tried to update a submodule unsuccessfully`,
  'PUT /submodules/:id 404': `User ${userEmail} tried to update a submodule unsuccessfully`,
  'PUT /submodules/:id 500': `User ${userEmail} tried to update a submodule unsuccessfully`,
  'DELETE /submodules/:id 200': `User ${userEmail} deleted a submodule successfully`,
  'DELETE /submodules/:id 403': `User ${userEmail} tried to delete a submodule unsuccessfully`,
  'DELETE /submodules/:id 404': `User ${userEmail} tried to delete a submodule unsuccessfully`,
  'DELETE /submodules/:id 500': `User ${userEmail} tried to delete a submodule unsuccessfully`,
  //Publications
  'POST /publications 201': `User ${userEmail} created a new publication successfully`,
  'POST /publications 400': `User ${userEmail} tried to create a new publication unsuccessfully`,
  'POST /publications 403': `User ${userEmail} tried to create a new publication unsuccessfully`,
  'POST /publications 500': `User ${userEmail} tried to create a new publication unsuccessfully`,
  'PUT /publications/:id 200': `User ${userEmail} updated a publication successfully`,
  'PUT /publications/:id 400': `User ${userEmail} tried to update a publication unsuccessfully`,
  'PUT /publications/:id 403': `User ${userEmail} tried to update a publication unsuccessfully`,
  'PUT /publications/:id 404': `User ${userEmail} tried to update a publication unsuccessfully`,
  'PUT /publications/:id 500': `User ${userEmail} tried to update a publication unsuccessfully`,
  'DELETE /publications/:id 200': `User ${userEmail} deleted a åpublication successfully`,
  'DELETE /publications/:id 403': `User ${userEmail} tried to delete a publication unsuccessfully`,
  'DELETE /publications/:id 404': `User ${userEmail} tried to delete a publication unsuccessfully`,
  'DELETE /publications/:id 500': `User ${userEmail} tried to delete a publication unsuccessfully`,
});
export const messageTranslationSQ = (userEmail: string) => ({
  'POST /auth/login 200': `Perdoruesi ${userEmail} hyri me sukses`,
  'POST /auth/login 401': `Perdoruesi ${userEmail} tentoi të hyjë në sistem pa sukses`,
  'POST /auth/login 500': `Perdoruesi ${userEmail} tentoi të hyjë në sistem pa sukses`,
  'POST /auth/sms-authentication 200': `Perdoruesi ${userEmail} kaloi me sukses autentikimin e codit me sms`,
  'POST /auth/sms-authentication 401': `Perdoruesi ${userEmail} kaloi autentikimin e codit me sms pa sukses`,
  'POST /auth/sms-authentication 500': `Perdoruesi ${userEmail} kaloi autentikimin e codit me sms pa sukses`,
  'POST /auth/forgot-password 200': `Perdoruesi ${userEmail} nisi me sukses procesin e fjalekalimit te harruar`,
  'POST /auth/forgot-password 401': `Perdoruesi ${userEmail} tentoi te nisi pa sukses procesin e fjalekalimit te harruar`,
  'POST /auth/forgot-password 500': `Perdoruesi ${userEmail} tentoi te nisi pa sukses procesin e fjalekalimit te harruar`,
  'POST /auth/reset-password 200': `Perdoruesi ${userEmail} ndryshoi fjalekalimin me sukses`,
  'POST /auth/reset-password 401': `Perdoruesi ${userEmail} tentoi te ndryshoje fjalekalimin pa sukses`,
  'POST /auth/reset-password 500': `Perdoruesi ${userEmail} tentoi te ndryshoje fjalekalimin pa sukses`,
  //Themes
  'POST /themes 201': `Perdoruesi ${userEmail} krijoi nje teme te re me sukses`,
  'POST /themes 400': `Perdoruesi ${userEmail} tentoi te krijoje nje teme te re pa sukses`,
  'POST /themes 500': `Perdoruesi ${userEmail} tentoi te krijoje nje teme te re pa sukses`,
  'PUT /themes/:key 200': `Perdoruesi ${userEmail} perditesoi temen me sukses`,
  'PUT /themes/:key 403': `Perdoruesi ${userEmail} tentoi te perditesoi temen pa sukses`,
  'PUT /themes/:key 404': `Perdoruesi ${userEmail} tentoi te perditesoi temen pa sukses`,
  'PUT /themes/:key 500': `Perdoruesi ${userEmail} tentoi te perditesoi temen pa sukses`,
  'DELETE /themes/:key 204': `Perdoruesi ${userEmail} fshiu temen me sukses`,
  'DELETE /themes/:key 403': `Perdoruesi ${userEmail} tentoi te fshije temen pa sukses`,
  'DELETE /themes/:key 404': `Perdoruesi ${userEmail} tentoi te fshije temen pa sukses`,
  'DELETE /themes/:key 500': `Perdoruesi ${userEmail} tentoi te fshije temen pa sukses`,
  //Theme Users
  'POST /themes/:themeId/users 201': `Perdoruesi ${userEmail} krijoi nje perdorues teme te ri me sukses`,
  'POST /themes/:themeId/users 403': `Perdoruesi ${userEmail} tentoi te krijoje nje perdorues teme te ri pa sukses`,
  'POST /themes/:themeId/users 500': `Perdoruesi ${userEmail} tentoi te krijoje nje perdorues teme te ri pa sukses`,
  'DELETE /themes/:themeId/users/:userId 204': `Perdoruesi ${userEmail} fshiu nje perdorues teme me sukses`,
  'DELETE /themes/:themeId/users/:userId 403': `Perdoruesi ${userEmail} tentoi te fshije nje perdorues teme pa sukses`,
  'DELETE /themes/:themeId/users/:userId 500': `Perdoruesi ${userEmail} tentoi te fshije nje perdorues teme pa sukses`,
  //Institutions
  'POST /institutions 201': `Perdoruesi ${userEmail} krijoi nje institucion te ri me sukses`,
  'PUT /institutions/:id 200': `Perdoruesi ${userEmail} perditesoi nje institucion me sukses`,
  'PUT /institutions/:id 403': `Perdoruesi ${userEmail} tentoi te perditesoi nje institucion pa sukses`,
  'PUT /institutions/:id 404': `Perdoruesi ${userEmail} tentoi te perditesoi nje institucion pa sukses`,
  'PUT /institutions/:id 500': `Perdoruesi ${userEmail} tentoi te perditesoi nje institucion pa sukses`,
  'DELETE /institutions/:id 204': `Perdoruesi ${userEmail} fshiu nje institucion me sukses`,
  'DELETE /institutions/:id 403': `Perdoruesi ${userEmail} tentoi te fshije nje institucion pa sukses`,
  'DELETE /institutions/:id 404': `Perdoruesi ${userEmail} tentoi te fshije nje institucion pa sukses`,
  'DELETE /institutions/:id 500': `Perdoruesi ${userEmail} tentoi te fshije nje institucion pa sukses`,
  //Institutions User
  'POST /institutions/user/create 201': `Perdoruesi ${userEmail} krijoi nje perdorues institucioni me sukses`,
  'POST /institutions/user/create 500': `Perdoruesi ${userEmail} krijoi nje perdorues institucioni me sukses`,
  'DELETE /institutions/user/institutionUserId 204': `Perdoruesi ${userEmail} fshiu nje perdorues institucioni me sukses`,
  'DELETE /institutions/user/institutionUserId 403': `Perdoruesi ${userEmail} tentoi te fshije nje perdorues institucioni pa sukses`,
  'DELETE /institutions/user/institutionUserId 404': `Perdoruesi ${userEmail} tentoi te fshije nje perdorues institucioni pa sukses`,
  'DELETE /institutions/user/institutionUserId 500': `Perdoruesi ${userEmail} tentoi te fshije nje perdorues institucioni pa sukses`,
  //Indicators
  'POST /indicators 201': `Perdoruesi ${userEmail} krijoi nje indicator te ri me sukses`,
  'POST /indicators 400': `Perdoruesi ${userEmail} tentoi te krijoje nje indicator te ri pa sukses`,
  'POST /indicators 403': `Perdoruesi ${userEmail} tentoi te krijoje nje indicator te ri pa sukses`,
  'POST /indicators 500': `Perdoruesi ${userEmail} tentoi te krijoje nje indicator te ri pa sukses`,
  'PUT /indicators/:id 200': `Perdoruesi ${userEmail} perditesoi indicatorin me sukses`,
  'PUT /indicators/:id 400': `Perdoruesi ${userEmail} tentoi te perditesoi indicatorin pa sukses`,
  'PUT /indicators/:id 403': `Perdoruesi ${userEmail} tentoi te perditesoi indicatorin pa sukses`,
  'PUT /indicators/:id 404': `Perdoruesi ${userEmail} tentoi te perditesoi indicatorin pa sukses`,
  'PUT /indicators/:id 500': `Perdoruesi ${userEmail} tentoi te perditesoi indicatorin pa sukses`,
  'DELETE /indicators/:id 200': `Perdoruesi ${userEmail} fshiu indicatorin me sukses`,
  'DELETE /indicators/:id 403': `Perdoruesi ${userEmail} tentoi te fshije indicatorin pa sukses`,
  'DELETE /indicators/:id 404': `Perdoruesi ${userEmail} tentoi te fshije indicatorin pa sukses`,
  'DELETE /indicators/:id 422': `Perdoruesi ${userEmail} tentoi te fshije indicatorin pa sukses`,
  'DELETE /indicators/:id 500': `Perdoruesi ${userEmail} tentoi te fshije indicatorin pa sukses`,
  //Indicator User
  'POST /indicators/:indicatorId/users 201': `Perdoruesi ${userEmail} krijoi nje perdorues te ri te indicatorit me sukses`,
  'POST /indicators/:indicatorId/users 403': `Perdoruesi ${userEmail} tentoi te krijoje nje perdorues te ri te indicatorit pa sukses`,
  'POST /indicators/:indicatorId/users 404': `Perdoruesi ${userEmail} tentoi te krijoje nje perdorues te ri te indicatorit pa sukses`,
  'POST /indicators/:indicatorId/users 500': `Perdoruesi ${userEmail} tentoi te krijoje nje perdorues te ri te indicatorit pa sukses`,
  'DELETE /indicators/:indicatorId/users/:userId 204': `Perdoruesi ${userEmail} fshiu perdoruesin e indicatorit me sukses`,
  'DELETE /indicators/:indicatorId/users/:userId 403': `Perdoruesi ${userEmail} tentoi te fshije perdoruesin e indicatorit pa sukses`,
  'DELETE /indicators/:indicatorId/users/:userId 404': `Perdoruesi ${userEmail} tentoi te fshije perdoruesin e indicatorit pa sukses`,
  'DELETE /indicators/:indicatorId/users/:userId 500': `Perdoruesi ${userEmail} tentoi te fshije perdoruesin e indicatorit pa sukses`,
  //Indicator Values

  //Briefs
  'POST /briefs/post 201': `Perdoruesi ${userEmail} krijoi nje brief te ri me sukses`,
  'POST /briefs/post 400': `Perdoruesi ${userEmail} tentoi te krijoje nje brief te ri pa sukses`,
  'POST /briefs/post 500': `Perdoruesi ${userEmail} tentoi te krijoje nje brief te ri pa sukses`,
  'PUT /briefs/:query 200': `Perdoruesi ${userEmail} perditesoi nje brief me sukses`,
  'PUT /briefs/:query 404': `Perdoruesi ${userEmail} tentoi te perditesoi nje brief pa sukses`,
  'PUT /briefs/:query 400': `Perdoruesi ${userEmail} tentoi te perditesoi nje brief pa sukses`,
  'PUT /briefs/:query 500': `Perdoruesi ${userEmail} tentoi te perditesoi nje brief pa sukses`,
  'DELETE /briefs/:query 204': `Perdoruesi ${userEmail} fshiu nje brief me sukses`,
  'DELETE /briefs/:query 400': `Perdoruesi ${userEmail} tentoi te fshije nje brief pa sukses`,
  'DELETE /briefs/:query 404': `Perdoruesi ${userEmail} tentoi te fshije nje brief pa sukses`,
  'DELETE /briefs/:query 500': `Perdoruesi ${userEmail} tentoi te fshije nje brief pa sukses`,
  //Users
  'POST /users 201': `Perdoruesi ${userEmail} krijoi nje perdorues te ri me sukses`,
  'POST /users 400': `Perdoruesi ${userEmail} tentoi te krijoje nje perdorues te ri pa sukses`,
  'POST /users 403': `Perdoruesi ${userEmail} tentoi te krijoje nje perdorues te ri pa sukses`,
  'POST /users 500': `Perdoruesi ${userEmail} tentoi te krijoje nje perdorues te ri pa sukses`,
  'PUT /users/:id 200': `Perdoruesi ${userEmail} perditesoi nje perdorues me sukses`,
  'PUT /users/:id 400': `Perdoruesi ${userEmail} tentoi te perditesoi nje perdorues pa sukses`,
  'PUT /users/:id 403': `Perdoruesi ${userEmail} tentoi te perditesoi nje perdorues pa sukses`,
  'PUT /users/:id 404': `Perdoruesi ${userEmail} tentoi te perditesoi nje perdorues pa sukses`,
  'PUT /users/:id 500': `Perdoruesi ${userEmail} tentoi te perditesoi nje perdorues pa sukses`,
  'DELETE /users/:id 204': `Perdoruesi ${userEmail} fshiu nje perdorues me sukses`,
  'DELETE /users/:id 403': `Perdoruesi ${userEmail} tentoi te fshije nje perdorues pa sukses`,
  'DELETE /users/:id 404': `Perdoruesi ${userEmail} tentoi te fshije nje perdorues pa sukses`,
  'DELETE /users/:id 500': `Perdoruesi ${userEmail} tentoi te fshije nje perdorues pa sukses`,
  //User Invite
  'POST /users/resend-invite 204': `Perdoruesi ${userEmail} ridergoi ftese per perdoruesin me sukses`,
  'POST /users/resend-invite 500': `Perdoruesi ${userEmail} tentoi te ridergoi ftese per perdoruesin pa sukses`,
  //Modules
  'POST /modules 201': `Perdoruesi ${userEmail} krijoi nje modul te ri me sukses`,
  'POST /modules 400': `Perdoruesi ${userEmail} tentoi te krijoje nje modul te ri pa sukses`,
  'POST /modules 403': `Perdoruesi ${userEmail} tentoi te krijoje nje modul te ri pa sukses`,
  'POST /modules 500': `Perdoruesi ${userEmail} tentoi te krijoje nje modul te ri pa sukses`,
  'PUT /modules/:id 200': `Perdoruesi ${userEmail} perditesoi nje modul me sukses`,
  'PUT /modules/:id 400': `Perdoruesi ${userEmail} tentoi te perditesou nje module pa sukses`,
  'PUT /modules/:id 403': `Perdoruesi ${userEmail} tentoi te perditesou nje module pa sukses`,
  'PUT /modules/:id 404': `Perdoruesi ${userEmail} tentoi te perditesou nje module pa sukses`,
  'PUT /modules/:id 500': `Perdoruesi ${userEmail} tentoi te perditesou nje module pa sukses`,
  'DELETE /modules/:id 200': `Perdoruesi ${userEmail} fshiu nje modul me sukses`,
  'DELETE /modules/:id 403': `Perdoruesi ${userEmail} tentoi te fshije nje modul pa sukses`,
  'DELETE /modules/:id 404': `Perdoruesi ${userEmail} tentoi te fshije nje modul pa sukses`,
  'DELETE /modules/:id 500': `Perdoruesi ${userEmail} tentoi te fshije nje modul pa sukses`,
  //Submodules
  'POST /submodules 201': `Perdoruesi ${userEmail} krijoi nje submodul te ri me sukses`,
  'POST /submodules 400': `Perdoruesi ${userEmail} tentoi te krijoje nje submodul te ri pa sukses`,
  'POST /submodules 403': `Perdoruesi ${userEmail} tentoi te krijoje nje submodul te ri pa sukses`,
  'POST /submodules 500': `Perdoruesi ${userEmail} tentoi te krijoje nje submodul te ri pa sukses`,
  'PUT /submodules/:id 200': `Perdoruesi ${userEmail} perditesoi nje submodul me sukses`,
  'PUT /submodules/:id 400': `Perdoruesi ${userEmail} tentoi te perditesoi nje submodul pa sukses`,
  'PUT /submodules/:id 403': `Perdoruesi ${userEmail} tentoi te perditesoi nje submodul pa sukses`,
  'PUT /submodules/:id 404': `Perdoruesi ${userEmail} tentoi te perditesoi nje submodul pa sukses`,
  'PUT /submodules/:id 500': `Perdoruesi ${userEmail} tentoi te perditesoi nje submodul pa sukses`,
  'DELETE /submodules/:id 200': `Perdoruesi ${userEmail} fshiu nje submodule me sukses`,
  'DELETE /submodules/:id 403': `Perdoruesi ${userEmail} tentoi te fshije nje submodul pa sukses`,
  'DELETE /submodules/:id 404': `Perdoruesi ${userEmail} tentoi te fshije nje submodul pa sukses`,
  'DELETE /submodules/:id 500': `Perdoruesi ${userEmail} tentoi te fshije nje submodul pa sukses`,
  //Publications
  'POST /publications 201': `Perdoruesi ${userEmail} krijoi nje publikim te ri me sukses`,
  'POST /publications 400': `Perdoruesi ${userEmail} tentoi te krijoje nje publikim te ri pa sukses`,
  'POST /publications 403': `Perdoruesi ${userEmail} tentoi te krijoje nje publikim te ri pa sukses`,
  'POST /publications 500': `Perdoruesi ${userEmail} tentoi te krijoje nje publikim te ri pa sukses`,
  'PUT /publications/:id 200': `Perdoruesi ${userEmail} perditesoi nje publikim me sukses`,
  'PUT /publications/:id 400': `Perdoruesi ${userEmail} tentoi te perditesoi nje publikim me sukses`,
  'PUT /publications/:id 403': `Perdoruesi ${userEmail} tentoi te perditesoi nje publikim me sukses`,
  'PUT /publications/:id 404': `Perdoruesi ${userEmail} tentoi te perditesoi nje publikim me sukses`,
  'PUT /publications/:id 500': `Perdoruesi ${userEmail} tentoi te perditesoi nje publikim me sukses`,
  'DELETE /publications/:id 200': `Perdoruesi ${userEmail} fshiu nje publikim me sukses`,
  'DELETE /publications/:id 403': `Perdoruesi ${userEmail} tentoi te fshije nje publikim pa sukses`,
  'DELETE /publications/:id 404': `Perdoruesi ${userEmail} tentoi te fshije nje publikim pa sukses`,
  'DELETE /publications/:id 500': `Perdoruesi ${userEmail} tentoi te fshije nje publikim pa sukses`,
});
