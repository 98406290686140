import React from 'react';
import SubmoduleForm from '../ManageSubmodules/SubmoduleForm';
import Submodule from 'models/submodule';

interface IProps {
  onClose: () => void;
  refetch: () => void;
  submodule: Submodule;
}
const SubmoduleCreate: React.FunctionComponent<IProps> = (props) => {
  const { onClose, refetch, submodule } = props;
  return <SubmoduleForm submodule={submodule} onClose={onClose} refetch={refetch} />;
};

export default SubmoduleCreate;
