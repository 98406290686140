import React from 'react';
import { Table } from 'antd';
import { IndicatorValues } from 'models/indicator';
const { Column, ColumnGroup } = Table;

interface NessTableProps {
  baseline: number | undefined;
  current: IndicatorValues | undefined;
  selectedYear: string;
}

const NessTable: React.FunctionComponent<NessTableProps> = ({ baseline, current, selectedYear }) => {
  const data = [
    {
      key: '1',
      name: 'Type of Indicator',
      data: 'Impact indicator ',
    },
    {
      key: '2',
      name: 'No....Date ...., Name of Documdata:',
      data: 'National Employment and Skills Strategy 2014-2020, dated 26/11/2014',
    },
    {
      key: '3',
      name: 'Relation to NSDI      (No. of Pillar)',
      data: 'Pillar 5: Investment in human capital and social cohesion',
    },
    {
      key: '4',
      name: 'Strategic goal / Objective in NSDI',
      data: 'Increasing employment opportunities',
    },
    {
      key: '5',
      name: 'The purpose of corresponding policy',
      data: '“Promoting decent job opportunities and increasing employability of the work force through effective labour market policies, as well as by matching labour demand and supply from employment services, including: job vacancy information, employment mediation, career counselling and guidance, employment promotion programs, income support for the unemployed through unemployment benefits and vocational training programs. Providing quality vocational training.”',
    },
    {
      key: '6',
      name: 'Specific Objective which the indicator relates to',
      data: '"Improving employment services, by increasing the number of mediations, counselling and career guidance, targeting groups in need from the unemployed. Adjusting vocational training courses to the requirements of the labour market."',
    },
    {
      key: '7',
      name: 'Indicator relevance',
      data: 'Employment',
    },
    {
      key: '8',
      name: 'Source of performance indicator monitoring data',
      data: 'Labour Force Survey',
    },
    {
      key: '9',
      name: 'Institutions responsible for data collection',
      data: 'NSTAT MFE',
    },
    {
      key: '10',
      name: 'Description of Methodology',
      data: 'The indicator refers to persons who meet two conditions: they are not attending formal or informal education or training and they are not employed during the last 4 weeks of the survey. ',
    },
    {
      key: '11',
      name: 'Frequency of measurement',
      data: 'Annually Quarterly',
    },
    {
      key: '12',
      name: 'Type of indicator: Cumulative/incremental',
      data: 'Cumulative',
    },
    {
      key: '13',
      name: 'Direct or combined input',
      data: 'Direct',
    },
    {
      key: '14',
      name: 'Calculation formula',
      data: 'No. of young people neither in education nor in training / total no. of youth',
    },
    {
      key: '15',
      name: 'Data segregation',
      data: 'Downward trend',
    },
    {
      key: '16',
      name: 'Highlight the course of change / trend of performance',
      data: '',
    },
    {
      key: '17',
      name: 'Baseline values',
      data: `2018 - ${baseline ? baseline + '%' : 'No data'}`,
    },
    {
      key: '18',
      name: 'Target value / Target',
      data: `${new Date(selectedYear).getFullYear()} - ${current?.target ? current.target + '%' : 'No data'}`,
    },
    {
      key: '19',
      name: 'Current baseline value:',
      data: `2018 - ${current ? current.value + '%' : 'No data'}`,
    },
    {
      key: '20',
      name: 'SDG - Title of the Sustainable Development Goal in line with UN',
      data: 'Goal 8: Promote sustained, inclusive and sustainable economic growth, full and productive employment and decent work for all. ',
    },
  ];

  return (
    <div className="ness-table">
      {/* <div className="ness-actions">
        <section>
          <a href="#">Download</a>
          <a href="#">Share</a>
          <a href="#">Zoom</a>
        </section>
      </div> */}
      <Table dataSource={data} pagination={false} showHeader={false}>
        <ColumnGroup title="" className="ness-col-group">
          <Column title="" dataIndex="name" key="name" className="ness-col-name" />
          <Column className="ness-col-data" title="" dataIndex="data" key="data" />
        </ColumnGroup>
      </Table>
    </div>
  );
};

export default NessTable;
