import React from 'react';
import { Row, Breadcrumb, Col, Space } from 'antd';
import { useQuery } from 'react-query';
import { getModules, getPublicIndicators } from 'api/public';
import { getCurrentLng } from 'utils/helper';
import I18nLink from 'common/components/I18nLink';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

interface IndicatorSubheaderProps {
  indicatorKey: string;
}

const IndicatorSubheader: React.FunctionComponent<IndicatorSubheaderProps> = ({ indicatorKey }) => {
  const currLang = getCurrentLng();
  const { t } = useTranslation();
  let name = `name${currLang}`;
  let description = `description${currLang}`;
  const { data: allIndicators } = useQuery('getIndicators', getPublicIndicators);
  const filteredIndicator = allIndicators?.filter((indicator) => indicator.key === indicatorKey)[0];
  const { data: modules } = useQuery('getModules', getModules);
  const currentModule = modules?.find((module) =>
    module.submodules?.find((submodule) => submodule.id === filteredIndicator?.submoduleId)
  );
  const currentSubmodule = currentModule?.submodules?.find(
    (submodule) => submodule.id === filteredIndicator?.submoduleId
  );
  const indicatorName = filteredIndicator?.[name] || '';
  const indicatorDescription = filteredIndicator?.[description] || '';
  const source = filteredIndicator?.dataset || '';

  return (
    <>
      <div className="subheader-wrapper">
        <Row>
          <Col sm={{ span: 24 }} md={{ span: 16 }} style={{ overflowX: 'hidden' }}>
            <Space direction="vertical" size={24}>
              <Breadcrumb separator="•">
                <Breadcrumb.Item>
                  <I18nLink to="/">{i18next.t('_COMMON._HOME')}</I18nLink>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <I18nLink to={`/category/${currentModule?.id}`}>{currentModule?.[name]}</I18nLink>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <I18nLink to={`/category/${currentModule?.id}/subcategory/${currentSubmodule?.id}`}>
                    {currentSubmodule?.[name]}
                  </I18nLink>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <I18nLink to={`/indicator/${indicatorKey}`}>{indicatorName}</I18nLink>
                </Breadcrumb.Item>
              </Breadcrumb>
              <div className="title">{indicatorName}</div>
              <div className="description">{indicatorDescription}</div>
              <div className="source">
                {t('_INDICATORS._SOURCE')} :{' '}
                <span style={{ color: 'black', textDecoration: 'underline' }}>{source}</span>
              </div>
            </Space>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default IndicatorSubheader;
