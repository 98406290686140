import { Institution } from './institution';
import { User } from './user';

export interface Theme {
  id?: number;
  key: string;
  type: ThemeType;
  acronymEN: string;
  acronymSQ: string;
  nameEN: string;
  nameSQ: string;
  iFrameEN: string;
  iFrameSQ: string;
  descriptionEN: string;
  descriptionSQ: string;
  footnoteEN: string;
  footnoteSQ: string;
  source: string;
  image: string;
  institution?: Institution;
  institutionId?: number;
  createdAt?: Date;
  updatedAt?: Date;
  isPublic: boolean;
}

export enum ThemeType {
  CUSTOM = 'custom',
  INTEGRATION = 'integration',
}

export enum ThemeUserRole {
  READ = 'read',
}

export interface ThemeUser {
  id?: number;
  userId: number;
  themeId: number;
  accessType: ThemeUserRole;
  createdAt?: Date;
  updatedAt?: Date;
  user: User;
}

export interface CreateThemeUserBody {
  userId: number;
  accessType: ThemeUserRole;
}
