import React, { useState } from 'react';
import twitter from 'pages/Explorer/components/Indicator/components/icons/twitter.svg';
import instagram from 'pages//Explorer/components/Indicator/components/icons/instagram.svg';
import message from 'pages/Explorer/components/Indicator/components/icons/message.svg';
import linkedin from 'pages/Explorer/components/Indicator/components/icons/linkedin.svg';
import download from 'pages/Explorer/components/Indicator/components/icons/download.svg';
import image from 'pages/Explorer/components/Indicator/components/icons/image.png';
import { IndicatorValues, IndicatorWithId } from 'models/indicator';
import ReactEcharts from 'echarts-for-react';
import i18next from 'i18next';
import DownloadModal from 'common/components/DownloadModal/DownloadModal';
import useWindowDimensions from 'common/hooks/useWindowDimensions';
import { getCurrentLng } from 'utils/helper';
import {
  formatYAxis,
  generateColorForString,
  generateSerieName,
  transformXAxisBasedOnFrequency,
  formatDate,
  formatSerieName,
  addCommas,
  generateSeries,
  getAvailableFilters,
  getInitialStateForFilters,
} from 'pages/Explorer/components/Indicator/components/helpers';
import { formatCSVData } from '../helpers';
import CsvDownload from 'react-json-to-csv';
import { getModule } from 'api/module';
import { useQuery } from 'react-query';
import { getIndicator, getIndicatorVariables } from 'api/indicator';
import { getIndicatorFilters } from 'api/public';

interface LineGraphProps {
  indicator: IndicatorWithId;
  filter: any;
  data?: {
    option: string;
    values: IndicatorValues[];
  }[];
  xAxis?: string[];
  unitType: string;
  frequency: string;
  isTotal?: boolean;
}

const LineGraph: React.FunctionComponent<LineGraphProps> = ({
  data,
  xAxis,
  filter,
  unitType,
  frequency,
  indicator,
  isTotal,
}) => {
  const { data: filters } = useQuery(['getIndicatorFilters', indicator.id], () => getIndicatorFilters(indicator.id));
  const { data: generalVariables } = useQuery('getIndicatorVariables', getIndicatorVariables);
  const availableFilters = filters && generalVariables ? getAvailableFilters(filters, generalVariables) : [];
  const initState = getInitialStateForFilters(availableFilters);

  const allData = data
    ?.map((dt) => dt.values)
    .flat()
    .filter((x) => x);

  const variables = filter?.variables?.map((v) => v.key);
  const selectedVariables = { ...initState, [filter.key]: variables };
  const { series } = generateSeries(allData || [], selectedVariables);
  const finalSeries = isTotal ? [data?.[0].values || []] : series;

  const { data: indicatorData } = useQuery(['getSingleIndicator', indicator.id], () => getIndicator(indicator.id), {
    enabled: true,
    refetchOnWindowFocus: false,
  });

  const { data: moduleData } = useQuery('module', () => getModule(indicatorData?.submodule?.moduleId || 1), {
    enabled: !!indicatorData,
  });

  const currLang = getCurrentLng();
  const [previewModal, setPreviewModal] = useState(false);
  const { width } = useWindowDimensions();

  const activeFilter = isTotal
    ? { filter: 'total', options: [] }
    : {
        filter: filter?.key,
        options: filter?.variables?.map((variable) => variable.key) || [],
      };

  const translate = (key: string): string => {
    const variable = filter?.variables?.find((variable) => variable.key === key.toLowerCase());
    if (!variable) return key;
    return formatSerieName(variable?.[`${currLang.toLowerCase()}`]);
  };

  const buttonStyles = {
    border: 'none',
    backgroundColor: 'white',
  };

  const xAxisData = transformXAxisBasedOnFrequency(xAxis || [], frequency);

  const chartOptions = {
    xAxis: {
      show: true,
      type: 'category',
      showGrid: false,
      offset: 10,
      splitLine: { show: false },
      data: xAxisData,
      axisLine: {
        show: true,
        lineStyle: {
          width: 0.5,
          opacity: 0.5,
        },
      },
      axisTick: {
        show: false,
      },
      boundaryGap: false,
    },
    grid: {
      left: '0%',
      right: '10%',
      bottom: '5%',
      containLabel: true,
    },
    tooltip: {
      showContent: true,
      trigger: 'axis',
    },
    yAxis: {
      type: 'value',
      show: true,
      max: (value) => {
        return value.max;
      },
      min: (value) => {
        return value.min;
      },
      splitLine: {
        show: true,
      },
      splitNumber: 2,
      axisLabel: {
        formatter: (value) => formatYAxis(value, unitType),
      },
    },
    legend: {
      show: previewModal || width < 700,
    },
    series: finalSeries?.map((serie) => ({
      name: translate(generateSerieName(serie, activeFilter)),
      color: generateColorForString(generateSerieName(serie, activeFilter)),
      type: 'line',
      data: serie,
      smooth: true,
      symbol: 'circle',
      symbolSize: 12,
      showSymbol: xAxisData.length > 1 ? false : true,
      connectNulls: true,
      smoothMonotone: 'x',
      lineStyle: {
        width: width > 700 ? 5 : 3,
      },
      itemStyle: {
        borderWidth: 5,
      },
      emphasis: {
        focus: 'series',
      },
      endLabel: {
        show: (isTotal && !previewModal) || width < 700 ? false : true,
        distance: 7,
        formatter: previewModal ? '{c}' : '{a}',
        fontFamily: 'Source Sans Pro',
        fontSize: 12,
        overflow: 'breakAll',
        ellipsis: '...',
        align: 'left',
        color: generateColorForString(generateSerieName(serie, activeFilter)),
      },
    })),
  };

  const indicatorContainer = (
    <section>
      <div>
        <div
          style={{
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: 23,
            textTransform: 'uppercase',
          }}>
          {indicator[`name${currLang}`]}
        </div>
        <div
          style={{
            fontStyle: 'normal',
            fontSize: 17,
            paddingBottom: width > 700 ? 0 : '1rem',
          }}>
          {!isTotal && i18next.t('_INDICATORS._BY')} {filter?.[`${currLang.toLowerCase()}`]}
        </div>
      </div>
      <div className="indicator-chart">
        <ReactEcharts
          style={{ width: '100%', height: width > 700 ? 400 : 300 }}
          lazyUpdate={false}
          option={chartOptions}
        />
      </div>
    </section>
  );

  return (
    <div id={filter?.key?.split('_').join('')} className="indicator-chart-section">
      {previewModal && (
        <DownloadModal setPreviewModal={setPreviewModal} indicator={indicator}>
          {indicatorContainer}
        </DownloadModal>
      )}
      {indicatorContainer}
      <div className="indicator-graphs-footer">
        <div className="share">
          <div>{i18next.t('_COMMON._SHARE')}</div>
          <div>
            <img src={twitter} />
          </div>
          <div>
            <img src={message} />
          </div>
          <div>
            <img src={linkedin} />
          </div>
          <div>
            <img src={instagram} />
          </div>
          <div>
            <img style={{ width: '16px' }} src={image} onClick={() => setPreviewModal(true)} />
          </div>
          <div>
            {data && indicatorData && moduleData && (
              <CsvDownload
                style={buttonStyles}
                data={formatCSVData(data, indicatorData, moduleData)}
                filename={`${indicatorData?.nameEN}.csv`}>
                <img src={download} />
              </CsvDownload>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LineGraph;
