import React, { useContext } from 'react';
import Card from 'common/images/NoData/noData.png';
import ThemeCard from 'common/components/ThemeCard/ThemeCard';
import { useQuery } from 'react-query';
import { getRelatedMetrics } from 'api/public';
import { getCurrentLng } from 'utils/helper';
import { IndicatorWithId } from 'models/indicator';
import { Spin } from 'antd';
import i18n from 'i18next';
import { useHistory } from 'react-router-dom';
import bufferToImage from 'utils/bufferToImage';
import { downloadIndicatorData } from 'api/indicator';
import { DownloadOutlined } from '@ant-design/icons';
import SingleReport from 'pages/Home/components/Reports/SingleReport';
import IndicatorCard from 'common/components/IndicatorCard/IndicatorCard';
import { IndicatorPageContext } from './store/context';

interface IndicatorRelatedProps {
  indicator: IndicatorWithId;
}
const IndicatorRelated: React.FunctionComponent<IndicatorRelatedProps> = ({ indicator }) => {
  const currLang = getCurrentLng();
  const indicatorContext = useContext(IndicatorPageContext);
  const { setStartYearFilter, setEndYearFilter } = indicatorContext;
  const { data, isLoading, isError } = useQuery(
    ['relatedIndicatorMetrics', indicator.id],
    () => getRelatedMetrics(indicator.id),
    {
      refetchOnWindowFocus: false,
    }
  );
  const { data: downloadIndicator } = useQuery(
    ['getDownloadIndicator', indicator.id],
    () => downloadIndicatorData(indicator.id || 0),
    { refetchOnWindowFocus: false }
  );
  if (isLoading) {
    return (
      <div className="indicator-related">
        <div
          style={{
            height: '400px',
            width: '100%',
            display: 'grid',
            placeContent: 'center',
            background: 'white',
          }}>
          <Spin size="large" />
          <p style={{ fontWeight: 500 }}>{i18n.t('_COMMON._LOADING')}</p>
        </div>
      </div>
    );
  }
  if (isError) {
    return (
      <div style={{ height: '400px', display: 'grid', placeContent: 'center', background: 'white' }}>
        {i18n.t('_COMMON._ERROR')}
      </div>
    );
  }
  const removeFilterState = () => {
    setStartYearFilter(undefined);
    setEndYearFilter(undefined);
  };

  return (
    <div className="indicator-related">
      <a className="download-data-btn" href={downloadIndicator?.link}>
        <span>
          <DownloadOutlined style={{ fontSize: '20px', marginRight: '5px' }} />
        </span>
        <span>{downloadIndicator?.link ? i18n.t('_INDICATORS._DOWNLOAD_DATA') : i18n.t('_COMMON._NO_DATA')}</span>
      </a>
      <h1 className="indicator-title">{i18n.t('_RELATED_METRICS._METRICS')}</h1>
      {data?.indicators?.length == 0 && (
        <p className="related-unavailable">{i18n.t('_RELATED_METRICS._NO_INDICATORS')}</p>
      )}

      <div className="indicator-related-components">
        {/**RELATED CHART CARDS */}
        {data?.indicators.map((indicator) => {
          return (
            <div onClick={() => removeFilterState()}>
              <IndicatorCard
                key={indicator.id}
                responsive
                indicator={indicator}
                path={`/indicator/${indicator.key}`}
                button
                chartTitle={indicator[`name${currLang}`]}
                image={bufferToImage(indicator.graph_image?.data)}
              />
            </div>
          );
        })}
      </div>
      {/**RELATED REPORTS */}
      <h1 className="indicator-title">{i18n.t('_RELATED_METRICS._REPORTS')}</h1>
      {data?.publications?.length == 0 && (
        <p className="related-unavailable">{i18n.t('_RELATED_METRICS._NO_REPORTS')}</p>
      )}
      <div className="indicator-related-reports">
        {/* {data?.publications?.length == undefined && <p>No indicators available</p>} */}
        {data?.publications.map((publication) => {
          return (
            <SingleReport
              key={publication.id}
              image={publication.thumbnail}
              title={publication[`name${getCurrentLng()}`]}
              author={publication.author}
              href={publication.file || ''}
              date={publication.date}
            />
          );
        })}
      </div>
      {/**RELATED THEMES */}
      <div>
        <h1 className="indicator-title">{i18n.t('_RELATED_METRICS._THEMES')}</h1>
        {data?.themes?.length == 0 && <p className="related-unavailable">{i18n.t('_RELATED_METRICS._NO_THEMES')}</p>}
        <div className="indicator-themes">
          {data?.themes.map((theme) => {
            return (
              <ThemeCard
                key={theme.key}
                path={`/themes/${theme.key}`}
                image={theme.image}
                name={theme[`name${currLang}`]}
                acronym={theme[`acronym${currLang}`]}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};
export default IndicatorRelated;
