import React from 'react';
import { withRouter } from 'react-router';
import GlobalStyle from 'common/theme/globalStyle';
import Routes from 'routes';
import NotAllowed from 'pages/NotAllowed';

function App() {
  // if (isMobile) {
  //   return <NotAllowed />;
  // }
  return (
    <>
      <GlobalStyle />
      <Routes />
    </>
  );
}

export default withRouter(App);
