import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import Table from 'common/components/Table/Table';
import { Button, Modal, notification, Popconfirm, Row, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { translationBasePath, TranslationKeys } from './constants';
import { getCurrentLng } from 'utils/helper';
import { getInstitutions } from 'api/institution';
import ThemeForm from './ThemeForm';
import { Link } from 'react-router-dom';
import { Theme } from 'models/theme';
import { deleteTheme, getThemes } from 'api/theme';
import { Title } from 'common/components/Modal/styled';
import { ColumnProps } from 'antd/lib/table';
import I18nLink from 'common/components/I18nLink';

const ManageThemes: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const [updating, setUpdating] = useState<Theme | null>();
  const { data, isLoading, refetch } = useQuery('themes', getThemes);
  const { data: institutions, isLoading: isLoadingInstitutions } = useQuery('institutions', getInstitutions);
  const deleteMutation = useMutation(deleteTheme);
  const title = t(`${translationBasePath}.${TranslationKeys.manageThemes}`);

  const indicatorHeader = (
    <Row justify="space-between" style={{ marginBottom: 10 }}>
      <Title>{title}</Title>
      <Button
        type="primary"
        onClick={() => {
          setModalOpen(true);
          setUpdating(null);
        }}>
        {t(`${translationBasePath}.${TranslationKeys.createTheme}`)}
      </Button>
    </Row>
  );

  const themeForm = (
    <ThemeForm
      institutions={institutions}
      theme={updating || null}
      uniqueKeys={data ? data.map((i) => i?.key || '') : []}
      onClose={() => setModalOpen(false)}
      refetch={refetch}
    />
  );

  const modal = modalOpen && (
    <Modal
      maskClosable={false}
      footer={null}
      visible
      width="700px"
      title={
        updating
          ? t(`${translationBasePath}.${TranslationKeys.updateTheme}`)
          : t(`${translationBasePath}.${TranslationKeys.createTheme}`)
      }
      onCancel={() => {
        setModalOpen(false);
        setUpdating(null);
      }}>
      {themeForm}
    </Modal>
  );

  const currentLanguage: string = getCurrentLng();
  const nameLngKey = `name${currentLanguage}`;

  const columns: ColumnProps<any>[] = [
    {
      key: 'key',
      title: t(`${translationBasePath}._KEY`),
      dataIndex: 'key',
      width: 'auto',
      render: (text: string, _record) => {
        return <span>{text}</span>;
      },
    },
    {
      key: nameLngKey,
      title: t(`${translationBasePath}._${nameLngKey.toUpperCase()}`),
      dataIndex: nameLngKey,
      width: 'auto',
      render: (text: string, _record) => {
        return <span>{text}</span>;
      },
    },
    {
      key: 'type',
      title: t(`${translationBasePath}._TYPE`),
      dataIndex: 'type',
      width: 'auto',
      render: (text: string, _record) => {
        return <span>{text}</span>;
      },
    },
    {
      title: t(`${translationBasePath}._ACTION`),
      key: 'action',
      dataIndex: 'action',
      width: '20%',
      render: (_text, record) => {
        if (record.type === 'custom') {
          return <></>;
        }

        return (
          <Space size="middle">
            <Button style={{ fontSize: '15px' }} shape="round" type="link">
              <I18nLink to={`/themes/${record.key}`} target="_blank">
                {t(`${translationBasePath}.${TranslationKeys.preview}`)}
              </I18nLink>
            </Button>
            <Button
              style={{ fontSize: '15px' }}
              shape="round"
              type="link"
              onClick={() => {
                setModalOpen(true);
                setUpdating(record);
              }}>
              {t(`${translationBasePath}.${TranslationKeys.update}`)}
            </Button>
            <Button style={{ fontSize: '15px' }} disabled={record.isPublic} shape="round" type="link">
              <I18nLink to={`/dashboard/themes/${record.id}/users`}>
                {t(`${translationBasePath}._MANAGE_USERS`)}
              </I18nLink>
            </Button>
            <Popconfirm
              title={t(`${translationBasePath}._DELETE_CONFIRM`)}
              onConfirm={() => {
                deleteMutation
                  .mutateAsync(record)
                  .then(() => refetch())
                  .catch((err) => notification.error({ message: err.message, description: err.description }));
              }}>
              <Button style={{ fontSize: '15px' }} shape="round" type="link" danger>
                {t(`${translationBasePath}.${TranslationKeys.delete}`)}
              </Button>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];
  return (
    <>
      {indicatorHeader}
      {modal}
      <Table
        loading={isLoading || isLoadingInstitutions}
        dataSource={data}
        columns={columns}
        pagination={false}
        rowClassName="admin-table-row"
      />
    </>
  );
};

export default ManageThemes;
