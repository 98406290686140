import { useEffect, useState, useRef, useLayoutEffect } from 'react';
import { PlusCircleFilled, DownOutlined } from '@ant-design/icons';
import { useQuery } from 'react-query';
import { getModules } from 'api/public';
import i18n from 'i18next';
import { ExploreContext } from '../store/exploreContext';
import { Layout, Divider, Breadcrumb, Menu, Dropdown, Row, Col } from 'antd';
import './explorerstart.css';
import Indicator from 'pages/Explorer/components/Indicator/Indicator';
import ModalNewIndicator from './components/ModalNewIndicator/ModalNewIndicator';
import I18nLink from 'common/components/I18nLink';
import i18next from 'i18next';
import { getCurrentLng } from 'utils/helper';

const ExplorerStart = () => {
  const currentLng = getCurrentLng();
  const { data } = useQuery('modules', getModules);
  const [currentSelectedModule, setCurrentSelectedModule] = useState(0);
  const emptyDivRef = useRef<HTMLDivElement>(null);
  const [removing, setRemoving] = useState(false);
  const [showModal, setShowModal] = useState<boolean>(false); //control modal state
  const [addedIndicators, setAddedIndicators] = useState<{ id: number }[]>([]);

  //context
  const contextValue = {
    modalIsOpen: showModal,
    setModalIsOpen: setShowModal,
    addedIndicators: addedIndicators,
    setAddedIndicators: setAddedIndicators,
    removeIndicator: (indicatorId) => {
      setAddedIndicators([...addedIndicators.filter((obj) => obj.id !== indicatorId)]);
    },
    removing,
    setRemoving,
  };

  //UI
  useLayoutEffect(() => {
    //if the user removes the indicator component from UI, do not scroll
    if (removing) {
      return;
    }
    emptyDivRef?.current?.scrollIntoView({
      behavior: 'smooth',
    });
  }, [addedIndicators, removing]);

  //scroll to top on inital load of page
  useEffect(() => {
    window.scroll({ top: 0 });
  }, []);

  const menu = (
    <Menu
      onClick={(event) => {
        setCurrentSelectedModule(parseInt(event.key));
        setShowModal(true);
      }}>
      {data?.map((module) => (
        <Menu.Item key={module.id}>
          <span>{module[`name${currentLng}`]}</span>
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <>
      <ExploreContext.Provider value={contextValue}>
        <div
          style={{
            backgroundColor: 'white',
            width: '100%',
            position: 'relative',
            height: '100%',
            overflowX: 'hidden',
          }}>
          <Layout style={{ maxWidth: '1580px', margin: 'auto', background: '#fff', position: 'relative' }}>
            {showModal && (
              <ModalNewIndicator
                closeModal={() => {
                  setShowModal((prev) => !prev);
                }}
                module={data?.[0]}
              />
            )}
            <div className="theme-subheader-wrapper explorer-subheader">
              <Breadcrumb separator="•">
                <Breadcrumb.Item>
                  <I18nLink to="/">{i18next.t('_COMMON._HOME')}</I18nLink>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <I18nLink to="/explore">{i18next.t('_EXPLORER._DATA')}</I18nLink>
                </Breadcrumb.Item>
                <Breadcrumb.Item></Breadcrumb.Item>
              </Breadcrumb>
              <div className="theme-subheader-title">{i18next.t('_EXPLORER._DATA')}</div>
            </div>

            {addedIndicators?.map((indicator, index) => {
              return <Indicator key={index} indicatorId={indicator.id} />;
            })}
            <div ref={emptyDivRef} />
            <Row
              align="middle"
              gutter={10}
              className={
                addedIndicators.length === 0 ? 'add-indicator-container height-min' : 'add-indicator-container'
              }
              justify="center">
              <Col>
                <a
                  className="new"
                  onClick={(e) => {
                    e.preventDefault();
                    setShowModal(true);
                  }}>
                  <span>{i18n.t('_EXPLORER._ADD')}</span>
                  <span style={{ fontSize: '20px' }}>
                    <PlusCircleFilled />
                  </span>
                </a>
              </Col>
            </Row>

            {/* <div className={`add-indicator-btn`}>
              <a
                className="new"
                onClick={() => {
                  setShowModal(true);
                }}>
                <span>{i18n.t('_EXPLORER._ADD')}</span>
                <span style={{ fontSize: '20px' }}>
                  <PlusCircleFilled />
                </span>
              </a>
            </div> */}
            <Divider />
          </Layout>
        </div>
      </ExploreContext.Provider>
    </>
  );
};

export default ExplorerStart;
