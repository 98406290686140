import { IndicatorWithId } from 'models/indicator';
import { Header } from 'antd/lib/layout/layout';
import { useTranslation } from 'react-i18next';
import { getDateString, downloadIndicator } from 'pages/Explorer/components/Indicator/components/helpers';
import i18next from 'i18next';
import UNDPLogo from 'common/images/adp-04.png';
import { Button, Modal, Row } from 'antd';
import { getCurrentLng } from 'utils/helper';

interface Props {
  indicator: IndicatorWithId;
  children: React.ReactNode;
  setPreviewModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const DownloadModal: React.FunctionComponent<Props> = ({ children, indicator, setPreviewModal }) => {
  const { t } = useTranslation();
  const currLang = getCurrentLng();

  return (
    <Modal
      className="download-img-modal"
      maskClosable={false}
      footer={null}
      visible
      title={t('_COMMON._INDICATOR_PREVIEW')}
      onCancel={() => {
        setPreviewModal(false);
      }}
      width="700px">
      <div id={`${indicator?.id || ''}`} style={{ marginBottom: 20, height: 'auto', width: '100%', padding: 10 }}>
        <Header
          style={{
            backgroundColor: '#0e9898',
            height: 'auto',
            padding: '4px',
            lineHeight: '25px',
          }}>
          <Row align="middle">
            <img src={UNDPLogo} width="250px" max-height={'auto'} />
          </Row>
        </Header>
        <Row style={{ marginLeft: 10 }}>
          <p className="indicator-hashtags">
            {indicator?.submodule ? `#${indicator.submodule[`name${currLang}`].replace(/\s/g, '')}` : ''}
          </p>
          <p className="indicator-hashtags">
            {indicator?.institution ? `#${indicator.institution[`name${currLang}`].replace(/\s/g, '')}` : ''}
          </p>
        </Row>
        {children}
        <section style={{ color: '#828282', padding: '16px', fontSize: '15px' }}>
          {indicator[`description${currLang}`]}
        </section>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '6px 16px' }}>
          <p style={{ color: '#828282' }}>
            {t('_HOME._SOURCE')}: {indicator?.dataset}
          </p>
          <p className="indicator-hashtags">
            {indicator
              ? `${t('_INDICATORS._LAST_UPDATED')}: ${
                  getDateString(indicator.last_value_updated_at) || getDateString(indicator.updatedAt)
                }`
              : ''}
          </p>
        </div>
      </div>
      <Button htmlType="submit" onClick={() => downloadIndicator(indicator)}>
        {i18next.t('_COMMON._DOWNLOAD')}
      </Button>
    </Modal>
  );
};

export default DownloadModal;
