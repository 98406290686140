import * as yup from 'yup';

export const translationBasePath = '_DASHBOARD._MANAGE_MODULES';

export const validationSchema = (t) =>
  yup.object({
    nameEN: yup
      .string()
      .max(2000, t(`${translationBasePath}._NAME_MAX_LENGTH`))
      .required(t(`${translationBasePath}._FIELD_REQUIRED`)),
    nameSQ: yup
      .string()
      .max(2000, t(`${translationBasePath}._NAME_MAX_LENGTH`))
      .required(t(`${translationBasePath}._FIELD_REQUIRED`)),
  });
