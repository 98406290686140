export default (buffer: Buffer | undefined): string | undefined => {
  if (!buffer) {
    return undefined;
  }
  let binary = '';
  let bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (var i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  const base64String = window.btoa(binary);

  return `data:image/png;base64,${base64String}`;
};
