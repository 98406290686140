import i18n from 'i18next';

export const formatCategory = (category: string) => {
  switch (category) {
    case 'themes':
      return i18n.t('_SEARCH._THEME');
    case 'modules':
      return i18n.t('_SEARCH._MODULE');
    case 'subcategories':
      return i18n.t('_SEARCH._SUBCATEGORY');
    case 'keyIndicators':
      return i18n.t('_SEARCH._KEY_INDICATOR');
    case 'institutions':
      return i18n.t('_SEARCH._INSTITUTION');
    case 'indicators':
      return i18n.t('_SEARCH._INDICATOR');
    case 'publications':
      return i18n.t('_SEARCH._PUBLICATION');
    default:
      return '-';
  }
};

//format description display
export const formatDescription = (desc: string) => {
  return desc?.length > 100 ? desc?.slice(0, 100).concat('...') : desc;
};

export const formatRaportType = (type: string) => {
  if (!type) {
    return '';
  }
  return type.split('_').join(' ');
};
