/* eslint-disable max-len */
export default {
  translation: {
    _USER_ROLES: {
      _INSTITUTION_USER: 'Përdorues institucioni',
      _ADMIN: 'Admin',
      _DIRECTOR: 'Drejtor',
      _NORMAL: 'Normal',
    },
    _CLOSE: 'Mbyll',
    _UPDATE: 'Përditëso',
    _UPDATING: 'Duke përditësuar...',
    _INSTITUTION_NAME: 'Emri i institucionit',
    _WEAK_PASSWORD: `Fjalëkalimi duhet të jetë së paku 8 karaktere i gjatë, të përmbajë një shkronjë të madhe, një numër dhe një simbol`,
    _ROLE: 'Roli',
    _HOME: {
      _LOADING: 'Duke u ngarkuar...',
      _VIEW_ALL_THEMES: 'Shihni të gjitha temat',
      _EXPLORE_DATA: 'Eksploro të dhëna',
      _MINISTRIES: 'Ministritë',
      _CATEGORIES: 'Kategoritë',
      _INSTITUTIONS: 'Institucionet',
      _SOURCE: 'Burimi',
      _THEMES: 'Temat kryesore',
      _INTRO: {
        _DIGITAL: 'Platformë Digjitale Informacioni',
        _INSIGHTS: 'Që siguron të dhëna të lexueshme mbi treguesit e institucioneve',
        _ABOUT_US: 'Rreth nesh',
        _LEARN_MORE: 'Mësoni më shumë rreth projektit',
      },

      _KEY_DATA: {
        _TITLE: 'Të dhëna kryesore',
        _SUBTITLE: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry',
      },
      _EXPLORE: {
        _TITLE: 'Gjurmimi i zhvillimit ekonomik',
        _SUBTITLE: '',
      },
      _PARTNERS: {
        _PARTNERS: 'PARTNERËT',
        _SUBTITLE: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry',
      },
      _REPORTS: {
        _REPORTS: 'Raportet',
        _SUBTITLE: '',
        _MAIN_REPORT_TITLE: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry',
        _MAIN_REPORT_SUBTITLE: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry',
      },
    },
    _FOOTER: {
      _PLATFORM: 'Platforma',
      _INSTITUTIONS: 'Institucionet',
      _PARTNERS: 'Partnerët',
      _DIGITAL_PLATFORM: 'Platformë digjitale informacioni',
      _DIGITAL_PLATFORM_TEXT: 'që ofron njohuri të  lexueshme rreth treguesve të institucioneve',
      _ABOUT_LINK: 'Rreth EDT',
      _FAQ: 'FAQ',
      _DATA_SOURCES: 'Burimet e të dhënave',
      _PRINCIPLES: 'Parimet',
      _CITING: 'Citime',
      _SOCIAL_MEDIA: 'Rrjetet sociale',
      _CONTACT_US: 'Na kontaktoni',
      _MFE: 'MFE',
      _INSTAT: 'INSTAT',
      _NAES: 'NAES',
      _NAVETQ: 'NAVETQ',
      _GDT: 'GDT',
      _SII: 'SII',
      _MESY: 'MESY',
    },
    _HEADER: {
      _DATA: 'Të dhëna',
      _THEMES: 'Tema',
      _STRATEGIES: 'Strategjitë',
      _EXPLORE: 'Eksploroni',
      _ABOUT: 'Rreth nesh',
      _GOALS: 'Qëllimet',
      _LOGIN: 'Identifikohu',
      _LOGOUT: 'Dil',
      _REPORTS: 'Raportet',
      _ALBANIAN: 'Shqip',
      _BY_TOPIC: 'Sipas tematikave',
      _BY_INSTITUTION: 'Sipas Institucionit',
      _ALL_INSTITUTIONS: 'Të gjitha Institucionet',
    },
    _SUBHEADER: {
      _DATA: 'Të dhëna',
      _EMPLOYMENT: 'Punësimi',
      _EMPLOYMENT_CATEGORY: 'Punësimi dhe papunësia nga LFS',
    },
    _LOGIN: {
      _EMAIL: 'Email',
      _PASSWORD: 'Fjalëkalimi',
      _EMAIL_NOT_VALID: 'Ju lutem, fusni një email të vlefshëm',
      _EMAIL_REQUIRED: 'Ju lutem, fusni emailin tuaj',
      _PASSWORD_REQUIRED: 'Ju lutem, fusni fjalëkalimin tuaj',
      _FORGOT_PASSWORD_LINK: 'Keni harruar fjalëkalimin?',
      _SUBMIT: 'Identifikohu',
      _WRONG_CREDENTIALS: 'Kobiminim i gabuar i fjalëkalimit dhe emailit. Provoni përsëri! ',
      _WRONG_CODE: 'Kodi që keni futur është i pasaktë. Provoni përsëri! ',
      _SUCCESS: 'Linku per fjalekalimin u dergua me sukses!',
      _MFA: {
        _CODE: 'Kodi',
        _CODE_REQUIRED: 'Ju lutem, fusni kodin',
        _SUBMIT: 'Dërgo',
        _DIDNT_RECEIVE_A_CODE: 'Nuk morët kodin në SMS?',
        _RESEND_CODE: 'Ridërgo kodin',
      },
      _RESET_PASSWORD: {
        _NEW_PASSWORD: 'Fjalëkalim i ri',
        _NEW_PASSWORD_REQUIRED: 'Ju lutem, fusni fjalëkalimin e ri',
        _CONFIRM_PASSWORD: 'Konfirmoni fjalëkalimin',
        _CONFIRM_PASSWORD_REQUIRED: 'Ju lutem, konfirmoni fjalëkalimin tuaj',
        _CONFIRM_PASSWORD_NOT_MATCH: 'Fjalëkalimet nuk përputhen',
        _SUCCESS: 'Fjalëkalimi u ndryshua me sukses',
        _SUBMIT: 'Dërgo',
      },
      _FORGOT_PASSWORD: {
        _EMAIL: 'Email',
        _SUCCESS: 'Ju lutem, kontrolloni emailin tuaj. Ju kemi dërguar likun për rivendosjen e fjalëkalimit.',
        _SUBMIT: 'Dërgo',
        _REQUEST_RESET_PWD_LINK: 'Kërkoni linkun për rivendosjen e fjalëkalimit.',
        _ENTER_EMAIL: 'Fusni emailin tuaj të platformës',
      },
    },
    _DASHBOARD: {
      _HEADER: {
        _MANAGE_USERS: 'Menaxho Përdoruesit',
      },
      _MANAGE_LOGS: {
        _LOGS: 'Loget',
        _MANAGE_LOGS: 'Menaxho Loget',
      },
      _SIDEBAR: {
        _MANAGE_USERS: 'Përdoruesit',
        _MANAGE_PUBLICATIONS: 'Publikimet',
        _MANAGE_INDICATORS: 'Indikatorët',
        _MANAGE_INDICATOR_VALUES: 'Vlerat e Indikatorëve',
        _MANAGE_MODULES: 'Modulet',
        _MANAGE_INSTITUTIONS: 'Institucionet',
        _MANAGE_THEMES: 'Themes',
      },
      _MANAGE_THEMES: {
        _CREATE_THEME: 'Krijo Theme',
        _MANAGE_THEMES: 'Meanxho Themes',
        _MANAGE_USERS: 'Meanxho Aksesin',
        _UPDATE_THEME: 'Përditëso Theme',
        _DELETE_CONFIRM: 'Jeni i sigurtë që doni të fshini këtë theme?',
        _KEY: 'Key',
        _ACTION: 'Veprimi',
        _TYPE: 'Lloji',
        _UPDATE: 'Përditëso',
        _PREVIEW: 'Shikim paraprak',
        _DELETE: 'Fshi',
        _ACRONYMEN: 'Akronimi EN',
        _ACRONYMSQ: 'Akronimi (SQ)',
        _NAMEEN: 'Emri (EN)',
        _NAMESQ: 'Emri (SQ)',
        _DESCRIPTIONEN: 'Përshkrimi (EN)',
        _DESCRIPTIONSQ: 'Përshkrimi (SQ)',
        _INSTITUTION: 'Institucioni',
        _FOOTNOTEEN: 'Shënim (EN)',
        _FOOTNOTESQ: 'Shënim (SQ)',
        _SOURCE: 'Burimi',
        _TEXT_FIELD_MAX_LENGTH: 'Ju lutem, shkruani një tekst më të shkurtër',
        _FIELD_REQUIRED: 'Kjo fushë është e detyrueshme',
        _THEMETYPE: 'Lloji i Theme',
        _CUSTOM: 'Custom',
        _INTEGRATION: 'Integration',
        _IFRAMEEN: 'IFrame (EN)',
        _IFRAMESQ: 'IFame (SQ)',
        _LOADING_THEMES: 'Duke ngarkuar themes...',
        _IMAGE: 'Imazhi',
        _IS_PUBLIC: 'Eshtë Theme publike',
        _NO_PUBLIC: 'Jo Publike',
        _PUBLIC: 'Publike',
        _CLICK_OR_DRAG_TO_UPLOAD_IMAGE: 'Kliko ose drag për të ngarkuar një imazh',
        _FILE_TOO_LARGE: 'Madhësia e imazhit është më e madhe se limiti',
        _FILE_TOO_LARGE_PHOTO: 'Madhësia e imazhit është më e madhe se limiti. Ngarkoni nje foto me te vogel sesa 1MB',
        _UNIQUE_KEY: 'Key duhet të jetë unik',
      },
      _MANAGE_INSTITUTIONS_USERS: {
        _MANAGE_INSTITUTION_USERS: 'Përdoruesit e institucionit',
        _ID: 'ID',
        _INSTITUTIONS: 'Institucionet',
        _ASSIGN_USER: 'Shtoni përdorues',
        _REMOVE_BTN: 'Hiqni',
        _FIELD_REQUIRED: 'Kjo fushë është e detyrueshme',
        _REMOVE_INSTITUTION_USER_CONFIRM: 'Jeni i sigurt që doni të hiqni këtë përdorues nga institucioni?',
        _ACTION: 'Veprimi',
        _ROLE: 'Roli',
        _EMAIL: 'Përdoruesi',
        _DIRECTOR: 'Drejtori',
        _NORMAL: 'Normal',
        _NO_USERS: 'Nuk ka përdorues institucioni!',
      },
      _MANAGE_USERS: {
        _ID: 'ID',
        _TITLE: 'Menaxho Përdoruesit',
        _INVITE_USER: 'Fto Përdorues',
        _UPDATE_USER: 'Përditëso Përdoruesin',
        _EMAIL: 'Email',
        _PHONENUMBER: 'Numri i telefonit',
        _ROLE: 'Roli',
        _EMAIL_NOT_VALID: 'Shkruani një email të vlefshëm',
        _EMAIL_REQUIRED: 'Ju lutem, fusni emailin!',
        _PHONE_REQUIRED: 'Ju lutem, fusni numrin e  telefonit!',
        _PHONE_NOT_VALID: 'Ju lutem, fusni një numër telefoni të vlefshëm! ',
        _ROLE_REQUIRED: 'Ju lutem, zgjidhni rolin tuaj! ',
        _INVITE_USER_BTN: 'Fton Përdorues ',
        _UPDATE_USER_BTN: 'Përditëso Përdorues',
        _INSTITUTION_USER: 'Përdorues Institucioni ',
        _ADMIN: 'Admin',
        _TECHNICAL: 'Teknik',
        _NORMAL: 'Normal',
        _UPDATE_BTN: 'Përditëso',
        _DELETE_BTN: 'Fshi',
        _DELETE_USER_CONFIRM: 'Jeni i sigurtë që doni ta fshini këtë përdorues?',
        _STATUS: 'Statusi',
        _RESEND_INVITE: 'Ridërgo Ftesën',
        _ACTIVE: 'Aktiv',
        _PENDING: 'Në pritje',
        _ALERT_SUCCESS_MESSAGE: 'Ftesa u dërgua',
        _ALERT_ERROR_MESSAGE: 'Dicka shkoi gabim!',
        _LOADING_USERS: 'Duke ngarkuar përdoruesit...',
        _ACTION: 'Veprimi',
        _ERROR_EMAIL: 'Emaili ekziston në platformë!',
      },
      _MANAGE_SUBMODULES: {
        _NAME_MAX_LENGTH: 'Shkruani një emër më të shkurtër',
        _FIELD_REQUIRED: 'Kjo fushë është e detyrueshme',
        _NAMEEN: 'Emri (EN)',
        _NAMESQ: 'Emri (SQ)',
        _ID: 'ID',
        _SUBMODULES: 'Nënmodulet',
        _ADD_SUBMODULE_BTN: 'Shtoni një nënmodul',
        _DELETE_CONFIRM: 'Jeni i sigurtë që doni të fshini këtë nënmodul?',
        _CREATE_SUBMODULE: 'Krijoni një nënmodul',
        _UPDATE_SUBMODULE: 'Përditësoni nënmodulin',
        _SAVING: 'Duke ruajtur...',
        _ERROR: 'Gabim gjatë ruajtjes',
        _SAVE: 'Ruaj',
        _DELETE: 'Fshi',
        _UPDATE: 'Përditëso',
        _ACTION: 'Veprimi',
        _INDICATORS: 'Indikatorët',
        _LOADING_SUBMODULES: 'Duke ngarkuar nënmodulet...',
      },
      _MANAGE_INDICATORS: {
        _MANAGE_INDICATORS: 'Menaxho Indikatorët',
        _DETAILS: 'Detajet',
        _ID: 'ID',
        _KEY: 'Key',
        _INDICATOR_DETAILS: 'Detajet e indikatorit',
        _VARIABLES_MIN_LENGTH: 'Duhet të kesh së paku një variabël',
        _FREQUENCY_MIN_LENGTH: 'Duhet të përzgjedhësh së paku një frekuencë',
        _TEXT_FIELD_MAX_LENGTH: 'Shkruaj një emër më të shkurtër',
        _FIELD_REQUIRED: 'Kjo fushë është e detyrueshme',
        FREQUENCY_REQUIRED: 'Fusha `frekuenca` duhet të plotësohet në fillim',
        _NAMEEN: 'Emri (EN)',
        _NAMESQ: 'Emri (SQ)',
        _NAME_EN: 'Emri (EN)',
        _NAME_SQ: 'Emri (SQ)',
        _DESCRIPTIONEN: 'Përshkrimi (EN)',
        _DESCRIPTIONSQ: 'Përshkrimi (SQ)',
        _SUBMODULE: 'Nënmoduli',
        _INSTITUTION: 'Institucioni',
        _FREQUENCY: 'Frekuenca',
        _UNIT_TYPE: 'LLOJI I NJESISE',
        _UNIT_METHOD: 'METODA E NJESISE',
        _MONTHLY: 'Mujore',
        _YEARLY: 'Vjetore',
        _QUARTERLY: 'Tremujore',
        _INT: 'INT',
        _FLOAT: 'FLOAT',
        _NUMBER: 'Numër',
        _PERCENTAGE: 'Përqindje',
        _ACC: 'ACC',
        _AVG: 'AVG',
        _NONE: 'Asnjëra',
        _CREATE_INDICATOR: 'Krijo një indikator',
        _DELETE_CONFIRM: 'Jeni i sigurtë që doni ta fshini këtë indikator?',
        _VIEW_DETAILS: 'Detajet',
        _SAVING: 'Duke ruajtur...',
        _ERROR: 'Gabim gjatë ruajtjes',
        _SAVE: 'Ruaj',
        _DELETE: 'Fshi',
        _ACTION: 'Veprimi',
        _INDICATORS: 'Menaxho indikatorët',
        _CSV_TEMPLATE: 'Model i CSV',
        _MANAGE_USERS: 'Menaxho aksesin',
        _VARIABLES: 'Variablat',
        _ANALYTICAL_CHANGE: 'Ndryshimi analitik',
        _IS_NUMBER_POSITIVE_TREND: 'Është Numri Trend Pozitiv',
        _IS_INDICATOR_PUBLIC: 'Është Indikator Publik',
        _PUBLIC: 'Publik',
        _NOT_PUBLIC: 'Jo Publik',
        _DATASET: 'Grupi i të dhënave',
        _DESCRIPTION_EN: 'PERSHKRIMI (EN)',
        _DESCRIPTION_SQ: 'PERSHKRIMI (SQ)',
        _YOY: 'YoY',
        _QOQ: 'QoQ',
        _MOM: 'MoM',
        _CREATE: 'KRIJO',
        _UPDATE: 'Përditëso',
        _POSITIVE: 'Pozitiv',
        _NEGATIVE: 'Negativ',
        _UNIT: 'NJESIA',
        _PERIODICITY: 'Periodiciteti',
        _VARIABLE_KEY: 'Key i variablit',
        _VARIABLE_NAME: 'Emri i variablit',
        _VARIABLE_OPTIONS: 'Opsionet e variablit',
        _LOADING_INDICATORS: 'Duke ngarkuar indikatorët...',
        _MANAGE_VARIABLES: 'Menaxho variablat',
        _VARIABLES_SAVED: 'Variablat u ruajtën me sukses!',
        _ERROR_KEY: 'Key duhet të jetë unik!',
        _CREATED_INDICATOR_SUCCESS: 'Indikatori u krijua me sukses!',
        _UPDATED_INDICATOR_SUCCESS: 'Indikatori u përditësua me sukses!',
        _INDICATOR_DELETED: 'Indikatori u fshi!',
        _SUCESSFULLY_CREATED: 'Indikatori u krijua me sukses!',
        _DEFAULT_FREQUENCY: 'Frekuenca bazë',
        _DEFAULT_UNIT_TYPE: 'Njësia bazë',
        _CUMULATIVE: 'Kumulative',
        _SHARE: 'Shpërndarje',
        _RATE: 'Normë',
        _GROWTH: 'Rritje',
        UNPROCESSABLE_ENTITY_ERROR: 'Indikatori nuk mund të fshihet',
        UNPROCESSABLE_ENTITY_DESCRIPTION:
          'Ju nuk mund të fshini një indikator qe ka të dhëna. Nëse dëshironi qe ta bëni, ju lutem kontaktoni ofruesin tuaj të shërbimit.',
      },
      _MANAGE_INDICATOR_ACCESS_USERS: {
        _TITLE: 'Menaxho aksesin e përdoruesve',
        _COLUMN_ACCESS: 'Access',
        _COLUMN_INSTITUTION: 'Institution',
        _COLUMN_EMAIL: 'Email',
        _COLUMN_ROLE: 'Roli',
        _ACCESS_READ: 'Lexim',
        _ACCESS_READ_WRITE: 'Lexim + Shkrim',
        _ACCESS_NO_ACCESS: 'Nuk ka akses',
      },
      _MANAGE_THEME_ACCESS_USERS: {
        _TITLE: 'Menaxho përdoruesit që kanë sukses',
        _COLUMN_ACCESS: 'Akses',
        _COLUMN_INSTITUTION: 'Institucioni',
        _COLUMN_EMAIL: 'Email',
        _COLUMN_ROLE: 'Roli',
        _ACCESS_READ: 'Lexim',
        _ACCESS_READ_WRITE: 'Lexim + Shkrim',
        _ACCESS_NO_ACCESS: 'Nuk ka akses',
      },
      _MANAGE_INDICATOR_VALUES: {
        _FILE_PROCESSED_SUCCESSFULLY: 'Dokumenti po procesohet!',
        _SAMPLE_XLSX: 'Shkarko një shembull XLSX',
        _SYNC_LAST_VALUES: 'Rifresko vlerat e indikatoreve',
        _ID: 'ID',
        _CHECK_UPLOAD_PROGRESS: 'Shiko progresin e ngarkimit të dokumentit',
        _CLICK_OR_DRAG_TO_UPLOAD: 'Kliko ose drag për të ngarkuar CSV',
        _INITIATE_FILE_PROCESSING: 'Proceso dokumentin',
        _ABOUT_TO_UPLOAD: "Jeni gati për të ngarkuar dokumentin! You're about to upload",
        _FILE_NOT_FOUND: 'Nuk u gjet asnjë dokument. Jeni i sigurtë që keni ngarkuar një dokument më parë?',
        _ROWS: 'Rreshtat',
        _UPLOAD_A_CSV_FILE: 'Ngarko një dokument CSV',
        _UPDATEDAT: 'Përditësimi i fundit',
        _DOWNLOAD_DATA: 'Shkarko të dhënat',
        _UPLOAD_CSV: 'Ngarko CSV',
        _ACTION: 'Veprimi',
        _NAMEEN: 'Emri (EN)',
        _NAMESQ: 'Emri (SQ)',
        _VIEW_JOBS: 'Shih Jobs',
        _FILE_TOO_LARGE: 'Madhesia e dokumentit duhet me e vogel sesa 100MB!',
        _INDICATOR_VALUES: 'Menaxho Vlerat e Indikatorëve',
        _KEY: 'Key i indikatorit',
        _UPLOADS: 'Ngarkimet',
        _PROCESSEDROWS: 'Rreshta të procesuar',
        _SUCCESS: 'Sukses',
        _STATUS: 'Statusi',
        _CREATEDBY: 'Krijuar nga',
        _INDICATORKEY: 'Key i indikatorit',
        _FILENAME: 'Emri i dokumentit',
        _JOBSTART: 'Fillimi i Job',
        _JOBEND: 'Koha e përfundimit të Job',
        _LOADING_INDICATOR_VALUES: 'Duke ngarkuar vlerat e indikatorit...',
        _NO_UPLOADS: 'Nuk ka dokumente të ngarkuar në gjendje!',
        _UPLOAD_PROGRESS: 'Ngarkimi akoma në progres',
        _FAILED_PROCESSING: 'Procesimi dështoi në rreshtin me numër ',
        _FAILED: 'Nuk ka sukses!',
      },
      _MANAGE_MODULES: {
        _NAME_MAX_LENGTH: 'Shkruani një emër më të shkurtër',
        _FIELD_REQUIRED: 'Kjo fushë është e detyrueshme',

        _NAMEEN: 'Emri (EN)',
        _NAMESQ: 'Emri (SQ)',
        _ADD_MODULE_BTN: 'Shto një modul',
        _MODULES: 'Menaxho modulet',
        _DELETE_CONFIRM: 'Jeni i sigurtë që doni të fshini këtë modul?',
        _CREATE_MODULE: 'Krijoni një modul',
        _UPDATE_MODULE: 'Përditësoni modulin',
        _SAVING: 'Duke ruajtur...',
        _ERROR: 'Gabim gjatë ruajtjes',
        _SAVE: 'Ruaj',
        _DELETE: 'Fshij',
        _UPDATE: 'Përditëso',
        _ACTION: 'Veprimi',
        _ID: 'ID',
        _SUBMODULES: 'Nënmodulet',
        _SUBMODULES_FORBIDDEN_DELETION_ALERT: 'Për të fshirë këtë modul, duhet të fshini nënmodulet e tij më para!',
      },
      _MANAGE_INSTITUTIONS: {
        _TITLE: 'Menaxho Institutet',
        _CREATE_INSTITUTION: 'Krijo Institucion',
        _NAMEEN: 'Emri (EN)',
        _NAMESQ: 'Emri (SQ)',
        _ACRONYMEN: 'Akronimi (EN)',
        _ACRONYMSQ: 'Akronimi (SQ)',
        _URL: 'URL',
        _NAME_EN_REQUIRED: 'Ju lutem, fusni emrin në Anglisht!',
        _NAME_SQ_REQUIRED: ' Ju lutem, fusni emrin Shqip!',
        _ACRONYM_EN_REQUIRED: 'Ju lutem, fusni akronimin në Anglisht!',
        _ACRONYM_SQ_REQUIRED: 'Ju lutem, fusni akronimin Shqip!',
        _ACTIONS: 'Veprimet',
        _UPDATE_BTN: 'Përditëso',
        _DELETE_BTN: 'Fshij',
        _MANAGE_INSTITUTION_USERS: 'Përdoruesit e institucionit',
        _ASSIGN_USER: 'Shto përdorues',
        _UPDATE_INSTITUTION_BTN: 'Përditëso Institucionin',
        _DELETE_INSTITUTION_CONFIRM: 'Jeni i sigurtë që doni ta fshini këtë institucion?',
        _CORRECT_URL: 'Shkruani URL-n e saktë!',
        _ENTER_WEBSITE: 'Ju lutem, shkruani website-n',
      },
      _MANAGE_PUBLICATIONS: {
        _NAME_MAX_LENGTH: 'Shkruani një emër më të shkurtër',
        _FIELD_REQUIRED: 'Kjo fushë është e detyrueshme',
        _FILE_REQUIRED: 'Ju duhet të ngarkoni një dokument',
        _FILE_SIZE: 'Madhësia e dokumentit',
        _FILE_TOO_LARGE: 'Madhësia e dokumentit është e madhe! Ngarkoni një dokument më të vogël sesa 10MB!',
        _FILE_TOO_LARGE_PHOTO: 'Madhësia e dokumentit është e madhe! Ngarkoni një foto më të vogël sesa 1MB!.',
        _FILE_TYPE: 'Lloji i dokumentit',
        _CREATE_PUBLICATION: 'Krijoni publikim',
        _UNSUPPORTED_FILE_FORMAT: 'Formati i dokumentit nuk suportohet',
        _NAMEEN: 'Emri (EN)',
        _NAMESQ: 'Emri (SQ)',
        _AUTHOR: 'Autori',
        _ADD_PUBLICATION_BTN: 'Shto një publikim',
        _PUBLICATIONS: 'Menaxho Publikimet',
        _THUMBNAIL: 'Thumbnail',
        _LANG: 'Gjuha',
        _ENGLISH: 'Anglisht',
        _ALBANIAN: 'Shqip',
        _DELETE_CONFIRM: 'Jeni i sigurtë që doni ta fshini këtë publikim?',
        _UPDATE_PUBLICATION: 'Përditësoni publikimin',
        _SAVING: 'Duke ruajtur...',
        _ERROR: 'Gabim gjatë ruajtjes',
        _SAVE: 'Ruaj',
        _TYPE: 'Lloji',
        _DELETE: 'Fshij',
        _UPDATE: 'Përditëso',
        _ACTION: 'Veprimi',
        _ID: 'ID',
        _LINK: 'Dokumenti',
        _FILE: 'Linku',
        _FILE_LINK: 'Linku i dokumentit',
        _THUMBNAIL_LINK: 'Linku i Thumbnail',
        _DATE: 'Data',
        _PUBLISHINGDATE: 'Data e publikimit',
        _MODULE: 'Moduli',
        _TYPE_BOOKLET: 'Booklet',
        _TYPE_BROCHURE: 'Broshurë',
        _TYPE_CASE_STUDY: 'Rast studimi',
        _TYPE_FACE_FACTS: 'Face Facts',
        _TYPE_GUIDANCE_NOTE: 'Shënime udhëzuese',
        _TYPE_ISSUE_BRIEF: 'Issue brief',
        _TYPE_LESSONS_LEARNED: 'Mësimet e nxjerra Përvoja krahasuese',
        _TYPE_NEWSLETTER: 'Gazeta',
        _TYPE_POLICY_BRIEF: 'Përmbledhje e politikave',
        _TYPE_REPORT: 'Raport',
        _TYPE_RESEARCH_DISCUSSION_PAPERS: 'Research Discussion papers',
        _TYPE_LESSONS_LEARNED_COMPARATIVE_EXPERIENCE: 'Mësimet e nxjerra Përvoja krahasuese',
        _TYPE_STRATEGY: 'Strategjia',
        _TYPE_TOOLKIT_HOW_TO_MANUAL: 'Toolkit how-to manual',
        _CLICK_OR_DRAG_TO_UPLOAD_PDF: 'Kliko ose drag dokumentin PDF për ta ngarkuar',
        _CLICK_OR_DRAG_TO_UPLOAD_THUMBNAIL: 'Kliko ose drag imazhin për ta ngarkuar',
        _CLICK_OR_DRAG_TO_UPDATE_PDF: 'Kliko ose drag dokumentin PDF për ta perditesuar dokumentin aktual',
        _CLICK_OR_DRAG_TO_UPDATE_THUMBNAIL: 'Kliko ose drag imazhin për te perditesuar imazhin aktual',
        _UPLOAD_FAILED: 'Ngarkimi i dokumentit dështoi',
        _UPLOADED_FILE_URL: 'URL-ja e dokumentit të ngarkuar',
        _NO_PUBLICATIONS: 'Nuk ka publikime për të treguar!',
        _CREATED: 'Publikimi u krijua me sukses!',
        _UPDATED: 'Publikimi u perditesua me sukses!',
      },
      _MANAGE_ACCOUNT: {
        _TITLE: 'Menaxho llogarinë',
        _ACCOUNT_DETAILS: {
          _TITLE: 'Detajet e llogarisë',
          _YOUR_INSTITUTIONS: 'Institucionet e tuaja',
          _NO_INSTITUTIONS_FOUND: 'Nuk u gjetën institucione',
          _ERROR_GETTING_DATA: 'Oops! Dicka shkoi gabim gjatë ngarkimit të të dhënave. Provoni përsëri më vonë!',
          _PHONE: 'Telefoni',
          _ROLE: 'Roli',
        },
        _CHANGE_PASSWORD: {
          _TITLE: 'Ndrysho fjalëkalimin',
          _CURRENT_PASSWORD: 'Fjalëkalimi aktual',
          _NEW_PASSWORD: 'Fjalëkalimi i ri',
          _CONFIRM_PASSWORD: 'Konfirmoni fjalëkalimin',
          _CHANGING_PASSWORD: 'Duke ndryshuar fjalëkalimin...',
          _REQUIRED_MESSAGE: 'Kjo fushë është e detyrueshme',
          _PASSWORDS_DONT_MATCH: `Fjalëkalimet nuk përputhen`,
          _PASSWORD_IS_INCORRECT: 'Fjalëkalimi aktual është i pasaktë',
          _ERROR_CHANGING_PASSWORD: 'Dicka shkoi gabim gjatë ndryshimit të fjalëkalimit!',
          _PASSWORD_CHANGED_SUCCESSFULLY: 'Fjalëkalimi u ndryshua me sukses!',
        },
      },
    },
    _USER: {
      _ROLE: {
        _ADMIN: 'Admin',
        _TECHNICAL: 'Teknik',
        _INSTITUTION_USER: 'Përdorues Institucioni',
        _NORMAL: 'Normal',
      },
    },
    _COMMON: {
      _CHART_CARD: 'Vetëpunësimi',
      _EXPLORE_BTN: 'Eksploro të dhënat',
      _VIEW_ALL: 'Shiko të gjitha',
      _FILTERS: 'Filtrat',
      _CATEGORY: 'Kategoria',
      _EMPLOYMENT: 'Punësimi',
      _DATA: 'Të dhëna',
      _LOADING: 'Duke u ngarkuar...',
      _ERROR: 'Diçka shkoi gabim. Provo përsëri!',
      _LOADING_KEY_DATA: 'Duke ngarkuar të dhënat kryesore...',
      _NO_DATA: 'Nuk ka të dhëna',
      _WRONG_PAGE: 'Faqja e gabuar!',
      _DATA_NOT_AVAILABLE: 'Nuk ka të dhëna! :(',
      _SHARE: 'Shpërndaj',
      _THEMES: 'Temat',
      _DOWNLOAD: 'Shkarko',
      _INDICATOR_PREVIEW: 'Preview i indikatorit',
      _DONE: 'Perfundo',
      _FILTER: 'Filtro',
      _NO_DATA_FOUND: 'Nuk u gjetën të dhëna!',
      _RESET: 'Pastro',
      _DOWNLOAD_DATA: 'Shkarko të dhëna',
      _IN_NUMBER: 'në numër',
      _IN_PERCENTAGE: 'në normë',
      _NUMBER: 'Numri',
      _PERCENTAGE: 'Norma',
      _HOME: 'Faqja kryesore',
      _NUMBER_TEXT: 'Numër',
    },
    _THEMES: {
      _ALL_THEMES: 'Të gjitha temat',
      _ALL_STRATEGIES: 'Të gjitha strategjitë',
      _THEME_SUBHEADER_DESC: 'Lorem ipsum dolore',
      _FOOTNOTE: 'Footnote',
      _INSTITUTION: 'Institution',
      _DATA_SOURCE: 'Burimi i të dhënave',
      _NO_AVAILABLE: 'Nuk ka në gjendje',
      _SHARE: 'Shpërndaj',
      _HOME: 'Faqja Kryesore',
      _TITLE: 'Titulli',
      _DESCRIPTION: 'Pershkrimi',
      _UNDP: 'UNITED NATIONS DEVELOPMENT PROGRAMME',
      _EDT: 'ECONOMIC DEVELOPMENT TRACKER',
      _INDICATOR_PREVIEW: 'Indicator Preview',
      _CHART_CARD: 'Self Employment',
      _EXPLORE_BTN: 'Explore Data',
      _VIEW_ALL: 'View all',
      _FILTERS: 'Filters',
      _CATEGORY: 'Category',
      _EMPLOYMENT: 'Employment',
      _DATA: 'Data',
      _LOADING: 'Loading',
      _ERROR: 'Something went wrong! Try again!',
      _LOADING_KEY_DATA: 'Loading Key Data',
      _NO_DATA: 'No data',
      _WRONG_PAGE: 'Wrong page!',
      _DATA_NOT_AVAILABLE: 'Data is not available :(',
      _THEMES: 'Themes',
    },
    _EXPLORER: {
      _DATA: 'Eksplorimi i të dhënave',
      _NO_SUBMODULES: 'Nuk ka nënkategori për këtë kategori',
      _SUBTITLE: 'Lorem ipsum dolore',
      _ADD: 'Shto grafik për indikator',
      _RATE_OF: 'Norma e',
      _SHARE_OF: 'Shpërndarja e',
    },
    _REPORTS: {
      _REPORTS: 'Raportet',
      _FILTERS: 'Filtrat',
      _TYPE: 'Lloji i dokumentit',
      _LANG: 'Gjuha',
      _SORT: 'Rendit sipas',
      _BROCHURE: 'Broshurë',
      _CASE_STUDY: 'Rast studimi',
      _FACE_FACTS: 'Face facts',
      _BOOKLET: 'Broshurë',
      _GUIDANCE_NOTE: 'Shënim udhëzues',
      _ISSUE_BRIEF: 'Issue brief',
      _LESSONS_LEARNED_COMPARATIVE_EXPERIENCE: 'Lessons learned Comparative experience',
      _POLICY_BRIEF: 'Përmbledhje e politikave',
      _STRATEGY: 'Strategjia',
      _REPORT: 'Raporti',
      _RESEARCH_DISCUSSION_PAPERS: 'Punime për diskutim kërkimor',
      _NEWSLETTER: 'Buletini',
      _TOOLKIT_HOWTO_MANUAL: 'Toolkit how-to manual',
      _DATE: 'Data e publikimit',
      _RELEVANCE: 'Më të përshtatshëm',
      _ENGLISH: 'Anglisht',
      _ALBANIAN: 'Shqip',
      _SUBTITLE: 'Raportet',
      _POLICY_BRIEF_REPORT: 'Policy brief report',
      _REPORT_DESC: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry',
    },
    _INDICATORS: {
      _INDICATORS: 'Indikatorët',
      _ADD_INDICATORS: 'Shto indikatorë',
      _LOADING_INDICATOR: 'Duke ngarkuar indikatorin',
      _NO_INDICATORS: 'Nuk ka indikatorë në dispozicion për këtë përzgjedhje',
      _RELATED_INDICATORS: 'Indikatorë të ngjashëm',
      _EMPLOYMENT: 'Punësimi',
      _BY: 'nga',
      _DOWNLOAD_DATA: 'Shkarko të dhëna',
      _SUBHEADER_DESC:
        'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt utlaoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tationullamcorper suscipit lobortis nisl ut aliquip ex ea comm lorem ipsum dolor sit amet,.',
      _VISIT_EDT: 'Vizito',
      _LAST_UPDATED: 'Përditësimi i fundit',
      _ALL: 'Të gjithë indikatorët',
      _SEARCH_INDICATOR: 'Kërko një indikator',
      _BY_REGION: 'Sipas rajonit',
      _NO_INSTITUTIONS_INDICATORS: 'Nuk ka indicaktorë për këtë institucion!',
      _SOURCE: 'Burimi',
    },
    _FILTERS: {
      _FREQUENCY: 'Frekuenca',
      _MONTH: 'Mujore',
      _QUARTER: 'Tremujore',
      _YEAR: 'Vjetore',
      _UNIT_TYPE: 'Lloji i njësisë',
      _CUMULATIVE: 'Kumulative',
      _SHARE: 'Pjesa',
      _RATE: 'Norma',
      _GROWTH: 'Rritja në përqindje',
      _TIME_RANGE: 'Shtrirja kohore',
      _NO_FILTERS: 'Nuk ka filtra në dispozicion',
      _START_YEAR: 'Viti i parë',
      _END_YEAR: 'Viti i fundit',
    },
    _RELATED_METRICS: {
      _METRICS: 'Të dhëna të ngjashme',
      _REPORTS: 'Raporte të ngjashme',
      _THEMES: 'Themes të ngjashme',
      _NO_INDICATORS: 'Mungojnë indikatorët :(',
      _NO_REPORTS: 'Mungojnë raportet :(',
      _NO_THEMES: 'Mungojnë themes :(',
    },
    _EMPLOYMENT_GLANCE: {
      _TITLE: 'Employment at a glance',
      _THEMES: 'Temat',
      _THEME: 'Tema',
      _HOME: 'Faqja Kryesore',
      _CURRENT: 'Aktual',
      _BASELINE: 'Bazë',
      _COMPARE: 'Krahasim',
      _UNIT: 'Njësia',
      _CHANGE: 'Ndryshimi',
    },
    _NESS: {
      _ACRONYM: 'NESS',
      _INDICATOR_NAME: 'Emri i indikatorit',
      _BASELINE: 'Bazë',
      _TARGET: 'Targeti',
      _CURRENT: 'Aktual',
      _PROGRESS: 'Progresi',
      _YEAR: 'Viti',
      _STATUS: 'Statusi',
      _TITLE: 'Strategjia Kombetare e Punesimit dhe Aftesive',
      _NO_DATA: 'Nuk ka të dhëna!',
    },
    _BRIEFS: {
      _ADD_NEW_INDICATOR: 'Shto një indikator',
      _SUBMIT: 'Dërgo',
      _MY_BRIEFS: 'Brief-et e mia',
      _ADD_BRIEF: 'Shto Brief',
      _CREATING_BRIEFS: 'Duke krijuar Brief-in',
      _THEME: 'Theme',
      _FREQUENCY: 'Frekuenca',
      _YEARLY: 'Vjetore',
      _DATE_GENERATED: 'Data e gjenerimit',
      _NO_BRIEFS: 'Nuk ka brief të indikatorëve të shtuar akoma!',
      _MODULE_LOADING: 'Duke ngarkuar modulet',
      _NO_MODULES: 'Nuk ka module!',
      _DELETE: 'Fshi',
      _DELETED: 'U fshi!',
      _DOWNLOAD: 'Shkarko',
      _DELETED_SUCCESSFULLY: 'Briefi u fshi me sukses!',
      _CREATE_STORYLINE: 'Shtoni një koment (opsionale)',
      _NO_ALL_BRIEFS: 'Nuk ka Brief të shtuara',
      _ALL_BRIEFS: 'Të gjithë Brief',
      _LIST_ALL_BRIEFS: 'Lista e të gjithë Briefeve',
      _ERROR_CREATING_BRIEF: 'Gabim gjatë krijimit të Briefit',
      _INDICATOR_BRIEF_MISSING: 'Ju lutem, shtoni së paku një indikator për të krijuar një Brief!',
      _SUCCESS: 'Sukses!',
      _SUCCESS_MESSAGE: 'Briefi u krijua me sukses!',
      _ARE_YOU_SURE_DELETE: 'Jeni i sigurtë që doni ta fshini këtë Brief?',
    },
    _SEARCH: {
      _THEME: 'Theme',
      _KEY_INDICATOR: 'Indikator kryesor',
      _INSTITUTION: 'Institucion',
      _SUBCATEGORY: 'Nënkategori',
      _MODULE: 'Modul',
      _INDICATOR: 'Indikator',
      _PUBLICATION: 'Publikim',
      _SEE_ALL: 'Shihni të gjitha rezultatet',
      _SEARCH_FOR: 'Kërkimi për ',
      _NO_RESULTS: 'Nuk ka rezultate',
      _SEARCH_DATA: 'Kërko për të dhëna',
    },
  },
};
