import Flex from 'common/components/Flex/Flex';
import styled from 'styled-components';

const TitleBar = styled(Flex)`
  margin-bottom: 15px;
`;

const Title = styled.span`
  font-size: 20px;
  font-weight: bold;
`;

export { TitleBar, Title };
