import React, { useState } from 'react';
import { deleteSubmodule } from 'api/submodule';
import { useMutation, useQuery } from 'react-query';
import Table from 'common/components/Table/Table';
import { Breadcrumb, Button, Modal, Popconfirm, Row, Space } from 'antd';
import SubmoduleCreate from '../ManageSubmodules/SubmoduleCreate';
import SubmoduleUpdate from './SubmoduleUpdate';
import Submodule from 'models/submodule';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { translationBasePath } from './SubmoduleValidationForm';
import I18nLink from 'common/components/I18nLink';
import { getCurrentLng } from 'utils/helper';
import { ColumnProps } from 'antd/lib/table';
import { getSingleModule } from 'api/public';

interface RouterParams {
  moduleId: string;
}

const Submodules: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const [updating, setUpdating] = useState<Submodule | null>();
  const { moduleId } = useParams<RouterParams>();
  const parsed = parseInt(moduleId);
  const currentLanguage = getCurrentLng();
  const nameLngKey = `name${currentLanguage}`;
  const deleteMutation = useMutation(deleteSubmodule);
  const {
    data: moduleData,
    isLoading,
    refetch,
  } = useQuery(['module', moduleId], () => getSingleModule(parseInt(moduleId)));

  let submoduleForm;
  if (updating) {
    updating.moduleId = parsed;
    submoduleForm = <SubmoduleUpdate submodule={updating} onClose={() => setModalOpen(false)} refetch={refetch} />;
  } else {
    submoduleForm = (
      <SubmoduleCreate submodule={{ moduleId: parsed }} onClose={() => setModalOpen(false)} refetch={refetch} />
    );
  }

  const modal = modalOpen && (
    <Modal
      maskClosable={false}
      footer={null}
      visible
      title={updating ? t(`${translationBasePath}._UPDATE_SUBMODULE`) : t(`${translationBasePath}._CREATE_SUBMODULE`)}
      onCancel={() => {
        setModalOpen(false);
        setUpdating(null);
      }}>
      {submoduleForm}
    </Modal>
  );

  const columns: ColumnProps<any>[] = [
    {
      key: nameLngKey,
      title: t(`${translationBasePath}._${nameLngKey.toUpperCase()}`),
      dataIndex: nameLngKey,
      render: (text: string, _record: any) => <span>{text}</span>,
    },
    {
      key: 'indicators',
      title: t(`${translationBasePath}._INDICATORS`),
      dataIndex: 'indicators',
      render: (_text: string, record: any) => <span>{record.indicators?.length || 0}</span>,
    },
    {
      title: t(`${translationBasePath}._ACTION`),
      key: 'action',
      dataIndex: 'action',
      render: (_text, record) => (
        <Space size="middle">
          <Button
            onClick={() => {
              setModalOpen(true);
              setUpdating(record);
            }}
            style={{ fontSize: '15px' }}
            type="link"
            shape="round">
            {t(`${translationBasePath}._UPDATE`)}
          </Button>
          <Popconfirm
            title={t(`${translationBasePath}._DELETE_CONFIRM`)}
            onConfirm={() => {
              deleteMutation
                .mutateAsync(record.id)
                .then(() => refetch())
                .catch((err) => console.log(err));
            }}>
            <Button type="link" shape="round" danger style={{ fontSize: '15px' }}>
              {t(`${translationBasePath}._DELETE`)}
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <Row justify="space-between" style={{ marginBottom: 10 }}>
        <Breadcrumb style={{ fontSize: 20, fontWeight: 'bold' }}>
          <Breadcrumb.Item>
            <I18nLink to="/dashboard/modules">{t('_DASHBOARD._MANAGE_MODULES._MODULES')}</I18nLink>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{moduleData ? moduleData[nameLngKey] : ''} </Breadcrumb.Item>{' '}
          <Breadcrumb.Item> {t(`${translationBasePath}._SUBMODULES`)}</Breadcrumb.Item>
        </Breadcrumb>
        <Button
          type="primary"
          onClick={() => {
            setModalOpen(true);
            setUpdating(null);
          }}>
          {t(`${translationBasePath}._ADD_SUBMODULE_BTN`)}
        </Button>
      </Row>
      {modal}
      <Table
        rowClassName="admin-table-row"
        loading={isLoading}
        dataSource={moduleData?.submodules}
        columns={columns}
        pagination={false}
      />
    </div>
  );
};

export default Submodules;
