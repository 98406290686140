import * as yup from 'yup';

export const translationBasePath = '_DASHBOARD._MANAGE_PUBLICATIONS';

export const validationSchema = (t) =>
  yup.object({
    nameEN: yup
      .string()
      .max(2000, t(`${translationBasePath}._NAME_MAX_LENGTH`))
      .required(t(`${translationBasePath}._FIELD_REQUIRED`)),
    nameSQ: yup
      .string()
      .max(2000, t(`${translationBasePath}._NAME_MAX_LENGTH`))
      .required(t(`${translationBasePath}._FIELD_REQUIRED`)),
    author: yup
      .string()
      .max(2000, t(`${translationBasePath}._NAME_MAX_LENGTH`))
      .required(t(`${translationBasePath}._FIELD_REQUIRED`)),
    lang: yup.string().required(t(`${translationBasePath}._FIELD_REQUIRED`)),
    date: yup.date().required(t(`${translationBasePath}._FIELD_REQUIRED`)),
    file: yup.string().required(t(`${translationBasePath}._FIELD_REQUIRED`)),
    thumbnail: yup.string().required(t(`${translationBasePath}._FIELD_REQUIRED`)),
    type: yup.string().required(t(`${translationBasePath}._FIELD_REQUIRED`)),
  });
