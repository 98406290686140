export const generateDataForCSV = (data: any, selectedValue: string) => {
  if (data) {
    let arr = [] as any;
    const processedData = data.map((dt) => {
      const { nameEN: submoduleName } = dt;
      const indicatorData = dt.indicators?.map(
        ({
          difference,
          id,
          is_positive_number_positive_trend,
          nameSQ,
          name_en,
          name_sq,
          submodule_id,
          unit_type,
          compare_to_year,
          baseline_year,
          baseline_value,
          compare_value,
          key,
          nameEN,
          ...rest
        }) => {
          return {
            Theme: selectedValue,
            Subtheme: submoduleName,
            'Indicator name': nameEN,
            ...rest,
            'baseline value': baseline_value,
            'compare value': compare_value,
            'baseline year': parseInt(baseline_year),
            'compare to year': parseInt(compare_to_year),
          };
        }
      );
      return {
        ...indicatorData,
      };
    });
    processedData?.map((object) => {
      for (let item in object) {
        arr.push(object[item]);
      }
    });

    return arr;
  }
  return;
};
