import { apiUrl, post } from 'utils/apiMgmt';

export const uploadFileUrl: string = apiUrl + '/file-upload/uploadFileToS3';

export const deleteFile = async ({ fileUrl }: { fileUrl: any }): Promise<void> => {
  try {
    await post(apiUrl + '/file-upload/deleteFileFromS3', { fileUrl });
  } catch (err) {
    console.error(err);
  }
};
