import { Spin } from 'antd';
import { getInstitutions, getPublicIndicators } from 'api/public';
import { getAllSubmodules } from 'api/submodule';
import i18next from 'i18next';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { getCurrentLng, indicatorsToHide } from 'utils/helper';
import { InsitutionIndicatorsCategorized, organizeIndicatorsindicators } from '.';
import InstitutionSubheader from './InstitutionSubheader';

const InstitutionAllIndicators = () => {
  const { data: institutions, isLoading: loadingInstitutions } = useQuery('allInstitutions', getInstitutions);
  const { data: allIndicators, isLoading: loadingIndicators } = useQuery('indicators', getPublicIndicators);
  const { id, submoduleId } = useParams<{ id: string; submoduleId: string }>();
  const { data: submodules, isLoading: loadingSubmodules } = useQuery('submodules', getAllSubmodules);

  const lang = getCurrentLng();
  const institution = institutions?.find((inst) => inst.id === parseInt(id));
  const submodule = submodules?.find((submodule) => submodule.id === parseInt(submoduleId));

  const institutionIndicators = allIndicators
    ?.filter((indicator) => {
      return indicatorsToHide.every((key) => key !== indicator.key);
    })
    .filter((ind) => ind.submoduleId === parseInt(submoduleId))
    .filter((ind) => ind.institutionId === institution?.id);

  const organizedIndicators = organizeIndicatorsindicators(institutionIndicators, submodules);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { t } = useTranslation();

  if (loadingIndicators || loadingInstitutions || loadingSubmodules) {
    return (
      <div style={{ padding: '3.5rem', display: 'grid', placeContent: 'center', height: 300 }}>
        <Spin size="large" />
        {i18next.t('_COMMON._LOADING')}
      </div>
    );
  }

  return (
    <section className="institution-container">
      {institution && <InstitutionSubheader institution={institution} submoduleName={submodule?.[`name${lang}`]} />}
      {institutionIndicators &&
        allIndicators &&
        submodules &&
        organizedIndicators &&
        organizedIndicators &&
        institution &&
        organizedIndicators.map((item) => {
          return (
            <InsitutionIndicatorsCategorized
              subcategory={item?.submodule}
              indicators={item?.allIndicators}
              showAll
              institution={institution}
            />
          );
        })}
      {organizedIndicators && organizedIndicators.length === 0 && (
        <p className="no-ind">
          <h4>{t('_INDICATORS._NO_INDICATORS')}!</h4>
        </p>
      )}
      {/* {allIndicators && institutionIndicators && <InstitutionIndicators indicators={institutionIndicators} />} */}
    </section>
  );
};

export default InstitutionAllIndicators;
