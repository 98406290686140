export const applyFilter = (publications, filters) => {
  if (publications && publications.length) {
    if (filters.type.length) {
      publications = publications.filter((pub) => filters.type.includes(pub.type));
    }
    if (filters.lang.length) {
      publications = publications.filter((pub) => filters.lang.includes(pub.lang));
    }

    if (filters.sort.length) {
      if (filters.sort.includes('date')) {
        publications = publications.sort((a, b) => {
          return new Date(b.date).getTime() - new Date(a.date).getTime();
        });
      }
      if (filters.sort.includes('relevance')) {
        publications = publications.sort((a, b) => {
          return b.moduleId - a.moduleId;
        });
      }
    }
  }
  return publications;
};

export const translationBasePath = '_REPORTS';
