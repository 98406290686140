import { getEmploymentGlanceData } from 'api/public';
import { Spin } from 'antd';
import i18next from 'i18next';
import React, { useEffect } from 'react';
import { getCurrentLng } from 'utils/helper';
import { useQuery } from 'react-query';
import SubcategoryData from './SubcategoryData';

interface GlanceProps {
  currentYear: string;
  baselineYear: string;
  setCsvData: any;
}

const GlanceData: React.FunctionComponent<GlanceProps> = ({ currentYear, baselineYear, setCsvData }) => {
  const lang = getCurrentLng();
  const { data, isLoading, isError } = useQuery(['employmentGlanceData', baselineYear, currentYear], () =>
    getEmploymentGlanceData(baselineYear, currentYear)
  );
  const submodulesWithData = data?.filter((dt) => dt.indicators.length > 0);

  useEffect(() => {
    setCsvData(submodulesWithData);
  }, [data]);

  if (isLoading) {
    return (
      <div style={{ height: '600px', display: 'grid', placeContent: 'center' }}>
        <Spin />
      </div>
    );
  }

  if (isError) {
    return <div style={{ height: '200px', display: 'grid', placeContent: 'center' }}>{i18next.t('_COMMON._DATA')}</div>;
  }
  return (
    <div className="glance-container">
      {data?.map((subcategory) => {
        if (subcategory.indicators.length !== 0) {
          return (
            <SubcategoryData
              subcategoryName={subcategory[`name${lang}`]}
              indicators={subcategory.indicators}
              currentYear={currentYear}
              baselineYear={baselineYear}
            />
          );
        }
        return null;
      })}
    </div>
  );
};

export default GlanceData;
