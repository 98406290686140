export enum TranslationKeys {
  manageThemes = '_MANAGE_THEMES',
  createTheme = '_CREATE_THEME',
  updateTheme = '_UPDATE_THEME',
  action = '_ACTION',
  type = '_TYPE',
  update = '_UPDATE',
  preview = '_PREVIEW',
  delete = '_DELETE',
  acronymEN = '_ACRONYMEN',
  acronymSQ = '_ACRONYMSQ',
  nameEN = '_NAMEEN',
  nameSQ = '_NAMESQ',
  descriptionEN = '_DESCRIPTIONEN',
  descriptionSQ = '_DESCRIPTIONSQ',
  institution = '_INSTITUTION',
  footnoteEN = '_FOOTNOTEEN',
  footnoteSQ = '_FOOTNOTESQ',
  source = '_SOURCE',
  textFieldMaxLength = '_TEXT_FIELD_MAX_LENGTH',
  fieldRequired = '_FIELD_REQUIRED',
  themeType = '_THEMETYPE',
  custom = '_CUSTOM',
  integration = '_INTEGRATION',
  iFrameEN = '_IFRAMEEN',
  iFrameSQ = '_IFRAMESQ',
  image = '_IMAGE',
  isPublic = '_IS_PUBLIC',
  noPublic = '_NO_PUBLIC',
  Public = '_PUBLIC',
  clickToUploadImage = '_CLICK_OR_DRAG_TO_UPLOAD_IMAGE',
}

export const translationBasePath = '_DASHBOARD._MANAGE_THEMES';
